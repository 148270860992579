<template>
  <div class="advertising">
    <a-carousel :after-change="onChange" autoplay>
      <div v-for="item in list" :key="item.id">
        <a v-if="item.type == 1" class="advertising-item" target="_blank" :href="item.url">
          <img :src="item.img" alt="">
        </a>
        <div v-if="item.type == 2" class="advertising-item-html" v-html="item.content" :style="`background-image: url(${item.img});`"></div>
      </div>
    </a-carousel>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: []
    }
  },
  mounted() {
    this.api.adverts().then(res => {
      this.list = res.data.lists
    })
  },
  methods: {
    onChange(a, b, c) {
      console.log(a, b, c);
    },
  },
}
</script>
<style scoped>
.advertising {
  width: 100%;
  height: 100%;
}
.ant-carousel {
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 200px;
  /* line-height: 260px; */
  background: #364d79;
  overflow: hidden;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
.advertising-item {
  width: 100%;
  height: 100%;
  display: block;
}
.advertising-item-html {
  width: 100%;
  height: 200px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.advertising-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>