<template>
  <div class="copywriting">
    <a-form-model :model="form" layout="inline" style="margin: 10px 24px 20px 24px">
      <a-form-model-item label="关键字">
        <a-input v-model="form.keywords" />
      </a-form-model-item>
      <a-form-model-item label="创建日期">
        <a-range-picker @change="onChange" />
      </a-form-model-item>
      <a-form-model-item>
        <a-button shape="circle" icon="search" @click="$refs.table.getData()"></a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click="reset">重置</a-button>
      </a-form-model-item>
    </a-form-model>

    <Table ref="table" :request="api.recycleCopywritingLists" :columns="columns" :param="{...form}">
      <!-- <div slot="content" slot-scope="scope" v-html="scope.record.content"></div> -->
      <a-button slot="content" type="link" slot-scope="scope" @click="copywritingInfo(scope)" style="padding: 0">查看详情</a-button>
      <div class="btns" slot="action" slot-scope="scope">
        <a-space>
          <a-popconfirm
            title="将所选内容还原至原项目"
            ok-text="是"
            cancel-text="否"
            @confirm="restore(scope)">
            <a-button type="primary" size="small">还原</a-button>
          </a-popconfirm>
          <a-popconfirm
            title="永久删除所选内容，该操作不可撤销"
            ok-text="是"
            cancel-text="否"
            @confirm="del(scope)">
            <a-button type="danger" size="small">永久删除</a-button>
          </a-popconfirm>
        </a-space>
      </div>
    </Table>
    <!-- 文案弹窗 -->
    <a-modal v-model="copywritingShow" title="文案详情" @ok="copywritingShow = false">
      <div class="copywriting-content" v-html="copywritingContent"></div> 
    </a-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        keywords: '',
        start_time: '',
        end_time: ''
      },
      columns: [
        {
          title: 'id',
          dataIndex: 'id',
          width: 80,
        },
        {
          title: '文案标题',
          dataIndex: 'title',
        },
        {
          title: '文案',
          dataIndex: 'content',
          scopedSlots: { customRender: 'content' },
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' },
          width: 120
        },
      ],
      copywritingShow: false,
      copywritingContent: ''
    }
  },
  methods: {
    // 时间选择
    onChange(date, dateString) {
      console.log(date, dateString, this.date);
      this.form.start_time = dateString[0]
      this.form.end_time = dateString[1]
    },
    reset() {
      this.form = {
        keywords: '',
        start_time: '',
        end_time: ''
      }
    },
    restore(scope) {
      this.api.recycleCopywritingRestore({id: scope.record.id}).then(res => {
        if (res.code == 0) {
          this.message.success(res.message)
          this.$refs.table.getData()
        } else {
          this.message.error(res.message);
        }
      })
    },
    del(scope) {
      this.api.recycleCopywritingDel({id: scope.record.id}).then(res => {
        if (res.code == 0) {
          this.message.success(res.message)
          this.$refs.table.getData()
        } else {
          this.message.error(res.message);
        }
      })
    },
    // 文案详情
    copywritingInfo(scope) {
      this.copywritingShow = true
      this.copywritingContent = scope.record.content
    }
  }
}
</script>

<style scoped>
.edit {
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 20px;
}
.copywriting-content {
  border: 1px dashed #ccc;
  padding: 10px;
  border-radius: 5px;
}
</style>