/*
 * @Author: your name
 * @Date: 2022-03-08 09:38:34
 * @LastEditTime: 2022-10-14 17:15:14
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /qjh-xiaoxxx-cn/src/router/index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

// meta参数:
// menu 菜单名称
// right: [0, 1] 甲乙方菜单显示
// power: 权限  'any'所有可查看


import login from '../views/login/login.vue'
import auditing from '../views/login/auditing.vue'
import index from '../views/index/index.vue'
import create from '../views/create/create.vue'

import home from '../views/home/home.vue' // 首页
import project from '../views/project/list.vue' // 项目列表
import projectInfo from '../views/project/info.vue' // 项目列表-项目信息
import basic from '../views/project/info/basic.vue' // 项目列表-项目基本信息
import result from '../views/project/info/result.vue' // 项目列表-项目总结
import picture from '../views/project/info/picture.vue' // 项目列表-图片物料
import pictureInfo from '../views/project/info/pictureInfo.vue' // 项目列表-图片详情
import copywriting from '../views/project/info/copywriting.vue' // 项目列表-文案物料
import copywritingInfo from '../views/project/info/copywritingInfo.vue' // 项目列表-文案物料详情
import h5 from '../views/project/info/h5.vue' // 项目列表-h5链接
import tags from '../views/project/info/tags.vue' // 项目列表-标签管理
import channel from '../views/project/info/channel.vue' // 项目列表-渠道管理

import companyList from '../views/company/list.vue' // 公司列表
import companyInfo from '../views/company/info.vue' // 公司详情

import copyright from '../views/copyright/copyright.vue' // 版权库
import copyrightFont from '../views/copyright/copyrightFont.vue' // 字体版权
import copyrightAudio from '../views/copyright/copyrightAudio.vue' // 音频版权
import copyright3D from '../views/copyright/copyright3D.vue' // 3D版权

import recycle from '../views/recycle/recycle.vue' // 回收站
import recycleProject from '../views/recycle/recycleProject.vue' // 回收站-项目
import recyclePicture from '../views/recycle/recyclePicture.vue' // 回收站-图片素材
import recycleCopywriting from '../views/recycle/recycleCopywriting.vue' // 回收站-文案素材
import recycleH5 from '../views/recycle/recycleH5.vue' // 回收站-文案素材
import recycleFont from '../views/recycle/recycleFont.vue' // 回收站-文案素材
import recycle3D from '../views/recycle/recycle3D.vue' // 回收站-3D素材

import setup from '../views/setup/setup.vue' //设置
import capacity from '../views/setup/page/capacity.vue' // 设置-容量管理
import setupbasic from '../views/setup/page/basic.vue' // 设置-企业基本信息
import member from '../views/setup/page/member.vue' // 设置-空间成员
import partyb from '../views/setup/page/partyb.vue' // 设置-乙方公司
import personal from '../views/setup/page/personal.vue' // 设置-个人信息

import space from '../views/create/space.vue'
import notice from '../views/notice/notice.vue' //通知
import noticeProject from '../views/notice/noticeProject.vue' // 项目动态
import noticeCompany from '../views/notice/noticeCompany.vue' // 公司人员动态
import noticePlatform from '../views/notice/noticePlatform.vue' // 平台公告
import noticeDetails from '../views/notice/noticeDetails.vue' // 平台公告详情


// import knowledgeBase from '../views/knowledgebase/knowledgeBase.vue' // 知识库
import knowledgeBase from '../views/knowledgebase/knowledgeBase.vue' // 知识库

import companyKnowledgeBase from '../views/knowledgebase/companyKnowledgeBase.vue'  // 企业知识库
import personalKnowledgeBase from '../views/knowledgebase/personalKnowledgeBase.vue'  // 个人知识库

import warning from '../views/warning/warning.vue' // 警告提示页面

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    {
      name: 'login',
      path: '/login',
      component: login,
      meta: {
        right: [1, 0],
        power: 'any'
      },
    },
    {
      name: 'create',
      path: '/create',
      component: create,
      meta: {
        right: [1, 0],
        power: 'any'
      },
    },
    {
      name: 'auditing',
      path: '/auditing',
      component: auditing,
      meta: {
        right: [1, 0],
        power: 'any'
      },
    },
    {
      name: 'index',
      path: '/',
      component: index,
      redirect: '/home',
      children: [
        {
          name: 'space',
          path: '/space',
          component: space,
          meta: {
            right: [1, 0],
            power: 'any'
          },
        },
        {
          name: 'home',
          path: '/home',
          component: home,
          meta: {
            right: [1, 0],
            menu: '首页',
            power: 'any'
          },
        },
        {
          name: 'projectList',
          path: '/projectList',
          component: project,
          meta: {
            right: [1, 0],
            menu: '项目列表',
            power: 'project'
          }
        },
        {
          name: 'project',
          path: '/project',
          component: projectInfo,
          // redirect: '/basic',
          meta: {
            right: [1, 0],
            power: 'project'
          },
          children: [
            {
              name: 'basic',
              path: '/project/basic',
              component: basic,
              meta: {
                right: [1, 0],
                menu: '基本信息',
                power: 'project/basicInformation'
              }
            },
            {
              name: 'result',
              path: '/project/result',
              component: result,
              meta: {
                right: [1],
                menu: '项目总结',
                power: 'project/summary'
              }
            },
            {
              name: 'picture',
              path: '/project/picture',
              component: picture,
              meta: {
                right: [1, 0],
                menu: '图片物料',
                power: 'project/picture'
              }
            },
            {
              name: 'pictureInfo',
              path: '/project/pictureInfo',
              component: pictureInfo,
              meta: {
                right: [1, 0],
                power: 'project/picture'
              }
            },
            {
              name: 'copywriting',
              path: '/project/copywriting',
              component: copywriting,
              meta: {
                right: [1, 0],
                menu: '文案物料',
                power: 'project/copywriting'
              }
            },
            {
              name: 'copywritingInfo',
              path: '/project/copywritingInfo',
              component: copywritingInfo,
              meta: {
                right: [1, 0],
                power: 'project/copywriting'
              }
            },
            {
              name: 'h5',
              path: '/project/h5',
              component: h5,
              meta: {
                right: [1, 0],
                menu: 'H5链接',
                power: 'project/url'
              }
            },
            {
              name: 'tags',
              path: '/project/tags',
              component: tags,
              meta: {
                right: [1, 0],
                menu: '标签管理',
                power: 'project/label'
              }
            },
            {
              name: 'channel',
              path: '/project/channel',
              component: channel,
              meta: {
                right: [1, 0],
                menu: '渠道管理',
                power: 'project/channel'
              }
            },
          ]
        },
        {
          name: 'companyList',
          path: '/companyList',
          component: companyList,
          meta: {
            right: [0],
            menu: '公司列表',
            power: 'any'
          }
        },
        {
          name: 'company',
          path: '/company',
          component: companyInfo,
          children: [
            {
              name: 'companyProjectList',
              path: '/company/projectList',
              component: project,
              meta: {
                menu: '项目列表',
                right: [0],
                power: 'project'
              }
            },
            {
              name: 'companyCopyrightFont',
              path: '/company/copyrightFont',
              component: copyrightFont,
              meta: {
                right: [0],
                menu: '字体版权',
                power: 'copyright/font'
              }
            },
            {
              name: 'companyCopyright3D',
              path: '/company/copyright3D',
              component: copyright3D,
              meta: {
                right: [0],
                menu: '3D素材版权',
                power: 'copyright/material'
              }
            },
            {
              name: 'companyKnowledgebase',
              path: '/company/knowledgebase',
              component: companyKnowledgeBase,
              meta: {
                right: [0],
                menu: '知识库',
                power: 'knowledge'
              },
            },
            {
              name: 'companyRecycle',
              path: '/company/recycle',
              component: recycle,
              redirect: '/recyclePicture',
              meta: {
                right: [0],
                menu: '回收站',
                power: 'recycleBin'
              },
              children: [
                {
                  name: 'companyRecyclePicture',
                  path: '/company/recyclePicture',
                  component: recyclePicture,
                  meta: {
                    right: [1, 0],
                    menu: '图片素材',
                    power: 'recycleBin/picture'
                  },
                },
                {
                  name: 'companyRecycleCopywriting',
                  path: '/company/recycleCopywriting',
                  component: recycleCopywriting,
                  meta: {
                    right: [1, 0],
                    menu: '文案素材',
                    power: 'recycleBin/copywriting'
                  },
                },
                {
                  name: 'companyRecycleH5',
                  path: '/company/recycleH5',
                  component: recycleH5,
                  meta: {
                    right: [1, 0],
                    menu: 'H5链接',
                    power: 'recycleBin/url'
                  },
                },
                {
                  name: 'companyRecycleFont',
                  path: '/company/recycleFont',
                  component: recycleFont,
                  meta: {
                    right: [1, 0],
                    menu: '字体版权',
                    power: 'recycleBin/font'
                  },
                },
                {
                  name: 'companyRecycle3D',
                  path: '/company/recycle3D',
                  component: recycle3D,
                  meta: {
                    right: [1, 0],
                    menu: '3D素材版权',
                    power: 'recycleBin/material'
                  },
                },
              ]
            }
          ]
        },
        {
          name: 'copyright',
          path: '/copyright',
          component: copyright,
          redirect: '/copyrightFont',
          meta: {
            right: [1],
            menu: '版权库',
            power: 'copyright'
          },
          children: [
            {
              name: 'copyrightFont',
              path: '/copyrightFont',
              component: copyrightFont,
              meta: {
                right: [1, 0],
                menu: '字体版权',
                power: 'copyright/font'
              },
            },
            {
              name: 'copyright3D',
              path: '/copyright3D',
              component: copyright3D,
              meta: {
                right: [1, 0],
                menu: '3D素材版权',
                power: 'copyright/material'
              },
            },
            {
              name: 'copyrightAudio',
              path: '/copyrightAudio',
              component: copyrightAudio,
              meta: {
                right: [1, 0],
                menu: '音频版权',
                power: 'copyright/material'
              },
            },
          ]
        },
        {
          name: 'recycle',
          path: '/recycle',
          component: recycle,
          redirect: '/recycleProject',
          meta: {
            right: [1],
            menu: '回收站',
            power: 'recycleBin'
          },
          children: [
            {
              name: 'recycleProject',
              path: '/recycleProject',
              component: recycleProject,
              meta: {
                right: [1],
                menu: '项目',
                power: 'recycleBin'
              },
            },
            {
              name: 'recyclePicture',
              path: '/recyclePicture',
              component: recyclePicture,
              meta: {
                right: [1, 0],
                menu: '图片素材',
                power: 'recycleBin/picture'
              },
            },
            {
              name: 'recycleCopywriting',
              path: '/recycleCopywriting',
              component: recycleCopywriting,
              meta: {
                right: [1, 0],
                menu: '文案素材',
                power: 'recycleBin/copywriting'
              },
            },
            {
              name: 'recycleH5',
              path: '/recycleH5',
              component: recycleH5,
              meta: {
                right: [1, 0],
                menu: 'H5链接',
                power: 'recycleBin/url'
              },
            },
            {
              name: 'recycleFont',
              path: '/recycleFont',
              component: recycleFont,
              meta: {
                right: [1, 0],
                menu: '字体版权',
                power: 'recycleBin/font'
              },
            },
            {
              name: 'recycle3D',
              path: '/recycle3D',
              component: recycle3D,
              meta: {
                right: [1, 0],
                menu: '3D素材版权',
                power: 'recycleBin/material'
              },
            },
          ]
        },
        {
          name: 'notice',
          path: '/notice',
          component: notice,
          redirect: '/noticeProject',
          meta: {
            right: [1, 0],
            power: 'notice'
          },
          children: [
            {
              name: 'noticeProject',
              path: '/noticeProject',
              component: noticeProject,
              meta: {
                right: [0, 1],
                menu: '项目动态',
                power: 'notice/operation'
              },
            },
            {
              name: 'noticeCompany',
              path: '/noticeCompany',
              component: noticeCompany,
              meta: {
                right: [0, 1],
                menu: '公司人员动态',
                power: 'notice/system'
              },
            },
            {
              name: 'noticePlatform',
              path: '/noticePlatform',
              component: noticePlatform,
              meta: {
                right: [0, 1],
                menu: '平台公告',
                power: 'notice'
              },
            },
            {
              name: 'noticeDetails',
              path: '/noticeDetails',
              component: noticeDetails,
              meta: {
                right: [1, 0],
                power: 'notice'
              },
            },
          ]
        },
        {
          name: 'setup',
          path: '/setup',
          component: setup,
          redirect: '/page/personal',
          meta: {
            right: [1, 0],
            menu: '设置',
            power: 'setup'
          },
          children: [
            {
              name: 'personal',
              path: '/page/personal',
              component: personal,
              meta: {
                right: [1, 0],
                menu: '个人信息',
                power: 'setup/personal'
              }
            },
            {
              name: 'capacity',
              path: '/page/capacity',
              component: capacity,
              meta: {
                right: [1],
                menu: '容量管理',
                power: 'setup/capacity'
              }
            },
            {
              name: 'setupbasic',
              path: '/page/setupbasic',
              component: setupbasic,
              meta: {
                right: [1, 0],
                menu: '企业基本信息',
                power: 'setup/capacity'
              }
            },
            {
              name: 'member',
              path: '/page/member',
              component: member,
              meta: {
                right: [1, 0],
                menu: '成员角色',
                power: 'setup/memberRole'
              }
            },
            {
              name: 'partyb',
              path: '/page/partyb',
              component: partyb,
              meta: {
                right: [1, 0],
                menu: '关联信息',
                power: 'setup/relates'
              }
            },
          ]
        },
        {
          name: 'knowledgebase',
          path: '/knowledgebase',
          component: knowledgeBase,
          redirect: '/knowledgebase/company',
          meta: {
            right: [1],
            menu: '知识库',
            power: 'knowledge'
          },
          children: [
            {
              name: 'companyKnowledgeBase',
              path: '/knowledgebase/company',
              component: companyKnowledgeBase,
              meta: {
                right: [1],
                menu: '企业知识库',
                power: 'knowledge/knowledgeManage/getKnowledge'
              }
            },
            {
              name: 'personalKnowledgeBase',
              path: '/knowledgebase/personal',
              component: personalKnowledgeBase,
              meta: {
                right: [1],
                menu: '个人知识库',
                power: 'knowledge'
              }
            }
          ]
        }
      ]
    },
    {
      name: 'warning',
      path: '/warning',
      component: warning,
      meta: {
        right: [1, 0],
        power: 'any'
      },
    }
  ]
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

router.beforeEach((to, from, next) => {
  // 存入邀请id
  if (to.query.inviteId) {
    store.commit('setinviteId', to.query.inviteId)
  }
  // console.log('store', store.state.inviteId);

  if (to.name !== 'login' && !window.localStorage.getItem('token')) {
    next({ name: 'login' })
  } else if (to.name == 'login' && window.localStorage.getItem('token')) {
    if (JSON.parse(window.localStorage.getItem('userInfo')).status == 1) {
      // 已登录用户跳转home
      next({ name: 'home' })
    } else {
      next({ name: 'auditing' })
    }
  } else if (to.name != 'auditing' && to.name != 'create' && to.name != 'space' && JSON.parse(window.localStorage.getItem('userInfo')) && JSON.parse(window.localStorage.getItem('userInfo')).status != 1) {
    next({ name: 'auditing' })
  } else if (JSON.parse(window.localStorage.getItem('userInfo'))?.company && JSON.parse(window.localStorage.getItem('userInfo')).company.status == 0) {

    if (to.name == 'auditing') {
      next()
    } else {
      next({ name: 'auditing' })
    }
  } else {
    // 判断是否有公司
    if (to.name !== 'create' && to.name !== 'space' && to.name !== 'auditing' && !JSON.parse(window.localStorage.getItem('userInfo'))?.company && window.localStorage.getItem('token')) {
      next({ name: 'create' })
    } else {
      // 判断甲乙方
      if (to.meta.right && to.meta.right.indexOf(store.state.party) > -1) {
        // 判断是否有权限查看
        // console.log(to, store.state.authA.indexOf(to.meta.power))
        const auth = store.state.party ? store.state.authA : store.state.authB
        if (auth.indexOf(to.meta.power) > -1 || to.meta.power == 'any') {
          next()
        } else {
          store.commit('setRouterHistory', from.name) // 保存路由记录
          next({ name: 'warning', query: { word: '暂无权限查看, 请联系管理员开通~' } })
          // next()
        }
      } else {
        store.commit('setRouterHistory', from.name) // 保存路由记录
        next({ name: 'warning', query: { word: '暂无权限查看~' } })
      }
    }
  }
})

export default router