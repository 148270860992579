<template>
  <div class="notice">
        <div class="screen-box">
          <a-form layout="inline" @submit="handleSubmit">
            <a-form-item>
              <a-select class="select-item" placeholder="选择公司" v-model="form.selectCompany">
                <a-select-option v-for="item in companyArr" :key="item.id" :value="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-select class="select-item" placeholder="项目动态类型" v-model="form.selectType">
                <a-select-option v-for="item in typeArr" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="日期：">
              <a-range-picker class="date-picker" v-model="form.selectDate" @change="onChangeDate" />
            </a-form-item>
            <a-form-item label="搜索：">
              <a-input v-model="form.selectMember" placeholder="请输入关键字搜索" />
            </a-form-item>
            <a-form-item>
              <a-button html-type="submit" shape="circle" icon="search"></a-button>
            </a-form-item>

            <a-form-item>
              <a-button @click="clearSelect">
                重置
              </a-button>
            </a-form-item>
          </a-form>
        </div>
        <Table ref="table" :request="api.operationGetLists" :columns="columns" :param="{ pageSize: 10,company_id:form.selectCompany,behavior:form.selectProject,keywords:form.selectMember,type: form.selectType,start_time:form.selectDate[0]?form.selectDate[0]:'',end_time:form.selectDate[1]?form.selectDate[1]:''}">
          <img class="img" slot="avatar" slot-scope="scope" :src="scope.text.user.avatar">
          <span slot="companyNmae" slot-scope="scope">{{scope.text.company.name}}</span>
          <span slot="role" slot-scope="scope">{{scope.text.user_role.name}}</span>
          <span slot="user" slot-scope="scope">{{scope.text.user.name}}</span>
          <span slot="project" slot-scope="scope">{{scope.text.project?scope.text.project.name:''}}</span>
          <span slot="behavior" slot-scope="scope">{{scope.text.behavior==1?'创建了':scope.text.behavior==2?'上传了':scope.text.behavior==3?'更新了':'删除了'}}</span>
          <span slot="type" slot-scope="scope">{{scope.text.type==1?'项目':scope.text.type==2?'图片':scope.text.type==3?'文案':scope.text.type==4?'链接':scope.text.type==5?'字体版权':'3d素材'}}</span>
        </Table>
        
  </div>
</template>

<script>
import Table from '../../components/table.vue'
export default {
  components: {
    Table
  },
  data() {
    return {
      companyArr: [],
      typeArr: [{
        label: '项目',
        value: 1
      }, {
        label: '图片',
        value: 2
      },
      {
        label: '文案',
        value: 3
      }, {
        label: '链接',
        value: 4
      }, {
        label: '字体版权',
        value: 5
      }, {
        label: '3d素材',
        value: 6
      }
    ],
      form: {
        selectProject: [], // 筛选条件-项目
        selectCompany: [], // 筛选条件-公司
        selectType: [], // 筛选条件-类型
        selectMember: '', // 筛选条件-成员
        selectDate: [], // 筛选条件-日期
      },
      columns: [
        {
          title: '头像',
          key: 'avatar',
          // dataIndex: 'image_url',
          scopedSlots: { customRender: 'avatar' },
          width: '200',
        },
        {
          title: '公司',
          key: 'companyNmae',
          // dataIndex: 'updated_at',
          scopedSlots: { customRender: 'companyNmae' },
          width: '200',
        },
        {
          title: '角色',
          key: 'role',
          // dataIndex: 'user',
          scopedSlots: { customRender: 'role' },
          width: '200',
        },
        {
          title: '用户',
          key: 'user',
          // dataIndex: 'user',
          scopedSlots: { customRender: 'user' },
          width: '200',
        },
        {
          title: '操作',
          key: 'behavior',
          // dataIndex: 'font_company',
          scopedSlots: { customRender: 'behavior' },
          width: '200',
        },
        {
          title: '项目',
          key: 'project',
          // dataIndex: 'matter_id',
          scopedSlots: { customRender: 'project' },
          width: '100',
        },
        {
          title: '内容',
          key: 'type',
          // dataIndex: 'name',
          scopedSlots: { customRender: 'type' },
          width: '100',
        },
        {
          title: '时间',
          // key: 'uplatetime',
          dataIndex: 'created_at',
          // scopedSlots: { customRender: 'uplatetime' },
          width: '100',
          sorter:true
        }
      ],
    };
  },
  methods: {
    // 重置
    clearSelect() {
      this.form.selectProject = []
      this.form.selectCompany = []
      this.form.selectType = []
      this.form.selectMember = ''
      this.form.selectDate = []
      setTimeout(() => {
        this.$refs.table.getData()
      }, 10);
    },
    onChangeDate(date, dateString) {
      this.form.selectDate = dateString
    },
    handleSubmit() {
      this.$refs.table.getData()
    },
    // 获取公司列表
    getCompanyList() {
      // 公司列表
      this.api.contractorLists({
        company_id:JSON.parse(window.localStorage.getItem('userInfo')).company_id
      }).then(res => {
        if(res.code==0){
          
          let arr = []
          res.data.lists&&res.data.lists.map((v)=>{
            arr.push(v.contractor)
          })
          this.companyArr = arr
        }
      })
    }
  },
  created() {
    this.getCompanyList()
  }
}
</script>

<style scoped>
.notice {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 40px;
}

.screen-box {
  padding: 0 0 16px 0;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.select-item {
  width: 130px;
}

.date-picker {
  width: 260px;
}

.img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}
</style>