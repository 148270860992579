<template>
  <div class="copyright-font">
    <a-page-header
      title="音频版权"
    />
    <div class="edit">
      <div>
        <a-button type="default" size="small" style="margin-right: 10px;" v-downZip="downloadArr" v-power="'copyright/font/download'" @click="downloadFont">下载</a-button>
        <a-popconfirm
          title="是否确认删除?"
          ok-text="是"
          cancel-text="否"
          @confirm="deleteFont"
        > 
          <a-button type="danger" size="small">删除</a-button>
        </a-popconfirm>
      </div>
      <a-space>
        <a-input placeholder="请输入关键字" v-model="keywords" />
        <a-button shape="circle" icon="search" @click="searchFun"></a-button>
        <a-button type="primary" @click="createFontCopy" v-power:disabled="'copyright/font/create'">
          新增音频版权
        </a-button>
      </a-space>
    </div>
    <Table selected ref="table" :request="api.copyrightAudioGetLists" :columns="columns" :param="{
      pageSize: 10,
      page: 1,
      company_id:ispartyB?companyId:'',
      keywords:keywords
      }"  @selectedChange="selectedChange">
      <span slot="uplatetime" slot-scope="scope">{{scope.text.operator.updated_at}}</span>
      <p slot="uplateuser" slot-scope="scope">{{scope.text.operator.user.name}}</p>
      <div class="btns" slot="action" slot-scope="scope">
        <a-button type="primary" @click="compileChange(scope.text)" size="small">编辑</a-button>
        <a-button type="default" size="small" v-down="{url:scope.text.file,name:scope.text.name}" @click="downloadFont(scope.text)" style="margin: 0 10px;" v-power:disabled="'copyright/font/download'">下载</a-button>
        <a-button type="default" size="small" style="margin-right: 10px;" v-power:disabled="'copyright/font/copyLink'" @click="copyFontDownload(scope.text)">复制下载链接</a-button>
        <a-popconfirm
          title="是否确认删除?"
          ok-text="是"
          cancel-text="否"
          @confirm="deleteFont(scope.text)"
        >
          <a-button type="danger" size="small">删除</a-button>
        </a-popconfirm>
      </div>
    </Table>
    <!-- 弹窗 -->
    <a-modal v-model="addH5" title="音频版权" @cancel="closeupload">
      <div class="label" v-if="user.operator">
        <span>操作人：{{ user.operator.user.name }}</span>
        <span>下载次数:{{ user.download_num }}</span>
      </div>
      <div class="label" v-if="user.operator">
        <span>{{user.operator.updated_at}}更新</span>
        <span>{{user.created_at}}创建</span>
      </div>
      <div class="label">
        <span>音频名称：</span>
        <a-input style="width: 70%" :disabled="isdisablad" v-model="fontName" />
      </div>
      <!-- <div class="label">
        <span>字体公司：</span>
        <a-input style="width: 70%" v-model="fontCompany" />
      </div> -->
      <div class="label">
        <span>音频文件：</span>
        <a-upload
          :fileList="fileList"
          name="file"
          style="width: 70%"
          :multiple="false"
          :max-count="1"
          :action="api.uploadUrl"
          :data="{file_name:1}"
          @change="handleChange"
        >
          <a-button v-if="fileList.length == 0"> <a-icon type="upload" />上传压缩文件</a-button>
          <a-button v-else> <a-icon type="upload" />重新上传</a-button>
        </a-upload>
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" :loading="false" @click="createFont">保存</a-button>
        <a-button key="back" @click="closeupload">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: ['companyId'],
  data() {
    return {
      isdolund:true,
      keywords:'',
      columns: [  //表头
        {
          title: 'id',
          key: 'id',
          dataIndex: 'id',
          width: '80px',
        },
        {
          title: '音频名称',
          // key: 'image_url',
          dataIndex: 'name',
          // scopedSlots: { customRender: 'img' },
          // width: '200px',
        },
        // {
        //   title: '字体公司',
        //   // key: 'image_url',
        //   dataIndex: 'font_company',
        //   // scopedSlots: { customRender: 'img' },
        //   width: '200',
        // },
        {
          title: '下载次数',
          // key: 'video_url',
          dataIndex: 'download_num',
          // scopedSlots: { customRender: 'video' },
          width: '100px',
        },
        {
          title: '更新人',
          key: 'uplateuser',
          // dataIndex: 'name',
          scopedSlots: { customRender: 'uplateuser' },
          width: '100px',
        },
        {
          title: '更新日期',
          key: 'uplatetime',
          // dataIndex: 'updated_at',
          scopedSlots: { customRender: 'uplatetime' },
          width: '200px',
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' },
          width: '320px',
        },
      ],
      addH5: false,  //新增或编辑弹框
      fontName:'',  //字体名称
      fontCompany:'00', //字体公司
      fontId:'', //编辑id
      fileList:[], //字体数组
      isloading:false, //是否显示加载
      user:{},  //上传素材的人
      downloadArr:[],//需要下载或删除的多个数据
      fontSize:0,//字体大小
      repetition:true,//防止重复点击
      ispartyB:false,
      isdisablad:true
    }
  },
  created(){
    if(!this.$store.state.party){
      this.ispartyB = true
    }
  },
  methods:{
    closeupload(){
      this.addH5 = false
      this.isdisablad = true
    },
    // 搜索
    searchFun(){
      this.$refs.table.getData()
    },
    // 下载次数
    downloadFont(data){
      if(this.repetition){
        this.repetition = false
        this.api.copyrightAudioDownload({
          id:data.id?data.id:this.fontId
        }).then((res)=>{
          if(res.code==0){
            this.repetition = true
            this.$refs.table.getData()
          }
        })
      }
    },
    //单选，全选
    selectedChange(data){
      console.log(data)
      this.downloadArr = []
      this.fontId = ''
      data.map((v,i)=>{
        this.downloadArr.push({name:v.name,url:v.file})
        if(i>0){
          this.fontId = this.fontId + ',' +v.id
        }else{
          this.fontId += v.id

        }
      })
      console.log(this.downloadArr,this.fontId)
    },
    // 添加和修改字体版权
    createFont(){

      console.log(this.fileList)
      if(this.fontName=='' || this.fontCompany=='' || this.fileList[0]==''){
        this.message.error('信息不能为空')
        return
      }
      // this.isdolund = false
      if(this.repetition){
        this.repetition = false
        if(this.fontId){
          this.api.copyrightAudioUpdate({
            name:this.fontName,
            audio_company:this.fontCompany,
            file:this.fileList[0].response.data.url,
            id:this.fontId,
            size:this.fontSize
          }).then((res)=>{
            if (res.code == 0) {
              this.isdolund = !this.isdolund
              this.message.success(res.message)
              this.addH5 = false
              this.repetition = true
              this.$refs.table.getData()
              window.location.reload()
            }
          })
        }else{
          let obj={
            name:this.fontName,
            audio_company:this.fontCompany,
            file:this.fileList[0].response.data.url,
            size:this.fontSize
          }
          if(!this.$store.state.party){
            obj['company_id'] = this.companyId
          }
          this.api.copyrightAudioCreate(obj).then((res)=>{
            console.log(res)
            if (res.code == 0) {
              // this.isdolund = true
              this.repetition = true
              this.message.success(res.message)
              this.addH5 = false
              this.$refs.table.getData()
            }
          })
        }
      }
      console.log(this.fileList)
    },
    // 上传素材
    handleChange({ fileList }) {
      this.fileList = []
      // console.log(fileList)
      if(fileList[fileList.length-1].response){
        if (fileList[fileList.length-1].response.code == 0) {
          this.fileList = [fileList[fileList.length-1]]
          console.log(this.fileList)
          this.fontName = this.fileList[0].name.split('.')[0]
          this.fontSize = fileList[fileList.length-1].size
          this.isdisablad = false
        }

      }
      this.fileList = [fileList[fileList.length-1]]
    },
    // 编辑
    compileChange(data){
      // this.api.copyrightDetails({id:data.id}).then((res)=>{
      //   console.log(res)
      //   if(res.code==0){
          this.fontName = data.name
          // this.fontCompany = data.font_company
          this.fileList = [{
            name:data.name,
            response:{code:0,data:{url:data.file}},
            uid:'-'+data.id
          }]
          this.addH5 = true
          this.fontId = data.id
          this.user = data

      //   }
      // })
    },
    // 新增字体版权
    createFontCopy(){
      this.fontName = ''
      // this.fontCompany = ''
      this.fileList = []
      this.addH5 = true
      this.fontId = ''
      this.fontSize = 0
      this.user = ''
    },
    // 删除
    deleteFont(data){
      if(this.repetition){
        this.repetition = false
        console.log(data.id,this.fontId)
        this.api.copyrightAudioSoftDelete({
          id:data.id?data.id:this.fontId
        }).then((res)=>{
          console.log(res)
          if (res.code == 0) {
            this.fontId = ''
            this.repetition = true
            this.message.success(res.message)
            this.$refs.table.getData()
          }
        })
      }
    },
    // 复制下载链接
    copyFontDownload(data){
      let oInput = document.createElement('input')
      oInput.value = data.file
      document.body.appendChild(oInput)
      oInput.select() // 选择对象;
      document.execCommand('Copy') // 执行浏览器复制命令
      this.message.success('复制成功');
      oInput.remove()
    }
  }
}
</script>

<style scoped>
.edit {
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 20px;
}
.label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.label span {
  margin-right: 20px;
}
</style>