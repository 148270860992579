<template>
  <div class="basic">
    <div class="company-item">
      <div class="item-title">企业名称：</div>
      <a-input style="width: 600px" autocomplete="off" v-model="company.name" disabled />
      <!-- updateEnterprisexxxxx：企业名称不支持修改 -->
      <a-icon class="edit-icon" type="form" v-power:disabled="'setup/enterprise/updateEnterprisexxxxx'"
        style="opacity: 0;" />
    </div>

    <div class="company-item">
      <div class="item-title">企业联系人：</div>
      <a-input style="width: 600px" autocomplete="off" v-model="company.contacts" disabled />
      <a-icon class="edit-icon" type="form" v-power:disabled="'setup/enterprise/updateEnterprise'"
        @click="update('contacts')" />
    </div>

    <div class="company-item">
      <div class="item-title">企业邮箱：</div>
      <a-input style="width: 600px" autocomplete="off" v-model="company.contact" disabled />
      <a-icon class="edit-icon" type="form" v-power:disabled="'setup/enterprise/updateEnterprise'"
        @click="update('email')" />
    </div>

    <!-- 编辑企业联系人 -->
    <a-modal title="编辑企业联系人" :visible="editModalContacts" @ok="handleOkEditContacts" @cancel="handleCancelEditContacts">
      <div>
        企业联系人： <a-input style="width: 360px" autocomplete="off" placeholder="请输入企业联系人" v-model="editContacts" />
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" :loading="loading" @click="handleOkEditContacts">
          保存
        </a-button>
        <a-button key="back" @click="handleCancelEditContacts">
          取消
        </a-button>
      </template>
    </a-modal>
    <!-- 编辑企业邮箱 -->
    <a-modal title="编辑企业邮箱" :visible="editModalEmail" @ok="handleOkEditEmail" @cancel="handleCancelEditEmail">
      <div>
        企业邮箱： <a-input style="width: 360px" autocomplete="off" placeholder="请输入企业邮箱" v-model="editEmail" />
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" :loading="loading" @click="handleOkEditEmail">
          保存
        </a-button>
        <a-button key="back" @click="handleCancelEditEmail">
          取消
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      loading: false,
      company: {
        name: '',
        contacts: '',
        contact: '',
      },

      editModalContacts: false,
      editContacts: '',

      editModalEmail: false,
      editEmail: '',
    }
  },
  methods: {
    getEnterpriseInfo() {
      this.api.enterpriseInfo({
      }).then(res => {
        console.log('res', res)
        this.company = res.data.info
      })
    },
    // 展示弹窗
    update(type) {
      if (type == 'contacts') {
        this.editContacts = this.company.contacts
        this.editModalContacts = true
      } else if (type == 'email') {
        this.editEmail = this.company.contact
        this.editModalEmail = true
      }
    },
    // 企业联系人修改-关闭
    handleCancelEditContacts() {
      this.editModalContacts = false
      this.editContacts = ''
    },
    // 企业联系人修改-保存
    handleOkEditContacts() {
      if (this.editContacts == '') {
        this.message.error('请输入企业联系人');
      } else {
        this.loading = true
        this.api.updateEnterprise({
          name: this.company.name,
          contacts: this.editContacts,
          email: this.company.contact
        }).then(res => {
          console.log('res', res)
          this.loading = false
          this.editModalContacts = false
          this.editContacts = ''
          if (res.code === 0) {
            this.message.success(res.message)
            this.getEnterpriseInfo()
          }
        })
      }
    },
    // 企业邮箱修改-关闭
    handleCancelEditEmail() {
      this.editModalEmail = false
      this.editEmail = ''
    },
    // 企业邮箱修改-保存
    handleOkEditEmail() {
      if (this.editEmail == '') {
        this.message.error('请输入企业邮箱');
      } else {
        this.loading = true
        this.api.updateEnterprise({
          name: this.company.name,
          contacts: this.company.contacts,
          email: this.editEmail
        }).then(res => {
          console.log('res', res)
          this.loading = false
          this.editModalEmail = false
          this.editEmail = ''
          if (res.code === 0) {
            this.message.success(res.message)
            this.getEnterpriseInfo()
          }
        })
      }
    },
  },
  created() {
    this.getEnterpriseInfo()
  }
}
</script>
s
<style scoped>
.basic {
  padding: 40px 0 0;
}

.company-item {
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0 40px;
}

.item-title {
  width: 100px;
  margin: 0 10px 0 0;
  text-align: right;
}

.edit-icon {
  /* position: absolute; */
  /* top: calc(50% - 10px); */
  /* right: -60px; */
  margin: 0 0 0 230px;
  font-size: 20px;
  cursor: pointer;
}
</style>