<template>
  <div class="sum">
    <div class="title">
      <span>{{$store.state.party ? '项目总数' : '我的项目'}}</span>
      <a-button v-if="$store.state.party" type="link" @click="linkTo">新建项目</a-button>
    </div>
    <div class="content">
      <span>
        <a-icon type="container" theme="filled" />
      </span>
      <Number class="amount" :num="$store.state.party ? data?.project_count : data?.userInfo?.project_b_count"></Number>
    </div>
  </div>
</template>

<script>
import Number from '../utils/number.vue'
export default {
  name: 'Sum',
  props: ['data'],
  components: {
    Number
  },
  data() {
    return {
      percent: 0,
      targetPercent: 80
    }
  },
  created() {
    console.log(this.data)
  },
  mounted() {
    window.requestAnimationFrame(this.step)
  },
  methods: {
    step() {
      if (this.percent < this.targetPercent) {
        this.percent += 2
        window.requestAnimationFrame(this.step)
      }
    },
    linkTo() {
      this.$router.push({name: 'projectList', params: {addVisible: true}})
    }
  }
}
</script>

<style scoped>
.sum {
  background: #fff;
  border-radius: 10px;
}
.title {
  height: 50px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 0 10px 15px;
}
.content {
  min-height: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px;
}
.content span {
  font-size: 50px;
}
.amount {
  font-size: 50px;
  font-weight: 700;
}
.annulus {
  color: rgb(46, 124, 208);
  margin: 2px auto;
}
.annulus-color {
  width: 100px;
  height: 100px;
  background-image: linear-gradient(#5bdf95, #2e7cd0);
  border-radius: 50%;
  position: relative;
}
.half-left {
  width: 50px;
  height: 100px;
  background-color: #f1f1f1;
  border-radius: 0 50px 50px 0;
  position: absolute;
  left: 50%;
  z-index: 9;
  transform-origin: 0 50%;
  transform: rotate(0);
  /* transition: transform 0.1s; */
}
.half-right {
  width: 50px;
  height: 100px;
  background-color: #f1f1f1;
  border-radius: 50px 0 0 50px;
  position: absolute;
  z-index: 9;
  transform-origin: 100% 50%;
  transform: rotate(-90deg);
  /* transition: transform 0.1s; */
}
.annulus-lid {
  width: 70px;
  height: 70px;
  font-size: 20px;
  text-align: center;
  line-height: 70px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.data {
  font-size: 20px;
}
.data p:first-child {
  margin-bottom: 20px;
}
</style>