<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-10-12 17:21:38
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-10-20 16:32:19
 * @FilePath: \h5case4.xiaoxxx.cne:\code\仙助数字资产管理平台\digital\src\views\index\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="main">
    <a-layout>
      <a-layout-header v-if="$store.state.userInfo.company_id != 0 && $store.state.userInfo.status == 1">
        <Header></Header>
      </a-layout-header>
      <a-layout>
        <a-layout-sider>
          <Sider></Sider>
        </a-layout-sider>
        <a-layout-content>
          <router-view></router-view>
        </a-layout-content>
      </a-layout>
      <a-layout-footer>
        <Footer></Footer>
      </a-layout-footer>
    </a-layout>
  </div>
</template>

<script>
import Header from '../../components/header.vue'
import Footer from '../../components/footer.vue'
import Sider from '../../components/sider.vue'
export default {
  components: {
    Header,
    Sider,
    Footer
  },
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
    popShow(props) {
      console.log(props.record)
    },
    onChange(checkedList) {
      console.log(checkedList)
    }
  }
}
</script>

<style scoped>
.main {
  height: 100%;
  background: #eee;
}

.ant-layout {
  height: 100%;
}

.ant-layout-header {
  background: #fff;
}

.ant-layout-sider {
  width: auto !important;
  min-width: 0 !important;
  max-width: none !important;
  flex: none !important;
  height: 100%;
  background: #fff;
  margin: 0 !important;
  z-index: 999;
}

.ant-layout-content {
  height: 100%;
  box-sizing: border-box;
  padding: 20px 20px 70px 20px;
  background: #eee;
  /* overflow: auto; */
}

.ant-layout-footer {
  width: 100%;
  height: 50px;
  position: fixed;
  bottom: 0;
  z-index: 9;
  padding: 0;
}
</style>