<template>
  <div class="create">
    <div class="create-bg"></div>
    <div v-if="showModeltype == 1">
      <div class="top-div">
        <span class="top-title">填写个人信息</span>
        <span class="top-desc">开始使用前，请确认个人信息</span>
      </div>
      <a-form v-if="!inviteId" :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" @submit="handleSubmit">
        <a-steps direction="vertical" class="timeaxis-box">
          <a-step class="timeaxis" status="process">
            <template slot="title">
              <div class="step-title">填写个人信息</div>
            </template>
            <span slot="description">
              <div class="step-content">
                <a-form-item label="用户名：">
                  <a-input placeholder="依据公司习惯，使用实名或花名"
                    v-decorator="['name', { rules: [{ required: true, message: '请输入用户名！' }] }]" @focus="inputStart"
                    @blur="inputEnd" />
                </a-form-item>
                <a-form-item label="联系方式：">
                  <a-input disabled v-decorator="['phone', { rules: [{ required: true, message: '请输入联系方式！' }] }]" />
                </a-form-item>
              </div>
            </span>
          </a-step>
          <a-step class="timeaxis" status="process">
            <template slot="title">
              <div class="step-title">进入企业空间</div>
            </template>
            <span slot="description">
              <div class="enterprise-name">
                <a-form-item label="企业名称：">
                  <a-select mode="default" label-in-value placeholder="请输入您所在的企业名称" style="width: 100%" allowClear
                    :filter-option="false" :not-found-content="fetching ? undefined : null" :showSearch="true"
                    v-decorator="['enterprisename', { rules: [{ required: true, message: '请选择您的企业!' }] }]"
                    @search="fetchUser" @change="handleChange">
                    <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                    <a-select-option :value='d.id' v-for="(d, index) in data" :key="index">
                      {{ d.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </div>
              <div class="create-enterprise-space" @click="goToCreateSpace">若您的企业首次使用本平台，请点击此处创建企业空间。</div>
            </span>
          </a-step>
        </a-steps>
        <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
          <div class="next-step">
            <!-- <a-button type="primary" html-type="submit">
              下一步
            </a-button> -->
            <div class="div-btn" @click="handleSubmit">下一步</div>
          </div>
        </a-form-item>
      </a-form>
      <a-form v-else :form="formTypeTwo" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }"
        @submit="handleInvitedIdSubmit">
        <div class="form-content">
          <div class="form-content-title">
            <span>{{ inviteUserName }}</span>邀请你加入"<span>{{ inviteCompanyName }}</span>"的企业空间
          </div>
          <div class="form-content-desc">
            请填写您个人信息
          </div>
          <a-form-item label="用户名：">
            <a-input placeholder="依据公司习惯，使用实名或花名"
              v-decorator="['name', { rules: [{ required: true, message: '请输入用户名！' }] }]" @focus="inputStart"
              @blur="inputEndTwo" />
          </a-form-item>
          <a-form-item label="联系方式：">
            <a-input disabled v-decorator="['phone', { rules: [{ required: true, message: '请输入联系方式！' }] }]" />
          </a-form-item>
        </div>
        <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
          <div class="next-step">
            <!-- 这个form 用submit 会刷新页面 -->
            <!-- <a-button type="primary" html-type="submit">下一步submit</a-button> -->
            <div class="div-btn" @click="handleInvitedIdSubmit">下一步</div>
          </div>
        </a-form-item>
      </a-form>
    </div>
    <div v-if="showModeltype == 2 || showModeltype == 3 || showModeltype == 4">
    </div>

    <headCanvas :canvasText="userNameEnd2" @childEvent="getHeadUrl"></headCanvas>

    <!-- 链接有邀请ID，链接查询信息 -->
    <a-modal title="消息通知" :visible="visibleModal" @ok="handleOkInvite">
      <div>
        <span>{{ visibleModalText }}</span>
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" @click="handleOkInvite"> 确定 </a-button>
      </template>
    </a-modal>

    <!-- 类型：邀请关联空间，信息弹窗 -->
    <a-modal title="关联空间邀请" :visible="visibleInviteSpace" @ok="handleOkInviteSpace" @cancel="handleCancelInviteSpace">
      <div v-if="inviteSpaceInfo && inviteSpaceInfo.originator">
        <span>收到以下公司的空间邀请，是否接受</span> <br><br>
        <span>企业名称：{{ inviteSpaceInfo.originator.name }}</span> <br><br>
        <span>企业联系人：{{ inviteSpaceInfo.originator.contacts }}</span> <br><br>
        <span>企业联系方式：{{ inviteSpaceInfo.originator.contact }}</span> <br><br>
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" :loading="loading" @click="handleOkInviteSpace">
          确定
        </a-button>
        <a-button key="back" @click="handleCancelInviteSpace">
          取消
        </a-button>
      </template>
    </a-modal>

    <!-- 类型：邀请加入公司，信息弹窗 -->
    <a-modal title="甲方邀请" :visible="visibleInviteUser" @ok="handleOkInviteUser" @cancel="handleCancelInviteUser">
      <div v-if="inviteSpaceInfo && inviteSpaceInfo.originator && inviteSpaceInfo.user">
        <!-- <span>甲方邀请</span> <br><br> -->
        <span>{{ inviteSpaceInfo.user.name }}邀请你加入"{{ inviteSpaceInfo.originator.name }}"企业空间参与协作</span> <br><br>
        <!-- <span>企业名称：{{ inviteSpaceInfo.originator.name }}</span> <br><br> -->
        <!-- <span>企业联系人：{{ inviteSpaceInfo.originator.contacts }}</span> <br><br> -->
        <!-- <span>企业联系方式：{{ inviteSpaceInfo.originator.contact }}</span> <br><br> -->
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" :loading="loading" @click="handleOkInviteUser">
          确定
        </a-button>
        <a-button key="back" @click="handleCancelInviteUser">
          取消
        </a-button>
      </template>
    </a-modal>

    <!-- 类型：邀请加入项目，信息弹窗 -->
    <a-modal title="甲方邀请" :visible="visibleInviteProject" @ok="handleOkInviteProject" @cancel="handleCancelInviteProject">
      <div v-if="inviteSpaceInfo && inviteSpaceInfo.originator && inviteSpaceInfo.project && inviteSpaceInfo.user">
        <span>{{ inviteSpaceInfo.user.name }}邀请你加入" {{ inviteSpaceInfo.originator.name }}"企业空间参与协作</span> <br><br>
        <span>协作项目：{{ inviteSpaceInfo.project.name }}</span> <br><br>
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" :loading="loading" @click="handleOkInviteProject">
          确定
        </a-button>
        <a-button key="back" @click="handleCancelInviteProject">
          取消
        </a-button>
      </template>
    </a-modal>

    <div class="quitLogin">
      <a-button type="danger" @click="quit"> 退出登录 </a-button>
    </div>

    <!-- 页面底部信息 -->
    <footerBox />
  </div>
</template>

<script>
import footerBox from "@/components/footer.vue";
import headCanvas from '../../components/headcanvas/headcanvas.vue'

import debounce from 'lodash/debounce';
export default {
  components: {
    headCanvas,
    footerBox,
  },
  data() {
    this.lastFetchId = 0;
    this.fetchUser = debounce(this.fetchUser, 800);
    return {
      loading: false,
      inviteId: '', //邀请链接ID
      inviteUserName: '', //邀请者名称
      inviteCompanyName: '',  // 被邀请进入公司的名称
      userInfo: null,
      form: this.$form.createForm(this, { name: '', enterprisename: '' }),
      formTypeTwo: this.$form.createForm(this, { name: '', enterprisename: '' }),
      checkParty: '',
      data: [],
      value: [],
      fetching: false,
      userNameEnd2: '', // 用户名 后两个字
      headImageUrl: '',

      visibleModal: false,
      visibleModalText: '',

      showModeltype: 0, // 页面展示类型
      visibleInviteSpace: false, // 是否显示 邀请加入空间，信息弹窗
      inviteSpaceInfo: null,// 邀请信息

      visibleInviteProject: false,
      visibleInviteUser: false, // 是否显示 邀请加入公司，信息弹窗

      isSubmit: false
    };
  },
  methods: {
    // 退出登录
    quit() {
      window.localStorage.removeItem('userInfo')
      window.localStorage.removeItem('token')
      this.$router.replace({ name: 'login' })
    },
    inputStart() {
      this.headImageUrl = ''
    },
    // 输入名字结束
    inputEnd() {
      console.log('输入名字结束');
      this.form.validateFields((err, value) => {
        console.log(err, value);
        this.userNameEnd2 = value.name.slice(0,2)
        console.log('this.userNameEnd2', this.userNameEnd2);
      });
    },
    // 输入名字结束
    inputEndTwo() {
      console.log('输入名字结束');
      this.formTypeTwo.validateFields((err, value) => {
        console.log(err, value);
        this.userNameEnd2 = value.name.slice(0,2)
        console.log('this.userNameEnd2', this.userNameEnd2);
      });
    },
    // 跳转-创建企业空间
    goToCreateSpace() {
      var userinfo = this.form.getFieldsValue()
      this.$router.replace({
        name: 'space',
        params: {
          userName: userinfo.name,
          userMobile: userinfo.phone,
          avatar: this.headImageUrl,
        }
      });
    },
    // 检索已注册企业
    pressEnter(data) {
      console.log('data', data);
    },
    // 弹窗确定键
    handleOkInvite() {
      console.log('弹窗确定键', this.userInfo);
      if (this.userInfo.company_id == 0) {
        this.visibleModal = false
        this.inviteId = ''
        this.showModeltype = 1
        setTimeout(() => {
          this.form.setFieldsValue({
            phone: this.userInfo.mobile
          })
        }, 1000);
      } else {
        this.$router.replace({ name: 'home' })
      }
      // if (this.visibleModal) {
      //   window.localStorage.removeItem('userInfo')
      //   window.localStorage.removeItem('token')
      //   this.$router.replace({ name: 'login' })
      // } else {
      //   this.$router.replace({ name: 'auditing' })
      // }
    },
    // 获取头像链接
    getHeadUrl(data) {
      this.headImageUrl = data
      console.log('this.headImageUrl', this.headImageUrl);
    },
    fetchUser(value) {
      console.log('fetching user', value);
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.data = [];
      this.fetching = true;
      this.api.companyQuery({
        name: value
      }).then(res => {
        console.log('res', res)
        if (fetchId !== this.lastFetchId) {
          // for fetch callback order
          return;
        }
        this.data = res.data.lists;
        this.fetching = false;
      })
    },
    handleChange(value) {
      console.log('handleChange', value);
    },
    // 选择用户类型
    check(val) {
      this.checkParty = val
    },
    // 无邀请ID
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('无邀请ID', values);
          if (!this.isSubmit) {
            this.isSubmit = true
            if (this.headImageUrl != '') {
              console.log('生成头像完成')
              this.api.updateUserInfo({
                name: values.name,
                mobile: values.phone,
                company_id: values.enterprisename.key,
                avatar: this.headImageUrl
              }).then(res => {
                console.log('res', res);
                if (res.code == 0) {
                  console.log('无邀请ID注册');
                  window.localStorage.setItem('auditingPageType', 'individual')
                  this.message.success(res.message);
                  this.$router.replace({
                    path: '/auditing',
                  });
                }
              })
            } else {
              let setIntTime = setInterval(() => {
                console.log('头像还在生成中')
                if (this.headImageUrl != '') {
                  console.log('有头像了', this.headImageUrl);
                  clearInterval(setIntTime)
                  this.api.updateUserInfo({
                    name: values.name,
                    mobile: values.phone,
                    company_id: values.enterprisename.key,
                    avatar: this.headImageUrl
                  }).then(res => {
                    console.log('res', res);
                    if (res.code == 0) {
                      console.log('无邀请ID注册');
                      window.localStorage.setItem('auditingPageType', 'individual')
                      this.message.success(res.message);
                      this.$router.replace({
                        path: '/auditing',
                      });
                    }
                  })
                }
              }, 10);
            }
          }
        }
      });
    },
    // 有邀请ID
    handleInvitedIdSubmit() {
      this.formTypeTwo.validateFields((err, values) => {
        if (!err) {
          console.log('有邀请ID: ', values);
          if (!this.isSubmit) {
            this.isSubmit = true
            if (this.headImageUrl != '') {
              console.log('生成头像完成')
              this.api.updateUserInfo({
                id: this.inviteId,
                name: values.name,
                mobile: values.phone,
                avatar: this.headImageUrl
              }).then(res => {
                console.log('res', res);
                if (res.code == 0) {
                  console.log('有邀请ID注册');
                  this.message.success(res.message);
                  this.$router.replace({
                    path: '/auditing',
                  });
                }
              })
            } else {
              let setIntTime = setInterval(() => {
                console.log('头像还在生成中')
                if (this.headImageUrl != '') {
                  console.log('有头像了', this.headImageUrl);
                  clearInterval(setIntTime)
                  this.api.updateUserInfo({
                    id: this.inviteId,
                    name: values.name,
                    mobile: values.phone,
                    avatar: this.headImageUrl
                  }).then(res => {
                    console.log('res', res);
                    if (res.code == 0) {
                      console.log('有邀请ID注册');
                      this.message.success(res.message);
                      this.$router.replace({
                        path: '/auditing',
                      });
                    }
                  })
                }
              }, 10);
            }
          }
        }
      });
    },
    // 邀请加入空间，信息弹窗-关闭
    handleCancelInviteSpace() {
      this.visibleInviteSpace = false;
      this.$router.replace({ name: 'home' })
    },
    // 邀请加入空间，信息弹窗-确定
    handleOkInviteSpace() {
      this.loading = true
      this.api.linkProcessing({
        id: this.$store.state.inviteId
      }).then(res => {
        this.loading = false
        console.log('res', res);
        if (res.code == 0) {
          window.localStorage.setItem('auditingPageType', 'spaceReview')
          // this.$router.replace({ name: 'auditing', query: { name: this.inviteSpaceInfo.name } })
          this.$router.replace({ name: 'home' })
        }
      })
    },
    // 邀请加入空间，信息弹窗-关闭
    handleCancelInviteUser() {
      this.visibleInviteUser = false;
      this.$router.replace({ name: 'home' })
    },
    // 邀请加入空间，信息弹窗-确定
    handleOkInviteUser() {
      this.loading = true
      this.api.linkProcessing({
        id: this.$store.state.inviteId
      }).then(res => {
        this.loading = false
        console.log('res', res);
        if (res.code == 0) {
          window.localStorage.setItem('auditingPageType', 'spaceReview')
          // this.$router.replace({ name: 'auditing', query: { name: this.inviteSpaceInfo.name } })
          this.$router.replace({ name: 'home' })
        }
      })
    },
    // 邀请加入项目，信息弹窗-关闭
    handleCancelInviteProject() {
      this.visibleInviteProject = false;
      this.$router.replace({ name: 'home' })
    },
    // 邀请加入项目，信息弹窗-确定
    handleOkInviteProject() {
      this.loading = true
      this.api.linkProcessing({
        id: this.$store.state.inviteId
      }).then(res => {
        this.loading = false
        console.log('res', res);
        if (res.code == 0) {
          this.message.success(res.message);
          setTimeout(() => {
            this.$router.replace({ name: 'home' })
          }, 2500);
        }
      })
    },
  },
  created() {
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
    this.headImageUrl = this.userInfo.avatar
    console.log('this.headImageUrl', this.headImageUrl);
    this.inviteId = this.$store.state.inviteId
    if (this.inviteId) {
      console.log('有邀请ID，调用链接查询接口');
      // originator 邀请方
      // recipient 被邀请进入的公司
      this.api.linkContent({
        id: this.inviteId,
        type: this.$store.state.party ? 1 : 2
        // type: 2
      }).then(res => {
        console.log('linkContent-res', res);
        if (res.code == 0) {
          // 'type',//类型 1加入空间 2空间关联 3用户关联 4项目关联
          if (res.data.type == 2) {
            this.inviteSpaceInfo = res.data.message
            this.showModeltype = 2
            this.visibleInviteSpace = true
          } else if (res.data.type == 3) {
            console.log('showModeltype', 3);
            this.inviteSpaceInfo = res.data.message
            this.showModeltype = 3
            this.visibleInviteUser = true
          } else if (res.data.type == 4) {
            this.inviteSpaceInfo = res.data.message
            this.showModeltype = 4
            this.visibleInviteProject = true
          } else {
            this.inviteUserName = res.data.message.user.name
            this.inviteCompanyName = res.data.message.recipient.name
            this.showModeltype = 1
          }
        } else {
          this.$store.commit('setinviteId', null)
          this.visibleModal = true
          this.visibleModalText = res.message
        }
      })
    } else {
      this.showModeltype = 1
    }
  },
  async mounted() {
    console.log('this.userInfo.mobile', this.userInfo.mobile, this.inviteId);
    setTimeout(() => {
      if (!this.inviteId) {
        this.form.setFieldsValue({
          name: this.userInfo.name,
          phone: this.userInfo.mobile
        })
      } else {
        this.formTypeTwo.setFieldsValue({
          name: this.userInfo.name,
          phone: this.userInfo.mobile
        })
      }
    }, 1000);
    console.log('this.userInfo', this.userInfo);
    if (this.userInfo.status == 3 && !this.inviteId) {
      await this.fetchUser(this.userInfo.company.name)
      console.log('3,被拒绝，渲染重新申请');
      // this.showStep = '12'
      this.current = 1
      setTimeout(() => {
        if (!this.inviteId) {
          this.form.setFieldsValue({
            name: this.userInfo.name,
            phone: this.userInfo.mobile,
            enterprisename: {
              name: this.userInfo.company.name,
              key: this.userInfo.company.id,
            }
          })
        } else {
          this.formTypeTwo.setFieldsValue({
            name: this.userInfo.name,
            phone: this.userInfo.mobile
          })
        }
      }, 1000);
    }
  }
}
</script>

<style scoped>
.create {
  position: relative;
  width: 68%;
  /* height: 70%; */
  min-width: 900px;
  margin: 0 auto;
  padding: 60px 0 0;
  overflow: hidden;
}

.create-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: url("//res.xiaoxxx.com/urlGather/2022110319f8ef41100991c0816186.jpeg") center no-repeat;
  background-size: cover;
  z-index: -1;
}

.footer {
  left: 50%;
  transform: translateX(-50%);
}

.top-div {
  width: 90%;
  margin: 0 auto;
  padding: 30px 0 12px;
  border-bottom: 1px solid rgba(0, 0, 0, .3);
}

.top-title {
  font-size: 18px;
  font-weight: bold;
}

.top-desc {
  font-size: 12px;
  font-weight: bold;
  margin: 0 0 0 20px;
}

.timeaxis-box {
  margin: 60px auto 0;
}

.timeaxis {
  width: 60%;
  margin: 0 auto 0;
}

.step-icon {
  font-size: 30px;
}

.step-title {
  color: black;
  font-size: 18px;
  font-weight: bold;
}

.step-content {
  /* background-color: rgba(0, 0, 0, .1); */
  margin: 20px 0 10px 0;
}

.ant-form-item {
  margin-bottom: 10px !important;
}

.party {
  border: 1px solid rgba(121, 121, 121, 1);
  border-radius: 10px;
  padding: 10px 20px;
  margin: 0 30px 0 0;
  cursor: pointer;
  color: #333;
}

.party-icon {
  width: 73px;
  height: 66px;
  background: url("https://res.xiaoxxx.com/urlGather/20221107cf15b51e5ae97811741075.png") center no-repeat;
  background-size: cover;
}

.party-text {
  width: 80px;
  height: 66px;
  line-height: 66px;
  font-size: 20px;
  text-align: center;
}

.party-check {
  border: 1px solid #f5222d;
  /* background-color: rgba(0, 0, 0, 0.1); */
  color: #f5222d;
}

.enterprise-name {
  margin: 20px 0 40px;
}

.create-enterprise-space {
  color: #333;
  font-size: 16px;
  cursor: pointer;
  text-decoration: underline;
}

.next-step {
  width: 570px;
  text-align: center;
  margin: 40px auto 0;
}

.select-user-type {
  display: inline-block;
  margin: 0 0 0 40px;
}

.form-content {
  width: 60%;
  margin: 100px auto 0;
  white-space: nowrap;
}

.form-content-title {
  width: 50%;
  margin: 0 auto 0;
}

.form-content-title span {
  font-weight: bold;
}

.form-content-desc {
  width: 50%;
  margin: 0 auto 30px;
}

.div-btn {
  display: inline-block;
  width: 74px;
  height: 32px;
  box-sizing: border-box;
  color: #fff;
  background-color: #f5222d;
  border-color: #f5222d;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);

  line-height: 2.3;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  border: 1px solid transparent;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  touch-action: manipulation;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 4px;
}


.quitLogin {
  position: fixed;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
}
</style>