<template>
  <div class="picture">
    <a-form-model :model="form" layout="inline" style="margin: 10px 24px 10px 24px">
      <!-- <a-form-model-item label="关键字">
        <a-input v-model="form.name" />
      </a-form-model-item> -->
      <a-form-model-item label="创建日期">
        <a-range-picker @change="onChangeDate" v-model="form.selectDate" />
      </a-form-model-item>
      <a-form-model-item>
        <a-button shape="circle" icon="search" @click="getMaterialList"></a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click="clearSelect">重置</a-button>
      </a-form-model-item>
    </a-form-model>
    <a-form-model :model="form" layout="inline" style="margin: 10px 24px 20px 24px">
      <a-form-model-item label="全选">
        <a-checkbox  :indeterminate="indeterminate" :checked="checkAll" @change="onCheckAllChange"></a-checkbox>
      </a-form-model-item>
      <a-form-model-item label="">
        <a-space>
          <a-popconfirm
            title="将所选内容还原至原项目"
            ok-text="是"
            cancel-text="否"
            @confirm="restoreFont"
          >
            <a-button type="default" size="small">还原</a-button>
          </a-popconfirm>
          <a-popconfirm
            title="永久删除所选内容，该操作不可撤销"
            ok-text="是"
            cancel-text="否"
            @confirm="deleteFont"
          >
            <a-button type="danger" size="small">永久删除</a-button>
          </a-popconfirm>
        </a-space>
      </a-form-model-item>
    </a-form-model>

    <a-row type="flex" justify="center" style="margin-top: 20px">
      <a-col :span="23">
        <ul class="img-list">
          <a-empty v-if="materialList.length == 0" style="margin: 0 auto;" />
          <li v-for="item in materialList" :key="item.id" class="img-item">
            <img :src="item.screenshot" alt="">
            <div class="img-handle">
              <span>{{item.name}}</span>
              <div>
                <a-checkbox @change="onChange" :checked="checkedList.indexOf(item.id)>-1" :value="item.id"></a-checkbox>
                <span class="deleted-time">{{item.deleted_at}}删除</span>
                <a-popover>
                  <template slot="content">
                    <a-popconfirm
                      title="将所选内容还原至原项目"
                      ok-text="是"
                      cancel-text="否"
                      @confirm="restoreFont(item)"
                    >
                    <a-button type="default" size="small" style="margin-bottom: 10px;" block>还原</a-button>
                    </a-popconfirm>
                    <a-divider />
                    <a-popconfirm
                      title="永久删除所选内容，该操作不可撤销"
                      ok-text="是"
                      cancel-text="否"
                      @confirm="deleteFont(item)"
                    >
                      <a-button type="danger" size="small" block>永久删除</a-button>
                    </a-popconfirm>
                  </template>
                  <span class="ellipsis">
                    <a-icon type="ellipsis" />
                  </span>
                </a-popover>
              </div>
            </div>
          </li>
        </ul>
      </a-col>
    </a-row>
    <a-pagination
      v-model="current"
      show-quick-jumper
      :page-size-options="['10', '20', '30', '40', '50']"
      :total="allCounts"
      show-size-changer
      :page-size="pageSize"
      @showSizeChange="onShowSizeChange"
    >
      <template slot="buildOptionText" slot-scope="props">
        <span v-if="props.value !== '50'">{{ props.value }}条/页</span>
        <span v-if="props.value === '50'">全部</span>
      </template>
    </a-pagination>
  </div>
</template>

<script>
export default {
  props: ['companyId'],
  data() {
    return {
      form: {
        // name: '',
        selectDate:[],  //日期
      },
      current: 1,
      allCounts:0,
      pageSize: 10,
      materialList:[], //数据列表
      repetition:true,//防止重复点击
      indeterminate:false,//是否半选
      checkAll:false,//是否全选
      checkedList:[],//选中的数组
      plainOptions:[],//当前页的总id
    }
  },
  created(){
    this.getMaterialList()
  },
  methods: {
    // 单选
    onChange(e){
      if(!e.target.checked){
        this.checkedList.splice(this.checkedList.indexOf(e.target.value),1)
      }else{
        this.checkedList.push(e.target.value)
      }
      this.checkAll = this.checkedList.length === this.plainOptions.length?true:false
      this.indeterminate =(this.checkedList.length === this.plainOptions.length)?false:this.checkedList.length>0?true:false
    },
    // 全选
    onCheckAllChange(e){
      this.checkAll =e.target.checked
      this.indeterminate = false
      this.checkedList = e.target.checked ? this.plainOptions : []
    },
    // 翻页
    onShowSizeChange(current, pageSize) {
      this.pageSize = pageSize;
    },
    // 获取数据列表
    getMaterialList(){
      let obj={
        page:this.current,
        pageSize:this.pageSize,
        start_time:this.form.selectDate[0]?this.form.selectDate[0]:'',
        end_time:this.form.selectDate[1]?this.form.selectDate[1]:''
      }
      if(!this.$store.state.party){
        obj['company_id'] = this.companyId
      }
      this.api.recycleBinMaterialGetLists(obj).then((res)=>{
        if(res.code==0){
          this.allCounts = res.data.allCounts
          this.materialList = res.data.lists
          this.plainOptions = []
          this.checkAll = false
          this.indeterminate = false
          this.checkedList = []
          res.data.lists.map((v)=>{
            this.plainOptions.push(v.id)
          })
        }
      })
    },
    // 日期
    onChangeDate(date, dateString) {
      this.form.selectDate = dateString
    },
    // 重置
    clearSelect(){
      this.form.selectDate = []
      this.current = 1
      this.getMaterialList()
    },
    // 还原
    restoreFont(data){
      if(!data.id&&this.checkedList.length==0){
        this.message.error('还未选择需要还原的数据')
        return
      }
      if(this.repetition){
        this.repetition = false
        this.api.recycleBinMaterialRestore({
          id:data.id?data.id:this.checkedList.join(',')
        }).then((res)=>{
          if(res.code==0){
            this.repetition = true
            this.current = 1
            this.message.success(res.message)
            this.getMaterialList()
          }
        })
      }
    },
    // 永久删除
    deleteFont(data){
      if(!data.id&&this.checkedList.length==0){
        this.message.error('还未选择需要删除的数据')
        return
      }
      if(this.repetition){
        this.repetition = false
        this.api.recycleBinMaterialForceDelete({
          id:data.id?data.id:this.checkedList.join(',')
        }).then((res)=>{
          if(res.code==0){
            this.repetition = true
            this.current = 1
            this.message.success(res.message)
            this.getMaterialList()
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.result {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
}
.edit {
  display: flex;
  justify-content: space-between;
  padding: 0 24px
}
.btns {
  width: 300px;
  display: flex;
  justify-content: space-between;
  margin: 50px auto;
}
.img-list {
  display: flex;
  flex-wrap: wrap;
}
.img-item {
  width: 16.5%;
  height: 300px;
  box-shadow: 1px 1px 5px 1px #ddd;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
}
.img-item:nth-child(5n) {
  margin-right: 0;
}
.img-item img {
  width: 70%;
  height: 200px;
  object-fit: cover;
  display: block;
  margin: 15px auto;
}
.img-handle {
  border-top: 1px solid #ccc;
  box-sizing: border-box;
  padding: 10px 6px;
}
.img-handle div {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.ellipsis {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
}
.sync-img-item {
  width: 22.5%;
  height: 250px;
  box-shadow: 1px 1px 5px 1px #ddd;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.sync-img-item img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  display: block;
  margin: 0 auto;
}
.ant-divider-horizontal {
  margin: 0;
}
.drawer-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
  z-index: 1;
}
.deleted-time{
  width: 70%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>