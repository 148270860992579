/*
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-10-13 17:24:48
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-10-13 17:58:27
 * @FilePath: \h5case4.xiaoxxx.cne:\code\仙助数字资产管理平台\digital\src\utils\power.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import store from '../store/index'

const elements = ['BUTTON', 'INPUT']
// 按钮权限指令
// 注册一个全局自定义指令 `v-power`
Vue.directive('power', {
  // 被绑定元素插入父节点时调用 
  inserted: function (el, binding) {
    const auth = store.state.party ? store.state.authA : store.state.authB
    if (auth.indexOf(binding.value) <= -1) {
      if (binding.arg == 'disabled') {
        // console.log(el.children)
        const seekEl = seek(el)
        loop(el)
        const parent = document.createElement('div'); // 新建父元素
        parent.className = 'parent';
        parent.style.cursor = 'not-allowed'
        parent.style.display = 'inline-block'
        el.parentNode.replaceChild(parent, el); // 获取子元素原来的父元素并将新父元素代替子元素
        parent.appendChild(el);//  在新父元素下添加原来的子元素
        // seekEl.style = 'cursor: not-allowed; pointer-events: none; color: rgba(0, 0, 0, 0.25); background-color: #f5f5f5; border-color: #d9d9d9; text-shadow: none; box-shadow: none;'
        disabledStyle(seekEl)
      } else if (binding.arg == 'warning') {
        const div = document.createElement('div')
        div.innerHTML = `
        <div style="width: 100%;height: 100%;display: flex;flex-direction: column;justify-content: center;align-items: center;position: absolute;left: 0;top: 0;background: #fff;z-index: 9">
          <img style="width: 168px;" src="https://res.xiaoxxx.com/urlGather/202306011f20d6696f1499da604351.png" alt="无权限">
          <p style="font-size: 16px;font-weight: 700;margin: 30px 0;">无权限~</p>
        </div>
        `
        el.appendChild(div)
      } else {
        console.log('el', el);
        el.remove()
      }
    }
  }
})

function loop(el) {
  disabledStyle(el)
  // el.style = 'cursor: not-allowed; pointer-events: none; color: rgba(0, 0, 0, 0.25); background-color: #f5f5f5; border-color: #d9d9d9; text-shadow: none; box-shadow: none;'
  for (let i = 0; i < el.children.length; i++) {
    disabledStyle(el.children[i])
    // el.children[i].style = 'cursor: not-allowed; pointer-events: none; color: rgba(0, 0, 0, 0.25); background-color: #f5f5f5; border-color: #d9d9d9; text-shadow: none; box-shadow: none;'
    if (el.children[i].length > 0) {
      loop(el.children[i])
    }
  }
}

function seek(el) {
  console.log(elements.indexOf(el.nodeName))
  if (elements.indexOf(el.nodeName) == -1) {
    if (el.children.length > 0) {
      for (let i = 0; i < el.children.length; i++) {
        return seek(el.children[i])
      }
    } else {
      return el
    }
  } else {
    return el
  }
}

// 设置禁用样式
function disabledStyle(el) {
  el.style.pointerEvents = 'none'
  el.style.color = 'rgba(0, 0, 0, 0.25)'
  el.style.backgroundColor = '#fff'
  el.style.borderColor = '#d9d9d9'
  el.style.textShadow = 'none'
  el.style.boxShadow = 'none'
}