<template>
  <div class="h5">
    <a-page-header
      :title="data.name || '项目名称...'"
      :breadcrumb="{props: {routes}}"
      sub-title="h5"
    >
      <template slot="tags" v-if="!$store.state.party && data.company">
        <router-link :to="{name: 'companyProjectList', query: {id: data.company.id}}">
          <a-button type="link"  style="margin-top: -4px">
            {{data.company.name}}
          </a-button>
        </router-link>
      </template>
    </a-page-header>
    <div class="edit">
      <div>
        <a-popconfirm
          title="是否确认删除选中项?"
          ok-text="是"
          cancel-text="否"
          @confirm="H5Del()"
          :disabled="checkList.length > 0 ? false : true"
        >
          <a-button type="danger" size="small" :disabled="checkList.length > 0 ? false : true">删除</a-button>
        </a-popconfirm>
      </div>
      <a-space>
        <a-button type="primary" @click="addH5 = true" v-power:disabled="'project/url/create'">
          新增H5
        </a-button>
      </a-space>
    </div>
    <Table v-if="data.id" :request="api.H5List" ref="table" :columns="columns" selected @selectedChange="selectedChange" :param="{project_id: data.id}" style="padding: 25px">
      <a slot="url" slot-scope="scope" :href="scope.record.url" target="_blank">{{scope.record.url}}</a>
      <div slot="tags" slot-scope="scope">
          <a-tag v-for="(item, index) in scope.record.label.split(',')" :key="index">{{item}}</a-tag>
      </div>
      <div slot="channel" slot-scope="scope">
         <a-tag v-for="(item, index) in scope.record.channel.split(',')" :key="index">{{item}}</a-tag>
      </div>
      <div class="btns" slot="action" slot-scope="scope">
        <a-space>
          <a-button type="primary" @click="H5Edit(scope)" size="small">编辑</a-button>
          <!-- <a-button type="link" size="small">复制文案</a-button> -->
          <a-popconfirm
            title="是否确认删除?"
            ok-text="是"
            cancel-text="否"
            @confirm="H5Del(scope)"
          >
            <a-button type="danger" size="small">删除</a-button>
          </a-popconfirm>
        </a-space>
      </div>
    </Table>

    <!-- 弹窗 -->
    <a-modal v-model="addH5" title="新建H5">
      <div class="label">
        <span>H5标题：</span>
        <a-input v-model="h5Title" style="width: 70%" />
      </div>
      <div class="label">
        <span>H5链接：</span>
        <a-input v-model="h5Url" style="width: 70%" />
      </div>
      <div class="label">
        <span>H5备注：</span>
        <a-input v-model="notes" style="width: 70%" />
      </div>
      <a-card
        style="width:100%"
        size="small"
        :tab-list="[
        {
          key: 'label',
          tab: '标签',
        },
        {
          key: 'channel',
          tab: '渠道',
        }
      ]"
         @tabChange="tabChange"
      >
         <div v-show="tabKey == 'label'">
          <a-tag class="a-tag" v-for="(item) in pitchOn.onLabel" :key="'label' + item.id" closable @close="closeTag(item, 'onLabel')">
            {{item.name}}
          </a-tag>
          <a-divider>选择标签</a-divider>
          <a-tag class="a-tag" @click="changeTag(item, 'onLabel')" v-for="item in labelData" :key="item.id" :color="pitchOn.onLabel.find(e => e.id == item.id) ? 'red' : ''">{{item.name}}</a-tag>
          <a-input
            v-if="newTagVisible"
            ref="tagInput"
            type="text"
            size="small"
            :style="{ width: '78px', height: '29px' }"
            v-model="tagInputValue"
            @keyup.enter="tagInputConfirm('label')"
          />
          <a-tag v-else class="a-tag" style="background: #fff; borderStyle: dashed;" @click="showNewTagInput">
            <a-icon type="plus" /> New Tag
          </a-tag>
        </div>
        <div v-show="tabKey == 'channel'">
          <a-tag class="a-tag" v-for="(item) in pitchOn.onChannel" :key="'channel' + item.id" closable @close="closeTag(item, 'onChannel')">
            {{item.name}}
          </a-tag>
          <a-divider>选择渠道</a-divider>
          <a-tag class="a-tag" @click="changeTag(item, 'onChannel')" v-for="item in channelData" :key="item.id" :color="pitchOn.onChannel.find(e => e.id == item.id) ? 'red' : ''">{{item.name}}</a-tag>
          <a-input
            v-if="newTagVisible"
            ref="tagInput"
            type="text"
            size="small"
            :style="{ width: '78px', height: '29px' }"
            v-model="tagInputValue"
            @keyup.enter="tagInputConfirm('channel')"
          />
          <a-tag v-else class="a-tag" style="background: #fff; borderStyle: dashed;" @click="showNewTagInput">
            <a-icon type="plus" /> New Tag
          </a-tag>
        </div>
      </a-card>
      <template slot="footer">
        <a-button key="submit" type="primary" @click="H5Save" :loading="saveLoading">保存</a-button>
        <a-button key="back" @click="addH5 = false">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: ['data'],
  data() {
    return {
      routes: [
        {
          path: '/projectList',
          breadcrumbName: '项目列表'
        },
        {
          path: '',
          breadcrumbName: 'H5链接'
        },
      ],
      columns: [
        {
          title: 'id',
          key: 'id',
          dataIndex: 'id',
          width: 80,
        },
        {
          title: 'H5标题',
          key: 'title',
          dataIndex: 'title',
        },
        {
          title: 'H5链接',
          key: 'url',
          dataIndex: 'url',
          scopedSlots: { customRender: 'url' },
        },
        {
          title: '标签管理',
          key: 'label',
          dataIndex: 'label',
          scopedSlots: { customRender: 'tags' },
        },
        {
          title: '渠道管理',
          key: 'channel',
          dataIndex: 'channel',
          scopedSlots: { customRender: 'channel' },
        },
        {
          title: '备注',
          key: 'notes',
          dataIndex: 'notes',
        },
        {
          title: '更新日期',
          key: 'updated_at',
          dataIndex: 'updated_at',
          width: 250
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' },
          width: 120
        },
      ],
      addH5: false,
      checkList: [],
      h5Id: null,
      h5Title: '',
      h5Url: '',
      notes: '',
      tabKey: 'label',
      pitchOn: {
        onLabel: [],
        onChannel: []
      },
      labelData: [],
      channelData: [],
      newTagVisible: false, // 新增标签框显示
      
      saveLoading: false // 保存按钮liading
    }
  },
  watch: {
    addH5(n) {
      if (n == false) {
        this.h5Id = null
        this.h5Title = ''
        this.h5Url = ''
      }
    }
  },
  created() {
    // 获取标签列表
    this.api.tagsList().then(res => {
      if (res.code == 0) {
        this.labelData = res.data.lists
      }
    })
    // 获取渠道列表
    this.api.channelList().then(res => {
      if (res.code == 0) {
        this.channelData = res.data.lists
      }
    })
  },
  methods: {
    H5Edit(scope) {
      console.log(scope)
      this.addH5 = true
      this.h5Id = scope.record.id
      this.h5Title = scope.record.title
      this.h5Url = scope.record.url
      this.pitchOn.onLabel = scope.record.label_name
      this.pitchOn.onChannel = scope.record.channel_name
    },
    H5Save() {
      let label = this.pitchOn.onLabel.map((item) => item.id)
      let channel = this.pitchOn.onChannel.map((item) => item.id)
      this.saveLoading = true
      if (this.h5Id) {
        this.api.H5Edit({
          project_id: this.data.id,
          id: this.h5Id,
          title: this.h5Title,
          url: this.h5Url,
          label: label,
          channel: channel,
          notes: this.notes
        }).then(res => {
          this.saveLoading = false
          if (res.code == 0) {
            this.message.success(res.message)
            this.$refs.table.getData()
          } else {
            this.message.error(res.message);
          }
          this.addH5 = false
        })
      } else {
        this.api.H5Create({
          project_id: this.data.id,
          title: this.h5Title,
          url: this.h5Url,
          label: label,
          channel: channel,
          notes: this.notes
        }).then(res => {
          this.saveLoading = false
          if (res.code == 0) {
            this.message.success(res.message)
            this.$refs.table.getData()
          } else {
            this.message.error(res.message);
          }
          this.addH5 = false
        })
      }
    },
    H5Del(scope) {
      if (scope) {
        this.api.H5Del({
          project_id: this.data.id,
          id: scope.record.id
        }).then(res => {
          if (res.code == 0) {
            this.message.success(res.message)
            this.$refs.table.getData()
          } else {
            this.message.error(res.message)
          }
        })
      } else if (this.checkList.length > 0) {
        let ids = this.checkList.map((item) => item.id)
        this.api.H5Del({
          project_id: this.data.id,
          id: ids
        }).then(res => {
          if (res.code == 0) {
            this.message.success(res.message)
            this.$refs.table.getData()
          } else {
            this.message.error(res.message)
          }
        })
      } else {
        this.message.error('请选择删除项')
      }
    },
    selectedChange(keys) {
      this.checkList = keys
    },
    tabChange(key) {
      this.tabKey = key
    },
    // 新增标签
    showNewTagInput() {
      this.tagInputValue = ''
      this.newTagVisible = true
      this.$nextTick(() => {
        this.$refs.tagInput.focus();
      });
    },
    // 新增标签确认
    tagInputConfirm(type) {
      console.log(this.tagInputValue)
      if (type == 'label') {
        this.api.tagsSave({name: this.tagInputValue, company_id: !this.$store.state.party && this.data.company.id}).then(res => {
          if (res.code == 0) {
            this.labelData.push(res.data)
            this.message.success(res.message)
          } else {
            this.message.error(res.message);
          }
          this.newTagVisible = false
        })
      }
      if (type == 'channel') {
        this.api.channelSave({name: this.tagInputValue, company_id: !this.$store.state.party && this.data.company.id}).then(res => {
          if (res.code == 0) {
            this.channelData.push(res.data)
            this.message.success(res.message)
          } else {
            this.message.error(res.message);
          }
          this.newTagVisible = false
        })
      }
    },
    // 批量编辑
    batchEdit() {
      this.tagsVisible = true
      // let ids = this.checkList.map((item) => item.id)
      // this.api.copywritingBatchInfo({ids: ids}).then(res => {
      //   if (res.code == 0) {
          // 多项标签取交集 (合并取交集方法)
          let labelsArr = this.checkList.map(item => {
            return item.labels.map(i => {
              return i.name
            })
          })
          this.pitchOn.onLabel = this.gather(labelsArr)
          // 多项渠道取交集 (合并取交集方法)
          let channelsArr = this.checkList.map(item => {
            return item.channels.map(i => {
              return i.name
            })
          })
          this.pitchOn.onChannel = this.gather(channelsArr)
      //   } else {
      //     this.message.error('请选择删除项')
      //   }
      // })
      
    },
    // 选择标签/渠道
    changeTag(item, type) {
      let arrIndex = 0
      let flag = this.pitchOn[type].some((i, index) => {
        arrIndex = index
        return i.id == item.id
      })
      if (flag) {
        // this.message.error('该项已选中')
        this.pitchOn[type].splice(arrIndex, 1)
      } else {
        this.pitchOn[type].push(item)
      }
      // this.$set(this[type], this[type].length, item)
    },
    // 取消标签/渠道
    closeTag(item, type) {
      console.log(item, type)
      this.pitchOn[type].forEach((element, index) => {
        if (element.id == item.id) {
          this.pitchOn[type].splice(index, 1)
        }
      })
      console.log(this.pitchOn[type])
    },
  }
}
</script>

<style scoped>
.edit {
  display: flex;
  justify-content: space-between;
  margin: 0 25px 25px 25px;
}
.label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.label span {
  margin-right: 20px;
}
.a-tag {
  font-size: 14px;
  padding: 3px 6px;
  margin-bottom: 10px;
}
</style>