<template>
  <div class="sum">
    <div class="title">
      <span>{{$store.state.party ? '空间成员' : '我的甲方'}}</span>
      <a-button type="link" @click="linkTo">添加成员</a-button>
    </div>
    <div class="content">
      <span>
        <a-icon v-if="$store.state.party" type="usergroup-add" />
        <a-icon v-else type="bank" theme="filled" />
      </span>
      <Number class="amount" :num="$store.state.party ? data?.member_count : data?.userInfo?.party_a_count"></Number>
    </div>
  </div>
</template>

<script>
import Number from '../utils/number.vue'
export default {
  name: 'Member',
  props: ['data'],
  components: {
    Number
  },
  data() {
    return {
    }
  },
  methods: {
    linkTo() {
      this.$router.push({name: 'member'})
    }
  }
}
</script>

<style scoped>
.sum {
  background: #fff;
  border-radius: 10px;
}
.title {
  height: 50px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 0 10px 15px;
}
.content {
  min-height: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px;
}
.content span {
  font-size: 50px;
}
.amount {
  font-size: 50px;
  font-weight: 700;
}
</style>