<template>
  <div class="info">
    <a-layout style="padding: 24px 0; background: #fff">
      <!-- <a-layout-sider width="200" style="background: #fff">
        <a-menu
          mode="inline"
          v-model="current"
          :default-selected-keys="['basic']"
          style="height: 100%"
        >
          <a-menu-item v-for="item in menu" :key="item.name" @click="linkTo(item.name)">
            {{item.meta.menuSecond}}
          </a-menu-item>
        </a-menu>
      </a-layout-sider> -->
      <a-layout-content :style="{ padding: '0 24px', minHeight: '280px' }">
        <router-view :data="data"></router-view>
      </a-layout-content>
    </a-layout>
    
    <!-- 引导遮罩 -->
    <div class="guide" v-if="guideShow">
      <div class="guide-content">
        <a-carousel v-if="$store.state.party" ref="carousel" class="guide-swiper" :dots="false">
          <div class="guide-item">
            <img src="https://res.xiaoxxx.com/urlGather/20230823299d69c90d4683a4415338.png" alt="">
            <p>添加本公司同事为项目成员</p>
          </div>
          <div class="guide-item">
            <img src="https://res.xiaoxxx.com/urlGather/20230823faba384bfb3c3df8209472.png" alt="">
            <p>添加关联公司和关联成员参与项目协作</p>
          </div>
        </a-carousel>
        <a-carousel v-else ref="carousel" class="guide-swiper" :dots="false">
          <div class="guide-item">
            <img src="https://res.xiaoxxx.com/urlGather/202308234c2cea16bd0c5cef953761.png" alt="">
            <p>项目状态设置仅对自己可见</p>
          </div>
          <div class="guide-item">
            <img src="https://res.xiaoxxx.com/urlGather/20230823f85d151f64c33991982509.png" alt="">
            <p>添加关联成员参与项目协作</p>
          </div>
        </a-carousel>
        <div class="guide-btns">
          <a-button v-if="guideIndex > 0" type="primary" ghost @click="guideStepPrev">上一步</a-button>
          <a-button v-if="guideIndex < guideItem.length - 1" type="primary" ghost @click="guideStepNext">下一步</a-button>
          <a-button v-if="guideIndex >= guideItem.length - 1" type="primary" ghost @click="guideHide">知道了</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: null,
      // current: ['basic'],
      // menu: [],
      data: {},
      guideShow: true, // 显示指引
      guideIndex: 0, // 指引步骤.
      guideItem: [
        {
          width: '43.5vw',
          height: '15vh',
          borderTop: '40vh',
          borderLeft: '33vw',
          content: '添加本公司同事为项目成员'
        },
        {
          width: '43.5vw',
          height: '11vh',
          borderTop: '66vh',
          borderLeft: '33vw',
          content: '添加关联公司和关联成员参与项目协作'
        },

      ], // 指引步骤内容
    }
  },
  created() {
    // this.$set(this.current, 0, this.$route.matched[2].name)
    // const routes = this.$router.options.routes
    // let routesArr = []
    // routes.forEach((item) => {
    //   if (item.name == 'index') {
    //     item.children.forEach(element => {
    //       if (element.name == 'project') {
    //         routesArr = element.children
    //       }
    //     });
    //   }
    // })
    // this.menu = routesArr.filter((item) => {
    //   if (item.meta?.menuSecond && item.meta?.right.indexOf(this.$store.state.party) > -1) {
    //     return item
    //   }
    // })
    this.id = this.$route.query.id
    this.getDetails(this.id)

    if (window.localStorage.getItem('projectGuideShow') && window.localStorage.getItem('projectGuideShow') == 'false') {
      this.guideShow = false
    }
  },
  methods: {
    // linkTo(name) {
    //   this.$router.push({ name: name, query: {id: this.id} })
    // },
    getDetails(id) {
      this.api.projectDetails({
        id: id
      }).then(res => {
        if (res.code == 0) {
          this.data = res.data.list
        }
      })
    },
    guideStepNext() {
      this.guideIndex += 1
      this.$refs.carousel.next()
    },
    guideStepPrev() {
      this.guideIndex -= 1
      this.$refs.carousel.prev()
    },
    guideHide() {
      this.guideShow = false
      window.localStorage.setItem('projectGuideShow', false)
    }
  }
}
</script>

<style scoped>
.info {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
}

.guide {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
  overflow: hidden;
}
.guide-content {
  width: 700px;
  height: 400px;
  background: #fff;
  border-radius: 10px;
  margin: 200px auto;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  overflow: hidden;
}
.guide-swiper {
  width: 100%;
  height: 340px;
  padding-top: 50px;
}
.guide-item {
  width: 100%;
  height: 340px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.guide-item img {
  width: 600px;
  height: 240px;
  margin: 0 auto;
  object-fit: contain;
}
.guide-item p {
  color: #000;
  text-align: center;
  margin-top: 10px;
}
.guide-btns {
  display: flex;
  justify-content: center;
}
.guide-btns .ant-btn {
  margin: 0 10px;
}
</style>