/*
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-10-13 17:24:48
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-10-13 17:58:27
 * @FilePath: \h5case4.xiaoxxx.cne:\code\仙助数字资产管理平台\digital\src\utils\power.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
Vue.directive('down', {
  inserted: function(el, binding) {
    console.log(el, binding)
    el.addEventListener('click', () => {
      if (typeof(binding.value) == 'string') {
        fileLoad('download', binding.value).then(res => {
          console.log(1, res)
          const a = document.createElement('a')
          a.setAttribute('href', window.URL.createObjectURL(res.file))
          a.setAttribute('download', res.name)
          a.setAttribute('target', '_blank')
          const clickEvent = document.createEvent('MouseEvents')
          clickEvent.initEvent('click', true, true)
          a.dispatchEvent(clickEvent)
        })
      } else {
        fileLoad(binding.value.name, binding.value.url).then(res => {
          console.log(2, res)
          const a = document.createElement('a')
          a.setAttribute('href', window.URL.createObjectURL(res.file))
          a.setAttribute('download', res.name)
          a.setAttribute('target', '_blank')
          const clickEvent = document.createEvent('MouseEvents')
          clickEvent.initEvent('click', true, true)
          a.dispatchEvent(clickEvent)
        })
      }
    })
  }
})

function fileLoad(name, url) {
  return new Promise((reslove) => {
    console.log(url.split('://')[1])
    let type = url.split('://')[1].split('.')[url.split('://')[1].split('.').length - 1]
    window.fetch('https://' + url.split('://')[1]).then(res => {
      return res.blob()
    }).then(res => {
      console.log(res)
      reslove({
        name: name + '.' + type,
        file: res
      })
    })
  })
}