<template>
  <div class="channel">
    <a-page-header
      title="渠道管理"
      :breadcrumb="{props: {routes}}"
    >
      <template slot="tags" v-if="!$store.state.party && data.company">
        <router-link :to="{name: 'companyProjectList', query: {id: data.company.id}}">
          <a-button type="link"  style="margin-top: -4px">
            {{data.company.name}}
          </a-button>
        </router-link>
      </template>
    </a-page-header>
    <a-form-model layout="inline" style="margin: 10px 25px 20px">
      <a-form-model-item label="关键字">
        <a-input v-model="keyword" />
      </a-form-model-item>
      <a-form-model-item>
        <a-button shape="circle" icon="search" @click="$refs.table.getData()"></a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click="keyword = ''">重置</a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button type="primary" @click="editChannel = true" v-power:disabled="'project/channel/getLists'">新增渠道</a-button>
      </a-form-model-item>
    </a-form-model>
    <Table v-if="data.id" :request="api.channelList" ref="table" :columns="columns" :param="{keyword: keyword, project_id: data.id}" style="padding: 25px">
      <div class="btns" slot="action" slot-scope="scope">
        <a-space>
          <a-button type="primary" @click="channelEdit(scope)" size="small" v-power:disabled="'project/channel/getLists'">编辑</a-button>
          <a-popconfirm
            title="是否确认删除?"
            ok-text="是"
            cancel-text="否"
            @confirm="channelDel(scope)"
          >
            <a-button type="danger" size="small" v-power:disabled="'project/channel/getLists'">删除</a-button>
          </a-popconfirm>
        </a-space>
      </div>
    </Table>

    <!-- 弹窗 -->
    <a-modal v-model="editChannel" title="编辑渠道名称">
      <div class="label">
        <span>渠道名称：</span>
        <a-input v-model="channelName" style="width: 70%" />
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" @click="channelSave" :loading="saveLoading">保存</a-button>
        <a-button key="back" @click="editChannel = false">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: ['data'],
  data() {
    return {
      routes: [
        {
          path: '/projectList',
          breadcrumbName: '项目列表'
        },
        {
          path: '',
          breadcrumbName: '渠道管理'
        },
      ],
      keyword: '',
      columns: [
        {
          title: 'id',
          key: 'id',
          dataIndex: 'id',
          width: '80',
        },
        {
          title: '渠道名称',
          key: 'name',
          dataIndex: 'name',
        },
        {
          title: '本项目所属图片数量',
          key: 'picture_count',
          dataIndex: 'picture_count',
          width: 180,
        },
        {
          title: '本项目所属文案数量',
          key: 'copywriting_count',
          dataIndex: 'copywriting_count',
          width: 180,
        },
        {
          title: '所属图片数量',
          key: 'picture_all_count',
          dataIndex: 'picture_all_count',
          width: 180,
        },
        {
          title: '所属文案数量',
          key: 'copywriting_all_count',
          dataIndex: 'copywriting_all_count',
          width: 180,
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' },
          width: 120
        },
      ],
      channelId: null,
      channelName: '',
      editChannel: false,
      saveLoading: false, // 保存按钮loading
    }
  },
  watch: {
    editChannel(n) {
      if (n == false) {
        this.channelName = ''
        this.channelId = null
      }
    }
  },
  methods: {
    // 标签编辑
    channelEdit(scope) {
      this.editChannel = true
      this.channelId = scope.record.id
      this.channelName = scope.record.name
    },
    channelSave() {
      this.saveLoading = true
      let params = {
        name: this.channelName,
        company_id: !this.$store.state.party && this.data.company.id
      }
      if (this.channelId) {
        params.id = this.channelId
      }
      this.api.channelSave(params).then(res => {
        this.saveLoading = false
        if (res.code == 0) {
          this.message.success(res.message)
          this.$refs.table.getData()
        } else {
          this.message.error(res.message);
        }
        this.editChannel = false
        this.channelName = ''
        this.channelId = null
      })
    },
    channelDel(scope) {
      this.api.channelDel({id: scope.record.id}).then(res => {
        if (res.code == 0) {
          this.message.success(res.message)
          this.$refs.table.getData()
        } else {
          this.message.error(res.message);
        }
      })
    }
  }
}
</script>

<style scoped>
.label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.label span {
  margin-right: 20px;
}
</style>