<template>
  <div class="main">
    <a-row :gutter="[16, 16]" type="flex" justify="center">
      <a-col :span="8">
        <div class="sum">
          <div class="title">
            <span>空间管理</span>
          </div>
          <div class="content">
            <div class="annulus">
              <div class="annulus-color">
                <div v-show="percent <= 50" class="half-left" :style="'transform: rotate(' + percent / 50 * 180 + 'deg)'">
                </div>
                <div v-show="percent <= 50" class="half-right" style="transform: rotate(0deg)"></div>
                <div v-show="percent > 50" class="half-left"
                  style="transform: rotate(0deg); background-image: linear-gradient(#5bdf95, #2e7cd0); z-index: 10;">
                </div>
                <div v-show="percent > 50" class="half-right"
                  :style="'transform: rotate(' + (percent - 50) / 50 * 180 + 'deg)'"></div>
                <div class="annulus-lid"></div>
              </div>
            </div>
            <div class="data">
              <p>&emsp;剩余空间: <Number :num="unusedCapacity"></Number>G</p>
              <p>已使用空间: <Number :num="usedCapacity"></Number>G</p>
              <p>&emsp;免费空间: <Number :num="capacity"></Number>G</p>
              <p>*需要升级空间请联系客服</p>
            </div>
          </div>
        </div>
      </a-col>
      <a-col :span="2">
      </a-col>
      <a-col :span="4">
        <div class="customer-service">
          <img src="https://res.xiaoxxx.com/urlGather/20221031070d8872f9f72149530900.png">
          <div class="ewm-tips">客服二维码</div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import Number from '../../../components/utils/number.vue'
export default {
  name: 'Sum',
  components: {
    Number
  },
  data() {
    return {
      percent: 0,
      targetPercent: 0,

      capacity: 0, // 总容量
      usedCapacity: 0, // 已用容量
      unusedCapacity: 0, // 未用容量
    }
  },
  mounted() {
    window.requestAnimationFrame(this.step)
  },
  methods: {
    step() {
      if (this.percent < this.targetPercent) {
        this.percent += 1
        window.requestAnimationFrame(this.step)
      }
    },
    getcapacity() {
      this.api.capacity({
      }).then(res => {
        this.capacity = parseFloat(res.data.capacity)
        this.usedCapacity = parseFloat((res.data.used_capacity / 1024 / 1024).toFixed(3))
        this.unusedCapacity = parseFloat(this.capacity - this.usedCapacity)
        this.targetPercent = parseFloat((this.usedCapacity / this.capacity) * 100)
        window.requestAnimationFrame(this.step)
      })
    }
  },
  created() {
    this.getcapacity()
  }
}
</script>

<style scoped>
.main {
  height: 100%;
  background: #fff;
  overflow: hidden;
  padding: 40px 0 0;
}

.sum {
  background: #eee;
  border-radius: 10px;
}

.title {
  height: 50px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 0 10px 15px;
}

.content {
  min-height: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px;
}

.amount {
  font-size: 50px;
  font-weight: 700;
}

.annulus {
  color: rgb(46, 124, 208);
  margin: 2px auto;
}

.annulus-color {
  width: 100px;
  height: 100px;
  background-image: linear-gradient(#5bdf95, #2e7cd0);
  border-radius: 50%;
  position: relative;
}

.half-left {
  width: 50px;
  height: 100px;
  background-color: #f1f1f1;
  border-radius: 0 50px 50px 0;
  position: absolute;
  left: 50%;
  z-index: 9;
  transform-origin: 0 50%;
  transform: rotate(0);
  /* transition: transform 0.1s; */
}

.half-right {
  width: 50px;
  height: 100px;
  background-color: #f1f1f1;
  border-radius: 50px 0 0 50px;
  position: absolute;
  z-index: 9;
  transform-origin: 100% 50%;
  transform: rotate(-90deg);
  /* transition: transform 0.1s; */
}

.annulus-lid {
  width: 70px;
  height: 70px;
  font-size: 20px;
  text-align: center;
  line-height: 70px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.data {
  font-size: 14px;
}

.data p {
  margin-top: 10px;
}

.data p:last-child {
  margin-top: 20px;
}

.number {
  font-weight: 400;
}

.customer-service {
  width: 200px;
  height: 200px;
}

.customer-service img {
  display: block;
  width: 100%;
  height: 100%;
}

.ewm-tips {
  text-align: center;
}
</style>