<template>
  <a-spin class="home" :spinning="spinning">
    <!-- 甲方 -->
    <template v-if="$store.state.party">
      <a-row :gutter="[16, 16]" type="flex" justify="center">
        <a-col :span="6">
          <Sum :data="data"></Sum>
        </a-col>
        <a-col :span="6">
          <Member :data="data"></Member>
        </a-col>
        <a-col :span="8">
          <Tool></Tool>
        </a-col>
      </a-row>
      <a-row :gutter="[16, 16]" type="flex" justify="center">
        <a-col :span="6">
          <Fodder :data="data"></Fodder>
        </a-col>
        <a-col :span="6">
          <Copy :data="data"></Copy>
        </a-col>
        <a-col :span="8">
          <Advertising></Advertising>
        </a-col>
      </a-row>
      <a-row :gutter="[16, 16]" type="flex" justify="center">
        <a-col :span="12">
          <Dynamic :data="data"></Dynamic>
        </a-col>
        <a-col :span="8">
          <Notice :data="data"></Notice>
        </a-col>
      </a-row>
      <a-row :gutter="[16, 16]" type="flex" justify="center">
        <a-col :span="12">
          <System :data="data"></System>
        </a-col>
        <a-col :span="8">
        </a-col>
      </a-row>
    </template>
    <!-- 乙方 -->
    <template v-else>
      <a-row :gutter="[16, 16]" type="flex" justify="center">
        <a-col :span="6">
          <Sum :data="data"></Sum>
        </a-col>
        <a-col :span="6">
          <Member :data="data"></Member>
        </a-col>
        <a-col :span="8">
          <Tool></Tool>
        </a-col>
      </a-row>
      <a-row :gutter="[16, 16]" type="flex" justify="center">
        <a-col :span="6">
          <Fodder :data="data"></Fodder>
        </a-col>
        <a-col :span="6">
          <Copy :data="data"></Copy>
        </a-col>
        <a-col :span="8">
          <Advertising></Advertising>
        </a-col>
      </a-row>
      <a-row :gutter="[16, 16]" type="flex" justify="center">
        <a-col :span="12">
          <Dynamic :data="data"></Dynamic>
        </a-col>
        <a-col :span="8">
          <Notice :data="data"></Notice>
        </a-col>
      </a-row>
      <a-row :gutter="[16, 16]" type="flex" justify="center">
        <a-col :span="12">
          <System :data="data"></System>
        </a-col>
        <a-col :span="8">
        </a-col>
      </a-row>
      <!-- <ProjectList></ProjectList> -->
    </template>

    <!-- 引导遮罩 -->
    <div class="guide" v-if="guideShow">
      <div class="guide-hollow guide-step1" :style="`width: ${guideItem[guideIndex].width}; height: ${guideItem[guideIndex].height}; border-top: ${guideItem[guideIndex].borderTop} solid rgba(0, 0, 0, 0.7); border-left: ${guideItem[guideIndex].borderLeft} solid rgba(0, 0, 0, 0.7);`">
        <div class="guide-control">
          <div class="guide-explain">{{guideItem[guideIndex].content}}</div>
          <div class="guide-btns">
            <a-button v-if="guideIndex > 0" type="dashed" ghost @click="guideStepPrev">上一步</a-button>
            <a-button v-if="guideIndex < guideItem.length - 1" type="dashed" ghost @click="guideStepNext">下一步</a-button>
            <a-button v-if="guideIndex >= guideItem.length - 1" type="dashed" ghost @click="guideHide">知道了</a-button>
          </div>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
import Sum from '../../components/homeTool/sum.vue' // 项目总数
import Member from '../../components/homeTool/member.vue' // 空间成员
import Tool from '../../components/homeTool/tool.vue' // 仙助工具
import Fodder from '../../components/homeTool/fodder.vue' // 项目素材
import Copy from '../../components/homeTool/copy.vue' // 版权库
import Dynamic from '../../components/homeTool/dynamic.vue' // 项目动态
import Notice from '../../components/homeTool/notice.vue' // 系统公告
// import ProjectList from '../project/list.vue' // 项目列表
import Advertising from '../../components/homeTool/advertising.vue' // 广告
import System from '../../components/homeTool/system.vue' // 系统公告
export default {
  components: {
    Sum,
    Member,
    Tool,
    Fodder,
    Copy,
    Dynamic,
    Notice,
    // ProjectList,
    Advertising,
    System
  },
  data() {
    return {
      data: {},
      spinning: true, // 加载中
      guideShow: true, // 显示指引
      guideIndex: 0, // 指引步骤.
      guideItem: [
        {
          width: '25vw',
          height: '25vh',
          borderTop: '7.5vh',
          borderLeft: '8.5vw',
          content: '这是您参与的项目数量，点击创建项目'
        },
        {
          width: '25vw',
          height: '25vh',
          borderTop: '7.5vh',
          borderLeft: '33vw',
          content: '这是空间成员数量，点击邀请新成员'
        },
        {
          width: '25vw',
          height: '25vh',
          borderTop: '30vh',
          borderLeft: '8.5vw',
          content: '这是您项目内的数据统计，点击进入项目列表'
        },
        {
          width: '25vw',
          height: '25vh',
          borderTop: '30vh',
          borderLeft: '33vw',
          content: '这是您版权库的数据统计，点击进入版权库'
        },
        {
          width: '33vw',
          height: '25vh',
          borderTop: '7.5vh',
          borderLeft: '57.5vw',
          content: '更多小仙实用工具等您探索'
        },
      ], // 指引步骤内容
    }
  },
  created() {
    this.api.homeData().then(res => {
      if (res.code == 0) {
        this.data = res.data
        this.spinning = false
      }
    })
    if (window.localStorage.getItem('guideShow') && window.localStorage.getItem('guideShow') == 'false') {
      this.guideShow = false
    }
  },
  methods: {
    guideStepNext() {
      this.guideIndex += 1
    },
    guideStepPrev() {
      this.guideIndex -= 1
    },
    guideHide() {
      this.guideShow = false
      window.localStorage.setItem('guideShow', false)
    }
  }
}
</script>

<style scoped>
.home {
  /* height: 100%; */
  background: #eee;
  /* overflow: hidden; */
}
.sum {
}
.guide {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 99;
  overflow: hidden;
}
.guide-hollow {
  width: 21vw;
  height: 25vh;
  display: block;
  box-sizing: initial;
  position: absolute;
  left: 0;
  top: 0;
  box-shadow: inset 0 0 10px 10px rgba(0,0,0,.73);
  transition: all 0.5s;

  border-bottom: 100vw solid rgba(0, 0, 0, 0.7);
  border-right: 100vw solid rgba(0, 0, 0, 0.7);
}
.guide-control {
  position: absolute;
  left: 50%;
  bottom: -100px;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.guide-explain {
  width: 200px;
  font-size: 14px;
  color: #fff;
  text-align: center;
  margin-bottom: 10px;
}
.guide-btns {
  /* width: 180px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.guide-btns .ant-btn {
  margin: 0 10px;
}
</style>