/*
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-10-13 17:15:09
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-10-13 17:15:30
 * @FilePath: \h5case4.xiaoxxx.cne:\code\仙助数字资产管理平台\digital\src\store\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  //数据，相当于data
  state: {
    party: 1, // 1甲方 0乙方
    userInfo: {}, // 个人信息
    inviteId: null, // 邀请ID
    authA: [], // 甲方权限列表
    authB: [], // 乙方权限列表
    enterpriseId: null, // 乙方选中甲方公司id
    routerHistory: null, // 历史路由(无权限时使用)
  },
  getters: {

  },
  //里面定义方法，操作state方法
  mutations: {
    setParty(state, data) {
      window.localStorage.setItem('party', data) // 防止页面刷新数据丢失
      state.party = data
    },
    setUserInfo(state, data) {
      state.userInfo = data
    },
    setinviteId(state, data) {
      state.inviteId = data
    },
    setAuthA(state, data) {
      state.authA = data
    },
    setAuthB(state, data) {
      window.localStorage.setItem('authMerge', JSON.stringify(data)) // 防止页面刷新数据丢失
      state.authB = data
    },
    setEnterpriseId(state, data) {
      window.localStorage.setItem('enterpriseId', data) // 防止页面刷新数据丢失
      state.enterpriseId = data
    },
    setRouterHistory(state, data) {
      state.routerHistory = data
    }
  },
  // 操作异步操作mutation
  actions: {

  },
  modules: {

  },
})