<template>
  <div class="company-info">
    <div class="select" >
      <a-select v-show="companyList.length > 0" :default-value="id" style="width: 320px" @change="handleChange" placeholder="选择公司">
        <a-select-option v-for="item in companyList" :key="item.id" :auth="item.auth_c" :value="item.owner_id">
          {{item.owner && item.owner.name || '/'}}
        </a-select-option>
      </a-select>
      <a-spin v-show="companyList.length <= 0">
        <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
      </a-spin>
    </div>
    <router-view :companyId="id" :data="data"></router-view>
  </div>
</template>

<script>
export default {
  inject: ['reload', ],
  data() {
    return {
      id: null,
      data: {},
      companyList: []
    }
  },
  created() {
    console.log(this.$route)
    this.id = parseInt(this.$route.query.id)
    this.data.id = this.id
    this.getList()
  },
  methods: {
    getList() {
      this.api.companyLists({company_id: this.$store.state.userInfo.company_id, noPage: true}).then(res => {
        this.companyList = res.data.lists
      })
    },
    handleChange(value, option) {
      // // 合并auth_b和auth_c
      const authB = this.$store.state.userInfo?.auth_b || []
      this.$store.commit('setAuthB', [...authB, ...option.data.attrs.auth])
      this.$store.commit('setEnterpriseId', value)
      this.id = value
      this.$router.replace({query: {id: value}})
      this.reload()
    }
    // getDetails(id) {
    //   this.api.projectDetails({
    //     id: id
    //   }).then(res => {
    //     if (res.code == 0) {
    //       this.data = res.data.list
    //     }
    //   })
    // }
  }
}
</script>

<style scoped>
.select {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  border-bottom: 1px solid #ddd;
  box-sizing: border-box;
  padding: 0 25px;
}
.company-info {
  background: #fff;
  border-radius: 10px;
}
</style>