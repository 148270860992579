<template>
  <div class="header">
    <div class="logo" @click="linkTo('home')">
      <span>仙助</span>
      数字资产管理平台
    </div>
    <a-menu v-model="current" mode="horizontal" v-if="$store.state.userInfo.company_id != 0">
      <a-menu-item v-for="item in menu" :key="item.name" @click="linkTo(item.name)">{{ item.meta.menu }}</a-menu-item>
    </a-menu>
    <div class="info" v-if="$store.state.userInfo.company_id != 0">
      <div class="search" :style="searchShow && `width: 180px;`">
        <a-icon @click="searchShow = !searchShow" class="search-icon" type="search" />
        <input type="text">
      </div>
      <a-badge count="0" @click="linkTo('notice')">
        <a-icon class="message" type="bell" />
      </a-badge>
      <div class="user">
        <img :src="userInfo.avatar || 'https://res.xiaoxxx.com/urlGather/202304046d11af19d887dc6b731149.jpg'" alt="">
        <a-popover>
          <template slot="content">
            <p @click="linkTo('personal')">个人信息</p>
            <p @click="quit">退出登录</p>
          </template>
          <a-button type="link" style="color:#000">
            {{ userInfo.name || '匿名1' }}
          </a-button>
        </a-popover>
        <a-radio-group style="width: 90px" v-model="party" @change="partyChange" size="small">
          <a-radio-button :value="1">
            甲方
          </a-radio-button>
          <a-radio-button :value="0">
            乙方
          </a-radio-button>
        </a-radio-group>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['reload'],
  data() {
    return {
      userInfo: {},
      menu: [],
      current: ['home'],
      searchShow: false,
      party: this.$store.state.party
    }
  },
  watch: {
    $route(to) {
      this.$set(this.current, 0, to.matched[1].name)
    },
    '$store.state.userInfo'(n) {
      this.userInfo = n
    }
  },
  created() {
    // console.log(this.$route)
    this.$set(this.current, 0, this.$route.matched[1].name)
    const routes = this.$router.options.routes
    const index = routes.findIndex((item) => {
      return item.name == 'index'
    })
    this.menu = routes[index].children.filter((item) => {
      // 判断甲乙方
      const auth = this.$store.state.party ? this.$store.state.authA : this.$store.state.authB
      // console.log(this.$store.state.authB, auth)
      if (auth.indexOf(item.meta?.power) > -1 || item.meta?.power == 'any') {
        return item.meta?.menu && item.meta?.right?.indexOf(this.$store.state.party) > -1 && item
      }
    })
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
  },
  methods: {
    linkTo(name) {
      this.$router.push({ name: name })
    },
    quit() {
      window.localStorage.removeItem('userInfo')
      window.localStorage.removeItem('token')
      this.$router.replace({ name: 'login' })
    },
    partyChange(e) {
      this.$store.commit('setParty', e.target.value)
      this.reload()
    }
  }
}
</script>

<style scoped>
.header {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.logo {
  font-size: 16px;
  color: #DC2829;
  line-height: 55px;
  cursor: pointer;
}

.logo span {
  font-size: 30px;
}

.ant-menu {
  font-size: 16px;
  font-weight: 700;
}

.ant-menu-horizontal {
  line-height: 60px;
  border-bottom: none
}

.info {
  width: 425px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.search {
  width: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  transition: width 0.5s;
}

.search-icon {
  color: #999;
  font-size: 20px;
}

.search input {
  width: 150px;
  font-size: 14px;
  line-height: 20px;
  margin-left: 10px;
  border: none;
  outline: none;
  border-bottom: 1px solid #aaa;
}

.message {
  font-size: 20px;
  color: #999;
  margin-left: 20px;
  cursor: pointer;
}

.user {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.user img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.ant-popover-inner-content p {
  cursor: pointer;
  line-height: 26px;
}
</style>