<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-10-14 16:09:57
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-10-20 15:26:46
 * @FilePath: \h5case4.xiaoxxx.cne:\code\仙助数字资产管理平台\digital\src\components\table.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="table">
    <a-table
      :columns="columns"
      :row-key="record => record.id"
      :data-source="requestTableData.length > 0 ? requestTableData : tableData"
      :pagination="paginationShow ? pagination : false"
      :loading="loading || requestLoading"
      v-bind="getBindValues"
      @change="handleTableChange"
      :row-selection="selected ? { selectedRowKeys: selectedRowKeys, onChange: onSelectChange, getCheckboxProps: getCheckboxProps } : null">
      
      <template v-for="column in columns" :slot="column.scopedSlots?column.scopedSlots.customRender:''"
        slot-scope="text,record,index">
        <slot :name="column.scopedSlots ? column.scopedSlots.customRender : ''" v-bind="{ text, record, index }"></slot>
      </template>

    </a-table>
  </div>
</template>

<script>
export default {
  props: {
    columns: Array, // 表格项
    tableData: Array, // 表格数据
    total: Number, // 数据总数
    current: Number, // 当前页
    paginationShow: {
      type: Boolean,
      default: true
    }, // 是否启用分页器
    // pageSize: Number, // 每页条数
    loading: Boolean, // 加载中
    request: Function, // 数据接口
    param: Object, // 接口参数
    selected: Boolean, // 是否开启选择功能
    selectedProps: Function // 选择功能禁用标识
  },
  data() {
    return {
      data: [],
      requestLoading: false, // 加载
      pagination: {
        showLessItems: true,
        showSizeChanger: true,
        showQuickJumper: true,
        total: this.total || 20,
        current: this.current || 1,
        pageSize: this.param && this.param.pageSize || 10
      },
      created_at: 1, // 时间排序 1正序 2倒序
      slotArr: [], // 插槽数组
      requestTableData: [],
      selectedRowKeys: [], // 表格选中项
    }
  },
  computed: {
    getBindValues() {
      return { ...this.$attrs }
    }
  },
  watch: {
    total: function(n) {
      this.pagination.total = n
    },
  },
  created() {
    this.columns.forEach(e => {
      if (e.scopedSlots && e.scopedSlots.customRender) {
        this.slotArr.push(e.scopedSlots.customRender)
      }
    })
    this.getData()
  },
  methods: {
    // 调用数据接口
    getData() {
      if (this.request) {
        this.requestLoading = true
        this.request({ ...this.param, page: this.pagination.current, pageSize: this.pagination.pageSize, created_at: this.created_at }).then(res => {
          // 获取为空向上翻页重新获取
          if (this.pagination.current > 1 && (res.data.count > 0 || res.data.allCounts > 0) && res.data.lists.length <= 0) {
            this.pagination.current -= 1
            this.getData()
          } else {
            this.pagination.total = res.data.count || res.data.allCounts || 0
            this.requestTableData = res.data.lists || 0
            this.requestLoading = false
          }
        })
      }
    },
    // 表格变化触发
    handleTableChange(pagination, filters, sorter) {
      console.log(sorter)
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      if (sorter.order == 'ascend') {
        this.created_at = 1
      } else if (sorter.order == 'descend') {
        this.created_at = 2
      } else {
        this.created_at = 1
      }
      this.getData()
    },
    // 表格选择项
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.$emit('selectedChange', selectedRows)
    },
    getCheckboxProps(record) {
      if (this.selectedProps) {
        return this.selectedProps(record)
      } else {
        return {
          props: {
            name: record.name,
          }
        }
      }
    },
    // 清空表格
    clearSelected() {
      this.selectedRowKeys = []
    }
  },
}
</script>

<style scoped>
.table {
  width: 100%;
}
</style>