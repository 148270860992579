<template>
  <div class="picture-info">
    <a-page-header
      :title="info.number"
      :sub-title="info.updated_at + '更新'"
      @back="() => $router.go(-1)"
    >
    <template slot="extra">
      <!-- <a-button @click="download(info)" key="3" type="primary">
        下载
      </a-button> -->
      <a-button v-down="info.url" @click="download(info)" key="3" type="primary">
        下载
      </a-button>
      <a-popconfirm
        title="是否确认删除?"
        ok-text="是"
        cancel-text="否"
        @confirm="pictureDel"
      >
        <a-button type="primary">
          删除
        </a-button>
      </a-popconfirm>
    </template>
    </a-page-header>
    <a-input placeholder="图片名称" v-model="imgName" style="width: 500px; margin-bottom: 20px" />
    <a-button type="primary" style="margin-left: 10px" @click="save">保存名称</a-button>
    <a-row type="flex" justify="space-between">
      <a-col :span="17">
        <a-carousel arrows v-if="info">
          <div
            slot="prevArrow"
            class="custom-slick-arrow"
            style="left: 10px;zIndex: 1"
          >
            <a-icon type="left-circle" />
          </div>
          <div slot="nextArrow" class="custom-slick-arrow" style="right: 10px">
            <a-icon type="right-circle" />
          </div>
          <div class="slide">
            <img class="info-img" :src="info.url" alt="">
          </div>
          <!-- <div>
            <img :src="info.url" alt="">
          </div> -->
        </a-carousel>
      </a-col>
      <a-col :span="6">
        <h2>{{info.number}}</h2>
        <div >
          <a-card title="基础信息" style="width: 250px" size="small">
            <a-card-grid class="card-grid">
              尺寸：
            </a-card-grid>
            <a-card-grid class="card-grid" >
              {{info.size}}
            </a-card-grid>
            <a-card-grid class="card-grid" >
              大小：
            </a-card-grid>
            <a-card-grid class="card-grid" >
              {{info.extent}}kb
            </a-card-grid>
            <a-card-grid class="card-grid" >
              格式：
            </a-card-grid>
            <a-card-grid class="card-grid" >
              {{info.format}}
            </a-card-grid>
            <a-card-grid class="card-grid" >
              创建者：
            </a-card-grid>
            <a-card-grid class="card-grid" >
              {{info.create_user && info.create_user.name || '/'}}
            </a-card-grid>
            <a-card-grid class="card-grid" >
              创建日期：
            </a-card-grid>
            <a-card-grid class="card-grid" >
              {{info.created_at}}
            </a-card-grid>
            <!-- <a-card-grid class="card-grid" >
              更新者：
            </a-card-grid>
            <a-card-grid class="card-grid" >
              XXX
            </a-card-grid>
            <a-card-grid class="card-grid" >
              更新日期：
            </a-card-grid>
            <a-card-grid class="card-grid" >
              {{info.updated_at}}
            </a-card-grid> -->
            <a-card-grid class="card-grid">
              下载次数：
            </a-card-grid>
            <a-card-grid class="card-grid">
              {{info.download_num}}
            </a-card-grid>
          </a-card>
          <a-card
            style="width: 250px"
            size="small"
            :tab-list="tabList"
            @tabChange="tabChange">
            <a-icon slot="tabBarExtraContent" type="edit" @click="tagsVisible = true" />
            <div v-show="tabKey == 'label'">
              <a-tag v-for="(item, index) in pitchOn.onLabel" :key="index">
                {{item.name}}
              </a-tag>
            </div>
            <div v-show="tabKey == 'channel'">
              <a-tag v-for="(item, index) in pitchOn.onChannel" :key="index">
                {{item.name}}
              </a-tag>
            </div>
          </a-card>
        </div>
      </a-col>
    </a-row>

    <!-- 编辑标签弹窗 -->
    <a-modal
      title="选择标签"
      :visible="tagsVisible"
      @ok="save"
      @cancel="tagsVisible = false"
    >
      <a-card
        style="width:100%"
        size="small"
        :tab-list="tabList"
        @tabChange="tabChange"
      >
         <div v-show="tabKey == 'label'">
          <a-tag class="a-tag" v-for="item in pitchOn.onLabel" :key="'label' + item.id" closable @close="closeTag(item, 'onLabel')">
            {{item.name}}
          </a-tag>
          <a-divider>选择标签</a-divider>
          <a-tag class="a-tag" @click="changeTag(item, 'onLabel')" v-for="item in labelData" :key="item.id" :color="pitchOn.onLabel.find(e => e.id == item.id) ? 'red' : ''">{{item.name}}</a-tag>
          <a-input
            v-if="newTagVisible"
            ref="tagInput"
            type="text"
            size="small"
            :style="{ width: '78px', height: '29px' }"
            v-model="tagInputValue"
            @keyup.enter="tagInputConfirm('label')"
          />
          <a-tag v-else class="a-tag" style="background: #fff; borderStyle: dashed;" @click="showNewTagInput">
            <a-icon type="plus" /> New Tag
          </a-tag>
        </div>
        <div v-show="tabKey == 'channel'">
          <a-tag class="a-tag" v-for="item in pitchOn.onChannel" :key="'channel' + item.id" closable @close="closeTag(item, 'onChannel')">
            {{item.name}}
          </a-tag>
          <a-divider>选择渠道</a-divider>
          <a-tag class="a-tag" @click="changeTag(item, 'onChannel')" v-for="item in channelData" :key="item.id" :color="pitchOn.onChannel.find(e => e.id == item.id) ? 'red' : ''">{{item.name}}</a-tag>
          <a-input
            v-if="newTagVisible"
            ref="tagInput"
            type="text"
            size="small"
            :style="{ width: '78px', height: '29px' }"
            v-model="tagInputValue"
            @keyup.enter="tagInputConfirm('channel')"
          />
          <a-tag v-else class="a-tag" style="background: #fff; borderStyle: dashed;" @click="showNewTagInput">
            <a-icon type="plus" /> New Tag
          </a-tag>
        </div>
      </a-card>
    </a-modal>
    
  </div>
</template>

<script>
export default {
  props: ['data', 'imgId'],
  data() {
    return {
      id: null,
      imgName: '',
      info: {},
      tagsVisible: false, // 编辑标签弹窗
      labelData: [],
      channelData: [],
      tabList: [
        {
          key: 'label',
          tab: '标签',
        },
        {
          key: 'channel',
          tab: '渠道',
        },
      ],
      tabKey: 'label',
      pitchOn: {
        onLabel: [],
        onChannel: []
      },
      newTagVisible: false, // 新增标签框显示
      tagInputValue: ''
    }
  },
  created() {
    // this.id = this.$route.query.pictureId
    this.id = this.imgId
     console.log(this.imgId)
    this.api.pictureInfo({id: this.id}).then(res => {
      if (res.code == 0) {
        this.info = res.data.list
        this.imgName = res.data.list.name
        this.pitchOn.onLabel = this.info.labels.map((item) => item.name)
        this.pitchOn.onChannel = this.info.channels.map((item) => item.name)
      }
    })
    // 获取标签列表
    this.api.tagsList().then(res => {
      if (res.code == 0) {
        this.labelData = res.data.lists
      }
    })
    // 获取渠道列表
    this.api.channelList().then(res => {
      if (res.code == 0) {
        this.channelData = res.data.lists
      }
    })
  },
  methods: {
    tabChange(key) {
      this.tabKey = key
    },
    // 选择标签/渠道
    changeTag(item, type) {
      let flag = true
      let arrIndex = 0
      this.pitchOn[type].forEach((element, index) => {
        if (element.id == item.id) {
          arrIndex = index
          flag = false
        }
      })
      if (flag) {
        this.pitchOn[type].push(item)
      } else {
        this.pitchOn[type].splice(arrIndex, 1)
      }
    },
    // 取消标签/渠道
    closeTag(item, type) {
      console.log(item, type)
      this.pitchOn[type].forEach((element, index) => {
        if (element.id == item.id) {
          this.pitchOn[type].splice(index, 1)
        }
      })
      console.log(this.pitchOn[type])
    },
    save() {
      if (this.imgName) {
        let label = this.pitchOn.onLabel.map((item) => item.id)
        let channel = this.pitchOn.onChannel.map((item) => item.id)
        this.api.pictureEdit({
          ...this.info,
          project_id: this.data.id,
          id: this.id,
          label: label,
          channel: channel,
          name: this.imgName
        }).then(res => {
          if (res.code == 0) {
            this.message.success(res.message)
          } else {
            this.message.error(res.message)
          }
        })
      } else {
        this.message.error('请填写图片名称')
      }
    },
    download(info) {
      this.api.pictureDownload({id: info.id})
    },
    // 图片删除
    pictureDel() {
      console.log(this.data)
      this.api.pictureDel({id: this.id, company_id: this.data.company_id}).then(res => {
        if (res.code == 0) {
          this.message.success(res.message)
          // this.getList()
          window.location.reload()
        } else {
          this.message.error(res.message)
        }
      })
    },

    // 新增标签
    showNewTagInput() {
      this.tagInputValue = ''
      this.newTagVisible = true
      this.$nextTick(() => {
        this.$refs.tagInput.focus();
      });
    },
    // 新增标签确认
    tagInputConfirm(type) {
      console.log(this.tagInputValue)
      if (type == 'label') {
        this.api.tagsSave({name: this.tagInputValue, company_id: !this.$store.state.party && this.data.company.id}).then(res => {
          if (res.code == 0) {
            this.labelData.push(res.data)
            this.message.success(res.message)
          } else {
            this.message.error(res.message);
          }
          this.newTagVisible = false
        })
      }
      if (type == 'channel') {
        this.api.channelSave({name: this.tagInputValue, company_id: !this.$store.state.party && this.data.company.id}).then(res => {
          if (res.code == 0) {
            this.channelData.push(res.data)
            this.message.success(res.message)
          } else {
            this.message.error(res.message);
          }
          this.newTagVisible = false
        })
      }
    }
  }
}
</script>

<style scoped>
/* For demo */
.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 560px;
  line-height: 560px;
  background: #000;
  overflow: hidden;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.5;
}
.slide {
  width: 100%;
  height: 100%;
}
.info-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.card-grid {
  width:50%;
  text-align:center;
  padding: 10px;
}
.ant-tag {
  margin-bottom: 8px;
}
.a-tag {
  font-size: 14px;
  padding: 3px 6px;
}
</style>