<template>
  <div class="warning">
    <img src="https://res.xiaoxxx.com/urlGather/202306011f20d6696f1499da604351.png" alt="无权限">
    <p class="hint">{{word}}</p>
    <a-button type="primary" @click="goBack">点击返回</a-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      word: '出错啦~',
    }
  },
  created() {
    this.word = this.$route.query.word || '出错啦~'
  },
  methods: {
    goBack() {
      if (this.$store.state.routerHistory && this.$store.state.routerHistory != 'warning') {
        this.$router.replace({name: this.$store.state.routerHistory})
        this.$store.commit('setRouterHistory', null) // 删除路由记录
      } else {
        if (this.$store.state.party) {
          this.$router.replace({name: 'home'})
        } else {
          this.$router.replace({name: 'projectList'})
        }
      }
    }
  }
}
</script>

<style scoped>
.warning {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
img {
  width: 168px;
}
.hint {
  font-size: 16px;
  font-weight: 700;
  margin: 30px 0;
}
</style>