/* 数字滚动效果 */
<template>
  <div class="number">
    <div class="number-box">
      <div class="number-item" v-for="(item, index) in numArr" :key="index">
        <div v-if="item == '.'">.</div>
        <ul v-else :style="start && `transform: translateY(${item * -10}%);`">
          <li>0</li>
          <li>1</li>
          <li>2</li>
          <li>3</li>
          <li>4</li>
          <li>5</li>
          <li>6</li>
          <li>7</li>
          <li>8</li>
          <li>9</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'number',
  props: {
    num: Number
  },
  data() {
    return {
      numArr: [],
      start: false
    }
  },
  watch: {
    num(n) {
      this.numArr = n.toString().split('')
      setTimeout(() => {
        this.start = true
      }, 300)
    }
  },
  mounted() {
    if (this.num || this.num == 0) {
      this.numArr = this.num.toString().split('')
      setTimeout(() => {
        this.start = true
      }, 300)
    }
  }
}
</script>

<style scoped>
.number {
  font-size: inherit;
  font-weight: 700;
  display: inline-block;
}

.number-box {
  height: 1em;
  line-height: 1;
  display: flex;
  overflow: hidden;
}

.number-item {
  /* width: 10px; */
  position: relative;
}

ul {
  /* position: absolute; */
  transform: translateY(0%);
  transition: all 1s;
}

li {}</style>