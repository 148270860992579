<template>
  <div class="sum">
    <div class="title">
      <span>仙助工具箱</span>
    </div>
    <div class="content">
      <div class="item">
        <img src="../../assets/images/icon-watermark.png" alt="">
        <div>
          <p>图片加水印</p>
          <a href="https://tool.xiaoxxx.cn/#/shuiyin">立即制作 ></a>
        </div>
      </div>
      <div class="item">
        <img src="../../assets/images/icon-size.png" alt="">
        <div>
          <p>图片编辑尺寸</p>
          <a href="https://tool.xiaoxxx.cn/#/chicun">立即制作 ></a>
        </div>
      </div>
      <div class="item">
        <img src="../../assets/images/icon-interact.png" alt="">
        <div>
          <p>常用数字互动工具</p>
          <a href="https://tool.xiaoxxx.cn/#/">立即制作 ></a>
        </div>
      </div>
      <div class="item">
        <img src="../../assets/images/icon-await.png" alt="">
        <div>
          <p>更多工具敬请期待</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Member',
  data() {
    return {
      percent: 0,
      targetPercent: 80
    }
  },
  mounted() {
    window.requestAnimationFrame(this.step)
  },
  methods: {
    step() {
      if (this.percent < this.targetPercent) {
        this.percent += 2
        window.requestAnimationFrame(this.step)
      }
    }
  }
}
</script>

<style scoped>
.sum {
  background: #fff;
  border-radius: 10px;
}
.title {
  height: 50px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 0 10px 15px;
}
.content {
  min-height: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 0 20px;
}
.item {
  width: 180px;
  display: flex;
}
.item img {
  width: 40px;
  margin-right: 15px;
}
</style>