<style scoped>
    
    .w-e-full-screen-container{
        z-index: 1000;
    }
    .knowledge-base{
        background-color: #ffffff;
        border-radius: 10px;
        padding: 40px;
        /* height: 90vh; */
    }
    .head-left p{
        font-size: 14px;
        /* font-weight: 700; */
        margin-right: 30px;
        cursor: default ;
        color: rgba(0, 0, 0, 0.5);
    }
    .head-right p{
        font-size: 16px;
        white-space: nowrap;
        margin-left: 20px;
        cursor: default ;
    }
    .head{
        border-bottom: 1px solid #b8b6b6;
        padding-bottom: 10px;
    }
    .edit-view{
        padding: 20px 30px;
        position: relative;
        height: 540px;
    }
    .menu{
        /* border-left: solid 1px #b8b6b6; */
        /* padding: 0 0 0 50px; */
        padding: 0 25px;
        text-align: left;
        /* width: 250px; */
        height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        position: relative;
    }
    .menu::-webkit-scrollbar,.knowledgebase-read-content::-webkit-scrollbar {
        width: 1px;
    }
    .menu::-webkit-scrollbar-thumb ,.knowledgebase-read-content::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        opacity: 0.2;
        background: rgba(0, 0, 0, 0.3);
    }
    .menu::-webkit-scrollbar-track ,.knowledgebase-read-content::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
    }
    .read-view{
        height: calc(100vh - 294px);
        overflow: hidden;
        box-sizing: border-box;
        padding-top: 40px;
    }
    .knowledgebase-read-content{
        padding: 0 40px 40px;
        height: 100%;
        /* width: 100%; */
        flex: 1;
        overflow-x: hidden;
        overflow-y: scroll;
        /* margin-top: 20px; */
    }
    #knowledgebase_menus_1{
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        font-size: 2em;
    }
    #knowledgebase_menus_2{
        margin-bottom: 0.5em;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        font-size: 1.5em;
    }
    #knowledgebase_menus_3{
        margin-bottom: 0.5em;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        font-size: 1.17em;
    }
    #knowledgebase_menus_4{
        margin-bottom: 0.5em;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
    }
    #knowledgebase_menus_5{
        margin-bottom: 0.5em;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        font-size: 0.83em;
    }
    .menu-list{
        width: 200px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: default;
    }
    .baseactive{
        color: red;
    }
    .btn-list{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -20px;
        z-index: 1;
    }
    #tips-search{
        font-size: 14px;
        /* font-weight: 700; */
        /* margin-right: 30px; */
        cursor: default ;
        color: rgba(0, 0, 0, 0.5);
    }
    .headb{
        position: fixed;
        right: 78px;
        top: 102px;
        border: none;
    }
    .knowledge-baseb{
        padding-top: 0;
    }
</style>
<template>
    <div class="knowledge-base" :class="$store.state.party?'':'knowledge-baseb'">
        <a-spin :spinning="loading">
            <div class="head flex justify-content-between align-items-center" :class="$store.state.party?'':'headb'">
                <div class="head-left flex  align-items-center">
                    <!-- <p>搜索快捷键：ctrl + f</p> -->
                    <!-- <a-input v-model="keyword" :allowClear="true" @pressEnter="pressEnterFun" placeholder="搜索关键词" /> -->
                </div>
                <div class="head-right flex align-items-center">
                    <p id="tips-search">搜索快捷键：ctrl + f</p>
                    <a-button type="primary" block @click="changeeditfun('0')" style="margin-left: 10px;" v-if="isedit==false">编辑</a-button>
                    <!-- <p @click="changeeditfun('0')" style="color:red;" v-if="isedit==false">编辑</p> -->
                    <!-- <p @click="changeeditfun('0')" style="color:red;" v-if="$store.state.party && isedit==false">编辑</p> -->
                    <a-button key="submit" type="primary" :loading="false" @click="createRepository" v-if="isedit" style="margin-left: 10px;">保存</a-button>
                    
                    <!-- <a-popconfirm title="是否将文档恢复到上个版本" ok-text="确定" cancel-text="取消" @confirm="confirm" >
                        <a-button key="submit" v-if="isLocation" style="margin-left: 10px;">历史版本</a-button>
                    </a-popconfirm> -->
                </div>
            </div>
            <div class="edit-view" v-if="isedit">
                <div style="border: 1px solid #ccc; margin-top: 10px;margin-bottom: 10px;">
                    <!-- 工具栏 -->
                    <Toolbar
                        style="border-bottom: 1px solid #ccc"
                        :editor="editor"
                        :defaultConfig="toolbarConfig"
                    />
                    <!-- 编辑器 -->
                    <Editor
                        style="height: 400px; overflow-y: hidden"
                        :defaultConfig="editorConfig"
                        v-model="html"
                        @onChange="onChange"
                        @onCreated="onCreated"
                    />
                </div>
                <!-- <div class="btn-list">
                    <a-button key="submit" type="primary" :loading="false" @click="createRepository">保存</a-button>
                    <a-popconfirm title="是否将文档恢复到上个版本" ok-text="确定" cancel-text="取消" @confirm="confirm" >
                        <a-button key="submit" v-if="isLocation" style="margin-left: 10px;">历史版本</a-button>
                    </a-popconfirm>
                </div> -->
            </div>
            <div class="read-view flex" v-if="isedit==false&&html!=''&&html!='<p><br></p>'" >
                <div class="knowledgebase-read-content" v-html="html"></div>
                <div class="menu" v-if="menulist.length!=0">
                    <!-- <a-icon @click="isMenu = false" style="font-size: 25px;position: absolute;left:10px; top:0px;" v-if="isMenu" type="eye" theme="filled" />
                    <a-icon @click="isMenu = true" style="font-size: 25px;position: absolute;left:10px; top:0px;" v-if="isMenu==false" type="eye-invisible" theme="filled" /> -->
                    <div v-if="isMenu">
                        <div v-for="(item,index) in menulist" class="menu-list" :id="item.divid" :style="currentMenu==index?'color:red':''" :key="index" @click="scrollToEle(item,index)">{{item.title}}</div>
                    </div>
                </div>
            </div>
            <a-empty style="margin-top: 30px;" v-if="(html==''|| html=='<p><br></p>')&&isedit==false" />
        </a-spin>
        <a-modal :visible="visible" title="提示" @cancel="visible = false" @ok="handleHtml">
            <p>检测到您有未保存的改动，是否覆盖当前版本？</p>
        </a-modal>
    </div>
</template>
<script>
import { Editor, Toolbar  } from "@wangeditor/editor-for-vue";
    export default{
        data(){
            return{
                isMenu:true,
                isLocation:false,
                visible:false,
                loading:false,
                currentMenu:-1,
                menulist:[],
                keyword:'',
                isedit:false,
                editor: null,
                html: "<p><br></p>",
                toolbarConfig: {
                    // toolbarKeys: [ /* 显示哪些菜单，如何排序、分组 */ ],
                    // excludeKeys: [ /* 隐藏哪些菜单 */ ],
                },
                editorConfig: {
                    placeholder: "请输入内容...",
                    // autoFocus: false,

                    // 所有的菜单配置，都要在 MENU_CONF 属性下
                    MENU_CONF: {
                        uploadImage: {
                            server: this.api.uploadUrl,
                            fieldName: 'file',
                            meta: {
                            file_name: '1',
                            },
                            customInsert: this.customInsert
                        },
                        uploadVideo: {
                            server: this.api.uploadUrl,
                            fieldName: 'file',
                            meta: {
                                file_name: '1',
                            },
                            customInsert: this.customInsertVideo
                        },
                        // header1:{
                        //     fontSizeList:['3.2em']
                        // }
                    },
                },
                knowledgebase:'',
                iscover:false
            }
        },
        components: { Editor, Toolbar },
        created(){
            // if(window.localStorage.getItem('knowledhtml')){
            //     this.visible = true
            // }
            this.getEditorMessage()
        },
        methods:{
            confirm(){
                this.api.getKnowledge({
                    type:1
                }).then((res)=>{
                    if(res.code==0){
                        this.loading = false
                        this.html = res.data.knowledge==null?'':res.data.knowledge.content
                        this.iscover = false
                    }
                })
            },
            handleHtml(){
                console.log('00',JSON.parse(window.localStorage.getItem('knowledhtml')).html)
                this.html = JSON.parse(window.localStorage.getItem('knowledhtml')).html
                this.visible = false
                this.isLocation = true
            },
            customInsert(res, insertFn) {
                insertFn(res.data.url)
            },
            customInsertVideo(res,insertFn){
                insertFn(res.data.url)
            },
            cutkNowledgebase(){
                this.loading = true
                this.isedit = false
                this.iscover = false
                this.getEditorMessage()
            },
            createRepository(){
                this.loading = true
                if(this.knowledgebase){
                    console.log('22')
                    this.api.getKnowledgeUpdate({
                        id:this.knowledgebase.id,
                        content:this.html
                    }).then((res)=>{
                        console.log(res)
                        window.localStorage.setItem('knowledhtml','')
                        if(res.code==0){
                            this.getEditorMessage()
                            this.message.success(res.message)
                            this.isedit = false

                        }
                    })
                }else{
                    this.api.getKnowledgeCreate({
                        type:1,
                        content:this.html
                    }).then((res)=>{ 
                        console.log(res)
                        window.localStorage.setItem('knowledhtml','')
                        if(res.code==0){
                            this.getEditorMessage()
                            this.message.success(res.message)
                            this.isedit = false
                        }
                    })
                }
            },
            getEditorMessage(){
                this.api.getKnowledge({
                    type:1
                }).then((res)=>{
                    if(res.code==0){
                        this.loading = false
                        this.html = res.data.knowledge==null?'':res.data.knowledge.content
                        this.knowledgebase = res.data.knowledge
                        setTimeout(() => {
                            this.hanlermenu()
                        }, 10);
                    }
                })
            },
            pressEnterFun(){
                if(!this.isedit){
                    let readview = document.querySelector('.knowledgebase-read-content')
                    for(let i=0;i<readview.children.length;i++){
                        readview.children[i].className = ''
                        if(readview.children[i].innerHTML.indexOf(this.keyword)!=-1){
                            readview.children[i].className = 'baseactive'
                            readview.children[i].scrollIntoView()
                        }
                    }
                }else{
                    this.html.indexOf(this.keyword)!==-1
                }

            },
            scrollToEle(item,index){
                this.currentMenu = index
                document.querySelector(item.id).scrollIntoView()
				// window.scrollTo(0,item.scrollTop-100)
			},
            changeeditfun(str){
                if(str==0){
                    this.keyword = ''
                    this.isedit = true
                }else{
                    this.isedit = false
                    setTimeout(() => {
                        this.hanlermenu()
                    }, 10);
                }
            },
            onCreated(editor) {
                this.editor = Object.seal(editor); // 【注意】一定要用 Object.seal() 否则会报错
                // this.iscover = true
            },
            onChange(editor) {
                // console.log(editor.children)
                // editor.children[0] && (editor.children[0]['textAlign'] = 'center')
                // console.log("onChange", editor.getHtml()); // onChange 时获取编辑器最新内容
                if(this.iscover){
                    this.html = editor.getHtml()
                }else{
                    this.iscover = true
                }
                console.log(this.html)
            },
            hanlermenu(){
                console.log('33')
                if(this.html==''||this.html=='<p><br></p>'){
                    return
                }
                let readview = document.querySelector('.knowledgebase-read-content')
                let els = readview.querySelectorAll("h1,h2,h3,h4,h5")
                let menus = []
                
                if(els){
                    els.forEach((item,index)=>{
                        let menu = {}
                        menu.isActive = null
						// 增加一个id标识
						menu.title = item.innerText
						menu.scrollTop = item.offsetTop
						let level = item.tagName.toLowerCase().replace("h", "")
						menu.level = parseInt(level)
                        item.setAttribute("id", `knowledgebase_menus_${index+1}`)
                        menu.id = `knowledgebase_menus_${index+1}`
                        if(item.nodeName=='H1'){
                            menu.divid = `knowledgebase_menus_1`
                        }else if(item.nodeName=='H2'){
                            menu.divid = `knowledgebase_menus_2`
                        }else if(item.nodeName=='H3'){
                            menu.divid = `knowledgebase_menus_3`
                        }else if(item.nodeName=='H4'){
                            menu.divid = `knowledgebase_menus_4`
                        }else if(item.nodeName=='H5'){
                            menu.divid = `knowledgebase_menus_5`
                        }
                        // console.log(item)
                        menus.push(menu)
                    })
                }
                // console.log(els)
                // console.log('00',menus)
                // console.log('00',this.menulist)
                this.menulist = menus
                console.log(this.menulist)
            }
        }
    }
</script>