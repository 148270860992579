<template>
  <div class="result">
    <a-page-header
      :title="data.name || '项目名称...'"
      :breadcrumb="{props: {routes}}"
      sub-title="项目总结"
    />
    <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="项目描述">
        <a-input v-model="form.describe" v-power:disabled="'project/summary/projectSummary'" />
      </a-form-model-item>
      <a-form-model-item label="项目目标">
        <a-input v-model="form.target" v-power:disabled="'project/summary/projectSummary'" />
      </a-form-model-item>
      <a-form-model-item label="项目完成情况">
        <a-input v-model="form.completion" v-power:disabled="'project/summary/projectSummary'" />
      </a-form-model-item>
      <a-form-model-item label="投入产出情况">
        <a-input v-model="form.io" v-power:disabled="'project/summary/projectSummary'" />
      </a-form-model-item>
      <a-form-model-item label="物料信息">
        <table border="1">
          <tr>
            <th></th>
            <th>图片物料</th>
            <th>文案物料</th>
            <th>H5链接</th>
          </tr>
          <tr>
            <td>数量</td>
            <td>{{data.picture_count}}</td>
            <td>{{data.copywriting_count}}</td>
            <td>{{data.url_count}}</td>
          </tr>
        </table>
      </a-form-model-item>
      <a-form-model-item label="营销渠道数据">
        <table border="1">
          <tr>
            <th>渠道名称</th>
            <th>图片物料</th>
            <th>文案物料</th>
            <th>H5链接</th>
          </tr>
          <tr v-for="item in data.channels" :key="item.id">
            <td>{{item.name}}</td>
            <td>{{item.pictures_count}}</td>
            <td>{{item.copywritings_count}}</td>
            <td>{{item.urls_count}}</td>
          </tr>
        </table>
      </a-form-model-item>
      <a-form-model-item label="营销分析">
        <a-input v-model="form.analysis" v-power:disabled="'project/summary/projectSummary'" />
      </a-form-model-item>
      <a-form-model-item label="运营人员反馈">
        <a-input v-model="form.operators_feedback" v-power:disabled="'project/summary/projectSummary'" />
      </a-form-model-item>
      <a-form-model-item label="用户反馈">
        <a-input v-model="form.user_feedback" v-power:disabled="'project/summary/projectSummary'" />
      </a-form-model-item>
      <a-form-model-item label="后续项目优化建议">
        <a-input v-model="form.proposal" v-power:disabled="'project/summary/projectSummary'" />
      </a-form-model-item>
    </a-form-model>
    <div class="btns">
      <a-button type="primary" @click="exportHtml">导出HTML</a-button>
      <a-button type="primary" @click="save" :loading="saveLoading" v-power:disabled="'project/summary/projectSummary'">保存</a-button>
      <a-button>取消</a-button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['data'],
  data() {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 12 },
      routes: [
        {
          path: '/projectList',
          breadcrumbName: '项目列表'
        },
        {
          path: '',
          breadcrumbName: '项目总结'
        },
      ],
      form: {
        desc: '',
        target: '',
        performance: '',
        output: '',
        analyse: '',
        feedback: '',
        userFeedback: '',
        optimize: ''
      },
      saveLoading: false // 保存按钮loading
    }
  },
  watch: {
    data(n) {
      this.form = n
      console.log(this.form)
    }
  },
  created() {
    this.form = this.data
  },
  methods: {
    save() {
      this.saveLoading = true
      this.api.projectSummaryEdit({
        id: this.data.id,
        describe: this.form.describe,
        target: this.form.target,
        completion: this.form.completion,
        io: this.form.io,
        channel: this.form.channel,
        analysis: this.form.analysis,
        operators_feedback: this.form.operators_feedback,
        user_feedback: this.form.user_feedback,
        proposal: this.form.proposal,
      }).then(res => {
        this.saveLoading = false
        if (res.code == 0) {
          this.message.success(res.message)
        }
      })
    },
    exportHtml() {
      let loopChannel = ''
      this.data.channel.forEach(element => {
        loopChannel += `
          <tr>
            <td>${element.name}</td>
            <td>${element.picture_count}</td>
            <td>${element.copywriting_count}</td>
          </tr>
        `
      });
      let htmlArr = [`
        <!DOCTYPE html>
        <html lang="en">
        <head>
          <meta charset="UTF-8">
          <meta http-equiv="X-UA-Compatible" content="IE=edge">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Document</title>
          <style>
            li {
              margin: 20px 0;
            }
            th, td {
              text-align: center
            }
          </style>
        </head>
        <body>
          <h1>${this.data.name || '项目名称'}</h1>
          <ul>
            <li>
              <span>项目描述：</span>
              <span>${this.form.describe}</span>
            </li>
            <li>
              <span>项目目标：</span>
              <span>${this.form.target}</span>
            </li>
            <li>
              <span>项目完成情况：</span>
              <span>${this.form.completion}</span>
            </li>
            <li>
              <span>投入产出情况：</span>
              <span>${this.form.io}</span>
            </li>
            <li>
              <span>物料信息：</span>
              <table border="1" cellspacing="0">
                <tr>
                  <th></th>
                  <th>图片物料</th>
                  <th>文案物料</th>
                </tr>
                <tr>
                  <td>数量</td>
                  <td>${this.data.picture_count}</td>
                  <td>${this.data.copywriting_count}</td>
                </tr>
              </table>
            </li>
            <li>
              <span>营销渠道数据：</span>
              <table border="1" cellspacing="0">
                <tr>
                  <th>渠道名称</th>
                  <th>图片物料</th>
                  <th>文案物料</th>
                </tr>
                ${loopChannel}
              </table>
            </li>
            <li>
              <span>营销分析：</span>
              <span>${this.form.analysis}</span>
            </li>
            <li>
              <span>运营人员反馈：</span>
              <span>${this.form.operators_feedback}</span>
            </li>
            <li>
              <span>用户反馈：</span>
              <span>${this.form.user_feedback}</span>
            </li>
            <li>
              <span>后续项目优化建议：</span>
              <span>${this.form.proposal}</span>
            </li>
          </ul>
        </body>
        </html>
      `]
      // 创建blob
      let htmlBlob = new Blob(htmlArr, {type : 'text/html'})
      // 下载文件
      let aTag = document.createElement('a');//创建一个a标签
      aTag.download = this.data.name || '项目名称';
      let href = URL.createObjectURL(htmlBlob);//获取url
      aTag.href = href;
      aTag.click();
      URL.revokeObjectURL(href);//释放url
    }
  }
}
</script>

<style scoped>
.result {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
}
tr {

}
th {
  text-align: center;
}
td {
  width: 100px;
  text-align: center;
}
.btns {
  width: 300px;
  display: flex;
  justify-content: space-between;
  margin: 50px auto;
}
</style>