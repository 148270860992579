<template>
  <div class="picture">
    <a-page-header
      :title="data.name || '项目名称...'"
      :breadcrumb="{props: {routes}}"
      sub-title="图片物料"
    >
    <template slot="tags" v-if="!$store.state.party && data.company">
        <router-link :to="{name: 'companyProjectList', query: {id: data.company.id}}">
          <a-button type="link"  style="margin-top: -4px">
            {{data.company.name}}
          </a-button>
        </router-link>
      </template>
    </a-page-header>
    <a-form-model :model="form" layout="inline" style="margin: 10px 24px 20px 24px">
      <a-form-model-item>
        <a-select
          style ="width: 180px"
          show-search v-model="form.label_id"
          placeholder="选择标签">
          <a-select-option v-for="i in labelData" :key="i.id" :value="i.id">{{i.name}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item>
        <a-select
          style ="width: 180px"
          v-model="form.channel_id"
          placeholder="选择渠道"
          >
          <a-select-option v-for="i in channelData" :key="i.id" :value="i.id">{{i.name}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item>
        <a-select style ="width: 100px" v-model="form.self" placeholder="是否本人">
          <a-select-option :value="1">本人</a-select-option>
          <a-select-option :value="2">非本人</a-select-option>
        </a-select>
      </a-form-model-item>
      <!-- <a-form-model-item>
        <a-select style ="width: 180px" v-model="form.user_id" placeholder="选择关键人">
          <a-select-option v-for="i in operatorData" :key="i.user_id" :value="i.user_id">{{i.user?.name || i.user?.id}}</a-select-option>
        </a-select>
      </a-form-model-item> -->
      <a-form-model-item label="创建日期">
        <a-range-picker v-model="date" @change="onChange" style="width: 250px" />
      </a-form-model-item>
      <a-form-model-item label="关键字">
        <a-input v-model="form.keywords" placeholder="请输入图片名称" style ="width: 150px" />
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click="getList()" shape="circle" icon="search"></a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click="reset">重置</a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click="sortCut">
          {{sort == 1 ? '时间正序' : '时间倒序'}}
          <a-icon v-if="sort == 1" type="caret-up" />
          <a-icon v-else type="caret-down" />
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <div class="edit">
      <div>
        <a-space>
          <a-checkbox :checked="allChecked" :indeterminate="indeterminate" @change="onCheckAllChange">全选</a-checkbox>
          <a-button size="small" @click="batchEdit" :disabled="allChecked || indeterminate ? false : true" v-power="'project/picture/batchUpdate'">编辑标签/渠道</a-button>
          <a-button size="small" v-downZip="[...checkList.map(i => {return {name: i.name, url: i.url}})]" @click="downStats(checkList.map(i => i.id))" :disabled="allChecked || indeterminate ? false : true" v-power="'project/picture/download'">下载</a-button>
          <a-popconfirm
            title="是否确认删除?"
            ok-text="是"
            cancel-text="否"
            @confirm="pictureDel()"
            :disabled="allChecked || indeterminate ? false : true"
          >
            <a-button size="small" type="danger" :disabled="allChecked || indeterminate ? false : true" v-power="'project/picture/softDeletes'">删除</a-button>
          </a-popconfirm>
          
          <!-- <a-button size="small" @click="sortCut">{{sort == 1 ? '时间正序' : '时间倒序'}}</a-button> -->
        </a-space>
      </div>
      <a-space>
        <a-button type="primary" @click="imgVisible = true">
          同步图片
        </a-button>
        <a-button type="primary" @click="uploadVisible = true" v-power:disabled="'project/picture/create'">
          上传图片
        </a-button>
      </a-space>
    </div>

    <a-row type="flex" justify="center" style="margin-top: 20px">
      <a-col :span="24">
        <a-spin :spinning="loading">
          <ul v-if="list.length > 0" class="img-list">
            <li v-for="(item, index) in list" :key="item.id" class="img-item">
              <p class="img-name">{{item.name || '暂无名称'}}</p>
              <img @click="imgInfo(item, index)" :src="item.url" alt="">
              <div class="img-handle">
                <div class="img-handle-tag">
                  <span>标签：</span>
                  <a-tag class="img-handle-tag-item" v-for="(i, index) in item.labels" :key="index" color="red">{{i.name.name}}</a-tag>
                </div>
                <div class="img-handle-tag">
                  <span>渠道：</span>
                  <a-tag class="img-handle-tag-item" v-for="(i, index) in item.channels" :key="index" color="blue">{{i.name.name}}</a-tag>
                </div>
                
                <div>
                  <a-checkbox :checked="checkList.map(i => i.id).indexOf(item.id) > -1" @change="onCheckChange(item)" />
                  <span>{{item.number}}</span>
                  <span>下载次数: {{item.download_num}}</span>
                  <a-popover>
                    <template slot="content">
                      <a-button type="link" block @click="editTag(item)">
                        编辑标签/渠道
                      </a-button>
                      <a-divider />
                      <a-button v-down="{name: item.name, url: item.url}" @click="downStats(item.id, index)" type="link" block v-power="'project/picture/download'">
                        下载
                      </a-button>
                      <a-divider />
                      <a-popconfirm
                        title="是否确认删除?"
                        ok-text="是"
                        cancel-text="否"
                        @confirm="pictureDel(item.id)"
                      >
                        <a-button type="link" block>
                          删除
                        </a-button>
                      </a-popconfirm>
                    </template>
                    <span class="ellipsis">
                      <a-icon type="ellipsis" />
                    </span>
                  </a-popover>
                </div>
              </div>
            </li>
          </ul>
          <a-empty v-else />
        </a-spin>
      </a-col>
    </a-row>
    <a-pagination
      v-model="current"
      show-quick-jumper
      :page-size-options="['10', '20', '30', '40']"
      :total="total"
      show-size-changer
      :page-size="pageSize"
      @change="onPageChange"
      @showSizeChange="onShowSizeChange"
    >
      <template slot="buildOptionText" slot-scope="props">
        <span v-if="props.value !== '50'">{{ props.value }}条/页</span>
        <span v-if="props.value === '50'">全部</span>
      </template>
    </a-pagination>

    <!-- 弹窗 -->
    <!-- 编辑标签 -->
    <a-modal
      title="选择标签"
      :visible="tagsVisible"
      :confirm-loading="tagLoading"
      @ok="tagsSave"
      @cancel="tagsVisible = false"
    >
      <a-card
        style="width:100%"
        size="small"
        :tab-list="[
        {
          key: 'label',
          tab: '标签',
        },
        {
          key: 'channel',
          tab: '渠道',
        }
      ]"
         @tabChange="tabChange"
      >
         <div v-show="tabKey == 'label'">
          <a-tag class="a-tag" v-for="(item) in pitchOn.onLabel" :key="'label' + item.id" closable @close="closeTag(item, 'onLabel')">
            {{item.name}}
          </a-tag>
          <a-divider>选择标签</a-divider>
          <a-tag class="a-tag" @click="changeTag(item, 'onLabel')" v-for="item in labelData" :key="item.id" :color="pitchOn.onLabel.find(e => e.id == item.id) ? 'red' : ''">{{item.name}}</a-tag>
          <a-input
            v-if="newTagVisible"
            ref="tagInput"
            type="text"
            size="small"
            :style="{ width: '78px', height: '29px' }"
            v-model="tagInputValue"
            @keyup.enter="tagInputConfirm('label')"
          />
          <a-tag v-else class="a-tag" style="background: #fff; borderStyle: dashed;" @click="showNewTagInput">
            <a-icon type="plus" /> New Tag
          </a-tag>
        </div>
        <div v-show="tabKey == 'channel'">
          <a-tag class="a-tag" v-for="(item) in pitchOn.onChannel" :key="'channel' + item.id" closable @close="closeTag(item, 'onChannel')">
            {{item.name}}
          </a-tag>
          <a-divider>选择渠道</a-divider>
          <a-tag class="a-tag" @click="changeTag(item, 'onChannel')" v-for="item in channelData" :key="item.id" :color="pitchOn.onChannel.find(e => e.id == item.id) ? 'red' : ''">{{item.name}}</a-tag>
          <a-input
            v-if="newTagVisible"
            ref="tagInput"
            type="text"
            size="small"
            :style="{ width: '78px', height: '29px' }"
            v-model="tagInputValue"
            @keyup.enter="tagInputConfirm('channel')"
          />
          <a-tag v-else class="a-tag" style="background: #fff; borderStyle: dashed;" @click="showNewTagInput">
            <a-icon type="plus" /> New Tag
          </a-tag>
        </div>
      </a-card>
    </a-modal>
    <!-- 同步图片 -->
    <a-modal
      title="同步图片"
      :visible="imgVisible"
      :width="1200"
      @ok="syncImg"
      @cancel="imgVisible = false"
    >
      <a-space style="margin-bottom: 15px">
        <a-checkbox :checked="taskAllChecked" :indeterminate="taskIndeterminate" @change="onTaskCheckAllChange">全选</a-checkbox>
        <a-input v-model="taskCode" placeholder="输入需求编号查询图片" />
        <a-button shape="circle" icon="search" @click="getTaskImg"></a-button>
      </a-space>
      <ul class="img-list" v-if="taskImgList.length > 0">
        <li class="sync-img-item" v-for="item in taskImgList" :key="item.id">
          <img :src="item.url" alt="">
          <!-- <a-checkbox>需求编号: 4H94HG</a-checkbox> -->
          <a-checkbox :checked="taskCheckList.map(i => i.id).indexOf(item.id) > -1" @change="onTaskCheckChange(item)">{{item.name}}</a-checkbox>
        </li>
      </ul>
      <div v-else>
        <a-empty description="暂无数据或需求编号不正确" />
      </div>
      <a-pagination
        v-model="taskCurrent"
        show-quick-jumper
        :page-size-options="['10', '20', '30', '40']"
        :total="taskTotal"
        show-size-changer
        :page-size="taskPageSize"
        @change="onTaskPageChange"
        @showSizeChange="onTaskShowSizeChange"
      >
        <template slot="buildOptionText" slot-scope="props">
          <span v-if="props.value !== '50'">{{ props.value }}条/页</span>
          <span v-if="props.value === '50'">全部</span>
        </template>
      </a-pagination>
    </a-modal>
    <!-- 上传图片抽屉 -->
    <a-drawer
      title="上传图片"
      width="900"
      placement="right"
      :closable="false"
      :visible="uploadVisible"
      @close="uploadVisible = false"
    >
      <a-upload
        :action="api.uploadUrl"
        :data="{'file_name': '1'}"
        list-type="picture-card"
        :file-list="fileList"
        :multiple="true"
        @preview="handlePreview"
        @change="handleChange"
      >
        <div v-if="fileList.length < 8">
          <a-icon type="plus" />
          <div class="ant-upload-text">
            Upload
          </div>
        </div>
      </a-upload>
      <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
        <img alt="example" style="width: 100%" :src="previewImage" />
      </a-modal>
      
      <a-card
        style="width:100%"
        size="small"
        :tab-list="[
        {
          key: 'label',
          tab: '标签',
        },
        {
          key: 'channel',
          tab: '渠道',
        }
      ]"
         @tabChange="tabChange"
      >
         <div v-show="tabKey == 'label'">
          <a-tag class="a-tag" v-for="(item) in pitchOn.onLabel" :key="'label' + item.id" closable @close="closeTag(item, 'onLabel')">
            {{item.name}}
          </a-tag>
          <a-divider>选择标签</a-divider>
          <a-tag class="a-tag" @click="changeTag(item, 'onLabel')" v-for="item in labelData" :key="item.id" :color="pitchOn.onLabel.find(e => e.id == item.id) ? 'red' : ''">{{item.name}}</a-tag>
          <a-input
            v-if="newTagVisible"
            ref="tagInput"
            type="text"
            size="small"
            :style="{ width: '78px', height: '29px' }"
            v-model="tagInputValue"
            @keyup.enter="tagInputConfirm('label')"
          />
          <a-tag v-else class="a-tag" style="background: #fff; borderStyle: dashed;" @click="showNewTagInput">
            <a-icon type="plus" /> New Tag
          </a-tag>
        </div>
        <div v-show="tabKey == 'channel'">
          <a-tag class="a-tag" v-for="(item) in pitchOn.onChannel" :key="'channel' + item.id" closable @close="closeTag(item, 'onChannel')">
            {{item.name}}
          </a-tag>
          <a-divider>选择渠道</a-divider>
          <a-tag class="a-tag" @click="changeTag(item, 'onChannel')" v-for="item in channelData" :key="item.id" :color="pitchOn.onChannel.find(e => e.id == item.id) ? 'red' : ''">{{item.name}}</a-tag>
          <a-input
            v-if="newTagVisible"
            ref="tagInput"
            type="text"
            size="small"
            :style="{ width: '78px', height: '29px' }"
            v-model="tagInputValue"
            @keyup.enter="tagInputConfirm('channel')"
          />
          <a-tag v-else class="a-tag" style="background: #fff; borderStyle: dashed;" @click="showNewTagInput">
            <a-icon type="plus" /> New Tag
          </a-tag>
        </div>
      </a-card>
      <div
        class="drawer-footer"
      >
        <a-button style="margin-right: 8px" @click="uploadVisible = false">
          取消
        </a-button>
        <a-button type="primary" @click="uploadSave" :loading="upLoading">
          上传
        </a-button>
      </div>
    </a-drawer>
    <!-- 图片详情弹窗 -->
    <a-modal
      title="图片详情"
      :width="1200"
      :visible="imgInfoVisible"
      @cancel="imgInfoVisible = false"
      :footer="null"
    >
      <div style="min-height: 700px">
        <img class="img-arrows" style="left: 50px" @click="imgSwitch('prev')" src="https://res.xiaoxxx.com/urlGather/20230706758617524278ebb4628945.png" alt="">
        <img class="img-arrows" style="right: 50px" @click="imgSwitch('next')" src="https://res.xiaoxxx.com/urlGather/20230706865c47e56229d2a5472159.png" alt="">
        <PictureInfo v-if="imgInfoVisible" :data="data" :imgId="pictureInfoId" :key="pictureInfoId"></PictureInfo>
      </div>
    </a-modal>
  </div>
</template>

<script>
import PictureInfo from './pictureInfo.vue'
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
export default {
  props: ['data'],
  components: {
    PictureInfo
  },
  data() {
    return {
      routes: [
        {
          path: '/projectList',
          breadcrumbName: '项目列表'
        },
        {
          path: '',
          breadcrumbName: '图片物料'
        },
      ],
      sort: 1, // 排序方式 1正序 2倒序
      list: [],
      loading: true,
      date: [],
      form: {
        label_id: undefined,
        user_id: undefined,
        channel_id: undefined,
        person: undefined,
        keyword: '',
        start_time: '',
        end_time: '',
        self: undefined,
        keywords: ''
      },
      current: 1,
      total: 0,
      pageSize: 30,
      tagsVisible: false, // 编辑标签弹窗
      imgVisible: false, // 同步图片弹窗
      uploadVisible: false, // 上传图片抽屉
      imgInfoVisible: false, // 图片详情弹窗
      pictureInfoId: null, // 图片id
      pictureInfoIndex: 0, // 图片
      previewVisible: false,
      fileList: [ // 图片列表
      ],
      previewImage: '',
      img: '',
      tagId: null,
      tabKey: 'label',
      tabList: [
        {
          key: 'label',
          tab: '标签',
        },
        {
          key: 'channel',
          tab: '渠道',
        },
      ],
      labelData: [],
      channelData: [],
      operatorData: [],
      pitchOn: {
        onLabel: [],
        onChannel: []
      },
      checkList: [],
      indeterminate: false,
      allChecked: false,

      upLoading: false, // 上传按钮loading
      tagLoading: false, // 标签渠道按钮loading

      taskImgList: [], // 同步图片列表
      taskCode: '', // 合同编号
      taskCurrent: 1,
      taskTotal: 0,
      taskPageSize: 10,
      taskCheckList: [],
      taskIndeterminate: false,
      taskAllChecked: false,

      newTagVisible: false, // 新增标签框显示
      tagInputValue: ''
    }
  },
  watch: {
    data() {
      this.getList()
      // 获取关键人
      this.api.operatorList({project_id: this.data.id}).then(res => {
        if (res.code == 0) {
          this.operatorData = res.data.lists
        }
      })
    }
  },
  created() {
    this.getList()
    // 获取标签列表
    this.api.tagsList().then(res => {
      if (res.code == 0) {
        this.labelData = res.data.lists
      }
    })
    // 获取渠道列表
    this.api.channelList().then(res => {
      if (res.code == 0) {
        this.channelData = res.data.lists
      }
    })
    // 获取关键人
    this.data.id && this.api.operatorList({project_id: this.data.id}).then(res => {
      if (res.code == 0) {
        this.operatorData = res.data.lists
      }
    })
  },
  methods: {
    getList() {
      if (this.data.id) {
        this.loading = true
        this.api.pictureList({
          project_id: this.data.id,
          page: this.current,
          pageSize: this.pageSize,
          created_at: this.sort,
          ...this.form
        }).then(res => {
          if (res.code == 0) {
            this.loading = false
            this.list = res.data.lists
            this.total = res.data.allCounts
          }
        })
      }
    },
    // 时间选择
    onChange(date, dateString) {
      console.log(date, dateString);
      this.form.start_time = dateString[0]
      this.form.end_time = dateString[1]
    },
    // 重置
    reset() {
      this.date = []
      this.form = {
        label_id: undefined,
        user_id: undefined,
        channel_id: undefined,
        person: undefined,
        keyword: '',
        start_time: '',
        end_time: ''
      }
      this.getList()
    },
    onPageChange(page) {
      this.current = page
      this.checkList = []
      this.indeterminate = false
      this.allChecked = false
      this.getList()
    },
    onTaskPageChange(page) {
      this.taskCurrent = page
      this.getTaskImg()
    },
    onShowSizeChange(current, pageSize) {
      this.pageSize = pageSize;
      this.getList()
    },
    onTaskShowSizeChange(current, pageSize) {
      this.taskPageSize = pageSize
      this.getTaskImg()
    },
    // 图片详情
    imgInfo(item, index) {
      this.pictureInfoId = item.id
      this.pictureInfoIndex = index
      this.imgInfoVisible = true
      console.log(item)
      // this.$router.push({name: 'pictureInfo', query: {id: this.data.id, pictureId: item.id}})
    },
    // 图片上传相关
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange({file, fileList}) {
      this.fileList = fileList;
      if (file.status == 'error') {
        this.message.error(file.response.message)
      }
    },
    // 图片删除
    pictureDel(id) {
      console.log(this.data)
      if (id) {
        this.api.pictureDel({id: id, company_id: this.data.company_id}).then(res => {
          if (res.code == 0) {
            this.message.success(res.message)
            this.getList()
          } else {
            this.message.error(res.message)
          }
        })
      } else {
        if (this.checkList.length > 0) {
          let ids = this.checkList.map(i => i.id)
          this.api.pictureDel({id: ids, company_id: this.data.company_id}).then(res => {
            if (res.code == 0) {
              this.message.success(res.message)
              this.checkList = []
              this.allChecked = false
              this.indeterminate = false
              this.getList()
            } else {
              this.message.error(res.message)
            }
          })
        } else {
          this.message.error('请选择删除项')
        }
      }
    },
    // 下载统计
    downStats(id, index) {
      this.api.pictureDownload({id: id}).then(() => {
        if (index) {
          this.list[index].download_num += 1
        } else {
          this.getList()
        }
        this.checkList = []
        this.allChecked = false
        this.indeterminate = false
      })
    },
    // 多选全选
    onCheckAllChange(e) {
      console.log(e)
      this.allChecked = e.target.checked
      if (e.target.checked) {
        this.indeterminate = false
        this.checkList = [...this.list]
      } else {
        this.checkList = []
        if (this.checkList.length > 0) {
          this.indeterminate = true
        } else {
          this.indeterminate = false
        }
      }
    },
    // 多选单选
    onCheckChange(item) {
      let index = this.checkList.map(i => i.id).indexOf(item.id)
      if (index > -1) {
        this.checkList.splice(index, 1)
      } else {
        this.checkList.push(item)
      }
      if (this.checkList.length > 0) {
        if (this.list.length == this.checkList.length) {
          this.allChecked = true
          this.indeterminate = false 
        } else {
          this.allChecked = false
          this.indeterminate = true 
        }
      } else {
        this.indeterminate = false
        this.indeterminate = false 
      } 
    },
    // 同步图片全选
    onTaskCheckAllChange(e) {
      this.taskAllChecked = e.target.checked
      if (e.target.checked) {
        this.taskIndeterminate = false
        this.taskCheckList = [...this.taskImgList]
      } else {
        this.taskCheckList = []
        if (this.taskCheckList.length > 0) {
          this.taskIndeterminate = true
        } else {
          this.taskIndeterminate = false
        }
      }
    },
    // 同步图片单选
    onTaskCheckChange(item) {
      let index = this.taskCheckList.map(i => i.id).indexOf(item.id)
      if (index > -1) {
        this.taskCheckList.splice(index, 1)
      } else {
        this.taskCheckList.push(item)
      }
      if (this.taskCheckList.length > 0) {
        if (this.list.length == this.taskCheckList.length) {
          this.taskAllChecked = true
          this.taskIndeterminate = false 
        } else {
          this.taskAllChecked = false
          this.taskIndeterminate = true 
        }
      } else {
        this.taskIndeterminate = false
        this.taskIndeterminate = false 
      } 
    },
    editTag(item) {
      this.tagsVisible = true
      this.tagId = item.id
      console.log(item)
      this.pitchOn.onLabel = item.labels.map(i => i.name)
      this.pitchOn.onChannel = item.channels.map(i => i.name)
    },
    tabChange(key) {
      this.tabKey = key
    },
    // 批量编辑
    batchEdit() {
      this.tagsVisible = true
      // let ids = this.checkList.map((item) => item.id)
      // this.api.copywritingBatchInfo({ids: ids}).then(res => {
      //   if (res.code == 0) {
          // 多项标签取交集 (合并取交集方法)
          let labelsArr = this.checkList.map(item => {
            return item.labels.map(i => {
              return i.name
            })
          })
          this.pitchOn.onLabel = this.gather(labelsArr)
          // 多项渠道取交集 (合并取交集方法)
          let channelsArr = this.checkList.map(item => {
            return item.channels.map(i => {
              return i.name
            })
          })
          this.pitchOn.onChannel = this.gather(channelsArr)
      //   } else {
      //     this.message.error('请选择删除项')
      //   }
      // })
      
    },
    // 选择标签/渠道
    changeTag(item, type) {
      let arrIndex = 0
      let flag = this.pitchOn[type].some((i, index) => {
        arrIndex = index
        return i.id == item.id
      })
      if (flag) {
        // this.message.error('该项已选中')
        this.pitchOn[type].splice(arrIndex, 1)
      } else {
        this.pitchOn[type].push(item)
      }
      // this.$set(this[type], this[type].length, item)
    },
    // 取消标签/渠道
    closeTag(item, type) {
      console.log(item, type)
      this.pitchOn[type].forEach((element, index) => {
        if (element.id == item.id) {
          this.pitchOn[type].splice(index, 1)
        }
      })
      console.log(this.pitchOn[type])
    },
    // 标签渠道修改保存
    tagsSave() {
      this.tagLoading = true
      let label = this.pitchOn.onLabel.map((item) => item.id)
      let channel = this.pitchOn.onChannel.map((item) => item.id)
      let ids = null
      if (this.tagId) {
        ids = this.tagId
      } else {
        ids = this.checkList.map((item) => item.id)
      }
      this.api.pictureBatchEdit({
        ids: ids,
        project_id: this.data.id,
        channel_id: channel,
        label_id: label
      }).then(res => {
        this.tagLoading = false
        if (res.code == 0) {
          this.message.success(res.message)
          this.getList()
        } else {
          this.message.error(res.message)
        }
        this.tagsVisible = false
        this.checkList = []
        this.allChecked = false
        this.indeterminate = false
      })
    },
    uploadSave() {
      this.upLoading = true
      this.fileList.forEach((item) => {
        // let file = this.fileList[0]
        
        let label = this.pitchOn.onLabel.map((item) => item.id)
        let channel = this.pitchOn.onChannel.map((item) => item.id)
        let obj = {
          url: item.response.data.url,
          name: item.name.substring(0, item.name.lastIndexOf(".")),
          size: '',
          extent: (item.size / 1000).toFixed(2),
          format: item.type,
          label: label,
          channel: channel,
          project_id: this.data.id
        }
        console.log(item, obj)
        let img = new Image()
        img.onload = () => {
          obj.size =  `${img.width} * ${img.height}`,
          console.log(obj)
          this.api.pictureCreate(obj).then(res => {
            this.upLoading = false
            if (res.code == 0) {
              this.message.success(res.message);
            } else {
              this.message.error(res.message);
            }
            this.fileList = []
            this.uploadVisible = false
            this.getList()
          })
        }
        img.src = item.response.data.url
        this.uploadVisible = false
      })
    },
    // 切换排序
    sortCut() {
      if (this.sort == 1) {
        this.sort = 2
      } else {
        this.sort = 1
      }
      this.getList()
    },
    // 图片详情切换
    imgSwitch(type) {
      if (type == 'next') {
        if (this.pictureInfoIndex < this.list.length - 1) {
          this.pictureInfoIndex += 1
          this.pictureInfoId = this.list[this.pictureInfoIndex].id
        }
      } else {
        if (this.pictureInfoIndex > 0) {
          this.pictureInfoIndex -= 1
          this.pictureInfoId = this.list[this.pictureInfoIndex].id
        }
      }
    },
    // 同步需求平台图片
    getTaskImg() {
      this.api.getTaskLists({
        page: this.taskCurrent,
        pageSize: this.taskPageSize,
        keywords: this.taskCode,
        project_id: this.data.id
      }).then(res => {
        this.taskImgList = res.data.lists
        this.taskTotal = res.data.allCounts
      })
    },
    // 同步图片确认
    syncImg() {
      console.log(this.taskCheckList)
      let idArr = this.taskCheckList.map(item => {
        return item.id
      })
      this.api.syncTaskImg({
        company_id: this.data.company_id,
        project_id: this.data.id,
        ids: idArr
      }).then(res => {
        if (res.code == 0) {
          this.message.success(res.message)
          this.imgVisible = false
          this.getList()
        }
      })
    },

    handleInputChange(e) {
      this.inputValue = e.target.value;
    },

    handleInputConfirm() {
      const inputValue = this.inputValue;
      let tags = this.tags;
      if (inputValue && tags.indexOf(inputValue) === -1) {
        tags = [...tags, inputValue];
      }
      console.log(tags);
      Object.assign(this, {
        tags,
        inputVisible: false,
        inputValue: '',
      });
    },
    // 数组合并取交集方法
    gather(arr) {
      return arr.reduce((total, currentValue, index) => {
        console.log(total, currentValue, index)
        let arr = []
        total.forEach(i => {
          currentValue.forEach(j => {
            if (i.id == j.id) {
              arr.push(j)
            }
          })
        })
        return arr
      })
    },

    // 新增标签
    showNewTagInput() {
      this.tagInputValue = ''
      this.newTagVisible = true
      this.$nextTick(() => {
        this.$refs.tagInput.focus();
      });
    },
    // 新增标签确认
    tagInputConfirm(type) {
      console.log(this.tagInputValue)
      if (type == 'label') {
        this.api.tagsSave({name: this.tagInputValue, company_id: !this.$store.state.party && this.data.company.id}).then(res => {
          if (res.code == 0) {
            this.labelData.push(res.data)
            this.message.success(res.message)
          } else {
            this.message.error(res.message);
          }
          this.newTagVisible = false
        })
      }
      if (type == 'channel') {
        this.api.channelSave({name: this.tagInputValue, company_id: !this.$store.state.party && this.data.company.id}).then(res => {
          if (res.code == 0) {
            this.channelData.push(res.data)
            this.message.success(res.message)
          } else {
            this.message.error(res.message);
          }
          this.newTagVisible = false
        })
      }
    }
  }
}
</script>

<style scoped>
.picture {
  min-height: calc(100vh - 202px);
}
.result {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
}
.edit {
  display: flex;
  justify-content: space-between;
  padding: 0 24px
}
.btns {
  width: 300px;
  display: flex;
  justify-content: space-between;
  margin: 50px auto;
}
.img-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 0 24px;
}
.img-item {
  width: 18.8%;
  /* height: 320px; */
  box-shadow: 1px 1px 5px 1px #ddd;
  margin-right: 1.5%;
  margin-bottom: 20px;
  border-radius: 10px;
}
.img-item:nth-child(5n) {
  margin-right: 0;
}
.img-name {
  width: 90%;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 10px auto;
}
.img-item img {
  width: 70%;
  height: 200px;
  object-fit: cover;
  display: block;
  margin: 5px auto 10px;
}
.img-handle {
  border-top: 1px solid #ccc;
  box-sizing: border-box;
  padding: 10px 6px;
}
.img-handle .img-handle-tag {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 5px;
}
.img-handle-tag-item {
  margin-bottom: 3px;
}
.img-handle div {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.ellipsis {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
}
.sync-img-item {
  width: 18%;
  height: 250px;
  box-shadow: 1px 1px 5px 1px #ddd;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.sync-img-item img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  display: block;
  margin: 0 auto;
}
.ant-divider-horizontal {
  margin: 0;
}
.drawer-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
  z-index: 1;
}
.img-arrows {
  width: 100px;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
  cursor: pointer;
}
.a-tag {
  font-size: 14px;
  padding: 3px 6px;
  margin-bottom: 10px;
}
</style>