<template>
  <div class="table">
    <canvas class="my-canvas" width="200" height="200" ref="canvas"></canvas>
  </div>
</template>

<script>
export default {
  props: {
    canvasText: String, // 表格数据
  },
  data() {
    return {
      base64_avater: '',
      text: this.canvasText// 重新定义数据
    }
  },
  computed: {
    switchStatus: function () {
      return this.text  // 监听text 的变化
    }
  },
  watch: {
    canvasText(newV) { // watch监听props里status的变化，然后执行操作
      this.text = newV
      // console.log('名字更新了，绘制canvas');
      this.canvasInvitation();
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    // base64转file
    base64ToFile(base64) {
      const arr = base64.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], 'upload' + Date.now(), { type: mime });
    },
    // canvas 画板绘制
    canvasInvitation() {
      let that = this;
      // 开始生成图片
      let canvas = that.$refs.canvas;
      let ctx = canvas.getContext("2d");
      ctx.fillStyle = "#DC2829";
      ctx.fillRect(0, 0, 200, 200);

      ctx.fillStyle = "white";
      ctx.font = '76px "微软雅黑"';
      ctx.textBaseline = "middle";
      ctx.textAlign = "center";
      ctx.fillText(that.text, 100, 100);

      var posterImg = canvas.toDataURL("image/jpeg", 1)

      var blob = that.base64ToFile(posterImg)
      that.upload(blob, '1')
    },
    upload(blob, name) {
      this.api.upload({
        file: blob,
        file_name: name
      }).then(res => {
        // console.log('res', res)
        this.$emit('childEvent', res.data.url)
      })
    },
  },
}
</script>
<style scoped>
.my-canvas {
  width: 200px;
  /* border: 1px solid red; */
  position: fixed;
  top: 1000%;
  left: 1000%;
}
</style>