<template>
  <div class="list">
    <a-row :gutter="[16, 16]" type="flex" justify="center">
      <a-col :span="23">
        <a-form-model :model="form" layout="inline" style="margin: 10px 0 20px">
          <a-form-model-item v-if="$store.state.party">
            <a-select style ="width: 180px" v-model="form.company_id" placeholder="选择乙方公司">
              <a-select-option v-for="item in allPartyBList" :key="item.contractor_id">{{item.contractor.name}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item v-else>
            <a-select style ="width: 180px" v-model="form.company_id" placeholder="选择甲方公司">
              <a-select-option v-for="item in partyAList" :key="item.owner_id">{{item.owner && item.owner.name || '/'}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <a-select style ="width: 180px" v-model="form.resource" placeholder="选择负责人">
              <a-select-option v-for="item in partyUsers" :key="item.id">{{item.name || '匿名'}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item v-show="!$store.state.party">
            <a-select style ="width: 180px" v-model="form.schedule" placeholder="选择项目状态">
              <a-select-option :value="1">进行中</a-select-option>
              <a-select-option :value="2">已完成</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="关键字">
            <a-input v-model="form.keywords" />
          </a-form-model-item>
          <a-form-model-item label="创建日期">
            <a-range-picker v-model="form.date" valueFormat="YYYY-MM-DD" />
          </a-form-model-item>
          <a-form-model-item>
            <a-button shape="circle" @click="$refs.table.getData()" icon="search"></a-button>
          </a-form-model-item>
          <a-form-model-item>
            <a-button @click="reset">重置</a-button>
          </a-form-model-item>
          <a-form-model-item v-if="$store.state.party" v-power:disabled="'project/projectOperate/createProject'">
            <a-button type="primary" @click="addVisible = true">新增项目</a-button>
          </a-form-model-item>
        </a-form-model>
        <Table ref="table" :request="api.projectList" :columns="$store.state.party ? columns : columnsB" selected @selectedChange="selectedChange" :param="{
          company_id: form.company_id || companyId,
          keywords: form.keywords,
          schedule: form.schedule,
          start_time: form.date && form.date[0],
          end_time: form.date && form.date[1],
          user_id: form.resource
        }">
          <span slot="company" slot-scope="scope">{{scope.text.company.name}}</span>
          <div slot="material" slot-scope="scope">
            图片物料: {{scope.text.picture_count}}
            文案物料: {{scope.text.copywriting_count}}
            H5链接: {{scope.text.url_count}}
          </div>
          <span slot="schedule" slot-scope="scope">{{scope.text.schedule == 1 ? '进行中': '已完成'}}</span>
          <!-- <div class="btns" slot="action" slot-scope="scope"> -->
          <a-space slot="action" slot-scope="scope">
            <a-button type="primary" danger @click="info(scope, 'picture')" size="small">物料详情</a-button>
            <a-button size="small" @click="info(scope, 'basic')">基本信息</a-button>
            <a-button size="small" @click="info(scope, 'result')">项目总结</a-button>
            <a-popconfirm
              title="是否确认删除?"
              ok-text="是"
              cancel-text="否"
              @confirm="del(scope)"
            >
              <a-button size="small" type="danger" block>
                删除
              </a-button>
            </a-popconfirm>
          </a-space>
          <!-- </div> -->
        </Table>
      </a-col>
    </a-row>

    <a-drawer
      title="新增项目"
      width="1000"
      placement="right"
      :closable="false"
      :visible="addVisible"
      @close="addVisible = false"
    >
      <a-form-model :model="drawerForm" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
        <a-form-model-item label="项目名称">
          <a-input v-model="drawerForm.name" />
        </a-form-model-item>
        <a-form-model-item label="项目编号">
          <a-input v-model="drawerForm.number" />
        </a-form-model-item>
        <a-form-model-item label="创建日期">
          <a-date-picker
            v-model="drawerForm.date"
            format="YYYY/MM/DD"
            type="date"
            placeholder="创建日期"
            style="width: 100%;"
          />
        </a-form-model-item>
      </a-form-model>
      <a-row type="flex" justify="center">
        <a-col :span="16">
          <div class="table-box">
            <div class="table-box-title">
              <span>{{$store.state.party ? '项目成员' : '甲方成员'}}</span>
              <a-button type="primary" size="small" @click="addPartyAUsers">添加成员</a-button>
            </div>
            <Table :tableData="haveMembers" :paginationShow="false" :columns="drawerColumns" :param="{pageSize: 5}">
              <img class="avatar" slot="avatar" slot-scope="scope" :src="scope.record.user_info?.avatar || scope.record.avatar" alt="">
              <span slot="name" slot-scope="scope">{{scope.record.user_info?.name || scope.record.name || '/'}}</span>
              <span slot="role" slot-scope="scope">{{scope.record.user_role?.name || scope.record.role_name || '/'}}</span>
              <div slot="action" slot-scope="scope">
                <a-button v-if="scope.text.id == 1465" @click="replace(scope)" size="small">替换</a-button>
                <a-popconfirm
                  v-else
                  title="是否确认删除?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="membersConfirm(scope)"
                  @cancel="cancel"
                >
                  <a-button type="primary" size="small">删除</a-button>
                </a-popconfirm>
              </div>
            </Table>
          </div>
        </a-col>
      </a-row>
      <a-row type="flex" justify="center" style="margin-top: 20px">
        <a-col :span="16">
          <div class="table-box">
            <div class="table-box-title">
              <span>乙方公司</span>
              <a-button type="primary" size="small" @click="addCompany = true">添加公司</a-button>
            </div>
            <a-divider />
            <ul v-if="affirmPartyBList && affirmPartyBList.length > 0">
              <li v-for="(item, index) in affirmPartyBList" :key="index" style="width: 100%">
                <a-row type="flex" justify="space-around">
                  <a-col :span="6">
                    <a-select
                      style="width: 100%"
                      placeholder="选择公司"
                      v-model="item.id"
                    >
                      <a-select-option v-for="i in allPartyBList" :key="i.id" :value="i.contractor.id">
                        {{i.contractor.name}}
                      </a-select-option>
                    </a-select>
                  </a-col>
                  <a-col :span="6">
                    <a-select
                      style="width: 100%"
                      mode="multiple"
                      placeholder="选择成员"
                      :default-value="item.users.map(e => parseInt(e.user_id || e.id))"
                      @change="(val, option) => {partyBUserChange(index, val, option)}"
                    >
                      <a-select-option v-for="i in partyBUserList[item.id]" :key="i.id" :data="i">
                        {{i.name || '匿名'}}
                      </a-select-option>
                    </a-select>
                  </a-col>
                  <!-- <a-col :span="6">
                    <p id="link" class="hide">https://www.baidu.com</p>
                    <a-button type="link" @click="copy" style="padding-right: 0">复制邀请链接</a-button>
                    <a-popover title="说明">
                      <template slot="content">
                        <p>成员不在企业空间内？使用链接邀请ta直接进入项目</p>
                        <p>链接有效期：24h</p>
                      </template>
                      <a-icon type="question-circle" />
                    </a-popover>
                  </a-col> -->
                  <a-col :span="2">
                    <a-popconfirm
                      title="是否确认删除?"
                      ok-text="是"
                      cancel-text="否"
                      @confirm="partyBConfirm"
                      @cancel="cancel"
                    >
                      <a-icon type="delete" style="font-size: 24px; color: #f5222d;" />
                    </a-popconfirm>
                  </a-col>
                </a-row>
                <a-divider />
              </li>
            </ul>
            <a-empty v-else />
          </div>
        </a-col>
      </a-row>

      <div class="btns">
        <a-button type="primary" @click="createProject" :loading="saveLoading">保存</a-button>
        <a-button>取消</a-button>
      </div>

      <!-- 弹窗 -->
      <a-modal v-model="memberVisible" title="邀请我方成员">
        <div class="label">
          <span>选择成员：</span>
          <a-select
            mode="multiple"
            style="width: 60%"
            placeholder="选择成员"
            @change="usersChange"
          >
            <a-select-option v-for="item in partyUsers" :key="item.id" :data="item">
              {{item.name || '匿名'}}
            </a-select-option>
          </a-select>
        </div>
        <p>成员不在企业空间内？使用链接邀请ta直接进入项目</p>
        <p><u>复制链接</u> 链接有效期：24小时</p>
        <template slot="footer">
          <a-button key="submit" type="primary" @click="usersSave">保存</a-button>
          <a-button key="back" @click="memberVisible = false">取消</a-button>
        </template>
      </a-modal>
      <a-modal v-model="replaceVisible" title="替换负责人">
        <div class="label">
          <span>选择成员</span>
          <a-select
            mode="multiple"
            style="width: 60%"
            placeholder="Please select"
            @change="handleChange"
          >
            <a-select-option v-for="i in 25" :key="(i + 9).toString(36) + i">
              {{ (i + 9).toString(36) + i }}
            </a-select-option>
          </a-select>
        </div>
        <template slot="footer">
          <a-button key="submit" type="primary" :loading="true" >保存</a-button>
          <a-button key="back" @click="replaceVisible = false">取消</a-button>
        </template>
      </a-modal>
      <a-modal v-model="addCompany" title="新建乙方公司">
        <div class="label">
          <span>公司全称：</span>
          <a-select v-model="partyB" style="width: 320px" placeholder="选择公司">
            <a-select-option v-for="item in allPartyBList" :key="item.id" :value="item.contractor.id" :data="item">
              {{item.contractor.name}}
            </a-select-option>
          </a-select>
        </div>
        <template slot="footer">
          <a-button key="submit" type="primary" @click="addPartyB">保存</a-button>
          <a-button key="back" @click="addCompany = false">取消</a-button>
        </template>
      </a-modal>
    </a-drawer>
  </div>
</template>

<script>
import Table from '../../components/table.vue'

import moment from 'moment';
export default {
  components: {
    Table
  },
  props: ['companyId'],
  data() {
    return {
      partyBList: [], // 关联乙方公司列表
      partyAList: [], // 关联甲方公司列表
      partyUsers: [], // 成员列表
      form: {
        keywords: '',
        company_id: undefined,
        date: undefined,
        resource: undefined,
        schedule: undefined
      },
      columns: [
        {
          title: 'id',
          // key: 'id',
          dataIndex: 'id',
          // width: '80',
        },
        {
          title: '项目名称',
          // key: 'name',
          dataIndex: 'name',
        },
        {
          title: '物料数量',
          key: 'material',
          scopedSlots: { customRender: 'material' },
        },
        {
          title: '创建日期',
          // key: 'date',
          dataIndex: 'date',
          width: 200,
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' },
          width: 300,
        },
      ],
      columnsB: [
        {
          title: 'id',
          // key: 'id',
          dataIndex: 'id',
          // width: '80',
        },
        {
          title: '项目名称',
          // key: 'name',
          dataIndex: 'name',
        },
        {
          title: '公司名称',
          key: 'company',
          scopedSlots: { customRender: 'company' },
        },
        {
          title: '物料数量',
          key: 'material',
          scopedSlots: { customRender: 'material' },
        },
        {
          title: '项目状态',
          key: 'schedule',
          scopedSlots: { customRender: 'schedule' },
        },
        {
          title: '创建日期',
          // key: 'date',
          dataIndex: 'date',
          width: 200,
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' },
          width: 300,
        },
      ],
      // 新增项目
      addVisible: false,
      drawerForm: {
        name: '',
        number: '',
        date: '',
      },
      drawerColumns: [
        {
          title: '头像',
          scopedSlots: { customRender: 'avatar' },
          width: 80
        },
        {
          title: '名称',
          scopedSlots: { customRender: 'name' },
          width: 150
        },
        {
          title: '角色',
          scopedSlots: { customRender: 'role' },
          width: 150
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      haveMembers: [], // 选中甲方成员列表
      membersModel: [],
      memberVisible: false, // 添加项目成员弹窗
      partyB: null,
      allPartyBList: [], // 所有乙方公司列表
      partyBUserList: {}, // 所有乙方公司员工
      affirmPartyBList: [], // 已选择乙方公司列表
      replaceVisible: false, // 替换项目负责人弹窗
      addCompany: false, // 添加公司弹窗

      saveLoading: false, // 保存按钮loading
    }
  },
  created() {
    this.getPartyUsers()
    if (this.$store.state.party) {
      this.getPartyBList()
    } else {
      this.getPartyAList()
    }
    this.addVisible = this.$route.params.addVisible || false // 判断是否默认打开新增窗口
  },
  methods: {
    // 成员列表
    getPartyUsers() {
      this.api.companyUsers({type: 1, company_id: this.$store.state.userInfo.company_id}).then(res => {
        this.partyUsers = res.data.lists
      })
    },
    // 时间选择
    onChange(date, dateString) {
      console.log(date, dateString);
    },
    // 重置
    reset() {
      this.form = {
        keywords: '',
        company_id: undefined,
        date: undefined,
        resource: [],
      }
      // this.$set(this.form, resource, [])
    },
    // 物料详情
    info(scope, name) {
      if (!this.$store.state.party) {
        // 合并auth_b和auth_c
        const authB = this.$store.state.userInfo?.auth_b || []
        this.$store.commit('setAuthB', [...authB, ...scope.record.auth_c])
        this.$store.commit('setEnterpriseId', scope.record.company_id)
      }
      this.$router.push({name: name, query: {id: scope.record.id}})
    },
    // 删除项目
    del(scope) {
      console.log(scope, this.api.projectDel)
      this.api.projectDel({id: scope.record.id}).then(res => {
        if (res.code == 0) {
          this.message.success(res.message)
          this.$refs.table.getData()
        }
      })
    },

    // 添加甲方成员
    addPartyAUsers() {
      this.memberVisible = true
      console.log('haveMembers', this.haveMembers)
      // this.defaultUsers = this.haveMembers.map(item => item.user_id || item.id)
    },
    // 邀请我方成员选择
    usersChange(value, option) {
      this.membersModel = []
      console.log('邀请我方成员选择', value, option)
      // this.haveMembers.push(option.data.attrs.data)
      option.forEach((item) => {
        console.log('forEach', item)
        this.membersModel.push(item.data.attrs.data)
      })
      console.log('membersModel', this.membersModel)
    },
    // 添加我方成员确认
    usersSave() {
      this.haveMembers = this.membersModel
      console.log(this.haveMembers)
      this.memberVisible = false
    },
    // 项目成员删除确认
    membersConfirm(scope) {
      this.haveMembers.splice(scope.index, 1)
    },
    // 所有关联乙方公司列表 和 成员列表
    getPartyBList() {
      this.api.contractorLists({company_id: this.$store.state.userInfo.company_id}).then(res => {
        this.allPartyBList = res.data.lists
        // 请求所有公司员工列表
        this.allPartyBList.forEach(element => {
          this.api.companyUsers({type: 2, owner_id: this.$store.state.userInfo.company_id, company_id: element.contractor_id}).then(res => {
            // this.partyBUserList[element.contractor_id] = res.data.lists
            this.$set(this.partyBUserList, element.contractor_id, res.data.lists)
          })
        })
      })
    },
    // 新建乙方公司
    addPartyB() {
      console.log(this.partyB)
      this.affirmPartyBList.push({
        id: this.partyB,
        users: []
      })
      this.addCompany = false
    },
    // 乙方公司成员选择
    partyBUserChange(index, val, option) {
      console.log(index, val, option)
      let arr = []
      option.forEach(element => {
        arr.push(element.data.attrs.data)
      });
      this.affirmPartyBList[index].users = arr
      console.log(this.affirmPartyBList)
    },
    // 乙方公司删除确认
    partyBConfirm(scope) {
      this.affirmPartyBList.splice(scope.index, 1)
    },

    // 邀请我方成员选择
    handleChange(value) {
      console.log(`selected ${value}`);
    },
    // 替换项目负责人
    replace(scope) {
      this.replaceVisible = true
      console.log(scope)
    },
    // 删除确定
    confirm() {
      this.message.success('Click on Yes');
    },
    // 删除取消
    cancel() {
      this.message.error('Click on No');
    },
    // 复制链接
    copy() {
      const range = document.createRange(); // 创建range对象
      range.selectNode(document.getElementById('link')); // 获取复制内容的 id 选择器
      const selection = window.getSelection();  // 创建 selection对象
      if (selection.rangeCount > 0) selection.removeAllRanges(); // 如果页面已经有选取了的话，会自动删除这个选区，没有选区的话，会把这个选取加入选区
      selection.addRange(range); // 将range对象添加到selection选区当中，会高亮文本块
      document.execCommand('copy'); // 复制选中的文字到剪贴板
      this.message.success('复制成功');
      selection.removeRange(range); // 移除选中的元素
    },
    selectedChange(v) {
      console.log(v, '+++++++++++++')
    },
    createProject() {
      this.saveLoading = true
      let memberArr = this.haveMembers.map(i => i.user_id || i.id)
      this.api.projectCreate({
        name: this.drawerForm.name,
        number: this.drawerForm.number,
        date:  moment(this.drawerForm.date).format('YYYY-MM-DD'),
        member: memberArr,
        participant:  JSON.stringify(this.affirmPartyBList)
      }).then(res => {
        this.saveLoading = false
        if (res.code == 0) {
          this.$refs.table.getData()
          this.message.success(res.message)
          this.addVisible = false
        }
      })
    },
    // 获取关联甲方公司列表
    getPartyAList() {
      this.api.ownerLists({company_id: this.$store.state.userInfo.company_id}).then(res => {
        if (res.code == 0) {
          this.partyAList = res.data.lists
        }
      })
    }
  }
}
</script>

<style scoped>
.list {
  background: #fff;
  border-radius: 10px;
}
.btns {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.btns button{
  margin-right: 10px;
}
.table-box {
  background: #eee;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px;
}
.table {
  background: #fff;
}
.table-box-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.avatar {
  width: 60px;
  height: 60px;
  object-fit: cover;
}
</style>