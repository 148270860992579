// const baseUrl = (window.location.host == 'admin.quanjihuijc.com' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL_TEST)
import { message } from 'ant-design-vue'
import router from '../router/index'

const baseUrl = window.location.host == 'xianzhu.xiaoxxx.com' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL_TEST

const requestGET = (path, data = {}) => {
  return new Promise((reslove, reject) => {
    let dataStr = ''
    for (let key in data) {
      dataStr += `${key}=${data[key]}&`
    }
    dataStr = dataStr.split('&')[0].length > 0 ? '?' + dataStr.substring(0, dataStr.length - 1) : dataStr.substring(0, dataStr.length - 1)
    window.fetch(baseUrl + path + dataStr, {
      method: 'GET',
      headers: {
        Authorization: window.localStorage.getItem('token') || ''
      },
    }).then(res => {
      return res.json()
    }).then(res => {
      if (res.code == 3003) {
        message.success(res.msg)
      }
      reslove(res)
    }).catch(err => {
      reject(err)
    })
  })
}

const requestPOST = (path, data = {}) => {
  // console.log(data)
  const fd = new FormData()
  for (let item in data) {
    // 删除空参数
    // if (data[item] && (JSON.stringify(data[item]) !== '{}' && JSON.stringify(data[item]) !== '[]') || (data[item] instanceof File)) {
    if (data[item] && (JSON.stringify(data[item]) !== '{}') || (data[item] instanceof File)) {
      // if (Array.isArray(data[item])) {
      //   data[item].forEach(element => {
      //     console.log(element)
      //     fd.append(item + '[]', JSON.stringify(element))
      //   });
      // } else {
      fd.append(item, data[item])
      // }
    }
  }
  return new Promise((reslove, reject) => {
    window.fetch(baseUrl + path, {
      method: 'POST',
      headers: {
        Authorization: window.localStorage.getItem('token') || '',
      },
      body: fd
    }).then(res => {
      return res.json()
    }).then(res => {
      if (res.code == -4) {
        message.error(res.message)
        router.push({
          name: 'create'
        })
      } else if (res.code != 0) {
        message.error(res.message)
      }
      reslove(res)
    }).catch(err => {
      reject(err)
    })
  })
}

export {
  requestGET,
  requestPOST
}