<template>
  <div class="basic">
    <a-page-header
      :title="form.name"
      :breadcrumb="{props: {routes}}"
      sub-title="基本信息"
    >
      <template slot="tags" v-if="!$store.state.party && form.company">
        <router-link :to="{name: 'companyProjectList', query: {id: form.company.id}}">
          <a-button type="link"  style="margin-top: -4px">
            {{form.company.name}}
          </a-button>
        </router-link>
      </template>
    </a-page-header>
    <!-- <a-breadcrumb>
      <a-breadcrumb-item>
        <router-link :to="'/projectList'">项目列表</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>基本信息</a-breadcrumb-item>
    </a-breadcrumb> -->
    <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="项目名称">
        <a-input v-power:disabled="'project/basicInformation/updateProject'" :disabled="$store.state.party ? false : true" v-model="form.name" />
      </a-form-model-item>
      <a-form-model-item label="项目编号">
        <a-input v-power:disabled="'project/basicInformation/updateProject'" :disabled="$store.state.party ? false : true" v-model="form.number" />
      </a-form-model-item>
      <a-form-model-item label="创建日期">
        <a-date-picker
          v-model="form.date"
          :disabled="$store.state.party ? false : true"
          show-time
          type="date"
          placeholder="创建日期"
          style="width: 100%;"
          valueFormat="YYYY-MM-DD HH:mm:ss"
          v-power:disabled="'project/basicInformation/updateProject'"
        />
      </a-form-model-item>
      <a-form-model-item v-if="!$store.state.party && form.schedule != 0" label="项目状态">
        <a-radio-group v-model="form.schedule" @change="scheduleChange" button-style="solid" v-power:disabled="'project/basicInformation/updateProject'">
          <a-radio-button :value="1">
            进行中
          </a-radio-button>
          <a-radio-button :value="2">
            已完成
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
    </a-form-model>
    <a-row type="flex" justify="center">
      <a-col :span="12">
        <div class="table-box">
          <div class="table-box-title">
            <span>{{$store.state.party ? '项目成员' : '甲方成员'}}</span>
            <a-button type="primary" size="small" v-if="$store.state.party" @click="addPartyAUsers" v-power:disabled="'project/basicInformation/updateProject'">添加成员</a-button>
          </div>
          <Table :tableData="haveMembers" :paginationShow="false" :columns="$store.state.party ? columns : columnsB" :param="{pageSize: 5}">
            <img class="avatar" slot="avatar" slot-scope="scope" :src="scope.record.user_info?.avatar || scope.record.avatar" alt="">
            <span slot="name" slot-scope="scope">{{scope.record.user_info?.name || scope.record.name || '/'}}</span>
            <span slot="role" slot-scope="scope">{{scope.record.user_role?.name || scope.record.role_name || '/'}}</span>
            <div slot="action" slot-scope="scope">
              <a-button v-if="scope.text.id == 1465" @click="replace(scope)" size="small">替换</a-button>
              <a-popconfirm
                v-else
                title="是否确认删除?"
                ok-text="是"
                cancel-text="否"
                @confirm="membersConfirm(scope)"
                @cancel="cancel"
              >
                <a-button type="danger" size="small" v-power:disabled="'project/basicInformation/updateProject'">删除</a-button>
              </a-popconfirm>
            </div>
          </Table>
        </div>
      </a-col>
    </a-row>
    <a-row type="flex" justify="center" style="margin-top: 20px">
      <a-col :span="12">
        <div v-if="$store.state.party" class="table-box">
          <div class="table-box-title">
            <span>乙方公司</span>
            <a-button type="primary" size="small" @click="addCompany = true" v-power:disabled="'project/basicInformation/updateProject'">添加公司</a-button>
          </div>
          <a-divider />
          <ul v-if="partyBList && partyBList.length > 0">
            <li v-for="(item, index) in partyBList" :key="index" style="width: 100%">
               <a-row type="flex" justify="space-around" align="top">
                  <a-col :span="6">
                    <a-select
                      style="width: 100%"
                      placeholder="选择公司"
                      v-model="item.id"
                      v-power:disabled="'project/basicInformation/updateProject'"
                    >
                      <a-select-option v-for="i in allPartyBList" :key="i.id" :value="i.contractor.id">
                        {{i.contractor.name}}
                      </a-select-option>
                    </a-select>
                  </a-col>
                  <a-col :span="6">
                    <a-select
                      style="width: 100%"
                      mode="multiple"
                      placeholder="选择成员"
                      :default-value="item.users.map(e => parseInt(e.user_id || e.id))"
                      @change="(val, option) => {partyBUserChange(index, val, option)}"
                      v-power:disabled="'project/basicInformation/updateProject'"
                    >
                      <a-select-option v-for="i in partyBUserList[item.id]" :key="i.id" :data="i">
                        {{i.name || '匿名'}}
                      </a-select-option>
                    </a-select>
                  </a-col>
                  <a-col :span="6" style="display: flex; align-items: center;">
                    <a-button type="primary" ghost @click="inviteLink(item.id)" >复制邀请链接</a-button>
                    <a-popover title="说明" style="margin-left: 6px">
                      <template slot="content">
                        <p>成员不在企业空间内？使用链接邀请ta直接进入项目</p>
                        <p>默认链接有效期：2h</p>
                      </template>
                      <a-icon type="question-circle" />
                    </a-popover>
                  </a-col>
                  <a-col :span="2">
                    <a-popconfirm
                      title="是否确认删除?"
                      ok-text="是"
                      cancel-text="否"
                      @confirm="partyBConfirm(index)"
                      @cancel="cancel"
                      v-power="'project/basicInformation/updateProject'"
                    >
                      <a-icon type="delete" style="font-size: 24px; color: #f5222d;" />
                    </a-popconfirm>
                  </a-col>
                </a-row>
              <a-divider />
            </li>
          </ul>
          <a-empty v-else />
        </div>
        <div v-else class="table-box">
          <div class="table-box-title">
            <span>我方成员</span>
            <a-button type="primary" size="small" @click="addPartyAUsers">添加成员</a-button>
          </div>
          <Table :tableData="haveMembersB" :paginationShow="false" :columns="columnsC" :param="{pageSize: 5}">
            <img class="avatar" slot="avatar" slot-scope="scope" :src="scope.record.avatar || scope.record.user?.avatar" alt="">
            <span slot="name" slot-scope="scope">{{scope.record.name || scope.record.user?.name || '/'}}</span>
            <div slot="action" slot-scope="scope">
              <a-button v-if="scope.text.id == 1465" @click="replace(scope)" size="small">替换</a-button>
              <a-popconfirm
                v-else
                title="是否确认删除?"
                ok-text="是"
                cancel-text="否"
                @confirm="confirm(scope)"
                @cancel="cancel"
              >
                <a-button type="primary" size="small">删除</a-button>
              </a-popconfirm>
            </div>
          </Table>
        </div>
      </a-col>
    </a-row>

    <div class="btns">
      <a-popconfirm
        title="有乙方公司未选成员，该公司将不会保存，是否继续？"
        :visible="visible"
        ok-text="是"
        cancel-text="否"
        @visibleChange="handleVisibleChange"
        @confirm="save"
      >
        <a-button type="primary" :loading="saveLoading" v-power="'project/basicInformation/updateProject'">保存</a-button>
      </a-popconfirm>
      <a-button v-power="'project/basicInformation/updateProject'">取消</a-button>
    </div>

    <!-- 弹窗 -->
    <a-modal v-model="addVisible" title="邀请我方成员">
      <div class="label">
        <span>选择成员</span>
        <a-select
          mode="multiple"
          style="width: 60%"
          placeholder="选择成员"
          :default-value="defaultUsers"
          @change="usersChange"
        >
          <a-select-option v-for="item in partyBUsers" :key="item.id" :data="item">
            {{item.name || '匿名'}}
          </a-select-option>
        </a-select>
      </div>
      <p>成员不在企业空间内？使用链接邀请ta直接进入项目</p>
      <p>
        <a-spin v-show="copyLoading">
          <a-icon slot="indicator" type="loading" style="font-size: 20px" spin />
        </a-spin>
        <u v-show="!copyLoading" @click="copy" style="cursor: pointer;">复制链接</u>
        链接有效期：2小时
      </p>
      <template slot="footer">
        <a-button key="submit" type="primary" @click="usersSave">保存</a-button>
        <a-button key="back" @click="addVisible = false">取消</a-button>
      </template>
    </a-modal>
    <a-modal v-model="replaceVisible" title="替换负责人">
      <div class="label">
        <span>选择成员</span>
        <a-select
          mode="multiple"
          style="width: 60%"
          placeholder="Please select"
          @change="handleChange"
        >
          <a-select-option v-for="i in 25" :key="(i + 9).toString(36) + i">
            {{ (i + 9).toString(36) + i }}
          </a-select-option>
        </a-select>
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" :loading="true" >保存</a-button>
        <a-button key="back" @click="replaceVisible = false">取消</a-button>
      </template>
    </a-modal>
    <a-modal v-model="addCompany" title="选择乙方公司">
      <div class="label">
        <span>公司全称：</span>
        <a-select v-model="partyB" style="width: 320px" placeholder="选择公司">
          <a-select-option v-for="item in allPartyBList" :key="item.id" :value="item.contractor.id" :data="item">
            {{item.contractor.name}}
          </a-select-option>
        </a-select>
      </div>
      <!-- <div class="label">
        <span>公司简称：</span>
        <a-input style="width: 70%" />
      </div> -->
      <template slot="footer">
        <a-button key="submit" type="primary" @click="addPartyB">保存</a-button>
        <a-button key="back" @click="addCompany = false">取消</a-button>
      </template>
    </a-modal>
    <a-modal v-model="copyVisible" title="使用链接邀请">
      <div class="label">
        <span>链接有效期：</span>
        <a-select v-model="linkTime" @change="createCopyLink(4)" :default-value="2" style="width: 320px" placeholder="选择时间">
          <a-select-option :key="2" :value="2">2小时</a-select-option>
          <a-select-option :key="24" :value="24">24小时</a-select-option>
          <a-select-option :key="48" :value="48">48小时</a-select-option>
        </a-select>
      </div>
      <template slot="footer">
        <a-button key="back" @click="recipientId = null; copyVisible = false">
          取消
        </a-button>
        <a-button key="submit" type="primary" :loading="copyLoading" @click="copy(4)">
          复制
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import Table from '../../../components/table.vue'
export default {
  components: {
    Table,
  },
  props: ['data'],
  data() {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 12 },
      routes: [
        {
          path: '/projectList',
          breadcrumbName: '项目列表'
        },
        {
          path: '',
          breadcrumbName: '基本信息'
        },
      ],
      form: {
        name: '',
        id: '',
        date: '',
        schedule: 0
      },
      columns: [
        {
          title: '头像',
          scopedSlots: { customRender: 'avatar' },
          width: 80
        },
        {
          title: '名称',
          scopedSlots: { customRender: 'name' },
          width: 150
        },
        {
          title: '角色',
          scopedSlots: { customRender: 'role' },
          width: 150
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      columnsB: [
        {
          title: '头像',
          scopedSlots: { customRender: 'avatar' },
          width: 80
        },
        {
          title: '名称',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: '角色',
          scopedSlots: { customRender: 'role' },
        },
      ],
      columnsC: [
        {
          title: '头像',
          scopedSlots: { customRender: 'avatar' },
          width: 80
        },
        {
          title: '名称',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      addVisible: false, // 添加项目成员弹窗
      defaultUsers: [], // 甲方成员默认选中成员
      partyAUsers: [], // 甲方成员列表
      partyBUsers: [], // 乙方己方成员列表
      haveMembers: [], // 选中甲方成员列表
      membersModel: [],
      replaceVisible: false, // 替换项目负责人弹窗
      addCompany: false, // 添加公司弹窗
      partyB: null,
      allPartyBList: [], // 所有乙方公司列表
      partyBUserList: {}, // 所有乙方公司员工
      partyBList: [], // 已选择乙方公司列表
      visible: false,

      copyVisible: false, // 复制弹窗
      recipientId: null, // 受邀请公司id
      linkTime: 2, // 邀请链接时间
      copyId: null, // 邀请链接id
      copyLoading: false, // 复制按钮loading
      saveLoading: false, // 保存按钮loading
      
    }
  },
  watch: {
    data(n) {
      console.log(22, n)
      this.form = n
      this.partyBList = n.participants
      this.haveMembers = n.members_info
      this.haveMembersB = n.our_members
      this.getPartyBUsers()
    }
  },
  created() {
    console.log(11, this.data)
    this.form = this.data
    this.getPartyAUsers()
    this.getPartyBUsers()
    this.getPartyBList()
    this.partyBList = this.data.participants
    this.haveMembers = this.data.members_info
    this.haveMembersB = this.data.our_members
  },
  methods: {
    // 添加甲方成员
    addPartyAUsers() {
      this.addVisible = true
      if (this.$store.state.party) {
        console.log('haveMembers', this.haveMembers)
        this.defaultUsers = this.haveMembers.map(item => item.user_id || item.id)
      } else {
        console.log(this.data.our_members)
        this.defaultUsers = this.haveMembersB.map(item => item.user_id || item.id)
        this.createCopyLink(4) // 生成邀请链接
      }
    },
    // 甲方成员列表
    getPartyAUsers() {
      this.api.companyUsers({type: 1, company_id: this.$store.state.userInfo.company_id}).then(res => {
        console.log('甲方成员', res)
        this.partyAUsers = res.data.lists
      })
    },
    // 乙方己方成员列表
    getPartyBUsers() {
      if (this.form.company && this.form.company.id) {
        this.api.companyUsers({type: 2, company_id: this.$store.state.userInfo.company_id, owner_id: this.form.company.id}).then(res => {
          console.log('乙方成员', res)
          this.partyBUsers = res.data.lists
        })
      }
    },
    // 邀请我方成员选择
    usersChange(value, option) {
      this.membersModel = []
      console.log('邀请我方成员选择', value, option)
      // this.haveMembers.push(option.data.attrs.data)
      option.forEach((item) => {
        console.log('forEach', item)
        this.membersModel.push(item.data.attrs.data)
      })
      console.log('membersModel', this.membersModel)
    },
    // 添加我方成员确认
    usersSave() {
      if (this.$store.state.party) {
        this.haveMembers = this.membersModel
        console.log(this.haveMembers)
      } else {
        this.haveMembersB = this.membersModel
        console.log(this.haveMembersB)
      }
      this.addVisible = false
    },
    handleChange(value) {
      console.log(`selected ${value}`);
    },
    // 替换项目负责人
    replace(scope) {
      this.replaceVisible = true
      console.log(scope)
    },
    // 项目成员删除确认
    membersConfirm(scope) {
      console.log(this.haveMembers, scope)
      this.haveMembers.splice(scope.index, 1)
    },
    // 乙方公司删除确认
    partyBConfirm(index) {
      let arr = this.partyBList
      arr.splice(index, 1)
      this.partyBList = []
      this.$nextTick(() => {
        this.partyBList = arr
      });
      // this.partyBList.splice(index, 1)
    },
    // 乙方成员删除确定
    confirm(scope) {
      this.haveMembersB.splice(scope.index, 1)
      // this.message.success('Click on Yes');
    },
    // 删除取消
    cancel() {
      this.message.error('Click on No');
    },
    // 所有关联乙方公司列表 和 成员列表
    getPartyBList() {
      this.api.contractorLists({company_id: this.$store.state.userInfo.company_id}).then(res => {
        this.allPartyBList = res.data.lists
        // 请求所有公司员工列表
        console.log(this.allPartyBList)
        this.allPartyBList.forEach(element => {
          this.api.companyUsers({type: 2, owner_id: this.$store.state.userInfo.company_id, company_id: element.contractor_id}).then(res => {
            console.log(res)
            // this.partyBUserList[element.contractor_id] = res.data.lists
            this.$set(this.partyBUserList, element.contractor_id, res.data.lists)
            console.log(this.partyBUserList, '++++++++++++++++++++')
          })
        })
      })
    },
    // 新建乙方公司
    addPartyB() {
      console.log(this.partyB)
      this.partyBList.push({
        id: this.partyB,
        users: []
      })
      this.addCompany = false
    },
    // 乙方公司选择
    // partyBchange(value, option) {
    //   console.log(value, option)
    // },
    // 乙方公司成员选择
    partyBUserChange(index, val, option) {
      console.log(index, val, option)
      let arr = []
      option.forEach(element => {
        arr.push(element.data.attrs.data)
      });
      this.partyBList[index].users = arr
      console.log(this.partyBList)
    },
    // 保存
    save() {
      this.saveLoading = true
      let obj = {}
      if (this.$store.state.party) {
        obj = {
          id: this.form.id,
          name: this.form.name,
          number: this.form.number,
          date: this.form.date,
          schedule: this.form.schedule,
          member: this.haveMembers.map(i => i.user_id || i.id),
          participant: JSON.stringify(this.partyBList)
        }
      } else {
        obj = {
          participant: this.haveMembersB.map(i => i.user_id || i.id),
          project_id: this.data.id,
          company_id: this.$store.state.userInfo.company_id,
        }
      }
      this.api.projectEdit(obj).then(res => {
        this.saveLoading = false
        if (res.code == 0) {
          this.message.success(res.message);
        }
      })
    },
    // 项目状态修改
    scheduleChange() {
      this.api.updateSchedule({
        schedule: this.form.schedule,
        project_id: this.data.id,
        company_id: this.$store.state.userInfo.company_id,
      }).then(res => {
        if (res.code == 0) {
          this.message.success(res.message)
        }
      })
    },
    
    handleVisibleChange(visible) {
      console.log(visible, this.partyBList)
      if (!visible) {
        this.visible = false;
        return;
      }
      if (this.$store.state.party) {
        let flag = true
        this.partyBList.forEach((item) => {
          if (item.users.length <= 0) {
            flag = false
          }
        })
        if (flag) {
          this.save(); // next step
        } else {
          this.visible = true;
        }
      } else {
        this.save()
      }
    },
    // 甲方添加乙方公司邀请链接
    inviteLink(id) {
      this.recipientId = id;
      this.copyVisible = true
      this.createCopyLink(4)
    },
    // 生成复制链接
    createCopyLink(type) {
      this.copyLoading = true
      let params 
      if (this.$store.state.party) {
        params = {
          type: type, // 项目链接
          user_id: this.$store.state.userInfo.id,
          originator_id: this.$store.state.userInfo.company_id,
          recipient_id: this.recipientId,
          project_id: this.data.id,
          h: this.linkTime,
          status: 1
        }
      } else {
        params = {
          type: type, // 项目链接
          user_id: this.$store.state.userInfo.id,
          originator_id: this.form.company.id,
          recipient_id: this.$store.state.userInfo.company_id,
          project_id: this.data.id,
          h: this.linkTime,
          status: 2
        }
      }
      this.api.invitationLink(params).then(res => {
        if (res.code == 0) {
          this.copyLoading = false
          this.copyId = res.data.id
        }
        this.recipientId = null
      })
    },
    // 复制链接
    copy() {
      let dom = null
      if (document.getElementById('inviteLink')) {
        dom = document.getElementById('inviteLink')
        dom.innerText = window.location.origin + window.location.pathname + '#/create?inviteId=' + this.copyId
      } else {
        dom = document.createElement('p')
        dom.id = 'inviteLink'
        dom.innerText = window.location.origin + window.location.pathname + '#/create?inviteId=' + this.copyId
        document.body.appendChild(dom)
      }
      const range = document.createRange(); // 创建range对象
      range.selectNode(document.getElementById('inviteLink')); // 获取复制内容的 id 选择器
      const selection = window.getSelection();  // 创建 selection对象
      if (selection.rangeCount > 0) selection.removeAllRanges(); // 如果页面已经有选取了的话，会自动删除这个选区，没有选区的话，会把这个选取加入选区
      selection.addRange(range); // 将range对象添加到selection选区当中，会高亮文本块
      document.execCommand('copy'); // 复制选中的文字到剪贴板
      this.message.success('复制成功');
      selection.removeRange(range); // 移除选中的元素
      this.recipientId = null
      this.copyVisible = false
    },
  }
}
</script>

<style scoped>
.basic {
}
.table-box {
  background: #eee;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px;
}
.table {
  background: #fff;
}
.table-box-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.avatar {
  width: 60px;
  height: 60px;
  object-fit: cover;
}
.label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.label span {
  margin-right: 20px;
}
.hide {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.btns {
  width: 200px;
  display: flex;
  justify-content: space-between;
  margin: 50px auto;
}
</style>