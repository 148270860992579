<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-10-12 17:21:29
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-10-14 16:51:39
 * @FilePath: \h5case4.xiaoxxx.cne:\code\仙助数字资产管理平台\digital\src\views\login\login.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="main">
    <div class="main-bg"></div>
    <div class="content">
      <!-- 页面标题 -->
      <div class="title">
        <h1>仙助数字资产管理平台</h1>
        <p>轻松管理您的数字营销资产，告别杂乱无章的文件夹</p>
      </div>
      <!-- 审核已通过 -->
      <div class="succeed" v-if="showpage == 'individual'">
        <a-form-model-item class="form-item">
          <div v-if="auditingType == 1">
            <div class="">
              <a-icon class="successIcon" type="check-circle" theme="filled" />
            </div>
            <div class="success-text">审核已通过</div>
          </div>
          <div v-if="auditingType == 2">

            <div class="">
              <a-icon class="successIcon" type="check-circle" theme="filled" />
            </div>
            <div class="success-text">信息提交成功，请等待您的空间管理员审核</div>
          </div>
          <div v-if="auditingType == 3">
            <div class="">
              <a-icon class="errorIcon" type="close-circle" theme="filled" />
            </div>
            <div class="success-text">管理员拒绝了您的申请，请确认申请信息是否正确</div>
          </div>
        </a-form-model-item>
        <a-form :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" v-if="this.userInfo">
          <a-form-item label="用户名：">
            <a-input class="input-width-400" v-model="this.userInfo.name" disabled />
          </a-form-item>
          <a-form-item label="联系方式：">
            <a-input class="input-width-400" v-model="this.userInfo.mobile" disabled />
          </a-form-item>
          <a-form-item label="企业名称：">
            <a-input class="input-width-400" v-model="this.userInfo.company.name" disabled />
          </a-form-item>
          <div class="reapply" v-if="auditingType == 3" @click="reapplyFun">重新申请</div>
        </a-form>
      </div>

      <!-- 创建企业状态： 审核中 \ 已拒绝 -->
      <div class="succeed" v-if="showpage == 'enterprise'">
        <a-form-model-item class="form-item">
          <div v-if="companyStatus == 0">
            <div>
              <a-icon class="successIcon" type="check-circle" theme="filled" />
            </div>
            <div class="success-text">信息提交成功，请等待客服审核</div>
          </div>
          <div v-if="companyStatus == 1">
            <div>
              <a-icon class="successIcon" type="check-circle" theme="filled" />
            </div>
            <div class="success-text">审核已通过</div>
          </div>
          <div v-if="companyStatus == 2">
            <div class="">
              <a-icon class="errorIcon" type="close-circle" theme="filled" />
            </div>
            <div class="success-text">您的企业信息有误，请重新申请</div>
          </div>
        </a-form-model-item>
        <a-form :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" v-if="this.userInfo">
          <a-form-item label="用户名：">
            <a-input class="input-width-400" v-model="this.userInfo.name" disabled />
          </a-form-item>
          <a-form-item label="联系方式：">
            <a-input class="input-width-400" v-model="this.userInfo.mobile" disabled />
          </a-form-item>
          <a-form-item label="企业名称：">
            <a-input class="input-width-400" v-model="this.userInfo.company.name" disabled />
          </a-form-item>
          <a-form-item label="企业联系人：">
            <a-input class="input-width-400" v-model="this.userInfo.company.contacts" disabled />
          </a-form-item>
          <a-form-item label="企业联系方式：">
            <a-input class="input-width-400" v-model="this.userInfo.company.contact" disabled />
          </a-form-item>
          <a-form-item label="企业类型：">
            <a-input class="input-width-400" v-model="this.userInfo.company.type" disabled />
          </a-form-item>
          <div v-if="companyStatus == 2">
            <div class="reapply" @click="reapplyFun">重新申请</div>
          </div>
        </a-form>
      </div>

      <!-- 提交成功，请等待审核 -->
      <div class="succeed" v-if="showpage == 'spaceReview'">
        <br><br><br>
        <a-form-model-item class="form-item">
          <div class="">
            <a-icon class="successIcon" type="check-circle" theme="filled" />
          </div>
          <div class="success-text" v-if="companyName != ''">已向“{{ companyName }}”企业空间提出关联申请，请等待管理员审核</div>
          <div class="success-text" v-else>已提出关联申请，请等待管理员审核</div>
        </a-form-model-item>
      </div>
    </div>

    <div class="ewm-box" v-if="showpage == 'enterprise'">
      <div class="ewm">二维码占位</div>
    </div>


    <div class="quitLogin">
      <a-button type="danger" @click="quit"> 退出登录 </a-button>
    </div>

    <!-- 页面底部信息 -->
    <footerBox />
  </div>
</template>

<script>
import footerBox from "@/components/footer.vue";
export default {
  components: {
    footerBox,
  },
  data() {
    return {
      showpage: '', // individual:个人  enterprise:企业
      succeedText: '', // 成功页面文案
      auditingType: null, // 个人-加入企业-审核详情，2:审核中，3:已拒绝
      userInfo: null, // 用户信息
      companyStatus: '',
      companyName: '',
    };
  },
  mounted() { },
  methods: {
    // 退出登录
    quit() {
      window.localStorage.removeItem('userInfo')
      window.localStorage.removeItem('token')
      this.$router.replace({ name: 'login' })
    },
    // 重新申请
    reapplyFun() {
      this.$router.replace({
        name: 'create'
      });
    }
  },
  created() {
    this.companyName = this.$route.query.name || ''
    console.log(this.companyName);
    // this.showpage = 'individual'
    // this.showpage = 'enterprise'
    // this.showpage = 'spaceReview'
    this.api.getUserInfo({
    }).then(res => {

      if (res.data.userInfo.company.status == 0) {
        window.localStorage.setItem('auditingPageType', 'enterprise')
      } else if (res.data.userInfo.company.status == 1) {
        if (res.data.userInfo.status == 2) {
          window.localStorage.setItem('auditingPageType', 'individual')
        } else if (res.data.userInfo.status == 3) {
          window.localStorage.setItem('auditingPageType', 'individual')
        }
      } else if (res.data.userInfo.company.status == 2) {
        window.localStorage.setItem('auditingPageType', 'enterprise')
      }

      this.showpage = window.localStorage.getItem('auditingPageType') || 'individual'
      console.log('res', res)
      this.userInfo = res.data.userInfo
      window.localStorage.setItem('userInfo', JSON.stringify(res.data.userInfo))
      this.userInfo.company.type = (this.userInfo.company.type == 1) ? '甲方' : '乙方'

      this.auditingType = this.userInfo.status
      // 用户'status',//审核状态 1审核通过 2审核中 3审核拒绝
      // this.auditingType = 3

      this.companyStatus = this.userInfo.company.status
      // 公司'status',//状态 0待审核 1审核通过 2审核未通过
      // this.companyStatus = 2

      console.log('加入公司审核状态', this.userInfo.company.status);
      if (this.userInfo.company.status == 1) {
        if (this.userInfo.status == 1) {
          // 1：审核通过
          this.$router.replace({ name: 'home' })
        }
      }
    })
  },
}
</script>

<style lang="less" scoped >
.main {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-bg {
  width: 100%;
  height: 100%;
  background: url("//res.xiaoxxx.com/urlGather/2022110319f8ef41100991c0816186.jpeg") center no-repeat;
  background-size: cover;
  z-index: -1;
}

.title {
  text-align: center;
}

.title h1 {
  font-weight: 900;
  font-size: 32px;
  margin: 0 0 10px 0;
  white-space: nowrap;
}

.title p {
  color: rgba(0, 0, 0, 0.427);
  font-weight: 400;
  line-height: 22px;
  font-size: 14px;
  white-space: nowrap;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 500px;
}

.succeed {
  width: 800px;
  margin: 40px auto 0;
}

.form-item {
  position: relative;
  margin: 10px 0 0;
}


button {
  line-height: 32px;
}

.successIcon {
  width: 100%;
  color: rgb(70, 189, 21);
  font-size: 40px;
  text-align: center;
}

.errorIcon {
  width: 100%;
  color: rgb(239, 0, 35);
  font-size: 40px;
  text-align: center;
}

.color-black {
  color: black;
  font-weight: bold;
}

.success-text {
  width: 100%;
  text-align: center;
  white-space: nowrap;
  font-weight: bold;
  color: black;
}

.ewm-box {
  position: fixed;
  bottom: 140px;
  right: 80px;
}

.ewm {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  background-color: #333;
  // flex-shrink: 0;
}

.reapply {
  display: table;
  margin: 0 auto;
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  background-color: rgba(245, 34, 45, 1);
  border-radius: 4px;
  font-size: 16px;
  color: #FFFFFF;
  font-weight: bold;
  letter-spacing: 2px;
  cursor: pointer;
}

.input-width-400 {
  width: 400px;
}

.quitLogin {
  position: fixed;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
}
</style>