<template>
  <div class="h5">
    <a-form-model :model="form" layout="inline" style="margin: 10px 24px 20px 24px">
      <!-- <a-form-model-item label="关键字">
        <a-input v-model="form.name" />
      </a-form-model-item> -->
      <a-form-model-item label="创建日期">
        <a-range-picker v-model="form.selectDate" @change="onChangeDate" />
      </a-form-model-item>
      <a-form-model-item>
        <a-button shape="circle" icon="search" @click="handleSubmit"></a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click="clearSelect">重置</a-button>
      </a-form-model-item>
    </a-form-model>
    <Table ref="table" :request="api.recycleBinFontGetLists" :columns="columns" :param="{pageSize: 10,start_time:form.selectDate[0]?form.selectDate[0]:'',end_time:form.selectDate[1]?form.selectDate[1]:'',company_id:($store.state.party===0?companyId:'')}">
      <!-- <span slot="img" slot-scope="scope">{{scope.record.image_url}}</span>
      <p slot="video" slot-scope="scope">{{scope.record.video_url}}</p> -->
      <div class="btns" slot="action" slot-scope="scope">
        <a-space>
          <a-popconfirm
            title="将所选内容还原至原项目"
            ok-text="是"
            cancel-text="否"
            @confirm="restoreFont(scope.text)"
          >
          <a-button type="primary" size="small">还原</a-button>
          </a-popconfirm>
          <a-popconfirm
            title="永久删除所选内容，该操作不可撤销"
            ok-text="是"
            cancel-text="否"
            @confirm="deleteFont(scope.text)"
          >
            <a-button type="danger" size="small">永久删除</a-button>
          </a-popconfirm>
        </a-space>
      </div>
    </Table>
  </div>
</template>

<script>
export default {
  props: ['companyId'],
  data() {
    return {
      form: {
        // name: '',
        selectDate:[], //筛选日期
      },
      columns: [
        {
          title: 'id',
          key: 'id',
          dataIndex: 'id',
          width: '80',
        },
        {
          title: '字体名称',
          // key: 'name',
          dataIndex: 'name',
          // scopedSlots: { customRender: 'img' },
          width: '200',
        },
        {
          title: '字体公司',
          // key: 'font_company',
          dataIndex: 'font_company',
          // scopedSlots: { customRender: 'video' },
          width: '100',
        },
        {
          title: '删除日期',
          // key: 'font_company',
          dataIndex: 'deleted_at',
          // scopedSlots: { customRender: 'video' },
          width: '100',
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      repetition:true//防止重复点击
    }
  },
  methods:{
    // 日期
    onChangeDate(date, dateString) {
      this.form.selectDate = dateString
    },
    // 重置
    clearSelect(){
      this.form.selectDate = []
      setTimeout(() => {
        this.$refs.table.getData()
      }, 10);
    },
    // 搜索
    handleSubmit(){
      this.$refs.table.getData()
    },
    // 还原
    restoreFont(data){
      if(this.repetition){
        this.repetition = false
        this.api.recycleBinFontRestore({
          id:data.id
        }).then((res)=>{
          if(res.code==0){
            this.repetition = true
            this.message.success(res.message)
            this.$refs.table.getData()
          }
        })
      }
    },
    // 删除
    deleteFont(data){
      if(this.repetition){
        this.repetition = false
        this.api.recycleBinFontForceDelete({
          id:data.id
        }).then((res)=>{
          if(res.code==0){
            this.repetition = true
            this.message.success(res.message)
            this.$refs.table.getData()
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.edit {
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 20px;
}
.label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.label span {
  margin-right: 20px;
}
</style>