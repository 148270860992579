/*
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-10-13 17:24:48
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-10-13 17:58:27
 * @FilePath: \h5case4.xiaoxxx.cne:\code\仙助数字资产管理平台\digital\src\utils\power.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import JSZip from 'jszip';

Vue.directive('downZip', {
  // inserted: function(el, binding) {
  //   console.log(binding)
  //   if (binding.value) {
  //     el.onclick = () => { 
  //       return fileDown(binding)
  //     }
  //   }
  // },
  update: function(el, binding) {
    if (binding.value) {
      el.onclick = () => { 
        return fileDown(binding)
      }
    }
  }
})

function fileDown(binding) {
  const zip = new JSZip();
  let arr = binding.value.map((item) => {
    let url = 'https://' + item.url.split('://')[1]
    return {
      name: item.name ? (item.name + '.' + item.url.split('.').pop()) : (item.url.split('/').pop()),
      url: url
    }
  })
  window.Promise.all(arr.map((item) => {
    return fileLoad(item.name, item.url)
  })).then(res => {
    console.log(res)
    res.forEach((item) => {
      zip.file(item.name, item.file);
    })
    zip.generateAsync({type:"blob"}).then(function(content) {
      const a = document.createElement('a')
      a.setAttribute('href', URL.createObjectURL(content))
      a.setAttribute('download', new Date().getTime() + '_' + window.location.hostname + '.zip')
      a.setAttribute('target', '_blank')
      const clickEvent = document.createEvent('MouseEvents')
      clickEvent.initEvent('click', true, true)
      a.dispatchEvent(clickEvent)
    });
  })
}

function fileLoad(name, url) {
  return new Promise((reslove) => {
    window.fetch(url).then(res => {
      return res.blob()
    }).then(res => {
      reslove({
        name: name,
        file: res
      })
    })
  })
}