<template>
  <div class="copy">
    <div class="title">
      <span>平台公告</span>
      <a-button type="link" @click="routerTo">查看全部</a-button>
    </div>
    <div class="content">
      <Table :request="api.platform" :columns="columns" :paginationShow="false">
        <div slot="user" slot-scope="scope">
          <span v-if="scope.record.user_info && scope.record.user_info.name">{{scope.record.user_info.name}}</span>
          <span v-else><a-icon type="smile" /></span>
        </div>
        <a-space slot="action" slot-scope="scope">
          <a-button type="primary" @click="info(scope)" size="small">查看</a-button>
        </a-space>
      </Table>
    </div>
  </div>
</template>

<script>
import Table from '../table.vue'
export default {
  props: ['data'],
  components: {
    Table
  },
  data() {
    return {
      columns: [
        {
          title: 'id',
          dataIndex: 'id',
          width: 50,
        },
        {
          title: '标题',
          dataIndex: 'title',
        },
        {
          title: '时间',
          dataIndex: 'created_at',
          width: 170,
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' },
          width: 100
        },
      ],
    }
  },
  watch: {
    data(n) {
      console.log('222222', n)
    }
  },
  created() {
    // 默认显示前10条
    // this.api.platform({
    //   page: 1,
    //   pageSize: 10
    // }).then((res) => {
    //   console.log(res)
    // })
  },
  methods: {
    routerTo() {
      this.$router.push({name: 'notice', query: {key: '2'}})
    },
    info(scope) {
      console.log(scope)
      this.$router.push({name: 'noticeDetails', query: {id: scope.record.id}})
    }
  }
}
</script>

<style scoped>
.copy {
  background: #fff;
  border-radius: 10px;
}
.title {
  height: 50px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 0 10px 15px;
}
.content {
  min-height: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px;
}
img {
  width: 80px;
  height: 80px;
  object-fit: cover;
}
</style>