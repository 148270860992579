/*
 * @Author: your name
 * @Date: 2022-01-21 11:17:24
 * @LastEditTime: 2022-10-19 17:05:26
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /qjh-xiaoxxx-cn/src/api/api.js
 * type 为甲乙方参数 1甲方 0乙方
 */
import { requestPOST } from "./request.js";
import store from '../store/index'

// 注册
export function register(data) {
  return requestPOST(`/admin/register`, data)
}
// 发送短信
export function sendSms(data) {
  return requestPOST(`/admin/sendSms`, data)
}
// 登录
export function login(data) {
  return requestPOST(`/admin/Login`, data)
}
// 忘记密码/修改密码
export function forget(data) {
  return requestPOST(`/admin/forget`, data)
}
// 填写个人信息
export function updateUserInfo(data) {
  return requestPOST(`/admin/updateUserInfo`, data)
}
// 企业创建
export function createEnterprise(data) {
  return requestPOST(`/admin/createEnterprise`, data)
}
// 获取用户信息
export function getUserInfo(data) {
  return new Promise(reslove => {
    requestPOST(`/admin/getUserInfo`, data).then(res => {
      if (res.code == 0) {
        window.localStorage.setItem('userInfo', JSON.stringify(res.data.userInfo))
        store.commit('setUserInfo', res.data.userInfo)
        store.commit('setAuthA', res.data.userInfo.auth_a)
        store.commit('setAuthB', res.data.userInfo.auth_b)
      }
      reslove(res)
    })
  })
}
// 查询公司
export function companyQuery(data) {
  return requestPOST(`/admin/companyQuery`, data)
}

// 主页
export function homeData(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/index`, data)
  } else {
    return requestPOST(`/admin/partyB/index`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
//  首页广告
export function adverts(data) {
  return requestPOST(`/admin/partyA/advertsLists`, data)
}
// 首页-平台公告
export function platform(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/noticeLists`, data)
  } else {
    return requestPOST(`/admin/partyB/noticeLists`, data)
  }
}

// 通知-项目动态
export function operationGetLists(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/notice/operation/getLists`, data)
  } else {
    return requestPOST(`/admin/partyB/notice/operation/getLists`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// export function operationGetLists(data) {
//   return requestPOST(`/admin/partyA/notice/operation/getLists`, data)
// }
// 通知-平台公告
export function noticeLists(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/notice/notice/getLists`, data)
  } else {
    return requestPOST(`/admin/partyB/notice/notice/getLists`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 通知-平台公告-详情
export function noticeDetails(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/notice/notice/noticeDetails`, data)
  } else {
    return requestPOST(`/admin/partyB/notice/notice/noticeDetails`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 通知-系统公告
export function systemGetLists(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/notice/system/getLists`, data)
  } else {
    return requestPOST(`/admin/partyB/notice/system/getLists`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}


// 列表 测试
export function getList(data) {
  return requestPOST(`/h5/UCANT/getList`, data)
}

// 项目
// 项目列表
export function projectList(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/project/projectOperate/getLists`, data)
  } else {
    return requestPOST(`/admin/partyB/project/projectOperate/getLists`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 新增项目
export function projectCreate(data) {
  return requestPOST(`/admin/partyA/project/projectOperate/createProject`, data)
}
// 删除项目
export function projectDel(data) {
  return requestPOST(`/admin/partyA/project/projectOperate/softDeletion`, data)
}
// 项目详情
export function projectDetails(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/project/basicInformation/projectDetails`, data)
  } else {
    return requestPOST(`/admin/partyB/project/basicInformation/projectDetails`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 项目修改(乙方修改用户)
export function projectEdit(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/project/basicInformation/updateProject`, data)
  } else {
    return requestPOST(`/admin/partyB/project/basicInformation/updateProject`, {...data, enterprise_id: store.state.enterpriseId})
  }
}
// 修改项目状态(乙方)
export function updateSchedule(data) {
  return requestPOST(`/admin/partyB/project/basicInformation/updateSchedule`, {...data, enterprise_id: store.state.enterpriseId})
}
// 项目总结修改
export function projectSummaryEdit(data) {
  return requestPOST(`/admin/partyA/project/summary/projectSummary`, data)
}
// 图片物料列表
export function pictureList(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/project/picture/getLists`, data)
  } else {
    return requestPOST(`/admin/partyB/project/picture/getLists`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 需求平台图片列表
export function getTaskLists(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/project/picture/getTaskLists`, data)
  } else {
    return requestPOST(`/admin/partyB/project/picture/getTaskLists`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 同步需求平台图片
export function syncTaskImg(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/project/picture/sync`, data)
  } else {
    return requestPOST(`/admin/partyB/project/picture/sync`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 图片物料新增
export function pictureCreate(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/project/picture/create`, data)
  } else {
    return requestPOST(`/admin/partyB/project/picture/create`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 图片物料详情
export function pictureInfo(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/project/picture/pictureDetails`, data)
  } else {
    return requestPOST(`/admin/partyB/project/picture/pictureDetails`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 图片物料修改
export function pictureEdit(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/project/picture/update`, data)
  } else {
    return requestPOST(`/admin/partyB/project/picture/update`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 图片物料删除
export function pictureDel(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/project/picture/softDeletes`, data)
  } else {
    return requestPOST(`/admin/partyB/project/picture/softDeletes`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 图片更新下载次数
export function pictureDownload(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/project/picture/download`, data)
  } else {
    return requestPOST(`/admin/partyB/project/picture/download`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 图片物料批量修改
export function pictureBatchEdit(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/project/picture/batchUpdate`, data)
  } else {
    return requestPOST(`/admin/partyB/project/picture/batchUpdate`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 文案物料
// 文案列表
export function copywritingList(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/project/copywriting/getLists`, data)
  } else {
    return requestPOST(`/admin/partyB/project/copywriting/getLists`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 新增文案
export function copywritingCreate(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/project/copywriting/create`, data)
  } else {
    return requestPOST(`/admin/partyB/project/copywriting/create`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 文案详情
export function copywritingInfo(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/project/copywriting/copywritingDetails`, data)
  } else {
    return requestPOST(`/admin/partyB/project/copywriting/copywritingDetails`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 修改文案
export function copywritingEdit(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/project/copywriting/update`, data)
  } else {
    return requestPOST(`/admin/partyB/project/copywriting/update`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 删除文案
export function copywritingDel(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/project/copywriting/softDeletes`, data)
  } else {
    return requestPOST(`/admin/partyB/project/copywriting/softDeletes`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 批量文案详情
export function copywritingBatchInfo(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/project/copywriting/batchDetails`, data)
  } else {
    return requestPOST(`/admin/partyB/project/copywriting/batchDetails`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 批量文案编辑
export function copywritingBatchEdit(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/project/copywriting/batchUpdate`, data)
  } else {
    return requestPOST(`/admin/partyB/project/copywriting/batchUpdate`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// h5链接
// h5链接列表
export function H5List(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/project/url/getLists`, data)
  } else {
    return requestPOST(`/admin/partyB/project/url/getLists`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 新增h5
export function H5Create(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/project/url/create`, data)
  } else {
    return requestPOST(`/admin/partyB/project/url/create`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 修改h5
export function H5Edit(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/project/url/update`, data)
  } else {
    return requestPOST(`/admin/partyB/project/url/update`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 删除h5
export function H5Del(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/project/url/softDeletes`, data)
  } else {
    return requestPOST(`/admin/partyB/project/url/softDeletes`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 标签管理
// 标签列表
export function tagsList(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/project/label/getLists`, data)
  } else {
    return requestPOST(`/admin/partyB/project/label/getLists`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 新增编辑标签
export function tagsSave(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/project/label/save`, data)
  } else {
    return requestPOST(`/admin/partyB/project/label/save`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 删除标签
export function tagsDel(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/project/label/delete`, data)
  } else {
    return requestPOST(`/admin/partyB/project/label/delete`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 渠道管理
// 渠道列表
export function channelList(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/project/channel/getLists`, data)
  } else {
    return requestPOST(`/admin/partyB/project/channel/getLists`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 新增编辑渠道
export function channelSave(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/project/channel/save`, data)
  } else {
    return requestPOST(`/admin/partyB/project/channel/save`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 删除渠道
export function channelDel(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/project/channel/delete`, data)
  } else {
    return requestPOST(`/admin/partyB/project/channel/delete`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}

// 公司
// 公司列表(乙方)
export function companyLists(data) {
  return requestPOST(`/admin/partyB/companyLists`, data)
}
// 乙方关联甲方公司列表
export function ownerLists(data) {
  return requestPOST(`/admin/common/ownerLists`, data)
}
// 公共接口
// 直传
export function STSToken() {
  return requestPOST(`/admin/common/sts`)
}
// 邀请链接
export function invitationLink(data) {
  return requestPOST(`/admin/common/invitationLink`, data)
}
// 链接查询
export function linkContent(data) {
  return requestPOST(`/admin/linkContent`, data)
}
// 上传文件
export function upload(data) {
  return requestPOST(`/admin/common/upload`, data)
}
// 上传链接
export const uploadUrl = process.env.VUE_APP_API_URL + '/admin/common/upload'
// 操作人列表
export function operatorList(data) {
  return requestPOST(`/admin/common/operator`, data)
}
// 甲方下的关联乙方公司列表
export function contractorLists(data) {
  return requestPOST(`/admin/common/contractorLists`, data)
}
// 公司员工
export function companyUsers(data) {
  return requestPOST(`/admin/common/companyUsers`, data)
}
// 项目列表
export function getProjectLists(data) {
  return requestPOST(`/admin/common/getProjectLists`, data)
}
// 已通过的公司员工
export function memberLists(data) {
  return requestPOST(`/admin/partyB/setup/relates/memberLists`, data)
}
// 添加同事
export function addColleagues(data) {
  return requestPOST(`/admin/partyB/setup/relates/addColleagues`, data)
}


// 设置模块
// 链接处理
export function linkProcessing(data) {
  return requestPOST(`/admin/linkProcessing`, data)
}
// 容量设置-获取容量信息
export function capacity(data) {
  return requestPOST(`/admin/partyA/setup/capacity/capacity`, data)
}
// 企业信息
export function enterpriseInfo(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/setup/enterprise/enterpriseInfo`, data)
  } else {
    return requestPOST(`/admin/partyB/setup/enterprise/enterpriseInfo`, data)
  }
}
// 修改企业信息
export function updateEnterprise(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/setup/enterprise/updateEnterprise`, data)
  } else {
    return requestPOST(`/admin/partyB/setup/enterprise/updateEnterprise`, data)
  }
}
// 员工列表 / 获取企业用户
export function employeeList(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/setup/memberRole/employeeList`, data)
  } else {
    return requestPOST(`/admin/partyB/setup/memberRole/employeeList`, data)
  }
}
// 员工审核
export function employeeReview(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/setup/memberRole/employeeReview`, data)
  } else {
    return requestPOST(`/admin/partyB/setup/memberRole/employeeReview`, data)
  }
}
// 员工删除
export function employeeDelete(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/setup/memberRole/employeeDelete`, data)
  } else {
    return requestPOST(`/admin/partyB/setup/memberRole/employeeDelete`, data)
  }
}
// 个人信息修改-修改用户信息
export function updateUser(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/setup/personal/updateUser`, data)
  } else {
    return requestPOST(`/admin/partyB/setup/personal/updateUser`, data)
  }
}
// 关联公司列表
export function getRelationCompany(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/setup/relates/getRelationCompany`, data)
  } else {
    return requestPOST(`/admin/partyB/setup/relates/getRelationCompany`, data)
  }
}
// 邀请公司列表
export function invitedList(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/setup/relates/invitedList`, data)
  } else {
    return requestPOST(`/admin/partyB/setup/relates/invitedList`, data)
  }
}
// 受邀公司列表
export function invitationList(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/setup/relates/invitationList`, data)
  } else {
    return requestPOST(`/admin/partyB/setup/relates/invitationList`, data)
  }
}
// 新增关联公司
export function creatRelationCompany(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/setup/relates/creatRelationCompany`, data)
  } else {
    return requestPOST(`/admin/partyB/setup/relates/creatRelationCompany`, data)
  }
}
// 审核关联公司
export function relationCompanyProcess(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/setup/relates/relationCompanyProcess`, data)
  } else {
    return requestPOST(`/admin/partyB/setup/relates/relationCompanyProcess`, data)
  }
}
// 修改关联公司简称
export function updateRelationCompany(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/setup/relates/updateRelationCompany`, data)
  } else {
    return requestPOST(`/admin/partyB/setup/relates/updateRelationCompany`, data)
  }
}
// 未关联公司列表
export function unassociatedCompany(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/setup/relates/unassociatedCompany`, data)
  } else {
    return requestPOST(`/admin/partyB/setup/relates/unassociatedCompany`, data)
  }
}
// 删除关联用户
export function deleteRelationUser(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/setup/relates/deleteRelationUser`, data)
  } else {
    return requestPOST(`/admin/partyB/setup/relates/deleteRelationUser`, data)
  }
}
// 审核关联用户
export function processUser(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/setup/relates/processUser`, data)
  } else {
    return requestPOST(`/admin/partyB/setup/relates/processUser`, data)
  }
}
// 重新提交
export function resubmit(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/setup/relates/resubmit`, data)
  } else {
    return requestPOST(`/admin/partyB/setup/relates/resubmit`, data)
  }
}
// 获取角色列表
export function getRoleList(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/setup/memberRole/getRoleList`, data)
  } else {
    return requestPOST(`/admin/partyB/setup/memberRole/getRoleList`, data)
  }
}
// 新增角色
export function createRole(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/setup/memberRole/createRole`, data)
  } else {
    return requestPOST(`/admin/partyB/setup/memberRole/createRole`, data)
  }
}
// 修改角色
export function updateRole(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/setup/memberRole/updateRole`, data)
  } else {
    return requestPOST(`/admin/partyB/setup/memberRole/updateRole`, data)
  }
}
// 删除角色
export function deleteRole(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/setup/memberRole/deleteRole`, data)
  } else {
    return requestPOST(`/admin/partyB/setup/memberRole/deleteRole`, data)
  }
}
// 替换管理员
export function changeAdmin(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/setup/memberRole/changeAdmin`, data)
  } else {
    return requestPOST(`/admin/partyB/setup/memberRole/changeAdmin`, data)
  }
}
// 修改员工角色
export function changeRole(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/setup/memberRole/changeRole`, data)
  } else {
    return requestPOST(`/admin/partyB/setup/memberRole/changeRole`, data)
  }
}
// 设置默认角色
export function changeDefaultRole(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/setup/memberRole/changeDefaultRole`, data)
  } else {
    return requestPOST(`/admin/partyB/setup/memberRole/changeDefaultRole`, data)
  }
}
// 权限列表
export function permissionList(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/setup/memberRole/permissionList`, data)
  } else {
    return requestPOST(`/admin/partyB/setup/memberRole/permissionList`, data)
  }
}
// 更新关联用户权限
export function relateschangeRCUserStatus(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/setup/relateschangeRCUserStatus`, data)
  } else {
    return requestPOST(`/admin/partyA/setup/relateschangeRCUserStatus`, data)
  }
}
// 获取关联角色列表
export function getAssociationRoleList(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/setup/relates/getAssociationRoleList`, data)
  } else {
    return requestPOST(`/admin/partyA/setup/relates/getAssociationRoleList`, data)
  }
}
// 新增关联角色
export function createAssociationRole(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/setup/relates/createAssociationRole`, data)
  } else {
    return requestPOST(`/admin/partyA/setup/relates/createAssociationRole`, data)
  }
}
// 修改关联角色
export function updateAssociationRole(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/setup/relates/updateAssociationRole`, data)
  } else {
    return requestPOST(`/admin/partyA/setup/relates/updateAssociationRole`, data)
  }
}
// 删除关联角色
export function deleteAssociationRole(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/setup/relates/deleteAssociationRole`, data)
  } else {
    return requestPOST(`/admin/partyA/setup/relates/deleteAssociationRole`, data)
  }
}
// 修改关联员工角色
export function changeAssociationRole(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/setup/relates/changeAssociationRole`, data)
  } else {
    return requestPOST(`/admin/partyA/setup/relates/changeAssociationRole`, data)
  }
}
// 设置关联默认角色
export function changeAssociationDefaultRole(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/setup/relates/changeAssociationDefaultRole`, data)
  } else {
    return requestPOST(`/admin/partyA/setup/relates/changeAssociationDefaultRole`, data)
  }
}
// 关联权限列表
export function permissionAssociationList(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/setup/relates/permissionAssociationList`, data)
  } else {
    return requestPOST(`/admin/partyB/setup/relates/permissionAssociationList`, data)
  }
}

// 版权-字体版权列表
export function copyrightList(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/copyright/font/getLists`, data)
  } else {
    return requestPOST(`/admin/partyB/copyright/font/getLists`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}

// 版权-字体版权列表新增
export function copyrightCreate(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/copyright/font/create`, data)
  } else {
    return requestPOST(`/admin/partyB/copyright/font/create`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}

// 版权-字体版权列表详情
export function copyrightDetails(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/copyright/font/details`, data)
  } else {
    return requestPOST(`/admin/partyB/copyright/font/details`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}

// 版权-字体版权列表修改
export function copyrightUpdate(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/copyright/font/update`, data)
  } else {
    return requestPOST(`/admin/partyB/copyright/font/update`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}

// 版权-字体版权列表删除
export function copyrightDetele(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/copyright/font/softDelete`, data)
  } else {
    return requestPOST(`/admin/partyB/copyright/font/softDelete`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}

// 版权-字体版权下载次数
export function copyrightDewnload(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/copyright/font/download`, data)
  } else {
    return requestPOST(`/admin/partyB/copyright/font/download`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 版权-3D版权列表
export function copyright3DList(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/copyright/material/getLists`, data)
  } else {
    // alert('3d列表')
    console.log({ ...data, enterprise_id: store.state.enterpriseId })
    return requestPOST(`/admin/partyB/copyright/material/getLists`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}

// 版权-3D版权列表新增
export function copyright3DCreate(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/copyright/material/create`, data)
  } else {
    return requestPOST(`/admin/partyB/copyright/material/create`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}

// 版权-3D版权列表详情
export function copyright3DDetails(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/copyright/material/details`, data)
  } else {
    return requestPOST(`/admin/partyB/copyright/material/details`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}

// 版权-3D版权列表修改
export function copyright3DUpdate(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/copyright/material/update`, data)
  } else {
    return requestPOST(`/admin/partyB/copyright/material/update`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}

// 版权-3D版权列表删除
export function copyright3DDetele(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/copyright/material/softDelete`, data)
  } else {
    return requestPOST(`/admin/partyB/copyright/material/softDelete`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}

// 版权-3D版权下载次数
export function copyright3DDownload(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/copyright/material/download`, data)
  } else {
    return requestPOST(`/admin/partyB/copyright/material/download`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}

// 版权-音频版权列表
export function copyrightAudioGetLists(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/copyright/audio/getLists`, data)
  } else {
    return requestPOST(`/admin/partyB/copyright/audio/getLists`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 版权-音频版权新增
export function copyrightAudioCreate(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/copyright/audio/create`, data)
  } else {
    return requestPOST(`/admin/partyB/copyright/audio/create`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 版权-音频版权修改
export function copyrightAudioUpdate(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/copyright/audio/update`, data)
  } else {
    return requestPOST(`/admin/partyB/copyright/audio/update`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 版权-音频版权详情
export function copyrightAudioDetails(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/copyright/audio/details`, data)
  } else {
    return requestPOST(`/admin/partyB/copyright/audio/details`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 版权-音频版权下载次数
export function copyrightAudioDownload(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/copyright/audio/download`, data)
  } else {
    return requestPOST(`/admin/partyB/copyright/audio/download`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 版权-音频版权删除
export function copyrightAudioSoftDelete(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/copyright/audio/softDelete`, data)
  } else {
    return requestPOST(`/admin/partyB/copyright/audio/softDelete`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}

// 知识库-获取
export function getKnowledge(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/knowledge/knowledgeManage/getKnowledge`, data)
  } else {
    return requestPOST(`/admin/partyB/knowledge/knowledgeManage/getKnowledge`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}

// 知识库-新增
export function getKnowledgeCreate(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/knowledge/knowledgeManage/create`, data)
  } else {
    return requestPOST(`/admin/partyB/knowledge/create`, {...data, enterprise_id: store.state.enterpriseId})
  }
}

// 知识库-修改
export function getKnowledgeUpdate(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/knowledge/knowledgeManage/update`, data)
  } else {
    return requestPOST(`/admin/partyB/knowledge/knowledgeManage/update`, {...data, enterprise_id: store.state.enterpriseId})
  }
}

// 回收站-项目-列表(甲)
export function recycleProjectLists(data) {
  return requestPOST(`/admin/partyA/recycleBin/project/getLists`, data)
}
// 回收站-项目-恢复(甲)
export function recycleProjectRestore(data) {
  return requestPOST(`/admin/partyA/recycleBin/project/restore`, data)
}
// 回收站-项目-删除(甲)
export function recycleProjectDel(data) {
  return requestPOST(`/admin/partyA/recycleBin/project/forceDelete`, data)
}

// 回收站-图片-列表
export function recyclePictureLists(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/recycleBin/picture/getLists`, data)
  } else {
    return requestPOST(`/admin/partyB/recycleBin/picture/getLists`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 回收站-图片-恢复
export function recyclePictureRestore(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/recycleBin/picture/restore`, data)
  } else {
    return requestPOST(`/admin/partyB/recycleBin/picture/restore`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 回收站-图片-删除
export function recyclePictureDel(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/recycleBin/picture/forceDelete`, data)
  } else {
    return requestPOST(`/admin/partyB/recycleBin/picture/forceDelete`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}

// 回收站-文案-列表
export function recycleCopywritingLists(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/recycleBin/copywriting/getLists`, data)
  } else {
    return requestPOST(`/admin/partyB/recycleBin/copywriting/getLists`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 回收站-文案-恢复
export function recycleCopywritingRestore(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/recycleBin/copywriting/restore`, data)
  } else {
    return requestPOST(`/admin/partyB/recycleBin/copywriting/restore`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 回收站-文案-删除
export function recycleCopywritingDel(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/recycleBin/copywriting/forceDelete`, data)
  } else {
    return requestPOST(`/admin/partyB/recycleBin/copywriting/forceDelete`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}

// 回收站-H5链接-列表
export function recycleH5Lists(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/recycleBin/url/getLists`, data)
  } else {
    return requestPOST(`/admin/partyB/recycleBin/url/getLists`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 回收站-H5链接-恢复
export function recycleH5Restore(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/recycleBin/url/restore`, data)
  } else {
    return requestPOST(`/admin/partyB/recycleBin/url/restore`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
// 回收站-H5链接-删除
export function recycleH5Del(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/recycleBin/url/forceDelete`, data)
  } else {
    return requestPOST(`/admin/partyB/recycleBin/url/forceDelete`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}

// 回收站-文字-列表
export function recycleBinFontGetLists(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/recycleBin/font/getLists`, data)
  } else {
    return requestPOST(`/admin/partyB/recycleBin/font/getLists`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}

// 回收站-文字-恢复
export function recycleBinFontRestore(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/recycleBin/font/restore`, data)
  } else {
    return requestPOST(`/admin/partyB/recycleBin/font/restore`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}

// 回收站-文字-删除
export function recycleBinFontForceDelete(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/recycleBin/font/forceDelete`, data)
  } else {
    return requestPOST(`/admin/partyB/recycleBin/font/forceDelete`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}

// 回收站-3D素材-列表
export function recycleBinMaterialGetLists(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/recycleBin/material/getLists`, data)
  } else {
    return requestPOST(`/admin/partyB/recycleBin/material/getLists`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}

// 回收站-3D素材-恢复
export function recycleBinMaterialRestore(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/recycleBin/material/restore`, data)
  } else {
    return requestPOST(`/admin/partyB/recycleBin/material/restore`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}

// 回收站-3D素材-删除
export function recycleBinMaterialForceDelete(data) {
  if (store.state.party) {
    return requestPOST(`/admin/partyA/recycleBin/material/forceDelete`, data)
  } else {
    return requestPOST(`/admin/partyB/recycleBin/material/forceDelete`, { ...data, enterprise_id: store.state.enterpriseId })
  }
}
