<template>
  <div class="sum">
    <div class="title">
      <span>项目素材</span>
    </div>
    <div class="content">
      <!-- <span>成员数量: </span> -->
      <div class="item" @click="visible = true" style="background-image: url(https://res.xiaoxxx.com/urlGather/202211047363be62fec4f65e264797.png)">
        <span>图片</span>
        <Number :num="$store.state.party ? data?.picture_count : data?.userInfo?.project_b_picture_count"></Number>
      </div>
      <div class="item" @click="visible = true" style="background-image: url(https://res.xiaoxxx.com/urlGather/20221104a570b5096ccf7196779114.png)">
        <span>文案</span>
        <Number :num="$store.state.party ? data?.copywriting_count : data?.userInfo?.project_b_copywriting_count"></Number>
      </div>
      <div class="item" @click="visible = true" style="background-image: url(https://res.xiaoxxx.com/urlGather/202211045db7e89a273673d2497806.png)">
        <span>H5</span>
        <Number :num="$store.state.party ? data.url_count : data?.userInfo?.project_b_url_count"></Number>
      </div>
    </div>
    <!-- 弹窗 -->
    <a-modal v-model="visible" title="选择项目" @ok="visible = false" :width="1200">
      <Table :request="api.projectList" :columns="columns">
        <div slot="material" slot-scope="scope">
          图片物料: {{scope.text.picture_count}}
          文案物料: {{scope.text.copywriting_count}}
          H5链接: {{scope.text.url_count}}
        </div>
        <a-space slot="action" slot-scope="scope">
          <a-button type="primary" @click="info(scope, 'picture')" size="small">物料详情</a-button>
          <a-button size="small" @click="info(scope, 'basic')">基本信息</a-button>
          <a-button size="small" @click="info(scope, 'result')">项目总结</a-button>
        </a-space>
      </Table>
    </a-modal>
    
  </div>
</template>

<script>
import Number from '../utils/number.vue'
import Table from '../table.vue'
export default {
  name: 'Fodder',
  props: ['data'],
  components: {
    Number,
    Table,
  },
  data() {
    return {
      visible: false,
      columns: [
        {
          title: 'id',
          dataIndex: 'id',
        },
        {
          title: '项目名称',
          dataIndex: 'name',
        },
        {
          title: '物料数量',
          key: 'material',
          scopedSlots: { customRender: 'material' },
        },
        {
          title: '创建日期',
          dataIndex: 'date',
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' },
          width: 100
        },
      ],
    }
  },
  methods: {
    info(scope, name) {
      this.$router.push({name: name, query: {id: scope.record.id}})
    }
  }
}
</script>

<style scoped>
.sum {
  background: #fff;
  border-radius: 10px;
}
.title {
  height: 50px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 0 10px 15px;
}
.content {
  min-height: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px;
}
.item {
  width: 32%;
  height: 110px;
  font-size: 30px;
  color: #fff;
  text-align: center;
  line-height: 110px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0px 10px 10px 1px #ddd;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  transition: all 1s;
}
.item::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.item::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7) url('../../assets/images/add.png') center no-repeat;
  background-size: contain;
  border-radius: 10px;
  z-index: 10;
  opacity: 0;
  transition: all 0.5s;
}
.item:hover::after {
  opacity: 1;
}
.item span {
  font-size: 16px;
  color: #fff;
  line-height: 1;
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 9;
}
.item .number {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
}
</style>