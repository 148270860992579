/*
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-10-12 15:48:14
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-10-19 15:50:47
 * @FilePath: \h5case4.xiaoxxx.cne:\code\仙助数字资产管理平台\digital\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import Antd from 'ant-design-vue'
import { message } from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import './style/main.css'
import './utils/power'
import * as api from './api/api' // 引入接口列表
import Table from './components/table.vue'
import '@wangeditor/editor/dist/css/style.css'
import '../src/utils/downLoad.js' // 引入绑定下载指令
import '../src/utils/downLoadZip.js' // 引入绑定打包下载指令
// import cavasBg from './utils/cavasBg';

Vue.prototype.zhCN = zhCN

Vue.prototype.api = api // 接口列表全局绑定

Vue.use(Antd);
Vue.prototype.message = message // 绑定全局message提示
// console.log(Down)
// Vue.use(down) // 绑定下载指令

Vue.config.productionTip = false

Vue.component('Table', Table) // 注册为全局组件

// 判断甲乙方
let party = window.localStorage.getItem('party') ? parseInt(window.localStorage.getItem('party')) : 1
store.commit('setParty', party)
store.commit('setAuthA', JSON.parse(window.localStorage.getItem('userInfo'))?.auth_a || []) // 缓存甲方权限存入vuex
if (window.localStorage.getItem('authMerge') && window.localStorage.getItem('authMerge') != '[]') {
  store.commit('setAuthB', JSON.parse(window.localStorage.getItem('authMerge'))) // 合并乙方权限存入vuex
} else {
  store.commit('setAuthB', JSON.parse(window.localStorage.getItem('userInfo'))?.auth_b || []) // 缓存乙方权限存入vuex
}
if (window.localStorage.getItem('enterpriseId')) {
  store.commit('setEnterpriseId', parseInt(window.localStorage.getItem('enterpriseId'))) // 选中乙方公司id(enterpriseId)存入vuex
}
// cavasBg.canvasFun()

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
