<template>
  <div class="create">
    <div class="title">
      创建企业空间
    </div>
    <a-form :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" @submit="handleSubmit">
      <a-steps direction="vertical" :current="current" class="timeaxis-box">
        <a-step class="timeaxis" status="process">
          <template slot="title">
            <div class="step-title">填写个人信息</div>
          </template>
          <span slot="description">
            <div class="step-content">
              <a-form-item label="用户名：">
                <a-input placeholder="依据公司习惯，使用实名或花名"
                  v-decorator="['name', { rules: [{ required: true, message: '请输入用户名！' }] }]" @blur="inputEnd" />
              </a-form-item>
              <a-form-item label="联系方式：">
                <a-input v-decorator="['phone', { rules: [{ required: true, message: '请输入联系方式！' }] }]" disabled />
              </a-form-item>
            </div>
          </span>
        </a-step>
        <a-step class="timeaxis" status="process">
          <template slot="title">
            <div class="step-title">填写企业信息</div>
          </template>
          <span slot="description">
            <div class="timeaxis-box step-content">
              <a-form-item label="企业名称：">
                <a-input v-decorator="['enterpriseName', { rules: [{ required: true, message: '请输入企业全称' }] }]"
                  placeholder="请输入企业全称" />
              </a-form-item>
              <a-form-item label="企业联系人：">
                <a-input v-decorator="['enterprisePhone', { rules: [{ required: true, message: '请输入企业联系人' }] }]"
                  placeholder="请输入企业联系人" />
              </a-form-item>
              <a-form-item label="企业邮箱：">
                <a-input v-decorator="['enterpriseEmail', { rules: [{ required: true, message: '请输入企业联系方式' }] }]"
                  placeholder="请输入企业联系方式" />
              </a-form-item>
              <a-form-item label="选择角色：">
                <div class="flex" v-decorator="['enterpriseType', { rules: [{ required: true, message: '请选择角色' }] }]">
                  <div class="party flex" :class="enterpriseType == 'partyA' ? 'party-check' : ''"
                    @click="check('partyA')">
                    <div class="party-icon"></div>
                    <div class="party-text">甲方</div>
                  </div>
                  <div class="party flex" :class="enterpriseType == 'partyB' ? 'party-check' : ''"
                    @click="check('partyB')">
                    <div class="party-icon"></div>
                    <div class="party-text">乙方</div>
                  </div>
                </div>
              </a-form-item>
            </div>
            <div class="flex tips">
              <div class="flex flex-direction-column justify-content-center">
                <span class="desc">您将自动成为企业空间管理员，管理员拥有企业空间设置，成员权限设置等权限，您可以随时替换管理员。</span>
                <span class="desc">提交后由人工审核企业信息，审核成功后立即生成您的企业空间，如有疑问请联系客服</span>
              </div>
              <div class="ewm">二维码占位</div>
            </div>
          </span>
        </a-step>
      </a-steps>
      <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
        <div class="next-step">
          <a-button type="primary" html-type="submit">
            提交信息
          </a-button>
        </div>
      </a-form-item>
    </a-form>
    <div class="text-align-center">
      <a-checkbox :checked="checked" @change="onChange">
        我已阅读并同意《仙助数字资产管理平台服务协议》
      </a-checkbox>
    </div>

    <headCanvas :canvasText="userNameEnd2" @childEvent="getHeadUrl"></headCanvas>

    <div class="quitLogin">
      <a-button type="danger" @click="returnNext"> 回到上页 </a-button> &emsp;&emsp;&emsp;
      <a-button type="danger" @click="quit"> 退出登录 </a-button>
    </div>

  </div>
</template>

<script>
import headCanvas from '../../components/headcanvas/headcanvas.vue'

export default {
  components: {
    headCanvas,
  },
  data() {
    return {
      headImageUrl: '123', // 用户默认头像
      userNameEnd2: '',
      current: 1, //当前步骤
      showStep: '12',
      form: this.$form.createForm(this, { name: 'coordinated' }),
      enterpriseType: '',
      checked: false
    };
  },
  methods: {
    // 返回上页
    returnNext() {
      this.$router.replace({ name: 'create' })
    },
    // 退出登录
    quit() {
      window.localStorage.removeItem('userInfo')
      window.localStorage.removeItem('token')
      this.$router.replace({ name: 'login' })
    },
    // 输入名字结束
    inputEnd() {
      console.log('输入名字结束');
      this.form.validateFields((err, value) => {
        console.log(err);
        this.userNameEnd2 = value.name.slice(0,2)
        console.log('this.userNameEnd2', this.userNameEnd2);
      });
    },
    // 获取头像链接
    getHeadUrl(data) {
      this.headImageUrl = data
      console.log('this.headImageUrl', this.headImageUrl);
    },
    userInfo() {
      this.api.getUserInfo({
      }).then(res => {
        console.log('res', res)
        window.localStorage.setItem('userInfo', JSON.stringify(res.data.userInfo))
      })
    },
    onChange() {
      this.checked = !this.checked
    },
    // 选择用户类型
    check(val) {
      this.enterpriseType = val
      this.form.setFieldsValue({
        enterpriseType: val
      })
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          if (this.checked) {
            console.log('Received values of form: ', values);
            this.api.createEnterprise({
              company_name: values.enterpriseName,
              contacts: values.enterprisePhone,
              contact: values.enterpriseEmail,
              name: values.name,
              mobile: values.phone,
              type: values.enterpriseType == 'partyA' ? '1' : '2',
              avatar: this.headImageUrl
            }).then(res => {
              console.log('res', res)
              if (res.code == 0) {
                console.log(res);
                this.$message.success(res.message);
                this.userInfo()
                setTimeout(() => {
                  this.$router.replace({ name: 'auditing' });
                }, 700);
              }
            })
          } else {
            this.$message.error('请先勾选下方协议');
          }
        }
      });
    },
  },
  mounted() {
    console.log(this.$route)
    this.form.setFieldsValue({
      name: this.$route.params.userName,
      phone: this.$route.params.userMobile,
      enterpriseName: '',
      enterprisePhone: '',
      enterpriseEmail: '',
      enterpriseType: 'partyA'
    })
    this.headImageUrl = this.$route.params.avatar
    this.enterpriseType = 'partyA'
  }
}
</script>

<style scoped>
.create {
  position: relative;
  border-radius: 10px;
  background-color: #fff;
  width: 70%;
  min-width: 1000px;
  margin: 30px auto 0;
  padding: 20px 40px;
  min-height: 80vh;
  overflow: hidden;
}

.title {
  height: 80px;
  font-size: 20px;
  color: #333;
  font-weight: bold;
  line-height: 80px;
  border-bottom: 1px solid rgba(121, 121, 121, 0.3);
}

.timeaxis-box {
  margin: 80px auto 0;
}

.tips {
  min-width: 850px;
  margin: 60px auto 50px;
  font-size: 14px;
  color: #333;
  justify-content: space-around;
}

.desc {
  /* width: 750px; */
  white-space: nowrap;
}

.ewm {
  width: 100px;
  height: 100px;
  background-color: #333;
  flex-shrink: 0;
}

.next-step {
  width: 570px;
  text-align: center;
  margin: 20px auto 0;
}



.party {
  border: 1px solid rgba(121, 121, 121, 1);
  border-radius: 10px;
  padding: 10px 20px;
  margin: 0 30px 0 0;
  cursor: pointer;
  color: #333;
}

.party-icon {
  width: 73px;
  height: 66px;
  background: url("https://res.xiaoxxx.com/urlGather/20221107cf15b51e5ae97811741075.png") center no-repeat;
  background-size: cover;
}

.party-text {
  width: 80px;
  height: 66px;
  line-height: 66px;
  font-size: 20px;
  text-align: center;
}

.party-check {
  border: 1px solid #f5222d;
  /* background-color: rgba(0, 0, 0, 0.1); */
  color: #f5222d;
}

.quitLogin {
  width: 100%;
  padding: 60px 0 0 0;
  text-align: center;
  /* position: fixed; */
  /* bottom: 100px; */
  /* left: 50%; */
  /* transform: translateX(-50%); */
}
</style>