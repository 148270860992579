<template>
  <div class="notice">
        <div class="screen-box">
          <a-form layout="inline" @submit="handleSubmit">
            <a-form-item label="日期：">
              <a-range-picker class="date-picker" v-model="form.selectDate" @change="onChangeDate" />
            </a-form-item>
            <a-form-item label="搜索：">
              <a-input v-model="form.selectMember" placeholder="请输入关键字搜索" />
            </a-form-item>
            <a-form-item>
              <a-button html-type="submit" shape="circle" icon="search"></a-button>
            </a-form-item>

            <a-form-item>
              <a-button @click="clearSelect">
                重置
              </a-button>
            </a-form-item>
          </a-form>
        </div>
        <Table ref="systemTable" :request="api.noticeLists" :columns="columnssystem" :param="{ pageSize: 10,keywords:form.selectMember,start_time:form.selectDate[0]?form.selectDate[0]:'',end_time:form.selectDate[1]?form.selectDate[1]:'' }">
          <!-- <img class="img" slot="avatar" slot-scope="scope" :src="scope.text.user_info.avatar"> -->
          <!-- <span slot="user" slot-scope="scope">{{scope.text.user_info.name}}</span> -->
          <div class="btns" slot="behavior" slot-scope="scope">
            <a-button type="primary" @click="compileChange(scope)" size="small">操作</a-button>
          </div>
          <!-- <span slot="behavior" slot-scope="scope">{{scope.text.behavior==1?'加入空间':scope.text.behavior==2?'移除空间':scope.text.behavior==3?'关联成功':''}}</span> -->
        </Table>
  </div>
</template>

<script>
import Table from '../../components/table.vue'
export default {
  components: {
    Table
  },
  data() {
    return {
      form: {
        selectMember: '', // 筛选条件-成员
        selectDate: [], // 筛选条件-日期
      },
      columnssystem: [
        // {
        //   title: '头像',
        //   key: 'avatar',
        //   // dataIndex: 'image_url',
        //   scopedSlots: { customRender: 'avatar' },
        //   width: '200',
        // },
        {
          title: '#',
          key: 'id',
          dataIndex: 'id',
          width: '80',
        },
        {
          title: '标题',
          // key: 'user',
          dataIndex: 'title',
          // scopedSlots: { customRender: 'user' },
          width: '200',
        },
        {
          title: '时间',
          // key: 'uplatetime',
          dataIndex: 'created_at',
          // scopedSlots: { customRender: 'uplatetime' },
          width: '100',
          sorter:true
        },
        {
          title: '操作',
          key: 'behavior',
          // dataIndex: 'user',
          scopedSlots: { customRender: 'behavior' },
          width: '200',
        },
      ],
      typekey:1,
    };
  },
  methods: {
    compileChange(data){
      this.$router.push({path:'/noticeDetails',query:{id:data.text.id}})
    },
    // 重置
    clearSelect() {
      this.form.selectMember = ''
      this.form.selectDate = []
      setTimeout(() => {
        this.$refs.systemTable.getData()
      }, 10);
    },
    onChangeDate(date, dateString) {
      this.form.selectDate = dateString
    },
    handleSubmit() {
        this.$refs.systemTable.getData()
    },
  },
  created() {
  }
}
</script>

<style scoped>
.notice {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 40px;
}

.screen-box {
  padding: 0 0 16px 0;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}


.date-picker {
  width: 260px;
}

.img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}
</style>