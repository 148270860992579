<template>
  <div class="index">
    <a-tabs default-active-key="1" type="card" @change="callback">
      <a-tab-pane key="1" tab="已关联公司" v-power:warning="'setup/relates/getRelationCompany'">
        <a-row type="flex" justify="center">
          <a-col :span="3">
            <div class="select-company">选择公司</div>
          </a-col>
          <a-col :span="1">
          </a-col>
          <a-col :span="16">
          </a-col>
        </a-row>
        <a-row type="flex" justify="center">
          <a-col :span="3">
            <a-menu mode="inline" :default-selected-keys="[defaultIndex]" style="height: 100%; border: none"
              @click="switchCompany">
              <a-menu-item class="company-item" key="addCompany" v-if="companyList.length == 0">
                暂无数据
              </a-menu-item>
              <a-menu-item class="company-item" v-for="(item, index) in companyList" :key="index" v-else>
                {{ ($store.state.party ? item.abbreviation : item.contractor_abbreviation) || ($store.state.party ?
                  item.contractor?.name : item.owner?.name) }}
              </a-menu-item>
            </a-menu>
          </a-col>
          <a-col :span="1">
          </a-col>
          <a-col :span="16">
            <div class="scroll-box">
              <div class="table-box">
                <div class="table-box-title">
                  <div>
                    <span>{{ companyFullName }}</span>&emsp;
                    <a-icon v-if="companyFullName" class="editCompanyName" @click="visibleEditcName = true" type="edit"
                      v-power="'setup/relates/updateRelationCompany'" />
                  </div>
                  <a-button type="primary" size="small" v-if="$store.state.party"
                    v-power="'setup/relates/inviteAssociatedUsers'" @click="showInviteLink()">使用链接邀请</a-button>
                  <div class="color-red" v-else v-power="'setup/relates/addColleagues'" @click="showAddColleague">
                    添加关联成员</div>
                </div>
                <Table ref="table" :columns="$store.state.party ? columns : columnsB" :tableData="listData"
                  :paginationShow="false" :loading="tableLoading" selected @selectedChange="selectedChange">
                  <img class="img-avatar" slot="img" slot-scope="scope" :src="scope.record.avatar" alt="">
                  <div slot="action" slot-scope="scope">
                    <a-button v-if="scope.record.status == 1" class="button" size="small" type="primary"
                      @click="showReviewerModal(scope.record)"
                      v-power:disabled="'setup/relates/processUser'">审核</a-button>
                    <a-button v-if="scope.record.status == 2 && $store.state.party" class="button" size="small"
                      @click="showEditModal(scope.record)">编辑</a-button>
                    <a-button v-if="scope.record.status == 2" class="button" size="small" type="danger"
                      @click="showDeleteModal(scope.record)"
                      v-power:disabled="'setup/relates/deleteRelationUser'">删除</a-button>
                  </div>
                </Table>
                <div class="batch-btn-box">
                  <a-button class="button" size="small" type="primary" @click="batchToExamineModal()"
                    v-power:disabled="'setup/relates/processUser'"
                    :disabled="selectedChangeStatusArr.indexOf(2) != -1">批量操作-审核</a-button>
                  <a-button v-if="$store.state.party" class="button" size="small" @click="batchEditModal()"
                    :disabled="selectedChangeStatusArr.indexOf(1) != -1">批量操作-编辑</a-button>
                  <a-button class="button" size="small" type="danger" @click="batchDeleteModal()"
                    v-power:disabled="'setup/relates/deleteRelationUser'"
                    :disabled="selectedChangeStatusArr.indexOf(1) != -1">批量操作-删除</a-button>
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </a-tab-pane>
      <a-tab-pane key="2" tab="收到关联申请的公司" v-power:warning="'setup/relates/invitedList'">
        <div class="label">
          <div class="label-span-status">审核状态：</div>
          <a-select slot="select" style="width: 140px" @change="handleStatusChange" placeholder="请选择状态">
            <a-select-option :value="item.id" v-for="(item, index) in statusList" :key="index">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="table-box">
          <div class="table-box-title">
            <span>收到关联申请的公司</span>
            <!-- <a-button type="primary" size="small">关联链接</a-button> -->
          </div>
          <Table :columns="columnsInvitation" :tableData="invitedListData" :paginationShow="false"
            :loading="tableLoading">
            <div slot="action" slot-scope="scope">
              <a-button v-if="scope.record.status == 1" class="power-button" size="small" type="primary"
                @click="toExamine(scope.record)" v-power:disabled="'setup/relates/relationCompanyProcess'">审核</a-button>
            </div>
          </Table>
        </div>
      </a-tab-pane>
      <a-tab-pane key="3" tab="发送关联申请的公司" v-power:warning="'setup/relates/invitationList'">
        <div class="label">
          <div class="label-span-status">审核状态：</div>
          <a-select slot="select" style="width: 140px" @change="handleStatusIonChange" placeholder="请选择状态">
            <a-select-option :value="item.id" v-for="(item, index) in statusList" :key="index">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="table-box">
          <div class="table-box-title">
            <span>发送关联申请的公司</span>
            <!-- <a-button type="primary" size="small">关联链接</a-button> -->
          </div>
          <Table :columns="columnsinvited" :tableData="invitationListData" :paginationShow="false"
            :loading="tableLoading">
            <div slot="action" slot-scope="scope">
              <a-button class="power-button" v-if="scope.record.status == 3" size="small"
                @click="againExamine(scope.record)" v-power:disabled="'setup/relates/resubmit'">重新申请</a-button>
            </div>
          </Table>
        </div>
      </a-tab-pane>
      <a-tab-pane key="4" tab="关联用户角色" v-if="$store.state.party">
        <div class="table-box">
          <div class="table-box-title">
            <span>关联用户角色</span>
            <a-button type="primary" size="small" @click="showaddRoleModal"
              v-power="'setup/relates/createAssociationRole'">+新增角色</a-button>
          </div>
          <Table :columns="aRoleColumns" :tableData="aRoleTableData" :paginationShow="false">
            <a-checkbox slot="checkbox" slot-scope="scope" :checked="scope.record.is_default == 1"
              @click="setDefaultRole(scope.record)"
              v-power:disabled="'setup/relates/changeAssociationDefaultRole'">设为默认角色</a-checkbox>
            <div slot="action" slot-scope="scope">
              <a-button class="power-button" @click="poewr(scope.record)" size="small"
                v-power:disabled="'setup/relates/updateAssociationRole'">配置权限</a-button>
              <a-button class="power-button" @click="showDelModal(scope.record)" size="small" type="danger"
                v-power:disabled="'setup/relates/deleteAssociationRole'">删除</a-button>
            </div>
          </Table>
        </div>
      </a-tab-pane>
    </a-tabs>

    <!-- 页面右上角按钮 -->
    <div class="right-btn-box">
      <a-button class="right-btn" type="primary" size="small" @click="showInviteSpace()"
        v-power="'setup/relates/creatRelationCompany'">关联链接</a-button>
      <a-button class="right-btn" type="primary" size="small" @click="openInviteCompany()"
        v-power="'setup/relates/creatRelationCompany'">关联搜索</a-button>
    </div>

    <!-- 邀请公司 弹窗 -->
    <a-modal v-model="visibleAddCompany" :title="$store.state.party ? '邀请乙方公司' : '邀请甲方公司'">
      <a-form :form="formDetails" @submit="addCompanyFun">
        <div class="label">
          <span>{{ $store.state.party ? '选择乙方公司' : '选择甲方公司' }}：</span>
          <a-select show-search :value="addCompanyId" :default-active-first-option="false" :show-arrow="false"
            :placeholder="$store.state.party ? '请输入乙方公司名称筛选' : '请输入甲方公司名称筛选'" style="width: 66%" :filter-option="false"
            v-decorator="['addCompanyId', { rules: [{ required: true, message: '请输入！' }] }]" :not-found-content="null"
            @search="fetchUser" @change="handleCompanyChange">
            <a-spin v-if="fetching" slot="notFoundContent" size="small" />
            <a-select-option :value="d.id" v-for="(d, index) in unCompanyList" :key="index">
              {{ d.name }}
            </a-select-option>
          </a-select>
        </div>
      </a-form>
      <template slot="footer">
        <a-button key="submit" type="primary" :loading="loading" @click="addCompanyFun">邀请</a-button>
        <a-button key="back" @click="closeAddCompany">取消</a-button>
      </template>
    </a-modal>

    <!-- 添加关联成员 弹窗 -->
    <a-modal v-model="visibleAddColleague" title="添加关联成员">
      <div class="label">
        <span>选择同事：</span>
        <a-select slot="select" style="width: 240px" @change="handleColleagueChange" placeholder="请选择同事">
          <a-select-option :value="item.id" v-for="(item, index) in colleagueList" :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" :loading="loading" @click="addColleagueFun">确定</a-button>
        <a-button key="back" @click="closeAddColleagueFun">取消</a-button>
      </template>
    </a-modal>

    <!-- 修改公司简称 弹窗 -->
    <a-modal v-model="visibleEditcName" title="修改公司简称">
      <div class="label">
        <span>乙方全称：</span>{{ companyFullName }}
      </div>
      <div class="label">
        <span style="margin:0">乙方简称：</span>
        <a-input style="width:50%" v-model="companyName" placeholder="请输入简称" />
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" :loading="loading" @click="updateCompanyName">保存</a-button>
        <a-button key="back" @click="visibleEditcName = false">取消</a-button>
      </template>
    </a-modal>

    <!-- 使用链接邀请 加入乙方-->
    <a-modal title="使用链接邀请" :visible="visibleInviteLink" @ok="handleOkInvite" @cancel="handleCancelInvite">
      <div>
        <span>链接有效期：</span>
        <a-select style="width: 80%" :value="inviteTime" :showSearch="true" @change="selectTimeChange" placeholder="请选择">
          <a-select-option value="2">2小时</a-select-option>
          <a-select-option value="24">24小时</a-select-option>
          <a-select-option value="48">48小时</a-select-option>
        </a-select>
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" :loading="copyLoading" @click="handleOkInvite">
          一键复制
        </a-button>

        <a-button key="back" @click="handleCancelInvite">
          取消
        </a-button>

        <div class="yeluosen">
          <input readonly="true" id="input" value />
        </div>
      </template>
    </a-modal>

    <!-- 使用链接邀请乙方 关联空间-->
    <a-modal title="关联链接" :visible="visibleInviteSpace" @ok="handleOkInviteSpace" @cancel="handleCancelInviteSpace">

      <div>
        <span>关联公司的用户打开链接，自动向本空间发送关联申请</span> <br><br>
        <span>链接有效期：</span>
        <a-select style="width: 80%" :value="inviteTime" :showSearch="true" @change="selectTimeSpaceChange"
          placeholder="请选择">
          <a-select-option value="2">2小时</a-select-option>
          <a-select-option value="24">24小时</a-select-option>
          <a-select-option value="48">48小时</a-select-option>
        </a-select>
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" :loading="copyLoading" @click="handleOkInviteSpace">
          一键复制
        </a-button>
        <a-button key="back" @click="handleCancelInviteSpace">
          取消
        </a-button>
        <div class="yeluosen">
          <input readonly="true" id="inputSpace" value />
        </div>
      </template>
    </a-modal>

    <!-- 审核甲、乙方公司 -->
    <a-modal :title="$store.state.party ? '审核乙方公司' : '审核甲方公司'" v-model="reviewPartyB">
      <div v-if="reviewerInfo.contractor || reviewerInfo.owner">
        <span style="margin:0">企业名称：{{ $store.state.party ? reviewerInfo.contractor?.name : reviewerInfo.owner?.name
        }}</span><br><br>
        <span style="margin:0">企业联系人：{{ $store.state.party ? reviewerInfo.contractor?.contacts :
          reviewerInfo.owner?.contacts }}</span><br><br>
        <span style="margin:0">企业联系方式：{{ $store.state.party ? reviewerInfo.contractor?.contact :
          reviewerInfo.owner?.contact }}</span><br><br>

      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" :loading="loading" @click="examinePartyB('adopt')">通过</a-button>
        <a-button key="back" :loading="loadingRefuse" @click="examinePartyB('refuse')">拒绝</a-button>
      </template>
    </a-modal>

    <!-- 重新申请 -->
    <a-modal title="重新申请" v-model="reviewAgaExamine">
      <div v-if="reviewerInfo.contractor || reviewerInfo.owner">
        <span style="margin:0">企业名称：{{ $store.state.party ? reviewerInfo.contractor?.name : reviewerInfo.owner?.name
        }} </span><br><br>
        <span style="margin:0">企业联系人：{{ $store.state.party ? reviewerInfo.contractor?.contacts :
          reviewerInfo.owner?.contacts }}</span><br><br>
        <span style="margin:0">企业联系方式：{{ $store.state.party ? reviewerInfo.contractor?.contact :
          reviewerInfo.owner?.contact }}</span><br><br>
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" :loading="loading" @click="examineAgain('adopt')">确定</a-button>
        <a-button key="back" @click="examineAgain('refuse')">取消</a-button>
      </template>
    </a-modal>

    <!-- 新增角色弹窗  -->
    <a-modal title="新增角色" :visible="addRoleModal" @ok="onSubmit" @cancel="handleAddRoleCancel">
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
        <a-form-item label="角色名:">
          <a-input v-decorator="['name', { rules: [{ required: true, message: '请输入用户名!' }] }]" />
        </a-form-item>
        <!-- <a-form-item label="角色名(en):">
          <a-input v-decorator="['enname', { rules: [{ required: true, message: '请输入用户名!' }] }]" />
        </a-form-item> -->
        <a-form-item label="权限组：">
          <a-tree v-model="checkedKeys" checkable :expanded-keys="expandedKeys" :auto-expand-parent="autoExpandParent"
            :replace-fields="replaceFields" :selected-keys="selectedKeys" :tree-data="permissionTreeData"
            @expand="onExpand" @check="onCheck" />
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- 配置权限弹窗 -->
    <a-modal title="配置权限" :visible="visibleSetPower" @ok="handleOk" @cancel="handleCancel">
      <div class="label">
        <span style="margin:0">角色名称：</span>
        <a-input style="width:50%" v-model="editRoleName" placeholder="请输入角色名称" />
      </div>
      <!-- <div class="label">
        <span style="margin:0">角色名称(en)：</span>
        <a-input style="width:50%" v-model="editRoleEnName" placeholder="请输入角色名称" />
      </div> -->
      <div class="label">
        <span style="margin:0">权限配置：</span>
        <a-tree v-model="checkedKeys" checkable :expanded-keys="expandedKeys" :auto-expand-parent="autoExpandParent"
          :replace-fields="replaceFields" :selected-keys="selectedKeys" :tree-data="permissionTreeData" @expand="onExpand"
          @check="onCheck" />
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" :loading="loading" @click="handleOk" :disabled="isEdit"> 保存 </a-button>
        <a-button key="back" @click="handleCancel"> 取消 </a-button>
      </template>
    </a-modal>

    <!-- 默认角色配置弹窗  -->
    <a-modal title="默认角色配置" :visible="visibleSetRole" @ok="handleOkRole" @cancel="handleCancelRole">
      <div>
        <p>系统自动为首次进入企业空间的同事分配角色，</p><br>
        <p v-if="currentDefault">当前默认角色是<span class="color-red">“{{ currentDefault }}”</span>，</p>
        <p v-else>当前暂无默认角色，</p><br>
        <p>是否切换为<span class="color-red">“{{ targetDefault }}”</span>？</p><br>
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" :loading="loading" @click="handleOkRole">
          保存
        </a-button>
        <a-button key="back" @click="handleCancelRole">
          取消
        </a-button>
      </template>
    </a-modal>

    <!-- 删除角色 弹窗 -->
    <a-modal v-model="deleteRoleShowModal" title="删除角色">
      <div v-if="delRoleInfo">
        <span style="margin:0">删除角色：{{ delRoleInfo.name }} </span><br><br>
      </div>
      <template slot="footer">
        <a-button key="submit" type="danger" :loading="loading" @click="deleteRole('adopt')">删除</a-button>
        <a-button key="back" @click="deleteRole('refuse')">取消</a-button>
      </template>
    </a-modal>

    <!-- 审核关联成员 弹窗 -->
    <a-modal v-model="reviewerShowModal" title="审核关联成员">
      <div v-if="reviewerInfo">
        <span style="margin:0">用户名：{{ reviewerInfo.name }}</span>&emsp;&emsp;
        <span style="margin:0">联系方式：{{ reviewerInfo.mobile }}</span><br><br>
        <span style="margin:0">企业名称：{{ companyFullName }} </span><br><br>
        <span style="margin:0">申请关联当前空间，是否通过？</span>
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" :loading="loading" @click="examine('adopt')">通过</a-button>
        <a-button key="back" type="danger" :loading="loadingRefuse" @click="examine('refuse')">拒绝</a-button>
      </template>
    </a-modal>

    <!-- 编辑成员弹窗  -->
    <a-modal title="编辑成员" :visible="visibleEditUser" @ok="handleOkEdituser" @cancel="handleCancelEditUser">
      <div v-if="modifyUserInfo">
        <span>用户名：{{ modifyUserInfo.name }}</span>&emsp;&emsp;&emsp;&emsp;
        <span>联系方式：{{ modifyUserInfo.mobile }}</span> <br><br>
        <span>成员角色：</span>
        <a-select :default-value="modifyUserInfo.role ? modifyUserInfo.role.id : ''" style="width: 80%" :showSearch="true"
          @change="editHandleChange">
          <a-select-option v-for="(item, index) in aRoleTableData" :key="index" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" :loading="loading" @click="handleOkEdituser">
          保存
        </a-button>
        <a-button key="back" @click="handleCancelEditUser">
          取消
        </a-button>
      </template>
    </a-modal>

    <!-- 删除关联成员 弹窗 -->
    <a-modal v-model="deleteShowModal" title="删除关联成员">
      <div v-if="reviewerInfo">
        <span style="margin:0">用户名：{{ reviewerInfo.name }}</span>&emsp;&emsp;
        <span style="margin:0">联系方式：{{ reviewerInfo.mobile }}</span><br><br>
        <span style="margin:0">企业名称：{{ companyFullName }} </span><br><br>
      </div>
      <template slot="footer">
        <a-button key="submit" type="danger" :loading="loading" @click="deleteRelUser('adopt')">删除</a-button>
        <a-button key="back" @click="deleteRelUser('refuse')">取消</a-button>
      </template>
    </a-modal>

    <!-- 批量操作-审核 -->
    <a-modal v-model="visibleBatchToExamine" title="批量审核关联成员">
      <div>
        <span>批量处理申请关联当前空间，是否通过？</span>
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" :loading="loading" @click="batchToExamine('adopt')">通过</a-button>
        <a-button key="back" type="danger" :loading="loadingRefuse" @click="batchToExamine('refuse')">拒绝</a-button>
      </template>
    </a-modal>

    <!-- 批量操作-编辑  -->
    <a-modal title="批量编辑成员" :visible="visibleBatchEdit" @ok="handleOkBatchEdit" @cancel="handleCancelBatchEdit">
      <div>
        <span>编辑角色：</span>
        <a-select style="width: 80%" :showSearch="true" @change="editHandleChange" placeholder="请先择角色">
          <a-select-option v-for="(item, index) in aRoleTableData" :key="index" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" :loading="loading" @click="handleOkBatchEdit">
          保存
        </a-button>
        <a-button key="back" @click="handleCancelBatchEdit">
          取消
        </a-button>
      </template>
    </a-modal>

    <!-- 批量操作-删除 -->
    <a-modal v-model="visibleBatchDelete" title="批量删除关联成员" @cancel="batchDelete('refuse')">
      <div>
        <span>是否批量删除？ </span>
      </div>
      <template slot="footer">
        <a-button key="submit" type="danger" :loading="loading" @click="batchDelete('adopt')">删除</a-button>
        <a-button key="back" @click="batchDelete('refuse')">取消</a-button>
      </template>
    </a-modal>

    <!-- 功能提示遮罩 -->
    <div class="guide" v-if="partyAGuideTipsShow || partyBGuideTipsShow">
      <div class="guide-content" v-if="partyAGuideTipsShow">
        <a-carousel ref="carousel" class="guide-swiper" :dots="false">
          <div class="guide-item">
            <img :src="guideItemPartyA[guideIndex].img">
            <p>{{ guideItemPartyA[guideIndex].content }}&emsp;({{ guideIndex + 1 }}/{{ guideItemPartyA.length }})</p>
          </div>
        </a-carousel>
        <div class="guide-btns">
          <a-button v-if="guideIndex > 0" type="primary" ghost @click="guideStepPrev">上一步</a-button>
          <a-button v-if="guideIndex < guideItemPartyA.length - 1" type="primary" ghost
            @click="guideStepNext">下一步</a-button>
          <a-button v-if="guideIndex >= (guideItemPartyA.length - 1)" type="primary" ghost
            @click="guideHide">知道了</a-button>
        </div>
      </div>
      <div class="guide-content" v-if="partyBGuideTipsShow">
        <a-carousel ref="carousel" class="guide-swiper" :dots="false">
          <div class="guide-item">
            <img :src="guideItemPartyB[guideIndex].img">
            <p>{{ guideItemPartyB[guideIndex].content }}&emsp;({{ guideIndex + 1 }}/{{ guideItemPartyB.length }})</p>
          </div>
        </a-carousel>
        <div class="guide-btns">
          <a-button v-if="guideIndex > 0" type="primary" ghost @click="guideStepPrev">上一步</a-button>
          <a-button v-if="guideIndex < guideItemPartyB.length - 1" type="primary" ghost
            @click="guideStepNext">下一步</a-button>
          <a-button v-if="guideIndex >= (guideItemPartyB.length - 1)" type="primary" ghost
            @click="guideHide">知道了</a-button>
        </div>
      </div>
    </div>

  </div>
</template>
<script>

import Table from '../../../components/table.vue'
import debounce from 'lodash/debounce';

export default {
  inject: ['reload'],
  components: {
    Table,
  },
  data() {
    this.lastFetchId = 0;
    this.fetchUser = debounce(this.fetchUser, 800);
    return {
      partyAGuideTipsShow: true, // 显示指引-甲方
      partyBGuideTipsShow: true, // 显示指引-乙方
      guideIndex: 0, // 指引步骤.
      guideItemPartyA: [
        {
          img: 'https://res.xiaoxxx.com/urlGather/20230915eca89dd65cce4c13590418.jpg',
          content: '您和乙方使用协作功能，需要先在此关联对方公司'
        }, {
          img: 'https://res.xiaoxxx.com/urlGather/20230915451670b4f3af4e78823107.jpg',
          content: '和乙方公司关联后，即可邀请对方成员。'
        }
      ], // 指引步骤内容-甲方
      guideItemPartyB: [
        {
          img: 'https://res.xiaoxxx.com/urlGather/2023091505e412bc5864191f217108.jpg',
          content: '您和甲方使用协作功能，需要先在此关联对方公司'
        }, {
          img: 'https://res.xiaoxxx.com/urlGather/202309150bd0e18e502090ff700292.jpg',
          content: '和甲方公司关联后，添加我方关联成员。只有关联成员才能参与甲方项目。'
        }
      ], // 指引步骤内容-乙方

      copyLoading: true,// 复制键是否loading
      copyText: '', //一键复制的文案
      formDetails: this.$form.createForm(this, { addCompanyId: '' }),

      parentList: [],
      noRemovable: [],
      parentNo: [], // 子集被默认全部选中的父级id数组

      fetching: false,
      tableLoading: true,
      columns: [
        {
          title: '头像',
          dataIndex: 'avatar',
          width: '200',
          scopedSlots: { customRender: 'img' },
          align: 'center',
        },
        {
          title: '用户名',
          align: 'center',
          dataIndex: 'name',
        },
        {
          title: '手机号',
          align: 'center',
          dataIndex: 'mobile',
        },
        {
          title: '角色',
          align: 'center',
          customRender: (row) => {
            return <p>{row.role.name}</p>;
          },
        },
        {
          title: '操作',
          key: 'action',
          width: '240',
          scopedSlots: { customRender: 'action' },
          align: 'center',
        },
      ],
      columnsB: [
        {
          title: '头像',
          dataIndex: 'avatar',
          width: '200',
          scopedSlots: { customRender: 'img' },
          align: 'center',
        },
        {
          title: '用户名',
          align: 'center',
          dataIndex: 'name',
        },
        {
          title: '手机号',
          align: 'center',
          dataIndex: 'mobile',
        },
        {
          title: '操作',
          key: 'action',
          width: '240',
          scopedSlots: { customRender: 'action' },
          align: 'center',
        },
      ],
      listData: [],
      columnsInvitation: [
        {
          title: '企业名称',
          align: 'center',
          customRender: (row) => {
            return <p>{this.$store.state.party ? row.contractor?.name : row.owner?.name}</p>;
          },
        },
        {
          title: '企业联系人',
          align: 'center',
          customRender: (row) => {
            return <p>{this.$store.state.party ? row.contractor?.contacts : row.owner?.contacts}</p>;
          },
        },
        {
          title: '企业联系方式',
          align: 'center',
          customRender: (row) => {
            return <p>{this.$store.state.party ? row.contractor?.contact : row.owner?.contact}</p>;
          },
        },
        {
          title: '审核状态',
          align: 'center',
          dataIndex: '',
          //1待审核 2通过 3拒绝
          customRender: (row) => {
            return <p>{row.status == 2 ? '已通过' : row.status == 3 ? '已拒绝' : '待审核'}</p>;
          },
        },
        {
          title: '申请时间',
          align: 'center',
          dataIndex: 'updated_at',
        },
        {
          title: '操作',
          key: 'action',
          width: '240',
          scopedSlots: { customRender: 'action' },
          align: 'center',
        },
      ],
      invitationListData: [],
      columnsinvited: [
        {
          title: '企业名称',
          align: 'center',
          customRender: (row) => {
            return <p>{this.$store.state.party ? row.contractor?.name : row.owner?.name}</p>;
          },
        },
        {
          title: '企业联系人',
          align: 'center',
          customRender: (row) => {
            return <p>{this.$store.state.party ? row.contractor?.contacts : row.owner?.contacts}</p>;
          },
        },
        {
          title: '企业联系方式',
          align: 'center',
          customRender: (row) => {
            return <p>{this.$store.state.party ? row.contractor?.contact : row.owner?.contact}</p>;
          },
        },
        {
          title: '审核状态',
          align: 'center',
          dataIndex: '',
          customRender: (row) => {
            return <p>{row.status == 2 ? '已通过' : row.status == 3 ? '已拒绝' : '待审核'}</p>;
          },
        },
        {
          title: '申请时间',
          align: 'center',
          dataIndex: 'updated_at',
        },
        {
          title: '操作',
          key: 'action',
          width: '240',
          scopedSlots: { customRender: 'action' },
          align: 'center',
        },
      ],
      invitedListData: [],
      aRoleColumns: [
        {
          title: '角色',
          dataIndex: 'name',
          align: 'center',
        },
        {
          title: '默认角色',
          scopedSlots: { customRender: 'checkbox' },
          align: 'center',
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' },
          align: 'center',
        },
      ],
      aRoleTableData: [], // 关联角色列表
      currentDefault: '', // 当前默认角色
      targetDefault: '', // 目标默认角色
      roleId: '', // 目标默认角色ID
      roleAdminId: '', // 当前管理员ID

      visibleAddCompany: false, // 是否显示新建乙方公司
      companyFullName: '', // 公司全称
      companyFullId: '', // 当前这条数据ID
      companyFullCid: '', // 公司ID
      companyName: '', // 公司简称
      companyList: [], // 关联公司列表
      defaultIndex: 0,// 默认下标
      loading: false, // 按钮 当前状态
      loadingRefuse: false, // 拒绝按钮 当前状态
      unCompanyList: [],//未关联公司列表
      visibleEditcName: false,
      reviewerShowModal: false, // 是否展示 审核关联成员弹窗
      deleteShowModal: false, // 是否展示 删除关联成员弹窗
      reviewerInfo: {}, // 当前审核(人员/公司)信息
      visibleInviteLink: false, // 是否显示 邀请链接弹窗
      inviteTime: '2',// 链接有效时长
      visibleInviteSpace: false, // 是否显示 关联链接 弹窗
      reviewPartyB: false, // 是否显示 审核乙方公司 弹窗
      reviewAgaExamine: false, // 是否显示 重新申请 弹窗
      addCompanyId: undefined, // 受邀公司id

      visibleAddColleague: false, // 是否显示 添加同事 弹窗
      addColleagueId: null, // 受邀同事id
      colleagueList: [],//未关联同事列表

      form: this.$form.createForm(this, { name: '', enname: '' }),
      addRoleModal: false,
      expandedKeys: [],
      checkedKeys: [],
      selectedKeys: [],
      autoExpandParent: true,
      replaceFields: {
        key: 'id',
        children: 'child',
        title: 'display_name',
      },
      permissionTreeData: [],

      visibleSetPower: false,
      isEdit: true, // 是否编辑过权限
      editRoleName: '', // 编辑角色名称
      editRoleEnName: '', // 编辑角色名称en
      editRoleId: '', // 编辑角色ID

      visibleSetRole: false,

      delRoleInfo: '', // 删除角色详情
      deleteRoleShowModal: false, // 是否显示删除角色详情弹窗

      visibleEditUser: false,
      modifyUserInfo: '',// 编辑、删除用户详情

      statusList: [
        {
          id: '',
          name: '全部状态'
        },
        {
          id: 1,
          name: '待审核'
        },
        {
          id: 2,
          name: '通过'
        },
        {
          id: 3,
          name: '未通过'
        },
      ],
      invitedStatus: '',// 收到列表，筛选审核状态
      invitationStatus: '',// 发送列表，筛选审核状态

      selectedChangeStatusArr: '',// 批量选中ID数组
      selectedChangeIdArr: '',// 批量选中ID数组
      selectedChangeUserIdArr: '',

      visibleBatchToExamine: false,// 是否显示-批量操作-审核
      visibleBatchEdit: false,// 是否显示-批量操作-审核
      visibleBatchDelete: false,// 是否显示-批量操作-删除
    }
  },
  methods: {
    // 重置列表多选
    clearSelect() {
      this.$refs.table.clearSelected()
      this.selectedChangeIdArr = ''
      this.selectedChangeUserIdArr = ''
      this.selectedChangeStatusArr = ''
      this.visibleBatchToExamine = false
      this.visibleBatchEdit = false
      this.visibleBatchDelete = false
    },
    fetchUser(value) {
      console.log('fetching user', value);
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.unCompanyList = [];
      this.fetching = true;
      this.api.unassociatedCompany({
        keywords: value
      }).then(res => {
        console.log('res', res)
        if (fetchId !== this.lastFetchId) {
          // for fetch callback order
          return;
        }
        this.unCompanyList = res.data.lists;
        this.fetching = false;
      })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    callback(key) {
      this.loading = false
      this.loadingRefuse = false
      if (key == 1) {
        this.getRelationList(this.defaultIndex)
      } else if (key == 2) {
        this.getInvitedList(this.invitedStatus)
      } else if (key == 3) {
        this.getInvitationList(this.invitationStatus)
      } else if (key == 4) {
        this.getAssociationRoleList()
      }
    },
    noRepeat2(arr) {
      var newArr = [];
      for (var i = 0; i < arr.length; i++) {
        if (newArr.indexOf(arr[i]) == -1) {
          //解释：如果新数组里没有就放进去，有就放不进了
          newArr.push(arr[i]);
        }
      }
      return newArr;
    },
    setCheckedKeys(permResult) {
      // console.log("进入设置半选:", permResult)
      permResult.forEach((v) => {
        let result = this.isChildNode(this.permissionTreeData, v)
        if (result) {
          console.log('result', result);
        }
      })
      this.checkedKeys = this.checkedKeys.filter(item => !["", null, undefined, NaN, false, true].includes(item));
      // 拼接上 子集被默认全部选中的父级id数组
      this.checkedKeys.push(...this.parentNo)
      // console.log("___________this.checkedKeys:", this.checkedKeys)
    },
    // 编辑成员-下拉选择框-选择
    editHandleChange(value) {
      this.editRoleId = value
    },
    //判断是否是子级节点
    isChildNode(list, id) {
      // console.log('list', list);
      for (let i = 0; i < list.length; i++) {
        const v = list[i];
        if (id == v.id) {
          // console.log('id:', id, 'v:', v);
          if (this.checkedKeys.indexOf(v.pid) != -1) {
            // this.checkedKeys.splice(this.checkedKeys.indexOf(v.pid), 1)
            this.checkedKeys = this.checkedKeys.map((item) => {
              if (item != v.pid) {
                // console.log('item', item);
                return item
              } else {
                return false
              }
            })
            // console.log('——————————', this.checkedKeys);
          } else {
            if (v.child) {
              this.isChildNode(v.child, id)
            }
          }
        } else {
          if (v.child) {
            this.isChildNode(v.child, id)
          }
        }
      }
      return false
    },
    onCheck(selectedKeys, e) {
      // console.log('onCheck', selectedKeys, e);
      this.isEdit = false
      this.selectedKeys = selectedKeys;
      this.halfCheckedKeys = e.halfCheckedKeys
    },
    onExpand(expandedKeys) {
      // console.log('onExpand', expandedKeys);
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    // 获取一已关联公司
    getRelationList(index) {
      index = index || 0
      this.tableLoading = true
      this.listData = []
      this.companyList = []
      this.companyFullName = ''
      this.companyFullId = ''
      this.companyFullCid = ''
      this.companyName = ''
      this.api.getRelationCompany({
      }).then(res => {
        if (res.data.lists) {
          this.tableLoading = false
          this.companyList = res.data.lists
          this.companyName = res.data.lists[index]?.abbreviation || ''
          if (this.$store.state.party) {
            this.companyFullName = res.data.lists[index]?.contractor.name
            this.companyFullCid = res.data.lists[index]?.contractor_id
          } else {
            this.companyFullName = res.data.lists[index]?.owner.name
            this.companyFullCid = res.data.lists[index]?.owner_id
          }
          this.companyFullId = res.data.lists[index]?.id
          this.listData = res.data.lists[index]?.contractor_users
          this.defaultIndex = index
        }
      })
    },
    // 获取一已邀请公司
    getInvitationList(status) {
      this.tableLoading = true
      this.invitationListData = []
      this.api.invitationList({
        status: status
      }).then(res => {
        this.tableLoading = false
        this.invitationListData = res.data.lists
      })
    },
    // 获取一受邀请公司
    getInvitedList(status) {
      this.tableLoading = true
      this.invitedListData = []
      this.api.invitedList({
        status: status
      }).then(res => {
        this.tableLoading = false
        this.invitedListData = res.data.lists
      })
    },
    // 邀请我方成员选择
    handleChange(value) {
      console.log(`selected ${value}`);
    },
    // 选择邀请乙方公司
    handleCompanyChange(value) {
      console.log('选择邀请公司', value);
      this.addCompanyId = value
    },
    // 显示 添加关联员工 弹窗
    showAddColleague() {
      this.visibleAddColleague = true
      this.api.memberLists({
        company_id: this.companyFullCid,
      }).then(res => {
        if (res.code == 0) {
          this.colleagueList = res.data.lists
        }
      })
    },
    // 选择邀请 同事
    handleColleagueChange(value) {
      console.log(`selected ${value}`);
      this.addColleagueId = value
    },
    // 切换公司
    switchCompany(e) {
      this.clearSelect()
      if (e.key == 'addCompany') {
        console.log('loading');
      } else {
        this.defaultIndex = e.key
        this.companyName = this.companyList[e.key].abbreviation
        if (this.$store.state.party) {
          this.companyFullName = this.companyList[e.key].contractor.name
          this.companyFullCid = this.companyList[e.key].contractor_id
        } else {
          this.companyFullName = this.companyList[e.key].owner.name
          this.companyFullCid = this.companyList[e.key].owner_id
        }
        this.companyFullId = this.companyList[e.key].id
        this.listData = this.companyList[e.key].contractor_users
      }
    },
    // 更新公司简称
    updateCompanyName() {
      this.api.updateRelationCompany({
        id: this.companyFullId,
        abbreviation: this.companyName
      }).then(res => {
        if (res.code == 0) {
          this.getRelationList(this.defaultIndex)
          this.visibleEditcName = false
          this.message.success(res.message);
        }
      })
    },
    // 审核
    toExamine(data) {
      console.log('data', data);
      this.reviewerInfo = data
      this.reviewPartyB = true
    },
    // 重新申请
    againExamine(data) {
      console.log('data', data);
      this.reviewerInfo = data
      this.reviewAgaExamine = true
    },
    // 展示 审核关联成员 弹窗、并且存储当前成员信息
    showReviewerModal(data) {
      // console.log('data', data);
      this.reviewerInfo = data
      this.reviewerShowModal = true
    },
    // 展示 删除关联成员 弹窗、并且存储当前成员信息
    showDeleteModal(data) {
      this.reviewerInfo = data
      this.deleteShowModal = true
    },
    // 关联信息-审核成员-接口
    processUser(ids, type) {
      this.api.processUser({
        id: ids,
        status: type
      }).then(res => {
        this.loading = false
        this.loadingRefuse = false
        this.reviewerShowModal = false
        this.clearSelect()
        this.getRelationList(this.defaultIndex)
        if (res.code == 0) {
          this.message.success(res.message);
        }
      })
    },
    // 审核关联成员，通过or拒绝
    examine(type) {
      console.log('this.reviewerInfo', this.reviewerInfo);
      if (type == 'adopt') {
        console.log('通过');
        this.loading = true
        this.processUser(this.reviewerInfo.id, 2)
      } else if (type == 'refuse') {
        console.log('拒绝');
        this.loadingRefuse = true
        this.processUser(this.reviewerInfo.id, 3)
      }
    },
    // 删除关联成员-接口
    deleteRelationUser(ids) {
      let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
      this.api.deleteRelationUser({
        userId: userInfo.id,
        relationId: ids,
      }).then(res => {
        this.loading = false
        this.deleteShowModal = false
        this.clearSelect()
        this.getRelationList(this.defaultIndex)
        if (res.code == 0) {
          this.message.success(res.message);
        }
      })
    },
    // 删除关联成员，通过or拒绝
    deleteRelUser(type) {
      console.log('this.reviewerInfo', this.reviewerInfo);
      if (type == 'adopt') {
        console.log('通过');
        this.loading = true
        this.deleteRelationUser(this.reviewerInfo.id)
      } else if (type == 'refuse') {
        console.log('拒绝=取消');
        this.deleteShowModal = false
      }
    },
    // 审核乙方公司，通过or拒绝
    examinePartyB(type) {
      if (type == 'adopt') {
        console.log('通过');
        this.loading = true
        this.api.relationCompanyProcess({
          id: this.reviewerInfo.id,
          status: 2
        }).then(res => {
          this.loading = false
          this.reviewPartyB = false
          this.getInvitedList(this.invitedStatus)
          if (res.code == 0) {
            this.message.success(res.message);
          }
        })
      } else if (type == 'refuse') {
        console.log('拒绝');
        this.loadingRefuse = true
        this.api.relationCompanyProcess({
          id: this.reviewerInfo.id,
          status: 3
        }).then(res => {
          this.loadingRefuse = false
          this.reviewPartyB = false
          this.getInvitedList(this.invitedStatus)
          if (res.code == 0) {
            this.message.success(res.message);
          }
        })
      }
    },
    // 关联搜索
    openInviteCompany() {
      // this.api.unassociatedCompany({
      // }).then(res => {
      //   console.log('res', res)
      //   this.unCompanyList = res.data.lists
      // })
      this.visibleAddCompany = true
    },
    selectTimeChange(value) {
      // console.log(value);
      this.copyLoading = true
      this.inviteTime = value
      this.showInviteLink()
    },
    selectTimeSpaceChange(value) {
      // console.log(value);
      this.copyLoading = true
      this.inviteTime = value
      this.showInviteSpace()
    },
    copyclick(text, type) {
      var input = document.getElementById(type);
      input.value = text; // 修改文本框的内容
      input.select(); // 选中文本
      document.execCommand("copy"); // 执行浏览器复制命令
      this.handleCancelInvite();
      this.handleCancelInviteSpace();
      this.message.success('复制成功');
    },
    // 链接邀请弹窗-关闭
    handleCancelInvite() {
      this.inviteTime = '2'
      this.visibleInviteLink = false;
    },
    // 链接邀请弹窗-一键复制
    handleOkInvite() {
      this.copyclick(this.copyText, 'input')
    },
    // 关联链接 弹窗-关闭
    handleCancelInviteSpace() {
      this.inviteTime = '2'
      this.visibleInviteSpace = false;
    },
    // 关联链接 弹窗-一键复制
    handleOkInviteSpace() {
      this.copyclick(this.copyText, 'inputSpace')
    },
    // 邀请公司 弹窗关闭
    closeAddCompany() {
      this.visibleAddCompany = false
      this.reload()
    },
    // 邀请公司
    addCompanyFun() {
      console.log('邀请公司', this.addCompanyId);
      this.api.creatRelationCompany({
        company_id: this.addCompanyId
      }).then(res => {
        this.addCompanyId = null
        if (res.code == 0) {
          this.getRelationList(this.defaultIndex)
          this.visibleAddCompany = false
          this.message.success(res.message);
        }
      })
    },
    // 邀请同事弹窗-关闭
    closeAddColleagueFun() {
      this.visibleAddColleague = false
      this.reload()
    },
    // 邀请同事-确定
    addColleagueFun() {
      console.log('邀请同事', this.addColleagueId);
      this.api.addColleagues({
        company_id: this.companyFullCid,
        id: this.addColleagueId
      }).then(res => {
        this.addColleagueId = null
        this.visibleAddColleague = false
        if (res.code == 0) {
          this.getRelationList(this.defaultIndex)
          this.message.success(res.message);
        }
      })
    },
    // 重新申请，通过or拒绝
    examineAgain(type) {
      console.log('this.reviewerInfo', this.reviewerInfo);
      if (type == 'adopt') {
        console.log('通过');
        this.loading = true
        this.api.resubmit({
          id: this.reviewerInfo.id
        }).then(res => {
          this.loading = false
          this.reviewAgaExamine = false
          if (res.code == 0) {
            this.getInvitationList(this.invitationStatus)
            this.message.success(res.message);
          }
        })
      } else if (type == 'refuse') {
        console.log('取消');
        this.reviewAgaExamine = false
      }
    },
    handleCancel() {
      this.form.setFieldsValue({
        name: '',
        enname: ''
      })
      this.editRoleName = '';
      this.editRoleEnName = '';
      this.editRoleID = '';
      this.checkedKeys = [];
      this.addRoleModal = false;
      this.visibleSetPower = false;
    },
    // 获取权限列表
    getAssociationPermission() {
      this.api.permissionAssociationList({
      }).then(res => {
        // console.log('res', res.data.permissions);
        this.permissionTreeData = res.data.permissions
        this.noRemovable = res.data.noRemovable
        this.parentList = res.data.parent
        this.parentNo = res.data.parent_no
      })
    },
    // 获取角色列表
    getAssociationRoleList() {
      this.api.getAssociationRoleList({
      }).then(res => {
        let roleLists = res.data.lists || []
        console.log('roleLists', roleLists);
        for (let i = 0; i < roleLists.length; i++) {
          if (roleLists[i].admin == 1) {
            this.roleAdminId = roleLists[i].id
          }
          if (roleLists[i].is_default == 1) {
            this.currentDefault = roleLists[i].name
          }
        }
        this.aRoleTableData = roleLists
      })
    },
    // 新增角色-展示弹窗
    showaddRoleModal() {
      this.addRoleModal = true
      this.checkedKeys = []
      this.selectedKeys = []
      this.halfCheckedKeys = []
      this.checkedKeys = this.noRemovable
    },
    // 新增角色-按钮提交
    onSubmit() {
      this.form.validateFields((err, value) => {
        if (!err) {
          this.addRole(value.name, value.enname, this.checkedKeys)
        }
      });
    },
    // 新增角色-取消
    handleAddRoleCancel() {
      this.form.setFieldsValue({
        name: '',
        enname: ''
      })
      this.checkedKeys = [];
      this.addRoleModal = false;
    },
    // 新增角色-调用接口
    addRole(name, enname, arr) {
      let permissionsArr = []
      permissionsArr.push(...arr)
      permissionsArr.push(...this.parentList)
      permissionsArr.push(...this.selectedKeys)
      permissionsArr.push(...this.halfCheckedKeys)
      permissionsArr = this.noRepeat2(permissionsArr);
      permissionsArr = permissionsArr.sort((a, b) => a - b)
      this.api.createAssociationRole({
        name: name,
        // enName: enname,
        enName: name,
        permissions: permissionsArr
      }).then(res => {
        if (res.code == 0) {
          this.message.success(res.message);
        }
        console.log('res', res);
        this.handleCancel()
        this.getAssociationRoleList()
      })
    },
    // 显示 配置权限 弹窗
    poewr(data) {
      console.log('显示 配置权限 弹窗', data);
      this.isEdit = true
      this.checkedKeys = data.permission;
      this.setCheckedKeys(data.permission)
      this.editRoleName = data.name
      this.editRoleEnName = data.en_name
      this.editRoleId = data.id
      this.visibleSetPower = true
    },
    // 显示 删除角色 弹窗
    showDelModal(data) {
      console.log('显示 删除角色 弹窗', data);
      this.delRoleInfo = data
      console.log(this.delRoleInfo);
      this.deleteRoleShowModal = true
    },
    // 配置角色-保存
    handleOk() {
      if (this.editRoleName == '' || this.editRoleEnName == '') {
        this.message.error('角色名称不能为空');
      } else {
        this.loading = true;

        this.halfCheckedKeys.push(...this.parentList)

        this.halfCheckedKeys.push(...this.checkedKeys)
        this.halfCheckedKeys = this.noRepeat2(this.halfCheckedKeys);
        this.halfCheckedKeys.sort((a, b) => a - b)
        // console.log('最终权限数组，permissions：', this.halfCheckedKeys);

        this.api.updateAssociationRole({
          id: this.editRoleId,
          name: this.editRoleName,
          // enName: this.editRoleEnName,
          enName: this.editRoleName,
          permissions: this.halfCheckedKeys
        }).then(res => {
          if (res.code == 0) {
            this.message.success(res.message);
          }
          console.log('res', res);
          this.editRoleName = '';
          this.editRoleEnName = '';
          this.editRoleID = '';
          this.checkedKeys = [];
          this.loading = false;
          this.visibleSetPower = false;
          this.getAssociationRoleList()
        })
      }
    },
    // 显示 设置默认角色 弹窗
    setDefaultRole(data) {
      this.targetDefault = data.name
      this.roleId = data.id
      this.visibleSetRole = true
    },
    // 默认角色配置-关闭
    handleCancelRole() {
      this.visibleSetRole = false
    },
    // 默认角色配置-保存
    handleOkRole() {
      this.loading = true;
      this.api.changeAssociationDefaultRole({
        roleId: this.roleId
      }).then(res => {
        console.log('res', res);
        if (res.code == 0) {
          this.loading = false;
          this.visibleSetRole = false
          this.getAssociationRoleList()
          this.message.success(res.message);
        }
      })
    },
    // 删除角色，通过or拒绝
    deleteRole(type) {
      console.log('this.delRoleInfo', this.delRoleInfo);
      if (type == 'adopt') {
        console.log('通过');
        this.loading = true
        this.api.deleteAssociationRole({
          id: this.delRoleInfo.id,
        }).then(res => {
          this.loading = false
          this.deleteRoleShowModal = false
          if (res.code == 0) {
            this.getAssociationRoleList()
            this.message.success(res.message);
          }
        })
      } else if (type == 'refuse') {
        console.log('拒绝=取消');
        this.deleteRoleShowModal = false
      }
    },
    // 打开 编辑 弹窗
    showEditModal(data) {
      console.log('data', data);
      this.modifyUserInfo = data
      console.log('modifyUserInfo', this.modifyUserInfo);
      this.visibleEditUser = true
    },
    // 编辑成员接口
    changeAssociationRole(ids) {
      this.api.changeAssociationRole({
        id: ids,
        roleId: this.editRoleId
      }).then(res => {
        if (res.code == 0) {
          this.message.success(res.message);
        }
        console.log('res', res);
        this.loading = false;
        this.visibleEditUser = false;
        this.visibleBatchEdit = false;
        this.modifyUserInfo = ''
        this.clearSelect()
        this.getRelationList(this.defaultIndex)
      })
    },
    // 编辑成员弹窗-保存
    handleOkEdituser() {
      this.loading = true;
      this.changeAssociationRole(this.modifyUserInfo.user_id)
    },
    // 编辑成员弹窗-关闭
    handleCancelEditUser() {
      this.visibleEditUser = false;
      this.modifyUserInfo = ''
    },
    // 切换筛选状态
    handleStatusChange(value) {
      this.invitedStatus = value
      this.getInvitedList(value)
    },
    handleStatusIonChange(value) {
      this.invitationStatus = value
      this.getInvitationList(value)
    },
    // 展示 链接邀请弹窗
    showInviteLink() {
      console.log('一键复制');
      this.copyLoading = true
      this.visibleInviteLink = true
      let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
      let company_id = userInfo.company_id
      this.api.invitationLink({
        type: 3,
        originator_id: company_id,
        recipient_id: this.companyFullCid,
        h: this.inviteTime,
        user_id: userInfo.id,
        status: this.$store.state.party ? 1 : 2,
      }).then(res => {
        this.copyText = window.location.origin + window.location.pathname + '#/create?inviteId=' + res.data.id
        this.copyLoading = false
      })
    },
    // 展示 链接邀请弹窗-two
    showInviteSpace() {
      console.log('一键复制');
      this.copyLoading = true
      this.visibleInviteSpace = true
      let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
      let company_id = userInfo.company_id
      this.api.invitationLink({
        type: 2,
        originator_id: company_id,
        h: this.inviteTime,
        user_id: userInfo.id,
        status: this.$store.state.party ? 1 : 2,
      }).then(res => {
        this.copyText = window.location.origin + window.location.pathname + '#/create?inviteId=' + res.data.id
        this.copyLoading = false
      })
    },
    // 列表选择
    selectedChange(data) {
      // console.log(data)
      this.selectedChangeIdArr = ''
      this.selectedChangeUserIdArr = ''
      this.selectedChangeStatusArr = ''
      data.map((v, i) => {
        if (i > 0) {
          this.selectedChangeIdArr = this.selectedChangeIdArr + ',' + v.id
          this.selectedChangeUserIdArr = this.selectedChangeUserIdArr + ',' + v.user_id
          this.selectedChangeStatusArr = this.selectedChangeStatusArr + ',' + v.status
        } else {
          this.selectedChangeIdArr += v.id
          this.selectedChangeUserIdArr += v.user_id
          this.selectedChangeStatusArr += v.status
        }
      })
      // console.log(this.selectedChangeIdArr)
      // console.log(this.selectedChangeStatusArr)
    },
    // 批量操作-审核
    batchToExamineModal() {
      if (this.selectedChangeIdArr == '') {
        this.message.error('请选择成员');
      } else {
        this.visibleBatchToExamine = true
      }
    },
    // 批量操作-审核,type:通过/拒绝
    batchToExamine(type) {
      if (type == 'adopt') {
        console.log('通过');
        this.loading = true
        this.processUser(this.selectedChangeIdArr, 2)
      } else if (type == 'refuse') {
        console.log('拒绝');
        this.loadingRefuse = true
        this.processUser(this.selectedChangeIdArr, 3)
      }
    },
    // 批量操作-编辑
    batchEditModal() {
      if (this.selectedChangeIdArr == '') {
        this.message.error('请选择成员');
      } else {
        this.visibleBatchEdit = true
      }
    },
    // 批量操作-编辑-保存
    handleOkBatchEdit() {
      this.loading = true;
      this.changeAssociationRole(this.selectedChangeUserIdArr)
    },
    // 批量操作-编辑-关闭
    handleCancelBatchEdit() {
      this.visibleBatchEdit = false;
    },
    // 批量操作-删除
    batchDeleteModal() {
      if (this.selectedChangeIdArr == '') {
        this.message.error('请选择成员');
      } else {
        this.visibleBatchDelete = true
      }
    },
    // 批量操作-删除,type:通过/拒绝
    batchDelete(type) {
      if (type == 'adopt') {
        console.log('通过');
        this.loading = true
        this.deleteRelationUser(this.selectedChangeIdArr)
      } else if (type == 'refuse') {
        console.log('取消');
        this.loading = false
        this.visibleBatchDelete = false
      }
    },
    guideStepNext() {
      this.guideIndex += 1
      this.$refs.carousel.next()
      console.log('2', this.guideIndex, this.guideItemPartyA.length)
    },
    guideStepPrev() {
      this.guideIndex -= 1
      this.$refs.carousel.prev()
    },
    guideHide() {
      if (this.$store.state.party) {
        window.localStorage.setItem('partyAGuideTipsShow', 'false')
        this.partyAGuideTipsShow = false
      } else {
        window.localStorage.setItem('partyBGuideTipsShow', 'false')
        this.partyBGuideTipsShow = false
      }
    }
  },
  mounted() {
    if (this.$store.state.party) {
      this.getAssociationRoleList()
      this.getAssociationPermission()
    }
    this.getRelationList(0)
  },
  created() {
    if (this.$store.state.party) {
      this.partyAGuideTipsShow = (window.localStorage.getItem('partyAGuideTipsShow') == 'false' ? false : true)
      this.partyBGuideTipsShow = false
    } else {
      this.partyAGuideTipsShow = false
      this.partyBGuideTipsShow = (window.localStorage.getItem('partyBGuideTipsShow') == 'false' ? false : true)
    }
    // console.log(this.partyAGuideTipsShow);
    // console.log(this.partyBGuideTipsShow);
  }
}
</script>

<style scoped>
.index {
  position: relative;
}

.button {
  margin: 0 10px;
}

.basic {
  position: relative;
}

.scroll-box {
  height: 74vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

.scroll-box::-webkit-scrollbar {
  width: 0 !important
}

.table-box {
  background: #eee;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px;
}

.table {
  background: #fff;
}

.table-box-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.label span {
  margin-right: 10px;
  width: 100px;
  text-align: right;
}

.label-span-status {
  margin: 0 0 0 10px;
  text-align: left;
}

.select-company {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  color: black;
}

.company-item {
  text-align: center;
}

.company-item::after {
  border: none !important;
}

.img-avatar {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.editCompanyName {
  cursor: pointer;
}

.right-btn-box {
  position: absolute;
  top: 0;
  right: 0;
}

.right-btn {
  margin: 10px 0 0 10px;
}

.yeluosen {
  position: absolute;
  overflow: hidden;
  z-index: -999;
  opacity: 0;
}

.power-button {
  margin-right: 10px;
}

.color-red {
  color: #f5222d;
  cursor: pointer;
}

.batch-btn-box {
  text-align: right;
  padding: 20px 0 0;
}



.guide {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
  overflow: hidden;
}

.guide-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 600px);
  max-height: calc(100vh - 100px);
  padding: 20px 0;
  background: #fff;
  border-radius: 10px;
  /* margin: 200px auto; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  overflow: hidden;
}

.guide-swiper {
  width: calc(100% - 20px);
  height: 100%;
  padding-top: 50px;
  margin: 0 auto;
}

.guide-item {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.guide-item img {
  width: calc(100% - 100px);
  height: calc(100% - 100px);
  margin: 0 auto;
  object-fit: contain;
}

.guide-item p {
  color: #000;
  text-align: center;
  margin-top: 20px;
}

.guide-btns {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.guide-btns .ant-btn {
  margin: 0 10px;
}
</style>