<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-10-12 17:51:56
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-10-12 17:52:36
 * @FilePath: \h5case4.xiaoxxx.cne:\code\仙助数字资产管理平台\digital\src\components\footer.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="footer color-black">
    <span>Copyright 2015-{{ new Date().getFullYear() }}</span>
    <span class="margin">
      版权所有：
      <a href="https://xiaoxxx.com" target="_blank">杭州小仙科技有限公司</a>
    </span>
    <a class="color-black">浙ICP备16000551号</a>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  created() {
  },
}
</script>

<style scoped>
.footer {
  width: 100%;
  height: 50px;
  text-align: center;
  white-space: nowrap;
  background: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
}

.margin {
  margin: 0 40px;
}
.margin a {
  color: #e50027;
}

.color-black {
  font-size: 12px;
  color: #000 !important;
}
</style>