<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-10-12 15:48:14
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-10-19 15:52:32
 * @FilePath: \h5case4.xiaoxxx.cne:\code\仙助数字资产管理平台\digital\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <a-config-provider :locale="zhCN">
      <router-view v-if="isRouterAlive"></router-view>
    </a-config-provider>
  </div>
</template>

<script>
export default {
  name: 'App',
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true
    }
  },
  created() {
    this.$store.commit('setUserInfo', JSON.parse(window.localStorage.getItem('userInfo')))
  },
  methods: {
    errorfn() {
      console.log('load-----------------', this.$refs.iframe.contentWindow.document.body.innerHTML)
      // this.$refs.iframe.contentDocument
    },
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  }
}
</script>

<style>
p,
li,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

#app {
  width: 100%;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.w-e-text-container h1 {
  font-size: 2em !important;
}

/*
tab栏 底边框 login去掉其他页面就也没有了
.ant-tabs-bar {
  border: none !important;
} */
.a-layout-header {
  background: #fff !important;
}

/* flex布局 */
.flex {
  display: flex;
}

.flex-direction-row {
  flex-direction: row;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-no {
  flex-wrap: nowrap;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

::selection {
  color: #fff !important;
  background: rgb(200, 200, 200) !important;
}

.text-align-center {
  text-align: center;
}

.font-weight-bold {
  font-weight: bold;
}

.color-427 {
  color: rgba(0, 0, 0, 0.427);
}

/* 覆盖ant默认样式 */
.ant-carousel .slick-list .slick-slide>div {
  width: 100%;
  height: 100%;
}

.baseactive {
  color: red;
}

.ant-pagination {
  margin: 16px !important;
}

.text-align-center {
  text-align: center;
}

.ant-tabs .ant-tabs-large-bar .ant-tabs-tab {
  padding: 12px 16px !important;
}

.ant-tabs-tabpane {
  position: relative;
}

.select-dis-none {
  display: none !important;
}
.knowledgebase-read-content h1{
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 2em;
}
.knowledgebase-read-content h2{
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 1.5em;
}
.knowledgebase-read-content h3{
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 1.17em;
}
.knowledgebase-read-content h4{
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}
.knowledgebase-read-content h5{
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 0.83em;
}
/* 轮播图样式 */
.ant-carousel .slick-slide img {
  display: inline-block !important;
}
</style>
