<template>
  <div class="copywriting">
    <a-page-header
      :title="data.name || '项目名称...'"
      :breadcrumb="{props: {routes}}"
      sub-title="文案物料"
    >
      <template slot="tags" v-if="!$store.state.party && data.company">
        <router-link :to="{name: 'companyProjectList', query: {id: data.company.id}}">
          <a-button type="link"  style="margin-top: -4px">
            {{data.company.name}}
          </a-button>
        </router-link>
      </template>
    </a-page-header>
    <a-form-model :model="form" layout="inline" style="margin: 10px 25px 20px 25px">
      <a-form-model-item>
        <a-select
          style ="width: 180px"
          show-search v-model="form.label_id"
          placeholder="选择标签">
          <a-select-option v-for="i in labelData" :key="i.id" :value="i.id">{{i.name}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item>
        <a-select
          style ="width: 180px"
          v-model="form.channel_id"
          placeholder="选择渠道"
          >
          <a-select-option v-for="i in channelData" :key="i.id" :value="i.id">{{i.name}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item>
        <a-select style ="width: 180px" v-model="form.self" placeholder="是否本人">
          <a-select-option :value="1">本人</a-select-option>
          <a-select-option :value="2">非本人</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item>
        <a-select
          style ="width: 180px"
          v-model="form.user_id"
          placeholder="选择关键人">
          <a-select-option v-for="i in operatorData" :key="i.user_id" :value="i.user_id">{{i.user.name || i.user.id}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item>
        <a-input v-model="form.keyword" placeholder="搜索关键字" />
      </a-form-model-item>
      <a-form-model-item label="创建日期">
        <a-range-picker v-model="date" @change="onChange" />
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click="$refs.table.getData()" shape="circle" icon="search"></a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click="reset">重置</a-button>
      </a-form-model-item>
    </a-form-model>

    <div class="edit">
      <div>
        <a-space>
          <a-button size="small" @click="batchEdit" :disabled="checkList.length > 0 ? false : true" v-power:disabled="'project/copywriting/batchUpdate'">编辑标签/渠道</a-button>
          <!-- <a-button type="link">下载</a-button> -->
          <a-popconfirm
            title="是否确认删除选中项?"
            ok-text="是"
            cancel-text="否"
            @confirm="copywritingDel()"
            :disabled="checkList.length > 0 ? false : true"
          >
            <a-button size="small" type="danger" :disabled="checkList.length > 0 ? false : true" v-power="'project/copywriting/softDeletes'">删除</a-button>
          </a-popconfirm>
        </a-space>
        <!-- <a-button type="link" @click="sortCut">{{form.created_at == 1 ? '时间正序' : '时间倒序'}}</a-button> -->
      </div>
      <a-button type="primary" @click="add" v-power:disabled="'project/copywriting/create'">
        新增文案
      </a-button>
    </div>

    <Table v-if="data.id" ref="table" :request="api.copywritingList" :columns="columns" selected @selectedChange="selectedChange" :param="{project_id: data.id, ...form}" style="padding: 25px">
      <a-icon
        slot="filterIcon"
        slot-scope="filtered"
        type="search"
        :style="{ color: filtered ? '#108ee9' : undefined }"
      />
      <div slot="content" slot-scope="scope" v-html="scope.record.content"></div>
      <div slot="tags" slot-scope="scope">
         <a-tag v-for="(item, index) in scope.record.labels" :key="index">{{item.name.name}}</a-tag>
      </div>
      <div slot="channel" slot-scope="scope">
         <a-tag v-for="(item, index) in scope.record.channels" :key="index">{{item.name.name}}</a-tag>
      </div>
      <div class="btns" slot="action" slot-scope="scope">
        <a-space>
          <a-button type="primary" @click="info(scope)" size="small">物料详情</a-button>
          <!-- <a-button type="link" size="small" v-power:disabled="'project/copywriting/copy'">复制文案</a-button> -->
          <a-popconfirm
            title="是否确认删除?"
            ok-text="是"
            cancel-text="否"
            @confirm="copywritingDel(scope)">
            <a-button type="danger" size="small" v-power:disabled="'project/copywriting/softDeletes'">删除</a-button>
          </a-popconfirm>
        </a-space>
      </div>
    </Table>
    <!-- 弹窗 -->
    <a-modal
      title="选择标签"
      :visible="tagsVisible"
      :confirm-loading="tagLoading"
      @ok="batchSave"
      @cancel="tagsVisible = false"
    >
      <a-card
        style="width:100%"
        size="small"
        :tab-list="[
        {
          key: 'label',
          tab: '标签',
        },
        {
          key: 'channel',
          tab: '渠道',
        }
      ]"
         @tabChange="tabChange"
      >
         <div v-show="tabKey == 'label'">
          <a-tag class="a-tag" v-for="(item) in pitchOn.onLabel" :key="'label' + item.id" closable @close="closeTag(item, 'onLabel')">
            {{item.name}}
          </a-tag>
          <a-divider>选择标签</a-divider>
          <a-tag class="a-tag" @click="changeTag(item, 'onLabel')" v-for="item in labelData" :key="item.id" :color="pitchOn.onLabel.find(e => e.id == item.id) ? 'red' : ''">{{item.name}}</a-tag>
          <a-input
            v-if="newTagVisible"
            ref="tagInput"
            type="text"
            size="small"
            :style="{ width: '78px', height: '29px' }"
            v-model="tagInputValue"
            @keyup.enter="tagInputConfirm('label')"
          />
          <a-tag v-else class="a-tag" style="background: #fff; borderStyle: dashed;" @click="showNewTagInput">
            <a-icon type="plus" /> New Tag
          </a-tag>
        </div>
        <div v-show="tabKey == 'channel'">
          <a-tag class="a-tag" v-for="(item) in pitchOn.onChannel" :key="'channel' + item.id" closable @close="closeTag(item, 'onChannel')">
            {{item.name}}
          </a-tag>
          <a-divider>选择渠道</a-divider>
          <a-tag class="a-tag" @click="changeTag(item, 'onChannel')" v-for="item in channelData" :key="item.id" :color="pitchOn.onChannel.find(e => e.id == item.id) ? 'red' : ''">{{item.name}}</a-tag>
          <a-input
            v-if="newTagVisible"
            ref="tagInput"
            type="text"
            size="small"
            :style="{ width: '78px', height: '29px' }"
            v-model="tagInputValue"
            @keyup.enter="tagInputConfirm('channel')"
          />
          <a-tag v-else class="a-tag" style="background: #fff; borderStyle: dashed;" @click="showNewTagInput">
            <a-icon type="plus" /> New Tag
          </a-tag>
        </div>
      </a-card>
      
    </a-modal>

  </div>
</template>

<script>
import Table from '../../../components/table.vue'
export default {
  components: {
    Table
  },
  props: ['data'],
  data() {
    return {
      routes: [
        {
          path: '/projectList',
          breadcrumbName: '项目列表'
        },
        {
          path: '',
          breadcrumbName: '文案物料'
        },
      ],
      labelData: [],
      channelData: [],
      operatorData: [],
      date: [],
      form: {
        label_id: undefined,
        user_id: undefined,
        channel_id: undefined,
        keyword: '',
        start_time: '',
        end_time: '',
        self: undefined,
      },
      columns: [
        {
          title: 'id',
          key: 'id',
          dataIndex: 'id',
          width: '80',
        },
        {
          title: '文案标题',
          key: 'title',
          dataIndex: 'title',
        },
        // {
        //   title: '文案',
        //   key: 'content',
        //   dataIndex: 'content',
        //   scopedSlots: { customRender: 'content' },
        // },
        {
          title: '标签管理',
          key: 'label',
          dataIndex: 'label',
          scopedSlots: { customRender: 'tags' },
        },
        {
          title: '渠道管理',
          key: 'channel',
          dataIndex: 'channel',
          scopedSlots: { customRender: 'channel' },
        },
        {
          title: '更新日期',
          key: 'updated_at',
          dataIndex: 'updated_at',
          sorter:true
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' },
          width: 240,
        },
      ],
      checkList: [],
      tagsVisible: false,
      tabKey: 'label',
      pitchOn: {
        onLabel: [],
        onChannel: []
      },
      tagLoading: false, // 标签渠道按钮loading

      newTagVisible: false, // 新增标签框显示
      tagInputValue: ''
    }
  },
  created() {
    // 标签列表
    this.api.tagsList().then(res => {
      console.log(res)
      if (res.code == 0) {
        this.labelData = res.data.lists
      }
    })
    // 渠道列表
    this.api.channelList().then(res => {
      console.log(res)
      if (res.code == 0) {
        this.channelData = res.data.lists
      }
    })
    // 获取关键人
    this.data.id && this.api.operatorList({project_id: this.data.id}).then(res => {
      if (res.code == 0) {
        this.operatorData = res.data.lists
      }
    })
  },
  methods: {
    // 时间选择
    onChange(date, dateString) {
      console.log(date, dateString, this.date);
      this.form.start_time = dateString[0]
      this.form.end_time = dateString[1]
    },
    reset() {
      this.date = []
      this.form = {
        label_id: undefined,
        user_id: undefined,
        channel_id: undefined,
        keyword: '',
        start_time: '',
        end_time: ''
      }
      this.$nextTick(() => {
        this.$refs.table.getData()
      })
    },
    add() {
      this.$router.push({name: 'copywritingInfo', query: {id: this.data.id}})
    },
    tabChange(key) {
      this.tabKey = key;
    },
    info(scope) {
      this.$router.push({name: 'copywritingInfo', query: {id: this.data.id, copywritingId: scope.record.id}})
    },
    // 删除
    copywritingDel(scope) {
      if (scope) {
        this.api.copywritingDel({
          id: scope.record.id,
          project_id: this.data.id,
          company_id:this.data.company_id
        }).then(res => {
          if (res.code == 0) {
            this.message.success(res.message)
            this.$refs.table.getData()
          } else {
            this.message.error(res.message)
          }
        })
      } else if (this.checkList.length > 0) {
        let ids = this.checkList.map((item) => item.id)
        this.api.copywritingDel({
          id: ids,
          project_id: this.data.id,
          company_id:this.data.company_id
        }).then(res => {
          if (res.code == 0) {
            this.message.success(res.message)
            this.$refs.table.getData()
          } else {
            this.message.error(res.message);
          }
        })
      } else {
        this.message.error('请选择删除项');
      }
    },
    selectedChange(keys) {
      this.checkList = keys
    },
    // 批量编辑
    batchEdit() {
      this.tagsVisible = true
      // this.api.copywritingBatchInfo({ids: ids}).then(res => {
      //   if (res.code == 0) {
          // 多项标签取交集 (合并取交集方法)
          let labelsArr = this.checkList.map(item => {
            return item.labels.map(i => {
              return i.name
            })
          })
          this.pitchOn.onLabel = this.gather(labelsArr)
          // 多项渠道取交集 (合并取交集方法)
          let channelsArr = this.checkList.map(item => {
            return item.channels.map(i => {
              return i.name
            })
          })
          this.pitchOn.onChannel = this.gather(channelsArr)

          // // 多个标签去重 (合并去重方法)
          // let labelsArr = []
          // this.checkList.forEach(item => {
          //   item.labels.forEach(i => {
          //     labelsArr.push(i.name)
          //   })
          // })
          // // 数组去重
          // this.pitchOn.onLabel = this.union(labelsArr)
          // // 多个渠道去重 (合并去重方法)
          // let channelsArr = []
          // this.checkList.forEach(item => {
          //   item.channels.forEach(i => {
          //     channelsArr.push(i.name)
          //   })
          // })
          // // 数组去重
          // this.pitchOn.onChannel = this.union(channelsArr)
      //   } else {
      //     this.message.error('请选择删除项')
      //   }
      // })
      
    },
    // 选择标签/渠道
    changeTag(item, type) {
      let flag = true
      let arrIndex = 0
      this.pitchOn[type].forEach((element, index) => {
        if (element.id == item.id) {
          arrIndex = index
          flag = false
        }
      })
      if (flag) {
        this.pitchOn[type].push(item)
      } else {
        this.pitchOn[type].splice(arrIndex, 1)
      }
    },
    // 取消标签/渠道
    closeTag(item, type) {
      console.log(item, type)
      this.pitchOn[type].forEach((element, index) => {
        if (element.id == item.id) {
          console.log(index)
          this.pitchOn[type].splice(index, 1)
        }
      })
      console.log(this.pitchOn[type])
    },
    // 批量保存
    batchSave() {
      this.tagLoading = true
      let ids = this.checkList.map((item) => item.id)
      let label = this.pitchOn.onLabel.map((item) => item.id)
      let channel = this.pitchOn.onChannel.map((item) => item.id)
      this.api.copywritingBatchEdit({
        ids: ids,
        project_id: this.data.id,
        channel_id: channel,
        label_id: label
      }).then(() => {
        this.tagLoading = false
        this.$refs.table.getData()
        this.tagsVisible = false
      })
    },
    // 切换排序
    sortCut() {
      if (this.form.created_at == 1) {
        this.form.created_at = 2
      } else {
        this.form.created_at = 1
      }
      this.$nextTick(() => {
        this.$refs.table.getData()
      })
    },

    // 数组合并取交集方法
    gather(arr) {
      return arr.reduce((total, currentValue, index) => {
        console.log(total, currentValue, index)
        let arr = []
        total.forEach(i => {
          currentValue.forEach(j => {
            if (i.id == j.id) {
              arr.push(j)
            }
          })
        })
        return arr
      })
    },
    // 数组去重并集
    union(arr) {
      let map = new Map()
      arr.forEach((element) => {
        if (!map.has(element.id)) {
          map.set(element.id, element)
        }
      })
      return [...map.values()]
    },

    // 新增标签
    showNewTagInput() {
      this.tagInputValue = ''
      this.newTagVisible = true
      this.$nextTick(() => {
        this.$refs.tagInput.focus();
      });
    },
    // 新增标签确认
    tagInputConfirm(type) {
      console.log(this.tagInputValue)
      if (type == 'label') {
        this.api.tagsSave({name: this.tagInputValue, company_id: !this.$store.state.party && this.data.company.id}).then(res => {
          if (res.code == 0) {
            this.labelData.push(res.data)
            this.message.success(res.message)
          } else {
            this.message.error(res.message);
          }
          this.newTagVisible = false
        })
      }
      if (type == 'channel') {
        this.api.channelSave({name: this.tagInputValue, company_id: !this.$store.state.party && this.data.company.id}).then(res => {
          if (res.code == 0) {
            this.channelData.push(res.data)
            this.message.success(res.message)
          } else {
            this.message.error(res.message);
          }
          this.newTagVisible = false
        })
      }
    }
  }
}
</script>

<style scoped>
.edit {
  display: flex;
  justify-content: space-between;
  margin: 0 25px 20px 25px;
}
.a-tag {
  font-size: 14px;
  padding: 3px 6px;
}
</style>