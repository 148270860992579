<template>
  <div class="copy">
    <div class="title">
      <span>项目动态</span>
      <a-button type="link" @click="routerTo">查看全部</a-button>
    </div>
    <div class="content">
      <Table v-if="data.operation" :tableData="data.operation.slice(0,5)" :paginationShow="false" :columns="columns">
        <span slot="name" slot-scope="scope">{{scope.record.user.name}}</span>
        <p slot="behavior" slot-scope="scope">{{scope.record.behavior | behavior}}</p>
        <p slot="project" slot-scope="scope">{{scope.record.project?.name}}</p>
        <p slot="type" slot-scope="scope">{{scope.record.type | type}}</p>
      </Table>
    </div>
  </div>
</template>

<script>
import Table from '../table.vue'
export default {
  props: ['data'],
  components: {
    Table
  },
  data() {
    return {
      columns: [
        {
          title: 'id',
          dataIndex: 'id',
          width: 70,
        },
        {
          title: '用户',
          dataIndex: 'user',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: '操作',
          dataIndex: 'behavior',
          scopedSlots: { customRender: 'behavior' },
          width: 80,
        },
        {
          title: '项目',
          dataIndex: 'project',
          scopedSlots: { customRender: 'project' },
        },
        {
          title: '类型',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },
          width: 80,
        },
        {
          title: '时间',
          dataIndex: 'created_at',
          width: 170,
        },
      ],
    }
  },
  filters: {
    behavior(value) {
      let val = ''
      switch (value) {
        case 1:
          val = '创建了'
          break;
        case 2:
          val = '上传了'
          break;
        case 3:
          val = '更新了'
          break;
        case 4:
          val = '删除了'
          break;
        default:
          val = '未知操作'
          break;
      }
      return val
    },
    type(value) {
      let val = ''
      switch (value) {
        case 1:
          val = '项目'
          break;
        case 2:
          val = '图片'
          break;
        case 3:
          val = '文案'
          break;
        case 4:
          val = '链接'
          break;
        case 5:
          val = '字体版权'
          break;
        case 6:
          val = '3d素材'
          break;
        default:
          val = '未知类型'
          break;
      }
      return val
    }
  },
  methods: {
    routerTo() {
      this.$router.push({name: 'notice'})
    }
  }
}
</script>

<style scoped>
.copy {
  background: #fff;
  border-radius: 10px;
}
.title {
  height: 50px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 0 10px 15px;
}
.content {
  min-height: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px;
}
.img {
  width: 100px;
}
</style>