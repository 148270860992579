<template>
  <div class="picture">
    <a-page-header
      @back="() => $router.go(-1)"
    >
      <template slot="tags">
        <a-input v-model="titleVal" style="margin-top: -4px; font-size: 18px" placeholder="文案标题" />
      </template>
    </a-page-header>
    <a-row type="flex" justify="space-between">
      <a-col :span="17">
        <a-button v-if="isEdit" class="save-btn" @click="save" type="primary" :loading="saveLoading" v-power:disabled="id ? 'project/copywriting/update' : 'project/copywriting/create'">
          保存
        </a-button>
        <a-button v-else class="save-btn" @click="isEdit = true" type="primary" :loading="saveLoading" v-power:disabled="id ? 'project/copywriting/update' : 'project/copywriting/create'">
          编辑
        </a-button>
        <div v-show="isEdit" style="border: 1px solid #ccc;">
          <!-- 工具栏 -->
          <Toolbar
            style="border-bottom: 1px solid #ccc"
            :editor="editor"
            :defaultConfig="toolbarConfig"
          />
          <!-- 编辑器 -->
          <Editor
            style="height: 500px; overflow-y: hidden"
            :defaultConfig="editorConfig"
            v-model="html"
            @onCreated="onCreated"
          />
        </div>
        <div v-show="!isEdit" class="html" v-html="html"></div>
      </a-col>
      <a-col :span="6">
        <div >
          <a-card title="基础信息" style="width: 250px" size="small">
            <a-card-grid class="card-grid" >
              创建者：
            </a-card-grid>
            <a-card-grid class="card-grid" >
              XXX
            </a-card-grid>
            <a-card-grid class="card-grid" >
              创建日期：
            </a-card-grid>
            <a-card-grid class="card-grid" >
              {{info?.created_at?.split(' ')[0] || "无"}}
            </a-card-grid>
            <a-card-grid class="card-grid" >
              更新者：
            </a-card-grid>
            <a-card-grid class="card-grid" >
              XXX
            </a-card-grid>
            <a-card-grid class="card-grid" >
              更新日期：
            </a-card-grid>
            <a-card-grid class="card-grid" >
              {{info?.updated_at?.split(' ')[0] || "无"}}
            </a-card-grid>
            <a-card-grid class="card-grid">
              下载次数：
            </a-card-grid>
            <a-card-grid class="card-grid">
              10
            </a-card-grid>
          </a-card>
          <a-card
            style="width: 250px"
            size="small"
            :tab-list="tabList"
            @tabChange="tabChange">
            <a-icon slot="tabBarExtraContent" type="edit" @click="tagsVisible = true" />
            <div v-show="tabKey == 'label'">
              <a-tag v-for="(item, index) in pitchOn.onLabel" :key="index">
                {{item.name}}
              </a-tag>
            </div>
            <div v-show="tabKey == 'channel'">
              <a-tag v-for="(item, index) in pitchOn.onChannel" :key="index">
                {{item.name}}
              </a-tag>
            </div>
          </a-card>
          <a-popconfirm
            title="是否确认删除?"
            ok-text="是"
            cancel-text="否"
            @confirm="copywritingDel()"
            style="margin-top: 10px">
            <a-button type="danger" size="small" v-power:disabled="'project/copywriting/softDeletes'">删除</a-button>
          </a-popconfirm>
        </div>
      </a-col>
    </a-row>

    <!-- 编辑标签弹窗 -->
    <a-modal
      :title="tabKey == 'label' ? '选择标签' : '选择渠道'"
      :visible="tagsVisible"
      @ok="tagsVisible = false"
      @cancel="tagsVisible = false"
    >
      <a-card
        style="width:100%"
        size="small"
        :tab-list="tabList"
         @tabChange="tabChange"
      >
         <div v-show="tabKey == 'label'">
          <a-tag class="a-tag" v-for="(item) in pitchOn.onLabel" :key="'label' + item.id" closable @close="closeTag(item, 'onLabel')">
            {{item.name}}
          </a-tag>
          <a-divider>选择标签</a-divider>
          <a-tag class="a-tag" @click="changeTag(item, 'onLabel')" v-for="item in labelData" :key="item.id" :color="pitchOn.onLabel.find(e => e.id == item.id) ? 'red' : ''">{{item.name}}</a-tag>
          <a-input
            v-if="newTagVisible"
            ref="tagInput"
            type="text"
            size="small"
            :style="{ width: '78px', height: '29px' }"
            v-model="tagInputValue"
            @keyup.enter="tagInputConfirm('label')"
          />
          <a-tag v-else class="a-tag" style="background: #fff; borderStyle: dashed;" @click="showNewTagInput">
            <a-icon type="plus" /> New Tag
          </a-tag>
        </div>
        <div v-show="tabKey == 'channel'">
          <a-tag class="a-tag" v-for="(item) in pitchOn.onChannel" :key="'channel' + item.id" closable @close="closeTag(item, 'onChannel')">
            {{item.name}}
          </a-tag>
          <a-divider>选择渠道</a-divider>
          <a-tag class="a-tag" @click="changeTag(item, 'onChannel')" v-for="item in channelData" :key="item.id" :color="pitchOn.onChannel.find(e => e.id == item.id) ? 'red' : ''">{{item.name}}</a-tag>
          <a-input
            v-if="newTagVisible"
            ref="tagInput"
            type="text"
            size="small"
            :style="{ width: '78px', height: '29px' }"
            v-model="tagInputValue"
            @keyup.enter="tagInputConfirm('channel')"
          />
          <a-tag v-else class="a-tag" style="background: #fff; borderStyle: dashed;" @click="showNewTagInput">
            <a-icon type="plus" /> New Tag
          </a-tag>
        </div>
      </a-card>
    </a-modal>
    
  </div>
</template>
<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
export default {
  components: { Editor, Toolbar },
  props: ['data'],
  data() {
    return {
      id: null,
      info: {},
      titleVal: '',
      isEdit: false,
      editor: null,
      html: '',
      toolbarConfig: {},
      editorConfig: {
        placeholder: '请输入内容...',
        MENU_CONF: {
          uploadImage: {
            server: this.api.uploadUrl,
            fieldName: 'file',
            meta: {
              file_name: '1',
            },
            customInsert: this.customInsert
          }
        }
      },
      labelData: [],
      channelData: [],
      tabList: [
        {
          key: 'label',
          tab: '标签',
        },
        {
          key: 'channel',
          tab: '渠道',
        },
      ],
      tabKey: 'label',
      tagsVisible: false, // 编辑标签弹窗
      pitchOn: {
        onLabel: [],
        onChannel: []
      },
      saveLoading: false, // 保存按钮loading

      newTagVisible: false, // 新增标签框显示
      tagInputValue: ''
    }
  },
  created() {
    console.log(this.$route.query)
    if (this.$route.query.copywritingId) {
      this.id = this.$route.query.copywritingId
      this.getInfo()
    } else {
      this.isEdit = true
    }

    // 获取标签列表
    this.api.tagsList().then(res => {
      if (res.code == 0) {
        this.labelData = res.data.lists
      }
    })
    // 获取渠道列表
    this.api.channelList().then(res => {
      if (res.code == 0) {
        this.channelData = res.data.lists
      }
    })
  },
  methods: {
    getInfo() {
      this.api.copywritingInfo({id: this.id}).then(res => {
        if (res.code == 0) {
          console.log(res)
          this.info = res.data.list
          this.titleVal = res.data.list.title
          this.html = res.data.list.content
          this.pitchOn.onLabel = this.info.labels.map((item) => item.name)
          this.pitchOn.onChannel = this.info.channels.map((item) => item.name)
        }
      })
    },
    save() {
      this.saveLoading = true
      this.tagsVisible = false
      let label = this.pitchOn.onLabel.map((item) => item.id)
      let channel = this.pitchOn.onChannel.map((item) => item.id)
      if (this.id) {
        this.api.copywritingEdit({
          id: this.id,
          title: this.titleVal,
          content: this.html,
          label: label,
          channel: channel,
          project_id: this.data.id
        }).then(res => {
          this.saveLoading = false
          if (res.code == 0) {
            this.message.success(res.message)
            // this.$router.go(-1)
            this.isEdit = false
          } else {
            this.message.error(res.message)
          }
        })
      } else {
        this.api.copywritingCreate({
          title: this.titleVal,
          content: this.html,
          label: label,
          channel: channel,
          project_id: this.data.id
        }).then(res => {
          if (res.code == 0) {
            this.message.success(res.message)
            this.$router.go(-1)
          } else {
            this.message.error(res.message)
          }
        })
      }
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    customInsert(res, insertFn) {
      console.log(res, insertFn)
      insertFn(res.data.url)
    },
    tabChange(key) {
      this.tabKey = key
    },
    // 选择标签/渠道
    changeTag(item, type) {
      let flag = true
      let arrIndex = 0
      this.pitchOn[type].forEach((element, index) => {
        if (element.id == item.id) {
          arrIndex = index
          flag = false
        }
      })
      if (flag) {
        this.pitchOn[type].push(item)
      } else {
        this.pitchOn[type].splice(arrIndex, 1)
      }
    },
    // 取消标签/渠道
    closeTag(item, type) {
      console.log(item, type)
      this.pitchOn[type].forEach((element, index) => {
        if (element.id == item.id) {
          console.log(index)
          this.pitchOn[type].splice(index, 1)
        }
      })
      console.log(this.pitchOn[type])
    },
    // 删除
    copywritingDel() {
      this.api.copywritingDel({
        id: this.info.id,
        project_id: this.data.id,
        company_id:this.data.company_id
      }).then(res => {
        if (res.code == 0) {
          this.message.success(res.message)
          this.$router.go(-1)
        } else {
          this.message.error(res.message)
        }
      })
    },
    // 新增标签
    showNewTagInput() {
      this.tagInputValue = ''
      this.newTagVisible = true
      this.$nextTick(() => {
        this.$refs.tagInput.focus();
      });
    },
    // 新增标签确认
    tagInputConfirm(type) {
      console.log(this.tagInputValue)
      if (type == 'label') {
        this.api.tagsSave({name: this.tagInputValue, company_id: !this.$store.state.party && this.data.company.id}).then(res => {
          if (res.code == 0) {
            this.labelData.push(res.data)
            this.message.success(res.message)
          } else {
            this.message.error(res.message);
          }
          this.newTagVisible = false
        })
      }
      if (type == 'channel') {
        this.api.channelSave({name: this.tagInputValue, company_id: !this.$store.state.party && this.data.company.id}).then(res => {
          if (res.code == 0) {
            this.channelData.push(res.data)
            this.message.success(res.message)
          } else {
            this.message.error(res.message);
          }
          this.newTagVisible = false
        })
      }
    }
  }
}
</script>

<style scoped>
.html {
  height: 580px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  padding: 15px 10px;
  overflow: auto;
}
.save-btn {
  position: absolute !important;
  right: 0;
  top: -50px;
}
/* For demo */
.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 560px;
  line-height: 560px;
  background: #364d79;
  overflow: hidden;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.5;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
.card-grid {
  width:50%;
  text-align:center;
  padding: 10px;
}
.ant-tag {
  margin-bottom: 8px;
}
.a-tag {
  font-size: 14px;
  padding: 3px 6px;
}
</style>