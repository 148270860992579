<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-10-12 17:51:56
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-10-12 17:52:36
 * @FilePath: \h5case4.xiaoxxx.cne:\code\仙助数字资产管理平台\digital\src\components\footer.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="sider">
    <transition name="slide-fade">
      <a-menu
        class="sider-menu"
        v-show="menu.length > 0"
        mode="inline"
        :selectedKeys="[this.$route.name]"
        style="height: 100%"
      >
        <template v-for="item in menu">
          <a-sub-menu v-if="item.children" :key="item.name">
            <span slot="title">
              <span>{{item.meta.menu}}</span>
            </span>
            <a-menu-item v-for="i in item.children" :key="i.name" @click="linkTo(i.name)">
              {{i.meta.menu}}
            </a-menu-item>
          </a-sub-menu>
          <a-menu-item v-else @click="linkTo(item.name)" :key="item.name">
            {{item.meta.menu}}
          </a-menu-item>
        </template>
        
      </a-menu>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menu: [],
      queryData: {}
    };
  },
  watch: {
    $route(to) {
      this.setMenu(to.matched[1].name, this.$router.options.routes)
      this.queryData = this.$route.query
    }
  },
  created() {
    this.setMenu(this.$route.matched[1].name, this.$router.options.routes)
    this.queryData = this.$route.query
  },
  methods: {
    linkTo(name) {
      console.log(this.queryData)
      this.$router.push({ name: name, query: {...this.queryData} })
    },
    loop(str, arr) {
      let output = []
      for (let i = 0; i < arr.length; i++) {
        if (str == arr[i].name) {
          output = arr[i].children || []
          return output
        } else {
          if (arr[i].children) {
            output = this.loop(str, arr[i].children)
            if (output) return output
          }
        }
      }
    },
    
    setMenu(name, routes) {
      this.menu = this.loop(name, routes).filter((item) => {
        if (item.meta?.menu && item.meta?.right.indexOf(this.$store.state.party) > -1) {
          const auth = this.$store.state.party ? this.$store.state.authA : this.$store.state.authB
          if (auth.indexOf(item.meta?.power) > -1 || item.meta?.power == 'any') {
            return item
          }
        }
      })
      console.log(this.menu)
    }
  }
}
</script>

<style scoped>
.sider {
}
.sider-menu {
  width: 200px;
}

.slide-fade-enter-active {
  transition: all .3s;
}
.slide-fade-leave-active {
  transition: all .3s;
}
.slide-fade-enter, .slide-fade-leave-to {
  width: 0;
  transform: translateX(10px);
  opacity: 0;
}
</style>