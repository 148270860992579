<style scoped>
    .knowledge-base{
        background-color: #ffffff;
        border-radius: 10px;
        padding: 40px;
        /* height: 90vh; */
    }
    .read-view{
        height: calc(100vh - 294px);
        overflow: hidden;
        box-sizing: border-box;
        padding-top: 40px;
    }
    .knowledgebase-read-content{
        padding: 0 40px 40px;
        height: 100%;
        /* width: 100%; */
        flex: 1;
        overflow-x: hidden;
        overflow-y: scroll;
        /* margin-top: 20px; */
    }
    .knowledge-baseb{
        padding-top: 0;
    }
</style>
<template>
    <div class="knowledge-base" :class="$store.state.party?'':'knowledge-baseb'">
        <a-spin :spinning="loading">
            <div class="read-view flex" v-if="html!=''" >
                <div class="knowledgebase-read-content" v-html="html"></div>
            </div>
            <a-empty style="margin-top: 30px;" v-if="html==''" />
        </a-spin>
    </div>
</template>
<script>
    export default{
        data(){
            return{
                loading:false,
                html: "",
            }
        },
        created(){
            this.loading = true
            this.getEditorMessage()
        },
        methods:{
            getEditorMessage(){
                this.api.noticeDetails({
                    id:this.$route.query.id
                }).then((res)=>{
                    if(res.code==0){
                        this.loading = false
                        this.html = res.data.notice.content
                    }
                })
            }
        }
    }
</script>