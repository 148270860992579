<template>
  <div class="notice">
    <router-view></router-view>
  </div>
</template>

<script>
  export default{}
</script>

<style scoped>

</style>