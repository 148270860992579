<template>
  <div class="member">
    <a-row type="flex" justify="center">
      <a-col :span="24">
        <div class="table-box">
          <div class="table-box-title">
            <span>公司角色配置</span>
            <a-button type="primary" size="small" @click="showaddRoleModal"
              v-power="'setup/memberRole/createRole'">+新增角色</a-button>
          </div>
          <Table :columns="columns" :tableData="roleListData" :paginationShow="false">
            <a-checkbox slot="checkbox" slot-scope="scope" v-if="scope.record.admin != 1"
              :checked="scope.record.is_default == 1" @click="setDefaultRole(scope.record)"
              v-power:disabled="'setup/memberRole/changeDefaultRole'">设为默认角色</a-checkbox>
            <div slot="action" slot-scope="scope">
              <a-button class="power-button" v-if="scope.record.admin == 1" @click="poewr(scope.record, 'see')"
                size="small" v-power:disabled="'setup/memberRole/getRoleList'">查看权限</a-button>
              <a-button class="power-button" v-if="scope.record.admin != 1" @click="poewr(scope.record, 'set')"
                size="small" v-power:disabled="'setup/memberRole/updateRole'">配置权限</a-button>
              <a-button class="power-button" type="danger" v-if="scope.record.admin != 1"
                @click="showDelModal(scope.record)" size="small"
                v-power:disabled="'setup/memberRole/deleteRole'">删除</a-button>
            </div>
          </Table>
        </div>
        <br>
        <div class="table-box">
          <div class="table-box-title">
            <span>我方公司成员</span>
            <div>
              <a-button type="primary" size="small" @click="showInviteLink"
                v-power="'setup/memberRole/link'">使用链接邀请</a-button>
            </div>
          </div>
          <Table ref="table" :columns="columnsCompanyMembers" :tableData="userListData" :paginationShow="false" selected
            @selectedChange="selectedChange" :selectedProps="selectedProps">
            <img class="img-avatar" slot="img" slot-scope="scope" :src="scope.record.avatar" alt="">
            <p slot="select" slot-scope="scope">
              <span> {{ scope.record.role ? scope.record.role.name : '暂无角色' }}</span>
            </p>
            <div slot="action" slot-scope="scope" v-if="scope.record.role ? scope.record.role.admin == 1 : false">
              <a-button class="power-button" @click="replaceUser(scope.record)" size="small"
                v-power:disabled="'setup/memberRole/changeAdmin'">替换</a-button>
            </div>
            <div slot="action" slot-scope="scope" v-else>
              <a-button class="power-button" v-if="scope.record.status == 2" @click="examineUser(scope.text)" size="small"
                type="primary" v-power:disabled="'setup/memberRole/employeeReview'">审核</a-button>
              <a-button class="power-button" v-if="scope.record.status == 1" @click="editUser(scope.text)" size="small"
                v-power:disabled="'setup/memberRole/changeRole'">编辑</a-button>
              <a-button class="power-button" v-if="scope.record.status == 1" @click="deleteUser(scope.text)" size="small"
                type="danger" v-power:disabled="'setup/memberRole/employeeDelete'">删除</a-button>
            </div>
          </Table>
          <div class="batch-btn-box">
            <a-button class="power-button" @click="batchToExamine()" size="small" type="primary"
              v-power:disabled="'setup/memberRole/employeeReview'"
              :disabled="selectedChangeStatusArr.indexOf(1) != -1">批量操作-审核</a-button>
            <a-button class="power-button" @click="batchEdit()" size="small"
              v-power:disabled="'setup/memberRole/changeRole'"
              :disabled="selectedChangeStatusArr.indexOf(2) != -1">批量操作-编辑</a-button>
            <a-button class="power-button" @click="batchDelete()" size="small" type="danger"
              v-power:disabled="'setup/memberRole/employeeDelete'"
              :disabled="selectedChangeStatusArr.indexOf(2) != -1">批量操作-删除</a-button>
          </div>
        </div>
      </a-col>
    </a-row>


    <!-- 新增角色弹窗  -->
    <a-modal title="新增角色" :visible="addRoleModal" :confirm-loading="confirmLoading" @ok="onSubmit"
      @cancel="handleAddRoleCancel">
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
        <a-form-item label="角色名:">
          <a-input v-decorator="['name', { rules: [{ required: true, message: '请输入用户名!' }] }]" />
        </a-form-item>
        <!-- <a-form-item label="角色名(en):">
          <a-input v-decorator="['enname', { rules: [{ required: true, message: '请输入用户名!' }] }]" />
        </a-form-item> -->
        <a-form-item label="权限组：">
          <a-tree v-model="checkedKeys" checkable :expanded-keys="expandedKeys" :auto-expand-parent="autoExpandParent"
            :replace-fields="replaceFields" :selected-keys="selectedKeys" :tree-data="permissionTreeData"
            @expand="onExpand" @check="onCheck" />
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- 配置权限弹窗 -->
    <a-modal :title="visibleSetPowertype == 'see' ? '查看权限' : '配置权限'" :visible="visibleSetPower"
      :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel">
      <div class="label">
        <span style="margin:0">角色名称：</span>
        <a-input style="width:50%" v-model="editRoleName" placeholder="请输入角色名称"
          :disabled="visibleSetPowertype == 'see'" />
      </div>
      <!-- <div class="label">
        <span style="margin:0">角色名称(en)：</span>
        <a-input style="width:50%" v-model="editRoleEnName" placeholder="请输入角色名称"
          :disabled="visibleSetPowertype == 'see'" />
      </div> -->
      <div class="label">
        <span style="margin:0">权限配置：</span>
        <a-tree v-model="checkedKeys" checkable :expanded-keys="expandedKeys" :auto-expand-parent="autoExpandParent"
          :replace-fields="replaceFields" :selected-keys="selectedKeys" :tree-data="permissionTreeData" @expand="onExpand"
          @check="onCheck" :disabled="visibleSetPowertype == 'see'" />
      </div>
      <template slot="footer" v-if="visibleSetPowertype == 'see'">
        <a-button key="back" @click="handleCancel"> 取消 </a-button>
      </template>
      <template slot="footer" v-else>
        <a-button key="submit" type="primary" :loading="loading" @click="handleOk" :disabled="isEdit"> 保存 </a-button>
        <a-button key="back" @click="handleCancel"> 取消 </a-button>
      </template>
    </a-modal>

    <!-- 默认角色配置弹窗  -->
    <a-modal title="默认角色配置" :visible="visibleSetRole" :confirm-loading="confirmLoading" @ok="handleOkRole"
      @cancel="handleCancelRole">
      <div>
        <p>系统自动为首次进入企业空间的同事分配角色，</p><br>
        <p v-if="currentDefault">当前默认角色是<span class="color-red">“{{ currentDefault }}”</span>，</p>
        <p v-else>当前暂无默认角色，</p><br>
        <p>是否切换为<span class="color-red">“{{ targetDefault }}”</span>？</p><br>
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" :loading="loading" @click="handleOkRole">
          保存
        </a-button>
        <a-button key="back" @click="handleCancelRole">
          取消
        </a-button>
      </template>
    </a-modal>

    <!-- 编辑成员弹窗  -->
    <a-modal title="编辑成员" :visible="visibleEditUser" :confirm-loading="confirmLoading" @ok="handleOkEdituser"
      @cancel="handleCancelEditUser">
      <div v-if="modifyUserInfo">
        <span>用户名：{{ modifyUserInfo.name }}</span>&emsp;&emsp;&emsp;&emsp;
        <span>联系方式：{{ modifyUserInfo.mobile }}</span> <br><br>
        <span>成员角色：</span>
        <a-select :default-value="modifyUserInfo.role.id" style="width: 80%" :showSearch="true"
          @change="editHandleChange">
          <a-select-option :class="item.admin == 1 ? 'select-dis-none' : ''" v-for="(item, index) in roleListData"
            :key="index" :value="item.id">{{ item.name }}</a-select-option>
        </a-select>
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" :loading="loading" @click="handleOkEdituser">
          保存
        </a-button>
        <a-button key="back" @click="handleCancelEditUser">
          取消
        </a-button>
      </template>
    </a-modal>

    <!-- 删除成员弹窗  -->
    <a-modal title="删除成员" :visible="visibleDeleteUser" :confirm-loading="confirmLoading" @ok="handleOkDeleteUser"
      @cancel="handleCancelDeleteUser">
      <div v-if="modifyUserInfo">
        <span>用户名：{{ modifyUserInfo.name }}</span>&emsp;&emsp;&emsp;&emsp;
        <span>联系方式：{{ modifyUserInfo.mobile }}</span> <br><br>
        <span>成员角色：</span>
        <span> {{ modifyUserInfo.role.name }}</span>
      </div>
      <template slot="footer">
        <a-button key="submit" type="danger" :loading="loading" @click="handleOkDeleteUser">
          确定
        </a-button>
        <a-button key="back" @click="handleCancelDeleteUser">
          取消
        </a-button>
      </template>
    </a-modal>

    <!-- 替换管理员弹窗  -->
    <a-modal title="替换管理员" :visible="visibleSetReplaceAdmin" :confirm-loading="confirmLoading" @ok="handleOkReplaceAdmin"
      @cancel="handleCancelReplaceAdmin">
      <div>
        <span>选择成员：</span>
        <a-select style="width: 80%" :value="editAdminId" :showSearch="true" @change="handleChange"
          placeholder="请选择新的管理员">
          <a-select-option
            :class="(item.status == 2 ? ' select-dis-none ' : '') + (item.role.admin == 1 ? ' select-dis-none ' : '')"
            v-for="(item, index) in userListData" :key="index" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" :loading="loading" @click="handleOkReplaceAdmin">
          保存
        </a-button>
        <a-button key="back" @click="handleCancelReplaceAdmin">
          取消
        </a-button>
      </template>
    </a-modal>

    <!-- 审核成员弹窗  -->
    <a-modal title="审核成员" :visible="visibleToExamine" :confirm-loading="confirmLoading" @ok="handleOkToExamine"
      @cancel="handleCancelExamine">
      <div v-if="examineInfo">
        <span>{{ examineInfo.name }} 申请加入当前企业空间，是否通过？</span>
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" :loading="loading" @click="handleOkToExamine">
          通过
        </a-button>
        <a-button key="back" type="danger" :loading="loadingRefuse" @click="handleCancelToExamine">
          拒绝
        </a-button>
      </template>
    </a-modal>

    <!-- 使用链接邀请 -->
    <a-modal title="使用链接邀请" :visible="visibleInvite" :confirm-loading="confirmLoading" @ok="handleOkInvite"
      @cancel="handleCancelInvite">
      <div>
        <span>链接有效期：</span>
        <a-select style="width: 80%" :value="inviteTime" :showSearch="true" @change="selectTimeChange" placeholder="请选择">
          <a-select-option value="2">2小时</a-select-option>
          <a-select-option value="24">24小时</a-select-option>
          <a-select-option value="48">48小时</a-select-option>
        </a-select>
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" :loading="copyLoading" @click="handleOkInvite">
          一键复制
        </a-button>

        <a-button key="back" @click="handleCancelInvite">
          取消
        </a-button>

        <div class="yeluosen">
          <input readonly="true" id="input" value />
        </div>
      </template>
    </a-modal>

    <!-- 删除角色 弹窗 -->
    <a-modal v-model="deleteRoleShowModal" title="删除角色">
      <div v-if="delRoleInfo">
        <span style="margin:0">删除角色：{{ delRoleInfo.name }} </span><br><br>
      </div>
      <template slot="footer">
        <a-button key="submit" type="danger" :loading="loading" @click="deleteRole('adopt')">删除</a-button>
        <a-button key="back" @click="deleteRole('refuse')">取消</a-button>
      </template>
    </a-modal>

    <!-- 批量操作-审核  -->
    <a-modal title="批量审核成员" :visible="visibleBatchToExamine" :confirm-loading="confirmLoading"
      @ok="handleOkBatchToExamine" @cancel="handleCancelBatchToExamine">
      <div>
        <span>批量处理申请加入当前企业空间，是否全部通过？</span>
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" :loading="loading" @click="handleOkBatchToExamine">
          通过
        </a-button>
        <a-button key="back" type="danger" :loading="loadingRefuse" @click="handleRefuseBatchToExamine">
          拒绝
        </a-button>
      </template>
    </a-modal>

    <!-- 编辑成员弹窗  -->
    <a-modal title="批量编辑成员" :visible="visibleBatchEdit" :confirm-loading="confirmLoading" @ok="handleOkBatchEdit"
      @cancel="handleCancelBatchEdit">
      <div>
        <span>选择角色：</span>
        <a-select style="width: 80%" :showSearch="true" @change="editHandleChange" placeholder="请先择角色">
          <a-select-option :class="item.admin == 1 ? 'select-dis-none' : ''" v-for="(item, index) in roleListData"
            :key="index" :value="item.id">{{ item.name }}</a-select-option>
        </a-select>
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" :loading="loading" @click="handleOkBatchEdit">
          保存
        </a-button>
        <a-button key="back" @click="handleCancelBatchEdit">
          取消
        </a-button>
      </template>
    </a-modal>

    <!-- 批量操作-删除  -->
    <a-modal title="批量删除成员" :visible="visibleBatchDelete" :confirm-loading="confirmLoading" @ok="handleOkBatchDelete"
      @cancel="handleCancelBatchDelete">
      <div>
        <span>是否批量删除？</span>
      </div>
      <template slot="footer">
        <a-button key="submit" type="danger" :loading="loading" @click="handleOkBatchDelete">
          确定
        </a-button>
        <a-button key="back" @click="handleCancelBatchDelete">
          取消
        </a-button>
      </template>
    </a-modal>

    <!-- 功能提示遮罩 -->
    <div class="guide" v-if="memberGuideTipsShow">
      <div class="guide-content">
        <a-carousel ref="carousel" class="guide-swiper" :dots="false">
          <div class="guide-item">
            <img :src="guideItem[guideIndex].img">
            <p>{{ guideItem[guideIndex].content }}&emsp;({{ guideIndex + 1 }}/{{ guideItem.length }})</p>
          </div>
        </a-carousel>
        <div class="guide-btns">
          <a-button v-if="guideIndex > 0" type="primary" ghost @click="guideStepPrev">上一步</a-button>
          <a-button v-if="guideIndex < guideItem.length - 1" type="primary" ghost @click="guideStepNext">下一步</a-button>
          <a-button v-if="guideIndex >= guideItem.length - 1" type="primary" ghost @click="guideHide">知道了</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Table from '../../../components/table.vue'
export default {
  components: {
    Table,
  },
  data() {
    return {
      copyLoading: true,// 复制键是否loading
      copyText: '', //一键复制的文案
      parentList: [],
      noRemovable: [],
      isEdit: true, // 是否编辑过权限
      halfCheckedKeys: [], // tree父级节点
      parentNo: [], // 子集被默认全部选中的父级id数组

      roleListData: [], // 角色列表
      userListData: [], // 用户列表
      currentDefault: '', // 当前默认角色
      targetDefault: '', // 目标默认角色
      roleId: '', // 目标默认角色ID
      roleAdminId: '', // 当前管理员ID
      modifyUserInfo: '',// 编辑、删除用户详情
      columns: [
        {
          title: '角色',
          dataIndex: 'name',
          align: 'center',
        },
        {
          title: '默认角色',
          scopedSlots: { customRender: 'checkbox' },
          align: 'center',
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' },
          align: 'center',
        },
      ],
      columnsCompanyMembers: [
        {
          title: '头像',
          scopedSlots: { customRender: 'img' },
          width: '200',
          align: 'center',
        },
        {
          title: '用户名',
          width: '200',
          align: 'center',
          customRender: (row) => {
            return <p>{row.name}</p>;
          },
        },
        {
          title: '手机号',
          width: '200',
          align: 'center',
          customRender: (row) => {
            return <p>{row.mobile}</p>;
          },
        },
        {
          title: '角色',
          // dataIndex: 'id',
          scopedSlots: { customRender: 'select' },
          width: '200',
          align: 'center',
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' },
          align: 'center',
        },
      ],
      visibleInvite: false,
      visibleSetPower: false,
      visibleSetPowertype: 'set',
      loading: false,
      loadingRefuse: false,
      confirmLoading: false,
      expandedKeys: [],
      autoExpandParent: true,
      editRoleName: '', // 编辑角色名称
      editRoleEnName: '', // 编辑角色名称en
      editRoleId: '', // 编辑角色ID
      checkedKeys: [],
      selectedKeys: [],
      permissionTreeData: [],
      replaceFields: {
        key: 'id',
        children: 'child',
        title: 'display_name',
      },
      visibleEditUser: false,
      visibleDeleteUser: false,
      visibleSetRole: false,
      visibleSetReplaceAdmin: false, // 是否显示 替换管理员 弹窗
      visibleSetReplaceAdminID: '', // 当前管理员ID
      editAdminId: [], // 新的管理员ID
      visibleToExamine: false,
      addRoleModal: false,
      submitLoading: false,
      form: this.$form.createForm(this, { name: '', enname: '' }),
      inviteTime: '2', // 链接有效时长
      examineInfo: '', // 审核员工信息
      checkedIds: [],
      delRoleInfo: '', // 删除角色详情
      deleteRoleShowModal: false, // 是否显示删除角色详情弹窗

      selectedChangeStatusArr: '',// 批量选中ID数组
      selectedChangeIdArr: '',// 批量选中ID数组

      visibleBatchToExamine: false,// 是否显示-批量操作-审核
      visibleBatchEdit: false,// 是否显示-批量操作-审核
      visibleBatchDelete: false,// 是否显示-批量操作-删除

      memberGuideTipsShow: true, // 显示指引
      guideIndex: 0, // 指引步骤.
      guideItem: [
        {
          img: 'https://res.xiaoxxx.com/urlGather/202309155ba364d9d5624cf6131014.jpg',
          content: '角色便于对成员进行权限管理，平台已为您创建2个常用角色'
        }, {
          img: 'https://res.xiaoxxx.com/urlGather/202309152db3f6ced9f6cee2822428.jpg',
          content: '您也可以自定义角色或修改角色权限'
        }, {
          img: 'https://res.xiaoxxx.com/urlGather/202309154901621bd9526de6216803.jpg',
          content: '默认角色为新用户进入时自动分配的角色'
        }, {
          img: 'https://res.xiaoxxx.com/urlGather/202309152399d81588b474f5928967.jpg',
          content: '同一公司的成员在此使用链接邀请'
        }, {
          img: 'https://res.xiaoxxx.com/urlGather/2023091542f219802d1ace07298492.jpg',
          content: '点击替换，替换当前企业空间的管理员，管理员仅有一位，请谨慎操作'
        }, {
          img: 'https://res.xiaoxxx.com/urlGather/20230915edeef240616a119f606194.jpg',
          content: '点击编辑，修改用户角色'
        }, {
          img: 'https://res.xiaoxxx.com/urlGather/2023091518a5fbff30ec65eb167418.jpg',
          content: '新用户需要手动审核'
        },
      ], // 指引步骤内容

    }
  },
  watch: {
    checkedKeys(val) {
      console.log('onWatchCheck', val);
    },
  },
  mounted() {
    this.getUser()
    this.getRole()
    this.getPermission()
  },
  methods: {
    setCheckedKeys(permResult) {
      // console.log("进入设置半选:", permResult)
      permResult.forEach((v) => {
        let result = this.isChildNode(this.permissionTreeData, v)
        if (result) {
          console.log('result', result);
        }
      })
      this.checkedKeys = this.checkedKeys.filter(item => !["", null, undefined, NaN, false, true].includes(item));
      // 拼接上 子集被默认全部选中的父级id数组
      this.checkedKeys.push(...this.parentNo)
      // console.log("___________this.checkedKeys:", this.checkedKeys)
    },
    //判断是否是子级节点
    isChildNode(list, id) {
      // console.log('list', list);
      for (let i = 0; i < list.length; i++) {
        const v = list[i];
        if (id == v.id) {
          // console.log('id:', id, 'v:', v);
          if (this.checkedKeys.indexOf(v.pid) != -1) {
            // this.checkedKeys.splice(this.checkedKeys.indexOf(v.pid), 1)
            this.checkedKeys = this.checkedKeys.map((item) => {
              if (item != v.pid) {
                // console.log('item', item);
                return item
              } else {
                return false
              }
            })
            // console.log('——————————', this.checkedKeys);
          } else {
            if (v.child) {
              this.isChildNode(v.child, id)
            }
          }
        } else {
          if (v.child) {
            this.isChildNode(v.child, id)
          }
        }
      }
      return false
    },
    copyclick(text) {
      var input = document.getElementById("input");
      input.value = text; // 修改文本框的内容
      input.select(); // 选中文本
      document.execCommand("copy"); // 执行浏览器复制命令
      this.handleCancelInvite()
      this.message.success('复制成功');
    },
    // 获取权限列表
    getPermission() {
      this.api.permissionList({
      }).then(res => {
        // console.log('permissionList-res', res.data.permissions);
        this.permissionTreeData = res.data.permissions
        this.noRemovable = res.data.noRemovable
        this.parentList = res.data.parent
        this.parentNo = res.data.parent_no
      })
    },
    // 获取角色列表
    async getRole() {
      await this.api.getRoleList({
      }).then(res => {
        // console.log('res', res);
        const roleLists = res.data.lists
        for (let i = 0; i < roleLists.length; i++) {
          if (roleLists[i].admin == 1) {
            this.roleAdminId = roleLists[i].id
          }
          if (roleLists[i].is_default == 1) {
            this.currentDefault = roleLists[i].name
          }
        }

        this.roleListData = roleLists

      })
    },
    // 获取成员列表
    getUser() {
      this.$refs.table.clearSelected()
      this.selectedChangeIdArr = ''
      this.selectedChangeStatusArr = ''
      this.visibleBatchToExamine = false
      this.visibleBatchEdit = false
      this.visibleBatchDelete = false
      this.api.employeeList({
        page: 1,
        pageSize: 100
      }).then(res => {
        // console.log('res', res);
        this.userListData = res.data.lists
      })
    },
    noRepeat2(arr) {
      var newArr = [];
      for (var i = 0; i < arr.length; i++) {
        if (newArr.indexOf(arr[i]) == -1) {
          //解释：如果新数组里没有就放进去，有就放不进了
          newArr.push(arr[i]);
        }
      }
      return newArr;
    },
    // 新增角色-展示弹窗
    showaddRoleModal() {
      // console.log('add');
      this.addRoleModal = true
      // this.checkedKeys = [36, 37, 38]
      // console.log('this.noRemovable', this.noRemovable);
      this.checkedKeys = []
      this.selectedKeys = []
      this.halfCheckedKeys = []
      this.checkedKeys = this.noRemovable
      // console.log('this.checkedKeys', this.checkedKeys);
    },
    // 新增角色-调用接口
    addRole(name, arr) {
      let permissionsArr = []
      permissionsArr.push(...arr)
      permissionsArr.push(...this.parentList)
      permissionsArr.push(...this.selectedKeys)
      permissionsArr.push(...this.halfCheckedKeys)
      permissionsArr = this.noRepeat2(permissionsArr);
      permissionsArr = permissionsArr.sort((a, b) => a - b)
      // console.log('permissions：permissions：permissions：', permissionsArr);
      this.api.createRole({
        name: name,
        enName: name,
        permissions: permissionsArr
      }).then(res => {
        if (res.code == 0) {
          this.message.success(res.message);
        }
        console.log('res', res);
        this.handleCancel()
        this.getRole()
      })
    },
    // 下拉选择框-选择
    handleChange(value) {
      console.log(`selected ${value}`);
      this.editAdminId = value
    },
    // 编辑成员-下拉选择框-选择
    editHandleChange(value) {
      this.editRoleId = value
    },
    showModal() {
      this.visible = true;
    },
    handleOk() {
      if (this.editRoleName == '' || this.editRoleEnName == '') {
        this.message.error('角色名称不能为空');
      } else {
        this.loading = true;
        this.confirmLoading = true;

        this.halfCheckedKeys.push(...this.parentList)

        this.halfCheckedKeys.push(...this.checkedKeys)
        this.halfCheckedKeys = this.noRepeat2(this.halfCheckedKeys);
        this.halfCheckedKeys.sort((a, b) => a - b)
        console.log('最终权限数组，permissions：', this.halfCheckedKeys);

        this.api.updateRole({
          id: this.editRoleId,
          name: this.editRoleName,
          // enName: this.editRoleEnName,
          enName: this.editRoleName,
          permissions: this.halfCheckedKeys
        }).then(res => {
          if (res.code == 0) {
            this.message.success(res.message);
          }
          console.log('res', res);
          this.editRoleName = '';
          this.editRoleEnName = '';
          this.editRoleID = '';
          this.checkedKeys = [];
          this.loading = false;
          this.confirmLoading = false;
          this.visibleSetPower = false;
          this.getRole()
        })
      }
    },
    handleAddRoleCancel() {
      this.form.setFieldsValue({
        name: '',
        enname: ''
      })
      this.checkedKeys = [];
      this.addRoleModal = false;
    },
    handleCancel() {
      this.form.setFieldsValue({
        name: '',
        enname: ''
      })
      this.editRoleName = '';
      this.editRoleEnName = '';
      this.editRoleID = '';
      this.checkedKeys = [];
      this.addRoleModal = false;
      this.visibleSetPower = false;
    },
    onExpand(expandedKeys) {
      // console.log('onExpand', expandedKeys);
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    onWatchCheck(checkedKeys) {
      // console.log('onWatchCheck', checkedKeys);
      this.checkedKeys = checkedKeys;
    },
    onCheck(selectedKeys, e) {
      console.log('onCheck', selectedKeys, e);
      this.isEdit = false
      this.selectedKeys = selectedKeys;
      this.halfCheckedKeys = e.halfCheckedKeys
    },
    poewr(data, type) {
      // console.log(type == 'see' ? '查看权限' : '配置权限', data);
      // this.checkedKeys = data.permission;
      this.isEdit = true
      this.checkedKeys = data.permission;
      this.setCheckedKeys(data.permission)
      this.editRoleName = data.name
      this.editRoleEnName = data.en_name
      this.editRoleId = data.id
      this.visibleSetPowertype = type
      this.visibleSetPower = true
    },
    // 设置默认角色
    setDefaultRole(data) {
      // console.log('data', data);
      // this.currentDefault = 'xxxxx'
      this.targetDefault = data.name
      this.roleId = data.id
      this.visibleSetRole = true
    },
    // 默认角色配置-关闭
    handleCancelRole() {
      this.visibleSetRole = false
    },
    // 默认角色配置-保存
    handleOkRole() {
      this.loading = true;
      this.confirmLoading = true;
      this.api.changeDefaultRole({
        roleId: this.roleId
      }).then(res => {
        console.log('res', res);
        if (res.code == 0) {
          this.loading = false;
          this.confirmLoading = false;
          this.visibleSetRole = false
          this.getRole()
          this.message.success(res.message);
        }
      })
    },
    // 替换管理员弹窗-关闭
    handleCancelReplaceAdmin() {
      this.editAdminId = []
      this.visibleSetReplaceAdmin = false
    },
    // 替换管理员弹窗-保存
    handleOkReplaceAdmin() {
      console.log('this.editAdminId ', this.editAdminId);
      if (this.editAdminId.length == 0) {
        this.message.error('请选择成员')
      } else {
        this.loading = true;
        this.api.changeAdmin({
          user_id: this.visibleSetReplaceAdminID,
          id: this.editAdminId
        }).then(res => {
          this.loading = false;
          this.editAdminId = []
          this.visibleSetReplaceAdmin = false;
          if (res.code == 0) {
            this.getUser()
            this.message.success(res.message);
          }
        })
      }
    },
    // 审核成员弹窗-关闭
    handleCancelExamine() {
      this.confirmLoading = false;
      this.visibleToExamine = false;
    },
    // 审核成员弹窗-拒绝
    handleCancelToExamine() {
      this.loadingRefuse = true;
      this.confirmLoading = true;
      this.employeeReview(this.examineInfo.id, 3)
    },
    // 审核成员弹窗-通过
    handleOkToExamine() {
      this.loading = true;
      this.confirmLoading = true;
      this.employeeReview(this.examineInfo.id, 1)
    },
    // 审核成员接口
    employeeReview(ids, status) {
      this.api.employeeReview({
        user_id: ids,
        status: status,
      }).then(res => {
        if (res.code == 0) {
          this.message.success(res.message);
          this.loading = false;
          this.loadingRefuse = false;
          this.confirmLoading = false;
          this.visibleToExamine = false;
          this.visibleBatchToExamine = false;
          this.getUser()
        }
      })
    },
    // 打开 编辑 弹窗
    editUser(data) {
      console.log('data', data);
      this.modifyUserInfo = data
      console.log('modifyUserInfo', this.modifyUserInfo);
      this.visibleEditUser = true
    },
    // 打开 替换 弹窗
    replaceUser(data) {
      // console.log(data.id);
      this.visibleSetReplaceAdminID = data.id
      this.visibleSetReplaceAdmin = true
    },
    // 打开 审核 弹窗
    examineUser(data) {
      this.examineInfo = data
      this.visibleToExamine = true
    },
    // 打开 删除 弹窗
    deleteUser(data) {
      this.modifyUserInfo = data
      this.visibleDeleteUser = true
    },
    onSubmit() {
      // console.log('checkedKeys', this.checkedKeys);
      this.form.validateFields((err, value) => {
        if (!err) {
          // console.log('data', this.checkedKeys);
          this.addRole(value.name, value.enname, this.checkedKeys)
        }
      });
    },
    // 批量操作接口-编辑
    changeRole(ids) {
      this.api.changeRole({
        id: ids,
        roleId: this.editRoleId
      }).then(res => {
        if (res.code == 0) {
          this.message.success(res.message);
        }
        console.log('res', res);
        this.loading = false;
        this.confirmLoading = false;
        this.visibleEditUser = false;
        this.visibleBatchEdit = false;
        this.modifyUserInfo = '';
        this.getUser()
      })
    },
    // 编辑成员弹窗-保存
    handleOkEdituser() {
      this.loading = true;
      this.confirmLoading = true;
      this.changeRole(this.modifyUserInfo.id)
    },
    // 编辑成员弹窗-关闭
    handleCancelEditUser() {
      this.visibleEditUser = false;
      this.modifyUserInfo = ''
    },
    // 删除成员弹窗-保存
    handleOkDeleteUser() {
      this.loading = true;
      this.confirmLoading = true;
      this.employeeDelete(this.modifyUserInfo.id)
    },
    // 批量操作接口-删除
    employeeDelete(ids) {
      this.api.employeeDelete({
        user_id: ids
      }).then(res => {
        if (res.code == 0) {
          this.message.success(res.message);
        }
        console.log('res', res);
        this.loading = false;
        this.confirmLoading = false;
        this.visibleDeleteUser = false;
        this.visibleBatchDelete = false;
        this.getUser()
      })
    },
    selectTimeChange(value) {
      // console.log(value);
      this.copyLoading = true
      this.inviteTime = value
      this.showInviteLink()
    },
    // 删除成员弹窗-关闭
    handleCancelDeleteUser() {
      this.visibleDeleteUser = false;
    },
    // 链接邀请弹窗-一键复制
    handleOkInvite() {
      this.copyclick(this.copyText)
    },
    // 链接邀请弹窗-关闭
    handleCancelInvite() {
      this.inviteTime = '2'
      this.visibleInvite = false;
    },
    // 显示 删除角色 弹窗
    showDelModal(data) {
      this.delRoleInfo = data
      console.log(this.delRoleInfo);
      this.deleteRoleShowModal = true
    },
    // 删除角色，通过or拒绝
    deleteRole(type) {
      console.log('this.delRoleInfo', this.delRoleInfo);
      if (type == 'adopt') {
        console.log('通过');
        this.loading = true
        this.api.deleteRole({
          id: this.delRoleInfo.id,
        }).then(res => {
          this.loading = false
          this.deleteRoleShowModal = false
          if (res.code == 0) {
            this.getRole()
            this.message.success(res.message);
          }
        })
      } else if (type == 'refuse') {
        console.log('拒绝=取消');
        this.loading = false
        this.deleteRoleShowModal = false
      }
    },
    // 展示 链接邀请弹窗
    showInviteLink() {
      this.visibleInvite = true
      this.copyLoading = true
      let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
      let company_id = userInfo.company_id
      this.api.invitationLink({
        type: 1,
        originator_id: company_id,
        recipient_id: company_id,
        user_id: userInfo.id,
        h: this.inviteTime,
        status: this.$store.state.party ? 1 : 2,
      }).then(res => {
        console.log(res)
        this.copyText = window.location.origin + window.location.pathname + '#/create?inviteId=' + res.data.id
        this.copyLoading = false
      })
    },
    selectedProps(record) {
      return {
        props: {
          disabled: (record.role || '').admin === 1, // Column configuration not to be checked
        }
      }
    },
    // 列表选择
    selectedChange(data) {
      this.selectedChangeIdArr = ''
      this.selectedChangeStatusArr = ''
      data.map((v, i) => {
        if (i > 0) {
          this.selectedChangeIdArr = this.selectedChangeIdArr + ',' + v.id
          this.selectedChangeStatusArr = this.selectedChangeStatusArr + ',' + v.status
        } else {
          this.selectedChangeIdArr += v.id
          this.selectedChangeStatusArr += v.status
        }
      })
      console.log(this.selectedChangeIdArr)
      // console.log(this.selectedChangeStatusArr)
    },
    // 批量操作-审核
    batchToExamine() {
      if (this.selectedChangeIdArr == '') {
        this.message.error('请选择成员');
      } else {
        this.visibleBatchToExamine = true
      }
    },
    // 批量操作-审核-关闭
    handleCancelBatchToExamine() {
      this.loading = false;
      this.confirmLoading = false;
      this.visibleBatchToExamine = false;
    },
    // 批量操作-审核-拒绝
    handleRefuseBatchToExamine() {
      this.loadingRefuse = true;
      this.confirmLoading = true;
      this.employeeReview(this.selectedChangeIdArr, 3)
    },
    // 批量操作-审核-通过
    handleOkBatchToExamine() {
      this.loading = true;
      this.confirmLoading = true;
      this.employeeReview(this.selectedChangeIdArr, 1)
    },
    // 批量操作-编辑
    batchEdit() {
      if (this.selectedChangeIdArr == '') {
        this.message.error('请选择成员');
      } else {
        this.visibleBatchEdit = true
      }
    },
    // 编辑成员弹窗-保存
    handleOkBatchEdit() {
      this.loading = true;
      this.confirmLoading = true;
      this.changeRole(this.selectedChangeIdArr)
    },
    // 编辑成员弹窗-关闭
    handleCancelBatchEdit() {
      this.visibleBatchEdit = false;
    },
    // 批量操作-删除
    batchDelete() {
      if (this.selectedChangeIdArr == '') {
        this.message.error('请选择成员');
      } else {
        this.visibleBatchDelete = true
      }
    },
    // 删除成员弹窗-保存
    handleOkBatchDelete() {
      this.loading = true;
      this.confirmLoading = true;
      this.employeeDelete(this.selectedChangeIdArr)
    },
    // 删除成员弹窗-关闭
    handleCancelBatchDelete() {
      this.visibleBatchDelete = false;
    },
    guideStepNext() {
      this.guideIndex += 1
      this.$refs.carousel.next()
    },
    guideStepPrev() {
      this.guideIndex -= 1
      this.$refs.carousel.prev()
    },
    guideHide() {
      window.localStorage.setItem('memberGuideTipsShow', 'false')
      this.memberGuideTipsShow = false
    }
  },
  created() {
    this.memberGuideTipsShow = (window.localStorage.getItem('memberGuideTipsShow') == 'false' ? false : true)
  }
}
</script>

<style scoped>
.color-red {
  color: red;
}

.table-box {
  background: #eee;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px;
}

.table {
  background: #fff;
}

.table-box-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.question-icon {
  margin-left: 10px;
}

.img-avatar {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.power-button {
  margin-right: 10px;
}

.label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.label span {
  margin-right: 20px;
  width: 100px;
  text-align: right;
}

.yeluosen {
  position: absolute;
  overflow: hidden;
  z-index: -999;
  opacity: 0;
}

.batch-btn-box {
  text-align: right;
  padding: 20px 0 0;
}



.guide {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
  overflow: hidden;
}

.guide-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 600px);
  max-height: calc(100vh - 100px);
  padding: 20px 0;
  background: #fff;
  border-radius: 10px;
  /* margin: 200px auto; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  overflow: hidden;
}

.guide-swiper {
  width: calc(100% - 20px);
  height: 100%;
  padding-top: 50px;
  margin: 0 auto;
}

.guide-item {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.guide-item img {
  width: calc(100% - 100px);
  height: calc(100% - 100px);
  margin: 0 auto;
  object-fit: contain;
}

.guide-item p {
  color: #000;
  text-align: center;
  margin-top: 20px;
}

.guide-btns {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.guide-btns .ant-btn {
  margin: 0 10px;
}
</style>