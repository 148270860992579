<template>
  <div class="h5">
    <a-form-model :model="form" layout="inline" style="margin: 10px 24px 20px 24px">
      <a-form-model-item label="关键字">
        <a-input v-model="form.keywords" />
      </a-form-model-item>
      <a-form-model-item label="创建日期">
        <a-range-picker @change="onChange" />
      </a-form-model-item>
      <a-form-model-item>
        <a-button shape="circle" icon="search" @click="$refs.table.getData()"></a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click="reset">重置</a-button>
      </a-form-model-item>
    </a-form-model>
    <Table ref="table" :request="api.recycleProjectLists" :columns="columns" :param="{...form}">
      <div class="btns" slot="action" slot-scope="scope">
        <a-space>
          <a-popconfirm
            title="是否确认还原?"
            ok-text="是"
            cancel-text="否"
            @confirm="restore(scope)">
            <a-button type="primary" size="small">还原</a-button>
          </a-popconfirm>
          <a-popconfirm
            title="是否确认删除?"
            ok-text="是"
            cancel-text="否"
            @confirm="del(scope)">
            <a-button type="danger" size="small">永久删除</a-button>
          </a-popconfirm>
        </a-space>
      </div>
    </Table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        keywords: '',
        start_time: '',
        end_time: ''
      },
      columns: [
        {
          title: 'id',
          dataIndex: 'id',
          width: 80,
        },
        {
          title: '项目标题',
          dataIndex: 'name',
        },
        {
          title: '删除时间',
          dataIndex: 'deleted_at',
          width: 250
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' },
          width: 120
        },
      ],
    }
  },
  methods: {
    // 时间选择
    onChange(date, dateString) {
      console.log(date, dateString, this.date);
      this.form.start_time = dateString[0]
      this.form.end_time = dateString[1]
    },
    reset() {
      this.form = {
        keywords: '',
        start_time: '',
        end_time: ''
      }
      this.$nextTick(() => {
        this.$refs.table.getData()
      })
    },
    restore(scope) {
      this.api.recycleProjectRestore({id: scope.record.id}).then(res => {
        if (res.code == 0) {
          this.message.success(res.message)
          this.$refs.table.getData()
        } else {
          this.message.error(res.message);
        }
      })
    },
    del(scope) {
      this.api.recycleProjectDel({id: scope.record.id}).then(res => {
        if (res.code == 0) {
          this.message.success(res.message)
          this.$refs.table.getData()
        } else {
          this.message.error(res.message);
        }
      })
    }
  }
}
</script>

<style scoped>
.edit {
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 20px;
}
.label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.label span {
  margin-right: 20px;
}
</style>