<template>
  <div class="copy">
    <div class="title">
      <span>版权库</span>
    </div>
    <div class="content">
       <div class="item" @click="linkTo('copyrightFont')" style="background-image: url(https://res.xiaoxxx.com/urlGather/202211047363be62fec4f65e264797.png)">
        <span>字体版权</span>
        <Number :num="$store.state.party ? data.font_count : data?.userInfo?.project_b_font_count"></Number>
      </div>
      <div class="item" @click="linkTo('copyright3D')" style="background-image: url(https://res.xiaoxxx.com/urlGather/20221104a570b5096ccf7196779114.png)">
        <span>3D素材版权</span>
        <Number :num="$store.state.party ? data.material_count : data?.userInfo?.project_b_material_count"></Number>
      </div>
    </div>
  </div>
</template>

<script>
import Number from '../utils/number.vue'
export default {
  props: ['data'],
  components: {
    Number
  },
  methods: {
    linkTo(name) {
      this.$router.push({name: name})
    }
  }
}
</script>

<style scoped>
.copy {
  background: #fff;
  border-radius: 10px;
}
.title {
  height: 50px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 0 10px 15px;
}
.content {
  min-height: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px;
}
.item {
  width: 48.5%;
  height: 110px;
  font-size: 30px;
  color: #fff;
  text-align: center;
  line-height: 110px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0px 10px 10px 1px #ddd;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  transition: all 1s;
}
.item::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.item::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7) url('../../assets/images/add.png') center no-repeat;
  background-size: contain;
  border-radius: 10px;
  z-index: 10;
  opacity: 0;
  transition: all 0.5s;
}
.item:hover::after {
  opacity: 1;
}
.item span {
  font-size: 16px;
  color: #fff;
  line-height: 1;
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 9;
}
.item .number {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
}
</style>