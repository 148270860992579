<template>
  <div class="personal">
    <div class="userinfo-item">
      <div class="item-title">用户名：</div>
      <a-input style="width: 600px" autocomplete="off" v-model="userInfo.name" disabled />
      <div class="avatar-upload-box">
        <a-icon class="edit-icon" type="form" @click="updateUserInfo('username')"
          v-power:disabled="'setup/personal/updateUser'" />
      </div>
    </div>
    <div class="userinfo-item">
      <div class="item-title">联系方式：</div>
      <a-input style="width: 600px" autocomplete="off" v-model="userInfo.mobile" disabled />
      <div class="avatar-upload-box">
        <a-icon class="edit-icon" type="form" @click="updateUserInfo('mobile')"
          v-power:disabled="'setup/personal/updateUser'" />
      </div>
    </div>
    <div class="userinfo-item">
      <div class="item-title">个人头像：</div>
      <div style="width: 600px;height: 200px;">
        <img class="img-avatar" v-if="userInfo.avatar" :src="userInfo.avatar" alt="avatar" />
      </div>
      <div class="avatar-upload-box">
        <a-icon class="edit-icon" type="form" v-power:disabled="'setup/personal/updateUser'" />

        <a-upload name="file" ref="upload" list-type="picture-card" class="avatar-uploader" :show-upload-list="false"
          :action="api.uploadUrl" :before-upload="beforeUpload" @change="handleChange" :data="{ file_name: 1 }"
          v-power:disabled="'setup/personal/updateUser'">
          <!-- <img class="img-avatar" v-if="userInfo.avatar" :src="userInfo.avatar" alt="avatar" /> -->
          <div>
            <a-icon :type="loading ? 'loading' : 'plus'" />
            <div class="ant-upload-text">
              上传
            </div>
          </div>
        </a-upload>
      </div>
    </div>

    <headCanvas :canvasText="userNameEnd2" @childEvent="getHeadUrl"></headCanvas>

    <!-- 编辑用户名  -->
    <a-modal title="编辑用户名" :visible="editModalUserName" @ok="handleOkEditUserName" @cancel="handleCancelEditUserName">
      <div>
        用户名： <a-input style="width: 360px" autocomplete="off" placeholder="请输入用户名" v-model="editUserInfoName" />
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" :loading="loading" @click="handleOkEditUserName">
          保存
        </a-button>
        <a-button key="back" @click="handleCancelEditUserName">
          取消
        </a-button>
      </template>
    </a-modal>
    <!-- 修改手机号  -->
    <a-modal title="修改手机号" :visible="editModalPhone" @ok="handleOkEditMobile" @cancel="handleCancelEditMobile">
      <div>
        <br>
        <div>
          原手机号： <a-input style="width: 360px" autocomplete="off" placeholder="请输入原手机号" v-model="editUserInfoOldMobile"
            disabled />
        </div>
        <br>
        <div>
          新手机号： <a-input style="width: 360px" autocomplete="off" placeholder="请输入新手机号" v-model="editUserInfoNewMobile" />
        </div>
        <br>
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" :loading="loading" @click="handleOkEditMobile">
          保存
        </a-button>
        <a-button key="back" @click="handleCancelEditMobile">
          取消
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import headCanvas from '../../../components/headcanvas/headcanvas.vue'
export default {
  components: {
    headCanvas,
  },
  data() {
    return {
      loading: false,
      userNameEnd2: '', // 用户名 后两个字
      avatarStatus: 1, // 1:默认 2:已修改
      userInfo: {
        name: '',
        mobile: '',
        avatar: '',
      },
      editUserInfoName: '',
      editUserInfoOldMobile: '',
      editUserInfoNewMobile: '',
      editModalUserName: false,
      editModalPhone: false,
    }
  },
  methods: {
    // 获取头像链接
    getHeadUrl(data) {
      if (this.avatarStatus == 1) {
        this.userInfo.avatar = data
        if (this.editUserInfoName) {
          console.log('名字更新、头像更新');
          this.api.updateUser({
            name: this.editUserInfoName,
            mobile: this.userInfo.mobile,
            avatar: this.userInfo.avatar,
            avatar_status: this.avatarStatus
          }).then(res => {
            console.log('res', res)
            if (res.code == 0) {
              this.editModalUserName = false
              this.loading = false
              this.$message.success(res.message);
              this.getUserInfo();
            }
          })
        }
      }
    },
    // 上传
    handleChange({ fileList }) {
      if (fileList[fileList.length - 1].response) {
        if (fileList[fileList.length - 1].response.code == 0) {
          this.userInfo.avatar = fileList[fileList.length - 1].response.data.url;
          console.log('fileList', fileList);
          this.avatarStatus = 2
          this.api.updateUser({
            name: this.userInfo.name,
            mobile: this.userInfo.mobile,
            avatar: this.userInfo.avatar,
            avatar_status: this.avatarStatus
          }).then(res => {
            console.log('res', res)
            if (res.code == 0) {
              this.editModalUserName = false
              this.loading = false
              this.$message.success(res.message);
              this.getUserInfo();
            }
          })
        }
      }
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.$message.error('You can only upload JPG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
    },
    getUserInfo() {
      this.api.getUserInfo({
      }).then(res => {
        // console.log('res', res)
        window.localStorage.setItem('userInfo', JSON.stringify(res.data.userInfo))
        this.$store.commit('setUserInfo', JSON.parse(window.localStorage.getItem('userInfo')))
        this.avatarStatus = res.data.userInfo.avatar_status
        this.userInfo.avatar = res.data.userInfo.avatar
        if (this.avatarStatus == 1) {
          this.userNameEnd2 = res.data.userInfo.name.slice(0, 2)
        }
        this.userInfo = res.data.userInfo
      })
    },
    // 展示更新弹窗
    updateUserInfo(type) {
      if (type == 'username') {
        console.log('更新用户名，type', type);
        this.editUserInfoName = this.userInfo.name
        this.editModalUserName = true
      } else if (type == 'mobile') {
        console.log('联系方式，type', type);
        this.editUserInfoOldMobile = this.userInfo.mobile
        this.editUserInfoNewMobile = ''
        this.editModalPhone = true
      }
    },
    // 修改用户名-保存
    handleOkEditUserName() {
      console.log('baocun', this.editUserInfoName);
      if (this.editUserInfoName == '') {
        this.message.error('请输入用户名');
      } else {
        this.loading = true
        console.log(this.userNameEnd2, this.editUserInfoName);
        if (this.avatarStatus == 1 && this.userNameEnd2 !== this.editUserInfoName.slice(0, 2)) {
          console.log('1');
          this.userNameEnd2 = this.editUserInfoName.slice(0, 2)
        } else {
          console.log('2');
          this.api.updateUser({
            name: this.editUserInfoName,
            mobile: this.userInfo.mobile,
            avatar: this.userInfo.avatar,
            avatar_status: this.avatarStatus
          }).then(res => {
            console.log('res', res)
            if (res.code == 0) {
              this.editModalUserName = false
              this.loading = false
              this.$message.success(res.message);
              this.getUserInfo();
            }
          })
        }
      }
    },
    // 修改用户名-关闭
    handleCancelEditUserName() {
      this.editModalUserName = false
      this.editUserInfoName = ''
    },
    // 修改手机号-保存
    handleOkEditMobile() {
      if (this.editUserInfoNewMobile == '') {
        this.message.error('请输入新手机号');
      } else {
        this.loading = true
        this.api.updateUser({
          name: this.userInfo.name,
          mobile: this.editUserInfoNewMobile,
          avatar: this.userInfo.avatar,
          avatar_status: this.avatarStatus
        }).then(res => {
          console.log('res', res)
          if (res.code == 0) {
            this.loading = false
            this.editModalPhone = false
            this.editUserInfoOldMobile = ''
            this.editUserInfoNewMobile = ''
            this.$message.success(res.message);
            this.getUserInfo();
          }
        })
      }
    },
    // 修改手机号-关闭
    handleCancelEditMobile() {
      this.editModalPhone = false
      this.editUserInfoOldMobile = ''
      this.editUserInfoNewMobile = ''
    },
  },
  created() {
    this.getUserInfo()
  }
}
</script>

<style scoped>
.personal {
  /* min-width: 1000px; */
  padding: 40px 0 0;
}

.avatar-uploader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.avatar-uploader>.ant-upload {
  /* width: calc(128px * 3); */
  /* height: calc(128px * 3); */
  width: 300px;
  height: 300px;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.img-avatar {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.posi-re {
  position: relative;
}

.avatar-upload-box {
  position: relative;
  /* border: 1px solid red; */
  width: 20px;
  height: 20px;
  margin: 0 0 0 230px;
  overflow: hidden;
}

.edit-icon {
  /* position: absolute; */
  /* top: calc(50% - 10px); */
  /* right: -60px; */
  /* margin: 0 0 0 230px; */
  font-size: 20px;
  cursor: pointer;
}

.userinfo-item {
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0 40px;
}

.item-title {
  min-width: 100px;
  margin: 0 10px 0 0;
  text-align: right;
  white-space: nowrap;
}
</style>