<template>
  <div class="list">
    <a-row :gutter="[16, 16]" type="flex" justify="center">
      <a-col :span="23">
        <a-form-model layout="inline" style="margin: 10px 0 20px">
          <a-form-model-item label="关键字">
            <a-input v-model="keywords" />
          </a-form-model-item>
          <a-form-model-item>
            <a-button shape="circle" icon="search" @click="$refs.table.getData()"></a-button>
          </a-form-model-item>
        </a-form-model>
        <Table ref="table" :request="api.companyLists" :columns="columns" :param="{
          company_id: $store.state.userInfo.company_id,
          keywords
        }">
          <span slot="id" slot-scope="scope">
            {{scope.record.owner_id}}
          </span>
          <span slot="name" slot-scope="scope">
            {{scope.record.owner && scope.record.owner.name || '/'}}
          </span>
          <!-- <div class="btns" slot="action" slot-scope="scope"> -->
            <a-space slot="action" slot-scope="scope">
              <a-button type="primary" @click="info(scope)" size="small">查看详情</a-button>
            </a-space>
          <!-- </div> -->
        </Table>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      keywords: '',
      columns: [
        {
          title: 'id',
          dataIndex: 'id',
          width: 100,
          scopedSlots: { customRender: 'id' },
        },
        {
          title: '公司名称',
          dataIndex: 'abbreviation',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: '操作',
          key: 'action',
          width: 100,
          scopedSlots: { customRender: 'action' },
        },
      ],
    }
  },
  created() {
  },
  methods: {
    info(scope) {
      console.log(scope.record)
      // 合并auth_b和auth_c
      const authB = this.$store.state.userInfo?.auth_b || []
      this.$store.commit('setAuthB', [...authB, ...scope.record.auth_c])
      this.$store.commit('setEnterpriseId', scope.record.owner_id)
      this.$router.push({name: 'companyProjectList', query: {id: scope.record.owner.id}})
      
    }
  }
}
</script>

<style scoped>
.list {
  background: #fff;
  border-radius: 10px;
}
</style>