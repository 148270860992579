<template>
  <div class="copyright-font">
    <a-page-header
      title="3D版权"
    />
    <div class="edit">
      <div>
        <a-checkbox  :indeterminate="indeterminate" :checked="checkAll" @change="onCheckAllChange"></a-checkbox>
        <a-button type="default" size="small" style="margin: 0 10px;" v-downZip="download3DList" @click="download3DMaterial"  v-power="'copyright/material/download'">下载</a-button>
        <a-popconfirm
          title="是否确认删除?"
          ok-text="是"
          cancel-text="否"
          @confirm="delete3DMaterial"
        >
          <a-button type="danger" size="small">删除</a-button>
        </a-popconfirm>
      </div>
      <a-space>
        <a-input placeholder="请输入关键字" v-model="keywords" />
        <a-button shape="circle" icon="search" @click="searchFun"></a-button>
        <a-button type="primary" @click="create3DCopy" v-power:disabled="'copyright/material/create'">
          新增3D版权
        </a-button>
      </a-space>
    </div>
    <a-spin :spinning="isloading">
    <a-row type="flex" justify="center" style="margin-top: 20px">
      <a-col :span="23">
        <ul class="img-list">
          <a-empty v-if="font3DList.length==0" style="margin: 0 auto;"/>
          <li v-for="item,i in font3DList" :key="i" class="img-item" >
            <img :src="item.screenshot" @click="handleCopyrightDetails(item)" alt="">
            <div class="img-handle">
              <p>{{item.name}}</p>
              <div class="box">
                <a-checkbox @change="onChange" :checked="checkedList.indexOf(item.id)>-1" :value="item.id"></a-checkbox>
                <span>下载次数: {{item.download_num}}</span>
                <a-popover>
                  <template slot="content">
                    <div>
                      <a-button type="primary" block @click="change3DMaterial(item)" size="small" style="margin-bottom: 10px;">
                      编辑
                    </a-button>
                    </div>
                    <a-button type="default" size="small" block v-down="{url:item.file,name:item.name}" @click="download3DMaterial(item)" v-power:disabled="'copyright/material/download'" style="margin-bottom: 10px;">
                      下载
                    </a-button>
                    <a-divider />
                    <a-popconfirm
                      title="是否确认删除?"
                      ok-text="是"
                      cancel-text="否"
                      @confirm="delete3DMaterial(item)"
                    >
                      <a-button type="danger" size="small">删除</a-button>
                    </a-popconfirm>
                  </template>
                  <span class="ellipsis">
                    <a-icon type="ellipsis" />
                  </span>
                </a-popover>
              </div>
            </div>
          </li>
        </ul>
      </a-col>
    </a-row>
  </a-spin>
    <a-pagination
      v-model="current"
      show-quick-jumper
      :page-size-options="['10', '20', '30', '40', '50']"
      :total="counts"
      show-size-changer
      :page-size="pageSize"
      @showSizeChange="onShowSizeChange"
    >
      <template slot="buildOptionText" slot-scope="props">
        <span v-if="props.value !== '50'">{{ props.value }}条/页</span>
        <span v-if="props.value === '50'">全部</span>
      </template>
    </a-pagination>

    <!-- 弹窗 -->
    <a-modal v-model="edit3D" title="3D版权" :closable="!loading" :maskClosable="!loading" @cancel="closeUpload">
      <div class="label label1" v-if="user.operator">
        <span>操作人：{{ user.operator.user.name }}</span>
        <span>下载次数:{{ user.download_num }}</span>
      </div>
      <div class="label label1" v-if="user.operator">
        <span>{{user.operator.updated_at}}更新</span>
        <span>{{user.created_at}}创建</span>
      </div>
      <div class="label">
        <span>3D素材名称：</span>
        <a-input v-model="materialName" :disabled="isdisablad" style="width: 70%" />
      </div>
      <div class="label">
        <span>3D素材公司：</span>
        <a-input v-model="materialCompany" style="width: 70%" />
      </div>
      <div class="label">
        <span>3D素材文件：</span>
          <a-upload
          name="file"
          :multiple="false"
          :data="{file_name:1}"
          action="#"
          :maxCount="2"
          :fileList="fileList"
          :beforeUpload="handleBeforeUpload"
          :customRequest="handleChangeFile"
        >
        <a-button v-if="fileList.length==0" > <a-icon type="upload"/>上传压缩文件</a-button>
        <a-button v-else> <a-icon type="upload" />重新上传</a-button>
        <a-progress :percent="progressNum" strokeColor="red" style="width: 200%;" v-if="progressNum>0&&progressNum<100" />
      </a-upload>
      </div>
      <div class="label">
        <span>素材截图：</span>
        <a-upload
        name="file"
        
        list-type="picture-card"
        class="avatar-uploader"
        :show-upload-list="false"
        :action="api.uploadUrl"
        :data="{file_name:1}"
        :maxCount="1"
        @change="handleChangeImg"
      >
        <img v-if="imageUrl" :src="imageUrl" class="avatar" alt="avatar" />
        <div v-else>
          <a-icon type="plus" />
          <div class="ant-upload-text">
            上传图片
          </div>
        </div>
      </a-upload>
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" :loading="loading"  @click="create3DMaterial
" >保存</a-button>
        <a-button key="back" @click="closeUpload" style="margin-left: 10px;">取消</a-button>
      </template>
    </a-modal>
    <!-- 素材详情弹框 -->
    <a-modal v-model="edit3DDetails" width="1012px" :footer="null">
      <div class="details-content flex align-items-center">

        <div class="details-left">
          <img :src="copyrightDetails.screenshot" alt="">
        </div>
        <div class="details-right flex flex-direction-column justify-content-between">
          <div>

            <p class="details-headline">{{ copyrightDetails.name }}</p>
            <div class="details-message">
              <p class="flex align-items-center justify-content-between" v-if="copyrightDetails.operator"><span>创建人：</span><span>{{ copyrightDetails.operator.user.name }}</span></p>
              <p class="flex align-items-center justify-content-between"><span>素材公司：</span><span>{{ copyrightDetails.material_company }}</span></p>
              <p class="flex align-items-center justify-content-between"><span>图片尺寸：</span><span>{{ detailsInfo.width}} * {{ detailsInfo.height}}</span></p>
              <p class="flex align-items-center justify-content-between"><span>下载次数：</span><span>{{ copyrightDetails.download_num }}</span></p>
              <p class="flex align-items-center justify-content-between"><span>创建时间：</span><span>{{copyrightDetails.created_at}}</span></p>
            </div>
          </div>
          <div class="details-btn flex align-items-center justify-content-end">
            <a-button key="submit" type="primary"  block v-down="{url:copyrightDetails.file,name:copyrightDetails.name}" @click="download3DMaterial(copyrightDetails)" v-power:disabled="'copyright/material/download'" style="width: 65px;">
              下载
            </a-button>
            <a-button key="back" @click="edit3DDetails = false" style="margin-left: 10px;">取消</a-button>

          </div>
        </div>
      </div>
    </a-modal>
  </div> 
</template>

<script>
export default {
  props: ['companyId'],
  data() {
    return {
      progressNum:0,
      keywords:'',
      edit3D: false, //新增或编辑弹窗
      isloading:false,  //是否展示加载
      loading: false,  //上传图片的按钮icon展示
      imageUrl: '',  //素材截图
      current: 1,  //分页
      pageSize: 10,  //分页
      font3DList:[],  //列表数据
      counts:0,  //数据总数
      materialName:'', //素材名称
      materialCompany:'',  //素材公司
      fileList:[], //素材文件列表
      user:{}, //上传素材的人
      materialId:'',  //编辑id
      indeterminate:false, //是否是半选
      checkAll:false, //全选
      checkedList:[], //单选列表
      plainOptions :[],  //当前页的所有数据id
      copy3DSize:0, //素材大小
      download3DList:[],  //多个下载文件列表
      repetition:true, //防止重复点击
      copyrightDetails:{},//单个素材详情
      edit3DDetails:false,//素材详情弹框
      detailsInfo:{width:0,height:0},
      client:null,
      isdisablad:true
    }
  },
  created(){
    this.isloading = true
    this.get3DFontList()
  },
  methods: {
    closeUpload(){
      this.edit3D = false
      this.loading = false
      this.isdisablad = true
    },
    handleBeforeUpload(){
      this.loading = true
    },
    handleCopyrightDetails(data){
      console.log(data)
      let img = new Image()
      img.src = data.screenshot
      img.onload = ()=>{
        console.log(img.width,img.height)
        this.detailsInfo.width = img.width
        this.detailsInfo.height = img.height
        this.copyrightDetails = data
        this.edit3DDetails = true
      }
    },
    // 搜索
    searchFun(){
      this.current = 1
      this.isloading = true
      this.get3DFontList()
    },
    async getststoken(){
      let data = null
      await this.api.STSToken().then((res)=>{
        if(res.code==0){
          data = res.data.data
        }
      })
      return data
    },
    // 获取client
    async getClicent(){
      let data = await this.getststoken();    //后台返回的OSS秘钥等等
      this.client = new window.OSS({
          region: 'oss-cn-beijing',
          accessKeyId: data.access_key_id,
          accessKeySecret: data.access_key_secret,
          stsToken: data.security_token,
          bucket: 'dnm-xiaoxxx'
      });
    },
    // 下载
    download3DMaterial(data){
      if(this.repetition){
        this.repetition = false
        this.api.copyright3DDownload({
          id:data.id?data.id:this.checkedList.join(',')
        }).then((res)=>{
          if(res.code==0){
            this.repetition = true
            this.edit3DDetails = false
            this.get3DFontList()
          }
        })
      }
    },
    // 单选
    onChange(e) {
        if(!e.target.checked){
          this.checkedList.splice(this.checkedList.indexOf(e.target.value),1)
        }else{
          this.checkedList.push(e.target.value)
        }
        this.checkAll = this.checkedList.length === this.plainOptions.length?true:false
        this.indeterminate =(this.checkedList.length === this.plainOptions.length)?false:this.checkedList.length>0?true:false
    },
    // 全选
    onCheckAllChange(e) {
      // console.log(e)
      this.checkAll =e.target.checked
      this.indeterminate = false
      this.checkedList = e.target.checked ? this.plainOptions : []
      this.download3DList = []
      this.font3DList.map((v)=>{
        this.download3DList.push({name:v.name,url:v.file})
      })
    },
    // 跳转页面
    onShowSizeChange(current, pageSize) {
      this.pageSize = pageSize;
    },
    // 获取3d版权列表
    get3DFontList(){
      if(this.repetition){
        this.repetition = false
        let obj={
          page:this.current,
          pageSize:this.pageSize,
          keywords:this.keywords
        }
        if(!this.$store.state.party){
          obj['company_id'] = this.companyId
        }
        this.api.copyright3DList(obj).then((res)=>{
          // console.log(res)
          this.isloading = false
          if(res.code==0){
            this.repetition = true
            this.font3DList = res.data.lists
            this.counts = res.data.allCounts
            this.checkAll = false
            this.indeterminate = false
            this.checkedList = []
            let arr = []
            this.font3DList.map((v)=>{
              arr.push(v.id)
            })
            this.plainOptions = arr
            // console.log(this.plainOptions)
          }
        })
      }
    },
    // 新增3d版权
    create3DCopy(){
      this.getClicent()
      this.edit3D = true
      this.materialName = ''
      this.materialCompany = ''
      this.imageUrl = ''
      this.user = {}
      this.fileList = []
      this.materialId = ''
      this.copy3DSize = 0
      this.checkedList = []
    },
    // 获得随机字符
    randomString(num) {
      const chars = ["0","1","2","3","4","5","6","7","8","9","a","b","c","d","e","f","g","h","i","j","k","l","m","n","o","p","q","r","s","t","u","v","w","x","y","z"];
      let res = "";
      for (let i = 0; i < num; i++) {
          var id = Math.ceil(Math.random() * 35);
          res += chars[id];
      }
      return res;
    },
    // 上传3d版权文件
    async handleChangeFile(file){
      console.log('文件',file,this.client)
      // if(fileList[fileList.length-1].response){
      //   if (fileList[fileList.length-1].response.code == 0) {
      //     this.fileList = [fileList[fileList.length-1]]
      //     this.copy3DSize = fileList[fileList.length-1].size
      //     //  console.log('23')
      //     this.loading = false
      //   }
      // }
      // this.fileList = [fileList[fileList.length-1]]
      // this.fileList = fileList
      const dateTime = new Date().getTime() // 当前时间
      const randomStr = this.randomString(4); //  4位随机字符串
      const extensionName = file.file.name.split('.');
      // const extensionName = file.name.substr(file.name.indexOf("."));
      const fileName = "design-dsgomo/" + dateTime + "_" + randomStr +'.'+ extensionName[extensionName.length-1];
      try {
        console.log('1')
        let result = await this.client.multipartUpload(fileName, file.file, {
          progress: async  (p) =>{//这是上传进度条
            // console.log('55',parseInt(p * 100))
            this.progressNum = parseInt(p * 100)
            // if(this.oncancel){
            //   this.progressNum = 0
            //   this.oncancel = false 
            //   this.client.cancal()
            // }
            // content.onProgress({percent: parseInt(p * 100)+'%', returnValue: true });
          }
        });
        console.log('3')
        return (file['response'] = [result].map(curr =>{//on-change回调的参数
          console.log('4',curr)
          this.loading = false
          this.isdisablad = false
          // this.form.size = (file.size / 1024 / 1024).toFixed(1)
          let url = curr.res.requestUrls[0].split('?')[0]
          this.materialName = extensionName[0]
          // let url2 = 'https://' + url.split('://')[1]
          // console.log(url2)
          this.copy3DSize = file.file.size
          // this.form.fileUrl = url2
          this.fileList = [{url:url,name:file.file.name,uid:new Date().getTime()}]
        })[0]);

      } catch (e) {
        console.log(e)
        if (e.code === 'ConnectionTimeoutError') {
          this.message.error('文件上传超时')
          throw "视频上传超时!";
        }else{
          this.message.error('文件上传失败,请刷新页面重新上传')
        }
      }
    },
    // 上传3d版权截图
    handleChangeImg({ fileList }){
      // console.log('截图',fileList.length-1,fileList)
      if(fileList[fileList.length-1].response){
        if (fileList[fileList.length-1].response.code == 0) {
          this.imageUrl = fileList[fileList.length-1].response.data.url;
        }
      }
    },
    // 新增或修改3d版权
    create3DMaterial(){
      if(this.materialName=='' || this.materialCompany==''|| this.fileList[0]=='' ||this.imageUrl==''){
        this.message.error('信息不能为空')
        return
      }
      if(this.repetition){
        this.repetition = false
        if(this.materialId){
          this.api.copyright3DUpdate({
            name:this.materialName,
            material_company:this.materialCompany,
            file:this.fileList[0].url,
            screenshot:this.imageUrl,
            id:this.materialId,
            size:this.copy3DSize
          }).then((res)=>{
            // console.log(res)
            if(res.code==0){
              this.current = 1
              this.repetition = true
              this.get3DFontList()
              this.edit3D = false
              window.location.reload()
            }
          })
        }else{
          let obj = {
            name:this.materialName,
            material_company:this.materialCompany,
            file:this.fileList[0].url,
            screenshot:this.imageUrl,
            size:this.copy3DSize
          }
          if(!this.$store.state.party){
            obj['company_id'] = this.companyId
          }
          this.api.copyright3DCreate(obj).then((res)=>{
            // console.log(res)
            if(res.code==0){
              this.current = 1
              this.repetition = true
              this.get3DFontList()
              this.edit3D = false
            }
          })
        }
      }
    },
    // 获取3d版权详情
    change3DMaterial(data){
      this.getClicent()
      // this.api.copyright3DDetails({
      //   id:data.id
      // }).then((res)=>{
      //   if(res.code==0){
          this.materialName = data.name
          this.materialCompany = data.material_company
          this.imageUrl = data.screenshot
          this.user = data
          this.fileList = [{
            name:data.name,
            url:data.file,
            uid:'-'+data.id
          }]
          this.materialId = data.id
          this.edit3D = true
      //   }
      // })
    },
    // 删除
    delete3DMaterial(data){
      if(this.repetition){
        this.repetition = false
        this.api.copyright3DDetele({
          id:data.id?data.id:this.checkedList.join(',')
        }).then((res)=>{
          // console.log(res)
          if (res.code == 0) {
            this.materialId = ''
            this.message.success(res.message)
            this.current = 1
            this.repetition = true
            this.get3DFontList()
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.ant-progress-bg{
  height: 5px !important;
}
.edit {
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 20px;
}
.details-left{
  width: 553px;
  height: 600px;
  margin-right: 22px;
}
.details-left img{
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
}
.details-right{
  width: 423px;
  height: 600px;
  /* box-sizing: border-box;
  padding: 30px 0 37px; */
}
.details-headline{
  padding: 25px 25px 16px;
  font-size: 22px;
  color: #333;
  border-bottom: 1px solid #f0f0f0;
}
.details-message{
  padding: 25px 25px;
  /* border-bottom: 1px solid #f0f0f0; */
}
.details-btn{
  margin-top: 30px;
  box-sizing: border-box;
  padding: 0 0 0 025px;
}
.details-message p{
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 24px;
  color: #999;
}
.label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.label span {
  width: 100px;
  margin-right: 20px;
}
.label1 span{
  width: auto;
}
.img-list {
  display: flex;
  flex-wrap: wrap;
}
.img-item {
  width: 16.5%;
  height: 300px;
  box-shadow: 1px 1px 5px 1px #ddd;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
}
.img-item:nth-child(5n) {
  margin-right: 0;
}
.img-item img {
  width: 70%;
  height: 200px;
  object-fit: cover;
  display: block;
  margin: 15px auto;
}
.img-handle {
  border-top: 1px solid #ccc;
  box-sizing: border-box;
  padding: 10px 6px;
}
.img-handle .box {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.ellipsis {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
}
.sync-img-item {
  width: 22.5%;
  height: 250px;
  box-shadow: 1px 1px 5px 1px #ddd;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.sync-img-item img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  display: block;
  margin: 0 auto;
}
.ant-divider-horizontal {
  margin: 0;
}
.drawer-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
  z-index: 1;
}
.avatar{
  width: 100%;
}
</style>