<template>
  <div class="copyright">
    <a-layout style="padding: 24px 0; background: #fff">
      <!-- <a-layout-sider width="200" style="background: #fff">
        <a-menu
          mode="inline"
          :default-selected-keys="['copyrightFont']"
          style="height: 100%"
        >
          <a-menu-item key="copyrightFont" @click="linkTo('copyrightFont')">
            字体版权
          </a-menu-item>
          <a-menu-item key="copyright3D" @click="linkTo('copyright3D')">
            3D素材版权
          </a-menu-item>
        </a-menu>
      </a-layout-sider> -->
      <a-layout-content :style="{ padding: '0 24px', minHeight: '280px' }">
        <router-view></router-view>
      </a-layout-content>
    </a-layout>
  </div>
</template>

<script>
export default {
  methods: {
    linkTo(name) {
      this.$router.push({ name: name })
    }
  }
}
</script>

<style scoped>
.copyright {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
}
</style>