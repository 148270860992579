<template>
  <div class="info">
    <a-layout style="padding: 24px 0; background: #fff">
      <!-- <a-layout-sider width="200" style="background: #fff">
        <a-menu mode="inline" :default-selected-keys="['capacity']" style="height: 100%" @click="handleClick">
          <a-menu-item key="capacity">
            容量管理
          </a-menu-item>
          <a-menu-item key="setupbasic">
            企业基本信息
          </a-menu-item>
          <a-menu-item key="member">
            成员角色
          </a-menu-item>
          <a-menu-item key="partyb">
            关联信息
          </a-menu-item>
          <a-menu-item key="personal">
            个人信息
          </a-menu-item>
        </a-menu>
      </a-layout-sider> -->
      <a-layout-content :style="{ padding: '0 24px', minHeight: '200px' }">
        <router-view></router-view>
      </a-layout-content>
    </a-layout>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      addVisible: false
    }
  },
  methods: {
    handleClick(e) {
      // console.log('click', e);
      this.$router.push({ name: e.key })
    },
  }
}
</script>

<style scoped>
.info {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
}
</style>