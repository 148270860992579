<template>
  <div class="system">
    <div class="title">
      <span>系统公告</span>
      <!-- <a-button type="link" @click="routerTo">查看全部</a-button> -->
    </div>
    <div class="content">
      <Table v-if="data.system" :tableData="data.system.slice(0,5)" :columns="columns" :paginationShow="false">
        <div slot="user" slot-scope="scope">
          <span v-if="scope.record.user_info">{{scope.record.user_info.name}}</span>
          <span v-else><a-icon type="smile" /></span>
        </div>
        <div slot="behavior" slot-scope="scope">
          <span v-if="scope.record.behavior == 1">加入空间</span>
          <span v-if="scope.record.behavior == 2">移除空间</span>
          <span v-if="scope.record.behavior == 3">关联成功</span>
        </div>
        <div slot="company" slot-scope="scope">
          {{scope.record.company.name || '/'}}
        </div>
      </Table>
    </div>
  </div>
</template>

<script>
export default {
  props: ['data'],
  data() {
    return {
      columns: [
        {
          title: 'id',
          dataIndex: 'id',
          width: 60,
        },
        {
          title: '用户',
          dataIndex: 'user',
          scopedSlots: { customRender: 'user' },
        },
        {
          title: '动态',
          dataIndex: 'behavior',
          scopedSlots: { customRender: 'behavior' },
          width: 100,
        },
        {
          title: '公司',
          dataIndex: 'company',
          scopedSlots: { customRender: 'company' },
        },
        {
          title: '时间',
          dataIndex: 'created_at',
          width: 170,
        },
      ]
    }
  },
  methods: {
    routerTo() {
      this.$router.push({name: 'notice', query: {key: '2'}})
    }
  }
}
</script>

<style scoped>
.system {
  background: #fff;
  border-radius: 10px;
}
.title {
  height: 50px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 0 10px 15px;
}
.content {
  min-height: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px;
}
.img {
  width: 100px;
}
</style>