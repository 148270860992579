<template>
  <div class="picture">
    <a-form-model :model="form" layout="inline" style="margin: 10px 24px 20px 24px">
      <a-form-model-item label="关键字">
        <a-input v-model="form.keywords" />
      </a-form-model-item>
      <a-form-model-item label="创建日期">
        <a-range-picker @change="onChange" />
      </a-form-model-item>
      <a-form-model-item>
        <a-button shape="circle" icon="search" @click="getList"></a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click="reset">重置</a-button>
      </a-form-model-item>
    </a-form-model>

    <div class="edit">
      <div>
        <a-checkbox :checked="allChecked" :indeterminate="indeterminate" @change="onCheckAllChange">全选</a-checkbox>
        <a-popconfirm
          title="将所选内容还原至原项目"
          ok-text="是"
          cancel-text="否"
          @confirm="restore()"
        >
          <a-button type="link">还原</a-button>
        </a-popconfirm>
        <a-popconfirm
          title="永久删除所选内容，该操作不可撤销"
          ok-text="是"
          cancel-text="否"
          @confirm="del()"
        >
          <a-button type="link">永久删除</a-button>
        </a-popconfirm>
      </div>
    </div>

    <a-row type="flex" justify="center" style="margin-top: 20px">
      <a-col :span="23">
        <ul class="img-list">
          <li v-for="item in list" :key="item.id" class="img-item">
            <p class="img-name">{{item.name || '暂无名称'}}</p>
            <img :src="item.url" alt="">
            <div class="img-handle">
              <a-checkbox :checked="checkList.map(i => i.id).indexOf(item.id) > -1" @change="onCheckChange(item)"></a-checkbox>
              <span style="margin-left: 15px">{{item.number}}</span>
              <div>
                {{item.deleted_at}} 删除
                <a-popover>
                  <template slot="content">
                    <a-popconfirm
                      title="将所选内容还原至原项目"
                      ok-text="是"
                      cancel-text="否"
                      @confirm="restore(item.id)">
                      <a-button type="link" block>还原</a-button>
                    </a-popconfirm>
                    <a-divider />
                    <a-popconfirm
                      title="永久删除所选内容，该操作不可撤销"
                      ok-text="是"
                      cancel-text="否"
                      @confirm="del(item.id)">
                      <a-button type="link" block>永久删除</a-button>
                    </a-popconfirm>
                  </template>
                  <span class="ellipsis">
                    <a-icon type="ellipsis" />
                  </span>
                </a-popover>
              </div>
            </div>
          </li>
        </ul>
      </a-col>
    </a-row>
    <a-pagination
      v-model="current"
      show-quick-jumper
      :page-size-options="['10', '20', '30', '40', '50']"
      :total="total"
      show-size-changer
      :page-size="pageSize"
      @change="onPageChange"
      @showSizeChange="onShowSizeChange"
    >
      <template slot="buildOptionText" slot-scope="props">
        <span v-if="props.value !== '50'">{{ props.value }}条/页</span>
        <span v-if="props.value === '50'">全部</span>
      </template>
    </a-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      form: {
        keywords: '',
        start_time: '',
        end_time: ''
      },
      list: [],
      current: 1,
      pageSize: 30,
      total: 0,
      checkList: [],
      indeterminate: false,
      allChecked: false
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.api.recyclePictureLists({
        page: this.current,
        pageSize: this.pageSize,
        ...this.form
      }).then(res => {
        if (res.code == 0) {
          this.loading = false
          this.list = res.data.lists
          this.total = res.data.allCounts
        }
      })
    },
    reset() {
      this.form = {
        keywords: '',
        start_time: '',
        end_time: ''
      }
    },
    // 时间选择
    onChange(date, dateString) {
      console.log(date, dateString);
      this.form.start_time = dateString[0]
      this.form.end_time = dateString[1]
    },
    onPageChange(page) {
      this.current = page
      // this.checkList = []
      // this.indeterminate = false
      // this.allChecked = false
      this.getList()
    },
    onShowSizeChange(current, pageSize) {
      this.pageSize = pageSize;
    },
    // 还原
    restore(id) {
      if (id) {
        this.api.recyclePictureRestore({id: id}).then(res => {
          if (res.code == 0) {
            this.message.success(res.message)
            this.getList()
          } else {
            this.message.error(res.message);
          }
        })
      } else {
        let ids = this.checkList.map(i => i.id)
        this.api.recyclePictureRestore({id: ids}).then(res => {
          if (res.code == 0) {
            this.message.success(res.message)
            this.getList()
          } else {
            this.message.error(res.message);
          }
        })
      }
    },
    del(id) {
      if (id) {
        this.api.recyclePictureDel({id: id}).then(res => {
          if (res.code == 0) {
            this.message.success(res.message)
            this.getList()
          } else {
            this.message.error(res.message);
          }
        })
      } else {
        let ids = this.checkList.map(i => i.id)
        this.api.recyclePictureDel({id: ids}).then(res => {
          if (res.code == 0) {
            this.message.success(res.message)
            this.getList()
          } else {
            this.message.error(res.message);
          }
        })
      }
    },
    // 多选全选
    onCheckAllChange(e) {
      console.log(e)
      this.allChecked = e.target.checked
      if (e.target.checked) {
        this.indeterminate = false
        this.checkList = [...this.list]
      } else {
        this.checkList = []
        if (this.checkList.length > 0) {
          this.indeterminate = true
        } else {
          this.indeterminate = false
        }
      }
    },
    // 多选单选
    onCheckChange(item) {
      let index = this.checkList.map(i => i.id).indexOf(item.id)
      if (index > -1) {
        this.checkList.splice(index, 1)
      } else {
        this.checkList.push(item)
      }
      if (this.checkList.length > 0) {
        if (this.list.length == this.checkList.length) {
          this.allChecked = true
          this.indeterminate = false 
        } else {
          this.allChecked = false
          this.indeterminate = true 
        }
      } else {
        this.indeterminate = false
        this.indeterminate = false 
      } 
    },
  }
}
</script>

<style scoped>
.result {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
}
.edit {
  display: flex;
  justify-content: space-between;
  padding: 0 24px
}
.btns {
  width: 300px;
  display: flex;
  justify-content: space-between;
  margin: 50px auto;
}
.img-list {
  display: flex;
  flex-wrap: wrap;
}
.img-item {
  width: 16.5%;
  /* height: 300px; */
  box-shadow: 1px 1px 5px 1px #ddd;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
}
.img-item:nth-child(5n) {
  margin-right: 0;
}
.img-item img {
  width: 70%;
  height: 200px;
  object-fit: cover;
  display: block;
  margin: 15px auto;
}
.img-name {
  width: 90%;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 10px auto;
}
.img-handle {
  border-top: 1px solid #ccc;
  box-sizing: border-box;
  padding: 10px 6px;
}
.img-handle div {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.ellipsis {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
}
.sync-img-item {
  width: 22.5%;
  height: 250px;
  box-shadow: 1px 1px 5px 1px #ddd;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.sync-img-item img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  display: block;
  margin: 0 auto;
}
.ant-divider-horizontal {
  margin: 0;
}
.drawer-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
  z-index: 1;
}
</style>