<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-10-12 17:21:29
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-10-14 16:51:39
 * @FilePath: \h5case4.xiaoxxx.cne:\code\仙助数字资产管理平台\digital\src\views\login\login.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="main">
    <div class="main-bg"></div>
    <div class="content">
      <!-- 页面标题 -->
      <div class="title">
        <h1>仙助数字资产管理平台</h1>
        <p>轻松管理您的数字营销资产，告别杂乱无章的文件夹</p>
      </div>
      <!-- 登录 -->
      <div class="login" v-if="showContent == 'login'">
        <a-tabs :default-active-key="tabNum" @change="callback" :tabBarGutter="110">
          <a-tab-pane key="1" tab="密码登录">
            <a-form-model layout="inline" :model="formInline" @submit="loginSubmit" @submit.native.prevent>
              <a-form-model-item class="form-item">
                <a-input class="width-300" v-model="formInline.phone" placeholder="手机号">
                  <a-icon slot="prefix" type="user" style="color:rgba(0,0,0,.25)" />
                </a-input>
              </a-form-model-item>
              <a-form-model-item class="form-item">
                <a-input-password class="width-300" v-model="formInline.password" placeholder="密码" autocomplete="off">
                  <a-icon slot="prefix" type="lock" style="color:rgba(0,0,0,.25)" />
                </a-input-password>
              </a-form-model-item>
              <br>
              <a-form-model-item class="form-item">
                <div class="width-300 flex justify-content-between">
                  <a-checkbox @change="onChange">自动登录</a-checkbox>
                  <a-button class="padding-0" type="link" @click="showType('forgot')">忘记密码</a-button>
                </div>
              </a-form-model-item>
              <a-form-model-item class="form-item">
                <a-button class="width-300" type="primary" html-type="submit"
                  :disabled="formInline.phone === '' || formInline.password === ''">
                  登录
                </a-button>
              </a-form-model-item>
              <br>
              <a-form-model-item class="form-item">
                <div class="width-300 flex justify-content-end">
                  <a-button class="padding-0" type="link" @click="showType('register')">注册账户</a-button>
                </div>
              </a-form-model-item>
            </a-form-model>
          </a-tab-pane>
          <a-tab-pane key="2" tab="验证码登录" force-render>
            <a-form-model layout="inline" :model="formInline" @submit="handleSubmit" @submit.native.prevent>
              <a-form-model-item class="form-item">
                <a-input class="width-300" v-model="formInline.phone" placeholder="手机号">
                  <a-icon slot="prefix" type="user" style="color:rgba(0,0,0,.25)" />
                </a-input>
              </a-form-model-item>
              <a-form-model-item class="form-item">
                <div class="width-300 flex justify-content-between">
                  <a-input class="width-190 inline-block" v-model="formInline.code" placeholder="验证码">
                    <a-icon slot="prefix" type="mail" style="color:rgba(0,0,0,.25)" />
                  </a-input>
                  <a-button class="width-100 inline-block" @click="getSMSCode">{{ getcodetext }}</a-button>
                </div>
              </a-form-model-item>
              <br>
              <a-form-model-item class="form-item">
                <div class="width-300 flex justify-content-between">
                  <a-checkbox @change="onChange">自动登录</a-checkbox>
                  <a-button class="padding-0" type="link" @click="showType('forgot')">忘记密码</a-button>
                </div>
              </a-form-model-item>
              <a-form-model-item class="form-item">
                <a-button class="width-300" type="primary" html-type="submit"
                  :disabled="formInline.phone === '' || formInline.code === ''">
                  登录
                </a-button>
              </a-form-model-item>
              <br>
              <a-form-model-item class="form-item">
                <div class="width-300 flex justify-content-end">
                  <a-button class="padding-0" type="link" @click="showType('register')">注册账户</a-button>
                </div>
              </a-form-model-item>
            </a-form-model>
          </a-tab-pane>
        </a-tabs>
      </div>
      <!-- 注册 -->
      <div class="register" v-if="showContent == 'register'">
        <a-form-model-item class="form-item">注册</a-form-model-item>
        <a-form-model-item class="form-item">
          <a-input-group compact>
            <a-input style="width: 20%" default-value="+86" disabled />
            <a-input style="width: 80%" default-value="" v-model="formInline.phone" placeholder="11位手机号" />
          </a-input-group>
        </a-form-model-item>
        <a-form-model-item class="form-item">
          <a-popover v-model="visible" trigger="click" placement="rightTop">
            <template slot="content">
              <div class="intensity">
                <span class="psdText">密码强度</span>
                <span class="line" :class="[level.includes('low') ? 'low' : '']"></span>
                <span class="line" :class="[level.includes('middle') ? 'middle' : '']"></span>
                <span class="line" :class="[level.includes('high') ? 'high' : '']"></span>
                <div class="warningtext">
                  请至少输入 6 个字符。请不要使用容易被猜到的密码。
                </div>
              </div>
            </template>
            <a-input-password class="width-300" @change="checkPassword(formInline.password)" v-model="formInline.password"
              autocomplete="on" type="password" placeholder="6-16位密码，区分大小写"></a-input-password>
          </a-popover>
        </a-form-model-item>
        <a-form-model-item class="form-item">
          <a-input-password class="width-300" v-model="formInline.passwordConfirm" type="password" placeholder="确认密码"
            autocomplete="on"></a-input-password>
        </a-form-model-item>
        <a-form-model-item class="form-item">
          <div class="width-300 flex justify-content-between">
            <a-input class="width-190 inline-block" v-model="formInline.code" placeholder="输入验证码">
            </a-input>
            <a-button class="width-100 inline-block" @click="getSMSCode">{{ getcodetext }}</a-button>
          </div>
        </a-form-model-item>
        <a-form-model-item class="form-item">
          <div class="width-300 flex justify-content-between">
            <a-button class="width-130" type="primary" html-type="submit"
              :disabled="formInline.phone === '' || formInline.password === '' || formInline.code === ''"
              @click="registerUser">
              注册
            </a-button>
            <a-button class="padding-0" type="link" @click="showType('login')">使用已有帐户登录</a-button>
          </div>
        </a-form-model-item>
      </div>
      <!-- 忘记密码 -->
      <div class="forgot" v-if="showContent == 'forgot'">
        <a-form-model-item class="form-item">忘记密码</a-form-model-item>
        <a-form-model-item class="form-item">
          <a-input-group compact>
            <a-input style="width: 20%" default-value="+86" disabled />
            <a-input style="width: 80%" default-value="" v-model="formInline.phone" placeholder="11位手机号" />
          </a-input-group>
        </a-form-model-item>
        <a-form-model-item class="form-item">
          <a-popover v-model="visible" trigger="click" placement="rightTop">
            <template slot="content">
              <div class="intensity">
                <span class="psdText">密码强度</span>
                <span class="line" :class="[level.includes('low') ? 'low' : '']"></span>
                <span class="line" :class="[level.includes('middle') ? 'middle' : '']"></span>
                <span class="line" :class="[level.includes('high') ? 'high' : '']"></span>
                <div class="warningtext">
                  请至少输入 6 个字符。请不要使用容易被猜到的密码。
                </div>
              </div>
            </template>
            <a-input-password class="width-300" @change="checkPassword(formInline.password)" v-model="formInline.password"
              autocomplete="off" type="password" placeholder="6-16位密码，区分大小写"></a-input-password>
          </a-popover>
        </a-form-model-item>
        <a-form-model-item class="form-item">
          <a-input-password class="width-300" v-model="formInline.passwordConfirm" type="password" placeholder="确认密码"
            autocomplete="off"></a-input-password>
        </a-form-model-item>
        <a-form-model-item class="form-item">
          <div class="width-300 flex justify-content-between">
            <a-input class="width-190 inline-block" v-model="formInline.code" placeholder="输入验证码">
            </a-input>
            <a-button class="width-100 inline-block" @click="getSMSCode">获取验证码</a-button>
          </div>
        </a-form-model-item>
        <a-form-model-item class="form-item">
          <div class="width-300 flex justify-content-between">
            <a-button class="width-130" type="primary" html-type="submit"
              :disabled="formInline.phone === '' || formInline.password === '' || formInline.code === ''"
              @click="forgotUser">
              确认修改
            </a-button>
            <a-button class="padding-0" type="link" @click="showType('login')">返回</a-button>
          </div>
        </a-form-model-item>
      </div>
      <!-- 注册/修改成功 -->
      <div class="succeed" v-if="showContent == 'registerSucceed' || showContent == 'forgotSucceed'">
        <a-form-model-item class="form-item ">
          <div class="width-300">
            <a-icon class="successIcon" type="check-circle" theme="filled" />
          </div>
        </a-form-model-item>
        <a-form-model-item class="form-item width-300">
          <div class="width-300 text-align-center color-black font-weight-bold">
            你的帐户：{{ formInline.phone }} {{ succeedText }}成功
          </div>
        </a-form-model-item>
        <a-form-model-item class="form-item width-300">
          <div class="width-300 text-align-center color-427">
            即将跳转至登录页面
          </div>
        </a-form-model-item>
      </div>
    </div>
    <!-- 页面底部信息 -->
    <footerBox />
    <div class="userType">
      <a-radio-group v-model="party" @change="partyChange" size="small">
        <a-radio-button :value="1">
          甲方
        </a-radio-button>
        <a-radio-button :value="0">
          乙方
        </a-radio-button>
      </a-radio-group>
    </div>
  </div>
</template>

<script>
import footerBox from "@/components/footer.vue";
export default {
  components: {
    footerBox,
  },
  data() {
    return {
      party: this.$store.state.party,
      showContent: 'login', // 当前所展示模块
      formInline: {
        phone: '', // 手机号
        password: '', // 密码
        passwordConfirm: '', // 二次校验密码
        code: '', // 短信验证码
      }, // 表单信息
      visible: false, // 注册时，是否显示密码强度
      level: [], // 强度等级
      succeedText: '',//成功页面文案
      tabNum: '1', // 默认登陆模块，1:密码登录，2:验证码登录
      getcodetextnum: 0,
      getcodetext: "获取验证码",
      snedSmstype: '1',// 获取验证码接口类型判断，1:不验证账号是否存在，2：验证账号是否存在
    };
  },
  mounted() {
  },
  methods: {
    partyChange(e) {
      this.$store.commit('setParty', e.target.value)
    },
    showType(val) {
      this.snedSmstype = (val == 'register') ? 1 : 2
      // this.formInline.phone = ''
      this.formInline.password = ''
      this.formInline.passwordConfirm = ''
      this.formInline.code = ''
      this.showContent = val
      console.log(val)
      console.log(val == 'register' ? '注册' : '登录', this.snedSmstype);
    },
    callback(key) {
      console.log('type', key)
      this.formInline.password = ''
      this.formInline.passwordConfirm = ''
      this.formInline.code = ''
      this.tabNum = key
    },
    loginSubmit() {
      console.log('this.formInline', this.formInline);
      this.api.login({
        mobile: this.formInline.phone,
        password: this.formInline.password,
        type: this.tabNum
      }).then(res => {
        console.log(res)
        if (res.code == 0) {
          // 跳转至后台首页
          console.log('跳转至后台首页');
          this.goToHome(res)
        }
      })
    },
    handleSubmit() {
      console.log('this.formInline', this.formInline);
      this.api.login({
        mobile: this.formInline.phone,
        code: this.formInline.code,
        type: this.tabNum
      }).then(res => {
        console.log(res)
        // 跳转至后台首页
        this.goToHome(res)
      })
    },
    goToHome(res) {
      window.localStorage.setItem('token', res.data.token)
      this.api.getUserInfo({}).then(res => {
        console.log('用户信息用户信息', res)
        window.localStorage.setItem('userInfo', JSON.stringify(res.data.userInfo))
        // if (res.data.userInfo.company_id == 0) {
        if (this.$store.state.inviteId) {
          console.log('vuex中有参数、跳转邀请信息页面');
          this.$router.replace({ name: 'create' })
        } else {
          if (res.data.userInfo.company_id == 0) {
            console.log('无参数、跳转填写个人信息页面');
            this.$router.replace({ name: 'create' })
          } else {
            console.log('res.data.userInfo.company.status', res.data.userInfo.company.status);
            if (res.data.userInfo.company.status == 0) {
              window.localStorage.setItem('auditingPageType', 'enterprise')
              this.$router.replace({ name: 'auditing' })
            } else if (res.data.userInfo.company.status == 1) {
              if (res.data.userInfo.status == 1) {
                // 1：审核通过
                this.$router.replace({ name: 'home' })
              } else if (res.data.userInfo.status == 2) {
                // 2：审核中
                window.localStorage.setItem('auditingPageType', 'individual')
                this.$router.replace({ name: 'auditing' })
              } else if (res.data.userInfo.status == 3) {
                // 3：已拒绝
                window.localStorage.setItem('auditingPageType', 'individual')
                this.$router.replace({ name: 'auditing' })
              }
            } else if (res.data.userInfo.company.status == 2) {
              window.localStorage.setItem('auditingPageType', 'enterprise')
              this.$router.replace({ name: 'auditing' })
            }
          }
        }
      })
    },
    onChange(e) {
      console.log(`checked = ${e.target.checked}`);
    },
    // 校验密码
    checkPassword(value) {
      this.level = []
      if (!value) {
        return console.log('密码不能为空');
      }
      if (value.length < 6) {
        return console.log('密码不少于6位');
      }
      if (value.length > 16) {
        return console.log('密码不大于16位');
      }
      // 低 校验是数字
      const regex1 = /^\d+$/
      // 低 校验字母
      const regex2 = /^[A-Za-z]+$/
      // 中 校验小写字母+数字 或者 大写字母+数字
      const regex3 = /^[A-Z\d]+$|[a-z\d]+$/
      // 高 校验大小写字母+数字
      const regex4 = /^[A-Za-z\d]+$/
      if (regex1.test(value)) {
        this.level.push('low')
      } else if (regex2.test(value)) {
        this.level.push('low')
      } else if (regex3.test(value)) {
        this.level.push('low')
        this.level.push('middle')
      } else if (regex4.test(value)) {
        this.level.push('low')
        this.level.push('middle')
        this.level.push('high')
      } else {
        return console.log('请输入数字与大小写字母');
      }
      return console.log(this.level);
    },
    // 获取验证码
    getSMSCode() {
      if (this.getcodetextnum !== 0) {
        console.log('获取验证码');
      } else {
        this.getcodetextnum = 60;
        this.getcodetext = this.getcodetextnum + "S";
        var getvc = setInterval(() => {
          if (this.getcodetextnum > 0) {
            this.getcodetextnum = this.getcodetextnum - 1;
            this.getcodetext = this.getcodetextnum + "S";
          } else {
            this.getcodetext = "获取验证码";
            clearInterval(getvc);
          }
        }, 1000);

        this.api.sendSms({
          mobile: this.formInline.phone,
          type: this.snedSmstype
        }).then(res => {
          console.log('res', res)
          if (res.code == 0) {
            alert(res.data.code)
          }
        })
      }
    },
    // 注册
    registerUser() {
      console.log('registerUser', this.formInline);
      if (this.formInline.passwordConfirm === this.formInline.password) {
        this.api.register({
          mobile: this.formInline.phone,
          password: this.formInline.password,
          code: this.formInline.code
        }).then(res => {
          if (res.code == 0) {
            this.showContent = 'registerSucceed'
            this.succeedText = '注册'
            setTimeout(() => {
              this.getcodetextnum = 0
              this.formInline.code = ''
              this.showContent = 'login'
            }, 2000);
          }
        })
      } else {
        this.$message.error('密码不一致');
      }
    },
    // 修改密码
    forgotUser() {
      if (this.formInline.passwordConfirm === this.formInline.password) {
        this.api.forget({
          mobile: this.formInline.phone,
          password_1: this.formInline.password,
          password_2: this.formInline.passwordConfirm,
          code: this.formInline.code
        }).then(res => {
          if (res.code == 0) {
            this.showContent = 'forgotSucceed'
            this.succeedText = '修改'
            setTimeout(() => {
              this.showContent = 'login'
            }, 2000);
          }
        })
      } else {
        this.$message.error('密码不一致');
      }
    },
  },
}
</script>

<style lang="less" scoped >
.main {
  position: relative;
  height: 100%;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
}

.main-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: url("//res.xiaoxxx.com/urlGather/2022110319f8ef41100991c0816186.jpeg") center no-repeat;
  background-size: cover;
  z-index: -1;
}

.title {
  text-align: center;
}

.title h1 {
  font-weight: 900;
  font-size: 32px;
  margin: 0 0 10px 0;
  white-space: nowrap;
}

.title p {
  color: rgba(0, 0, 0, 0.427);
  font-weight: 400;
  line-height: 22px;
  font-size: 14px;
  white-space: nowrap;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 500px;
}

.login,
.forgot,
.succeed,
.register {
  width: 300px;
  margin: 40px auto 0;
}

.form-item {
  position: relative;
  margin: 10px 0 0;
}

.width-300 {
  width: 300px;
}

.width-100 {
  width: 100px;
}

.width-130 {
  width: 130px;
}

.width-190 {
  width: 190px;
}

.margin-0 {
  margin: 0;
}

.padding-0 {
  padding: 0;
}

.inline-block {
  display: inline-block;
}

.intensity {
  .psdText {
    font-size: 14px;
    margin-right: 10px;
  }

  .line {
    display: inline-block;
    width: 48px;
    height: 4px;
    background: #d8d8d8;
    border-radius: 3px;
    margin-right: 8px;

    &.low {
      background: #f4664a;
    }

    &.middle {
      background: #ffb700;
    }

    &.high {
      background: #2cbb79;
    }
  }

  .level {
    margin: 0 16px 0 8px;
  }

  .warningtext {
    color: #5a5a5a;
    font-size: 12px;
    margin-top: 5px;
  }
}

button {
  line-height: 32px;
}

.successIcon {
  width: 100%;
  color: rgb(70, 189, 21);
  font-size: 40px;
  text-align: center;
}

.color-black {
  color: black;
  font-weight: bold;
}

.userType {
  position: fixed;
  top: 40px;
  right: 40px;
}
</style>